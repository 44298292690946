import { isPrimitive } from '@grafana/faro-core';
import { primitiveUnhandledType, primitiveUnhandledValue } from './const';
import { getErrorDetails } from './getErrorDetails';
export function registerOnunhandledrejection(api) {
  window.addEventListener('unhandledrejection', evt => {
    var _a, _b;
    let error = evt;
    if (error.reason) {
      error = evt.reason;
    } else if ((_a = evt.detail) === null || _a === void 0 ? void 0 : _a.reason) {
      error = (_b = evt.detail) === null || _b === void 0 ? void 0 : _b.reason;
    }
    let value;
    let type;
    let stackFrames = [];
    if (isPrimitive(error)) {
      value = `${primitiveUnhandledValue} ${String(error)}`;
      type = primitiveUnhandledType;
    } else {
      [value, type, stackFrames] = getErrorDetails(error);
    }
    if (value) {
      api.pushError(new Error(value), {
        type,
        stackFrames
      });
    }
  });
}
