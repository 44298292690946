export enum AppRoutes {
  CALLBACK = 'callback',
  REGISTER = 'register',
  TEMPLATES = 'templates',
  BROWSER_DEPRECATED = 'browser-deprecated',
  ACCESS_DENIED = 'access-denied',
  HOME = 'home',
  ACCOUNT = 'account',
  PATIENT = 'patient',
  SUPPORT = 'support',
  PET_OWNER = 'pet-owner',
  PET_PROFILE = 'pet-profile',
  PET_RECORD = 'pet-record',
  ORDER = 'order',
  ORDER_SUMMARY = 'order-summary',
  ORDER_RECORD = 'order-record',
  PRODUCTS = 'products',
  PRODUCTS_DAILY_ALLOWANCE = 'products/daily-allowance',
  SMART_RECO = 'smart-reco',
  FAST_RECO = 'fast-reco',
  RENAL_DETECT = 'renal-detect',
  DAILY_ALLOWANCE = 'daily-allowance',
  WEIGHT_MANAGEMENT = 'weight-management',
  MUF_PERSONALISED_BAG = 'muf-personalised-bag',
  MUF_STARTER_KIT = 'muf-starter-kit',
  NEW_PET = 'new-pet',
  ALLOWANCE = 'allowance',
  RECOMMENDATION = 'recommendation',
  SHARE_RECOMMENDATION = 'share-recommendation',
  HEALTH = 'health',
  NEW_WEIGHT_LOSS = 'new-weight-loss',
  STABILIZATION = 'stabilization',
  FOLLOW_UP = 'follow-up',
  PLAN_NEXT_VISIT = 'plan-next-visit',

  CONNECT_SUCCESS = 'connect-success',
  CONNECT_FAIL = 'connect-fail',
  CLINIC_INFO = 'clinic-info',
  CONNECTED = 'connected',
  SELECT_CLINIC = 'select-clinic',
  USED = 'used',
  TERMS = 'terms',
  CONTACT = 'contact',
  CONTACT_SUCCESS = 'contact-success',
  CONTACT_FAIL = 'contact-fail',
  MISSING_INFORMATION = 'missing-information',

  DAILY_ALLOWANCE_PO_TEMPLATE = 'daily-allowance-PO-template',
  WEIGHLOSS_PO_TEMPLATE = 'weight-loss-PO-template',
  DAILY_RENAL_DETECT_PO_TEMPLATE = 'daily-renal-detect-PO-template',
  SMART_RECO_PO_TEMPLATE = 'smart-reco-PO-template',
  FAST_RECO_PO_TEMPLATE = 'fast-reco-PO-template',
  RENAL_SMART_ECO_PO_TEMPLATE = 'renal-smart-reco-PO-template',
}

export enum AppRoutesParams {
  PATIENT_ID = 'patientID',
  CONTACT_ID = 'contactID',
  ORDER_ID = 'orderID',
  STATE = 'state',
  CLINIC_ID = 'clinicId',
}

// add routes that are part of a flow

export const RoutesInFlow = [
  AppRoutes.FOLLOW_UP,
  AppRoutes.DAILY_ALLOWANCE,
  AppRoutes.RENAL_DETECT,
  AppRoutes.SMART_RECO,
  AppRoutes.FAST_RECO,
  AppRoutes.ALLOWANCE,
  AppRoutes.WEIGHT_MANAGEMENT,
  AppRoutes.MUF_PERSONALISED_BAG,
  AppRoutes.MUF_STARTER_KIT,
  AppRoutes.SHARE_RECOMMENDATION,
  AppRoutes.PRODUCTS_DAILY_ALLOWANCE,
  AppRoutes.PLAN_NEXT_VISIT,
];
