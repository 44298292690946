import { BaseInstrumentation, EVENT_VIEW_CHANGED, VERSION } from '@grafana/faro-core';
// all this does is send VIEW_CHANGED event
export class ViewInstrumentation extends BaseInstrumentation {
  constructor() {
    super(...arguments);
    this.name = '@grafana/faro-web-sdk:instrumentation-view';
    this.version = VERSION;
  }
  sendViewChangedEvent(meta) {
    var _a, _b, _c;
    const view = meta.view;
    if (view && view.name !== ((_a = this.notifiedView) === null || _a === void 0 ? void 0 : _a.name)) {
      this.api.pushEvent(EVENT_VIEW_CHANGED, {
        fromView: (_c = (_b = this.notifiedView) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : '',
        toView: view.name
      }, undefined, {
        skipDedupe: true
      });
      this.notifiedView = view;
    }
  }
  initialize() {
    this.sendViewChangedEvent(this.metas.value);
    this.metas.addListener(this.sendViewChangedEvent.bind(this));
  }
}
