import { GenderCode } from '@app/shared/utils';

export interface OktaUser {
  uuid?: string;
  firstName: string;
  lastName: string;
  preferredLanguage?: string; // language code
  email: string;
  secondEmail: string;
  username: string;
  loginType: string;
  gender: GenderCode;
  locale: string;
  countryCode: string;
  qualificationsPetownerStatus?: string;
  division?: string;
  termsVersion?: string;
  mobilePhone?: string;
  termsTimestamp?: string;
  termsSource?: string;
  optsEmailStatus?: string;
  profileUrl: string;
  housing?: string;
  displayName: string;
  timezone?: string;
  optMobile?: string;
  county?: string;
  login: string;
  title: string;
  employeeNumber?: string;
  honorificSuffix?: string;
  optPrint: string;
  manager?: string;
  optEmail?: string;
  avatarUrl: string; // avatarPath
  costCenter: string;
  honorificPrefix?: string;
  externalId: string; // janrain User Id
  managerId: string;
  birthDate: string;
  primaryPhone: string;
  streetAddress: string;
  organization: string; // clinic ID
  userType: string; // account type
  region: string;
  zipCode: string;
  city: string;
  province?: string;
  state: string;
  department?: string;
  groups?: string[];
}

export enum OktaUserLoginType {
  EMAIL = 'email',
  USERNAME = 'username',
}
