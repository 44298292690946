import { PetInfo } from '@app/core/models';
import { NextVisitApiPost } from '@app/core/models/visit-next';
import { BenefitClaim, Influencer, Nutrient } from '@app/shared/components/nutrients-list/nutrients';
import { MeasurementCodeType } from '@app/shared/utils';

export const filterNutrients = (nutrient: any): boolean => {
  return (
    nutrient.influencers.length > 0 &&
    !!nutrient.maxPercentage &&
    !!nutrient.minPercentage &&
    !!nutrient.targetPercentage &&
    !!nutrient.nutrient &&
    !!nutrient.nutrient?.id &&
    !!nutrient.nutrient?.unit
  );
};

export const mapNutrientToFront = (nutrient: any): Nutrient => {
  return {
    nutrient: nutrient.nutrient,
    influencers: mapInfluencerForFront(nutrient.influencers) || [],
    minPercentage: nutrient.minPercentage,
    maxPercentage: nutrient.maxPercentage,
    targetPercentage: nutrient.targetPercentage,
    cutOff: {
      low: nutrient.cutOff.low || 0,
      high: nutrient.cutOff.high || 0,
    },
    targetValue: {
      low: nutrient.targetValue.low || null,
      high: nutrient.targetValue.high || null,
    },
  };
};

const mapInfluencerForFront = (influencer: any): Influencer[] => {
  return influencer.map((influencerItem) => ({
    type: influencerItem.code?.split(':')[0],
    key: influencerItem.code?.split(':')[1],
  }));
};

export const formatNutritionalAssessment = (nutritionalAssessmentData: any): any => {
  return {
    macroNutrients: nutritionalAssessmentData.macroNutrients
      ?.filter((macroNutr) => filterNutrients(macroNutr))
      .map((macroNutrient) => mapNutrientToFront(macroNutrient)),
    otherNutrients: nutritionalAssessmentData.otherNutrients?.rangeDisplay
      ?.filter((nutr) => filterNutrients(nutr))
      .map((otherNutr) => mapNutrientToFront(otherNutr)),
    benefits: nutritionalAssessmentData.otherNutrients?.textDisplay
      ?.filter((benefit) => !!benefit.claim && benefit.influencers.length > 0)
      .map(
        (benef): BenefitClaim => ({
          id: benef.claim,
          influencers: mapInfluencerForFront(benef.influencers),
        })
      ),
  };
};

export const formatPetInfoToNextVisit = (petInfo: PetInfo, currentBigMeasurementUnit: MeasurementCodeType): NextVisitApiPost => {
  return {
    speciesCode: petInfo.speciesCode,
    breedCode: petInfo.breedObject.breedCode,
    genderCode: petInfo.gender,
    petActivityCode: petInfo.petActivity,
    neutered: petInfo.neutered,
    dateOfBirth: petInfo.birthdate,
    bcs: petInfo.bcs,
    weight: {
      measure: petInfo.weight,
      measureUnit: currentBigMeasurementUnit,
    },
    idealBodyWeight: {
      measure: petInfo.IBW,
      measureUnit: currentBigMeasurementUnit,
    },
  };
};
