import { inject, Injectable } from '@angular/core';
import { RCAppInsightsService } from './rc-app-insights.service';
import { RCGrafanaService } from './rc-grafana.service';

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  readonly rcAppInsightsService = inject(RCAppInsightsService);
  readonly rcGrafanaService = inject(RCGrafanaService);

  /**
   * Method to manually track the pageView
   * @param name of the page
   * @param url of the page
   */
  monitorPage(name?: string, url?: string) {
    this.rcAppInsightsService.monitorPage(name, url);
  }

  /**
   * Method to manually track an Metric
   * @param name of the metric
   * @param average value to send
   * @param properties additional properties regarding the metric
   */
  monitorMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.rcAppInsightsService.monitorMetric(name, average, properties);
  }

  /**
   * Method to manually track an Exception
   * @param exception error
   * @param severityLevel error
   */
  monitorException(exception: Error, severityLevel?: number) {
    this.rcAppInsightsService.monitorException(exception, severityLevel);
  }

  /**
   * Method to manually track an application Trace
   * @param message log
   * @param properties additional properties regarding the trace
   */
  logTrace(message: string, properties?: { [key: string]: any }) {
    this.rcAppInsightsService.logTrace(message, properties);
  }

  /**
   * method to enable cookies tracking, is useful to call it when user accepts the cookies consents
   * @returns void
   */
  public enableCookiesTracking(): void {
    this.rcAppInsightsService.enableCookiesTracking();
  }
}
