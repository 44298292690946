{
  "name": "vfu",
  "version": "19.29.0",
  "scripts": {
    "analyzebundle": "webpack-bundle-analyzer dist/stats.json",
    "build": "ng build",
    "build:analyze": "ng build  --configuration prod --verbose --named-chunks=true && esbuild-visualizer --metadata ./dist/stats.json --filename ./dist/stats.html --open",
    "build:demo": "ng build --configuration demo",
    "build:develop": "ng build --configuration develop",
    "build:prod": "ng build --configuration prod",
    "build:staging": "ng build --configuration staging",
    "build:uat": "ng build --configuration uat",
    "build:srs-vetmanager-uat": "ng build --configuration srs-vetmanager-uat",
    "build:das-gmvet-uat": "ng build --configuration das-gmvet-uat",
    "build:srs-vetmanager-prod": "ng build --configuration srs-vetmanager-prod",
    "build:das-gmvet-prod": "ng build --configuration das-gmvet-prod",
    "ci:quality": "npm run lint:scripts && npm run lint:styles && npm run prettier:check",
    "compodoc": "npx compodoc -p tsconfig.json src -s",
    "generate-icons": "svg-to-ts && prettier ./src/app/shared/utils/icon --write",
    "lhci-static": "lhci autorun --config=./lighthouseConfigs/lighthouserc-static.js",
    "lint:scripts": "ng lint",
    "lint:styles": "stylelint \"**/*.scss\" --fix",
    "lint:staged": "lint-staged",
    "ng": "ng",
    "prettier:check": "prettier ./src --check",
    "prettier:write": "prettier ./src --write",
    "sonar": "sonar-scanner",
    "start": "ng serve",
    "start:demo": "ng serve --configuration=demo",
    "start:dev": "ng serve --configuration=develop",
    "start:prod": "ng serve --configuration=prod",
    "start:stg": "ng serve --configuration=staging",
    "start:uat": "ng serve --configuration=uat",
    "start:srs-vetmanager": "ng serve --configuration=srs-vetmanager",
    "start:das-gmvet": "ng serve --configuration=das-gmvet",
    "start:srs-myvete": "ng serve --configuration=srs-myvete",
    "build:srs-myvete-uat": "ng build --configuration srs-myvete-uat",
    "build:srs-myvete-prod": "ng build --configuration srs-myvete-prod",
    "test": "jest --maxWorkers=50% --detect-open-handles",
    "ci:test": "jest --ci --coverage --runInBand --coverageReporters=cobertura --coverageReporters=lcov --testResultsProcessor=jest-sonar-reporter --no-cache",
    "types:check": "tsc --noEmit",
    "template:da": "ts-node --project tsconfig-node.json templates/script.ts daily-allowance && prettier ./src/**/templates/**/*.html --write",
    "template:sr": "ts-node --project tsconfig-node.json templates/script.ts smart-reco && prettier ./src/**/templates/**/*.html --write",
    "template:fr": "ts-node --project tsconfig-node.json templates/script.ts fast-reco && prettier ./src/**/templates/**/*.html --write",
    "template:rsr": "ts-node --project tsconfig-node.json templates/script.ts renal-smart-reco && prettier ./src/**/templates/**/*.html --write",
    "template:dar": "ts-node --project tsconfig-node.json templates/script.ts daily-renal-detect && prettier ./src/**/templates/**/*.html --write",
    "template:wl": "ts-node --project tsconfig-node.json templates/script.ts weight-loss && prettier ./src/**/templates/**/*.html --write",
    "template:all": "npm run template:da && npm run template:sr && npm run template:fr && npm run template:rsr && npm run template:dar && npm run template:wl",
    "emails:aoi": "./node_modules/.bin/mjml ./emails/automatic-onboarding-invitation/source.mjml -o ./emails/automatic-onboarding-invitation/output.html",
    "prepare": "husky install"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.5",
    "@angular/cdk": "^17.3.5",
    "@angular/common": "^17.3.5",
    "@angular/compiler": "^17.3.5",
    "@angular/core": "^17.3.5",
    "@angular/forms": "^17.3.5",
    "@angular/localize": "^17.3.5",
    "@angular/material": "^17.3.5",
    "@angular/material-date-fns-adapter": "^17.3.5",
    "@angular/platform-browser": "^17.3.5",
    "@angular/platform-browser-dynamic": "^17.3.5",
    "@angular/router": "^17.3.5",
    "@grafana/faro-web-sdk": "^1.5.0",
    "@microsoft/applicationinsights-web": "^2.8.9",
    "@ngrx/effects": "^17.2.0",
    "@ngrx/router-store": "^17.2.0",
    "@ngrx/schematics": "^17.2.0",
    "@ngrx/store": "^17.2.0",
    "@ngrx/store-devtools": "^17.2.0",
    "@okta/okta-angular": "6.3.2",
    "@okta/okta-auth-js": "^6.0.0",
    "@rc/ui": "^17.2.0",
    "chart.js": "^2.9.3",
    "core-js": "^3.26.1",
    "d3": "^7.9.0",
    "date-fns": "^2.29.3",
    "handlebars": "^4.7.7",
    "jwt-decode": "^3.1.2",
    "ngrx-store-localstorage": "^17.0.0",
    "ngx-cookie-service": "^17.1.0",
    "object-path": "^0.11.5",
    "pdfjs-dist": "^3.1.81",
    "qrcode": "^1.5.1",
    "rxjs": "^7.8.1",
    "tslib": "^2.3.1",
    "utf-8-validate": "^5.0.10",
    "uuid": "^9.0.0",
    "validator": "^13.7.0",
    "zone.js": "~0.14.4"
  },
  "devDependencies": {
    "@angular-builders/dev-server": "^7.3.1",
    "@angular-devkit/architect": "^0.1703.5",
    "@angular-devkit/build-angular": "^17.3.5",
    "@angular-eslint/builder": "^17.3.0",
    "@angular-eslint/eslint-plugin": "^17.3.0",
    "@angular-eslint/eslint-plugin-template": "^17.3.0",
    "@angular-eslint/schematics": "^17.3.0",
    "@angular-eslint/template-parser": "^17.3.0",
    "@angular/cli": "^17.3.5",
    "@angular/compiler-cli": "^17.3.5",
    "@angular/language-service": "^17.3.5",
    "@compodoc/compodoc": "^1.1.18",
    "@rxweb/types": "^1.0.6",
    "@types/d3": "^7.4.0",
    "@types/jest": "^29.2.4",
    "@types/node": "^18.11.11",
    "@types/qrcode": "^1.4.2",
    "@types/validator": "^13.1.3",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "esbuild-visualizer": "^0.6.0",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^8.3.0",
    "eslint-plugin-import": "2.29.1",
    "eslint-plugin-jsdoc": "^39.6.4",
    "eslint-plugin-prefer-arrow": "1.2.2",
    "eslint-plugin-prettier": "^4.2.1",
    "eslint-plugin-unused-imports": "^3.1.0",
    "html-webpack-plugin": "^5.5.0",
    "husky": "^8.0.0",
    "i18n-iso-countries": "^7.3.0",
    "jest": "^29.7.0",
    "jest-extended": "^4.0.2",
    "jest-marbles": "3.0.3",
    "jest-preset-angular": "^13.1.5",
    "jest-sonar-reporter": "^2.0.0",
    "lint-staged": "^13.1.0",
    "mjml": "^4.14.1",
    "ng-mocks": "^14.12.2",
    "prettier": "2.2.1",
    "sass": "^1.56.2",
    "sonarqube-scanner": "^2.7.0",
    "stylelint": "^14.16.0",
    "stylelint-config-prettier": "^9.0.3",
    "stylelint-config-sass-guidelines": "^9.0.1",
    "stylelint-config-standard": "^24.0.0",
    "svg-to-ts": "^5.7.0",
    "ts-jest": "^29.0.3",
    "ts-node": "^10.9.1",
    "typescript": "~5.4.5",
    "webpack-bundle-analyzer": "^4.9.1"
  }
}
