export enum MeasurementCodeType {
  Gram = 'g',
  Kilogram = 'kg',
  Pound = 'lb',
  Ounce = 'oz',
  Cups = 'cups',
  Can = 'can',
  JapanCup = 'japanCup',
  GramPerMegacalory = 'g/Mcal',
  MilligramPerMegacalory = 'mg/Mcal',
  KilocaloryPerKilogram = 'kcal/kg',
}
