import { OperatorFunction } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

export class RxjsHelper {
  static filterCollFull<T>(): OperatorFunction<T[], T[]> {
    return filter((coll: T[]) => {
      for (const e of coll) {
        if (e === null) {
          return false;
        }
      }
      return true;
    });
  }

  static distinctUntilCollElemChanged<T>(): OperatorFunction<T[], T[]> {
    return distinctUntilChanged((coll1: T[], coll2: T[]) => {
      for (const i in coll1) {
        if (coll1[i] !== coll2[i]) {
          return false;
        }
      }
      return true;
    });
  }
}
