import { Env } from '@app/shared/utils/enums/environment.enum';
import { Environment } from './environment.type';

export const domain = 'https://uat-eus2-rc-vet-vet-webapp.cloud-effem.com';

export const environment: Environment = {
  envName: Env.UAT,
  production: true,
  isStandalone: false,
  scriptEnv: 'VetFoundation',
  bffApiUrl: `https://uat-eus1-rc-vet-vet-bff.cloud-effem.com`,
  productApiUrl: 'https://uat-eus2-rcapi-apim.azure-api.net/internal/product',
  vetApiUrl: 'https://uat-eus2-rcapi-apim.azure-api.net/internal/vet',
  clinicApiUrl: 'https://uat-eus2-rcapi-apim.azure-api.net/internal/clinic',
  toolApiUrl: 'https://uat-eus2-rcapi-apim.azure-api.net/internal/tool',
  nutritionApiUrl: 'https://uat-eus2-rcapi-apim.azure-api.net/internal/nutrition',
  healthNutritionApiUrl: 'https://uat-eus2-rcapi-apim.azure-api.net/internal/health-nutrition',
  petOwnersApiUrl: 'https://uat-eus2-rcapi-apim.azure-api.net/internal/petOwner',
  coreApiUrl: 'https://uat-eus2-rcapi-apim.azure-api.net/internal/core',
  myAccountUrl: 'https://prd-weu1-rc-df-myaccount-app-webapp-uat.cloud-effem.com',
  ciam: {
    apiUrl: 'https://prd-weu1-rc-df-ciam-api-webservice-dev.cloud-effem.com',
    app: 'https://prd-weu1-rc-df-ciam-app-webapp-dev.cloud-effem.com',
    redirectCallback: domain,
  },
  blobUrl: `https://uateus2rcvetfileblob.blob.core.windows.net/configuration`,
  blobQueryParams:
    'sp=racwd&st=2021-05-20T16:04:35Z&se=2025-01-01T01:04:35Z&spr=https&sv=2020-02-10&sr=c&sig=bbFVXcC9IjXiuAW6FvSA%2F3CSb3%2BSa5dDWVjt%2Frc8I2E%3D',
  blobApiUrl: `https://uateus2rcapivetblob.blob.core.windows.net`,
  apimSubscriptionKey: '92370805e86345aa8f4a3169e8e8d35f',
  appInsights: {
    instrumentationKey: '17fe5d4c-0f7a-4cb6-b5b9-83ae415fc427',
    role: 'vet-foundation-webapp',
    roleInstance: 'eus2-vet-foundation-webapp',
  },
  oktaConfig: {
    issuer: 'https://accountdev.royalcanin.com/oauth2/default',
    clientId: '0oaaxam8hqExiGHuG0x6',
    redirectUri: `${domain}/callback`,
    postLogoutRedirectUri: domain,
    pkce: true,
    maxClockSkew: 900,
    scopes: [
      'groups',
      'openid',
      'profile',
      'email',
      'user.profile:write',
      'user.consent:read',
      'purposes:read',
      'user.consent:collect',
      'user.consent:delete',
      'pets:read',
      'pets:write',
      'companies:read',
      'relations:write',
      'relations:read',
    ],
    tokenManager: {
      autoRenew: true,
      autoRemove: true,
    },
  },
  marsFooterScript: null,
  oneTrustId: '0643c84e-3a70-42c5-b752-f1611aa19742',
  fgsUrl: `https://shopuat.royalcanin.com/de/recommendation`,
  individualisUrl: `https://vet-preprod.individualis.com`,
  faroConfig: {
    enabled: true,
    url: 'https://grafana-agent.monitoring.global-dev.rc-df-aks.cloud-effem.com',
    apiKey: 'ZjRyMEFwNmRmSGU2-NDJO-anhjOTBMNjc2YnZj-SWRH-VnFBUVFw-TU5CalVFQjY0M0d5-dGVPU',
  },
};
