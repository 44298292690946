import { faro } from '@grafana/faro-core';
export function isSampled() {
  var _a, _b, _c;
  const sendAllSignals = 1;
  const sessionTracking = faro.config.sessionTracking;
  let samplingRate = (_c = (_b = (_a = sessionTracking === null || sessionTracking === void 0 ? void 0 : sessionTracking.sampler) === null || _a === void 0 ? void 0 : _a.call(sessionTracking, {
    metas: faro.metas.value
  })) !== null && _b !== void 0 ? _b : sessionTracking === null || sessionTracking === void 0 ? void 0 : sessionTracking.samplingRate) !== null && _c !== void 0 ? _c : sendAllSignals;
  if (typeof samplingRate !== 'number') {
    const sendNoSignals = 0;
    samplingRate = sendNoSignals;
  }
  return Math.random() < samplingRate;
}
