import {
  selectConsultationAllowanceParams,
  selectConsultationAllowanceParamsPetHasGrowthChart,
  selectConsultationCreatingConsultation,
  selectConsultationCurrentConsultation,
  selectConsultationLastConsultation,
  selectConsultationPatient,
  selectDeprecatedPetInfo,
  selectExpectedNextVisit,
  selectIsNeuteredToday,
  selectSterilizationType,
  selectVetSourceOrderStatus,
} from '@app/store/consultation/consultation.selectors';
import { Injectable } from '@angular/core';
import {
  resetConsultationData,
  setConsultationPetOwner,
  convertConsultationFlow,
  getExpectedNextVisit,
  setExpectedNextVisit,
  resetExpectedNextVisit,
  resetConsultationFlow,
} from '@app/store/consultation/consultation.actions';
import { Store } from '@ngrx/store';
import { AppState } from '..';
import { PetInfo, PetOwner } from '@app/core/models';
import { Tool } from '@app/shared/utils';
import { NextVisitData } from '@app/core/models/visit-next';
import { SystemPreferenceCode } from '@app/shared/utils/enums/system-preference-code';
import { selectSmartRecoProfile } from '@app/pages/smart-reco/store/selectors/smart-reco-patient.selectors';
import { map, take } from 'rxjs';

@Injectable()
export class ConsultationFacade {
  consultationAllowanceParams$ = this.store$.select(selectConsultationAllowanceParams);
  deprecatedPetInfo$ = this.store$.select(selectDeprecatedPetInfo);
  consultationCreatingConsultation$ = this.store$.select(selectConsultationCreatingConsultation);
  consultationPatient$ = this.store$.select(selectConsultationPatient);
  lastConsultation$ = this.store$.select(selectConsultationLastConsultation);
  currentConsultation$ = this.store$.select(selectConsultationCurrentConsultation);
  selectExpectedNextVisit$ = this.store$.select(selectExpectedNextVisit);
  selectConsultationAllowanceParamsPetHasGrowthChart$ = (currentSystemPreferenceCode: SystemPreferenceCode) =>
    this.store$.select(selectConsultationAllowanceParamsPetHasGrowthChart(currentSystemPreferenceCode));
  vetSourceOrderStatus$ = this.store$.select(selectVetSourceOrderStatus);
  isNeuteredToday$ = this.store$.select(selectIsNeuteredToday);
  sterilizationType$ = this.store$.select(selectSterilizationType);
  reproductionStatus$ = this.store$.select(selectSmartRecoProfile).pipe(
    take(1),
    map((value) => value.reproductionStatus)
  );
  speciesCode$ = this.store$.select(selectSmartRecoProfile).pipe(
    take(1),
    map((value) => value.speciesCode)
  );

  constructor(private store$: Store<AppState>) {}

  resetConsultationData(): void {
    this.store$.dispatch(resetConsultationData());
  }

  setConsultationPetOwner(value: PetOwner): void {
    this.store$.dispatch(setConsultationPetOwner({ value }));
  }

  convertConsultationFlow(flow: Tool): void {
    this.store$.dispatch(convertConsultationFlow({ flow }));
  }

  resetConsultationFlow(coreTool: Tool): void {
    this.store$.dispatch(resetConsultationFlow({ coreTool }));
  }

  getExpectedNextVisit(value: PetInfo, nextDate?: Date): void {
    this.store$.dispatch(getExpectedNextVisit({ value, nextDate }));
  }

  setExpectedNextVisit(value: NextVisitData): void {
    this.store$.dispatch(setExpectedNextVisit({ value }));
  }
  resetExpectedNextVisit(): void {
    this.store$.dispatch(resetExpectedNextVisit());
  }
}
