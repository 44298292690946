import { NutritionService } from '@app/core/services/network';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Clinic,
  CollaboratorUser,
  Consultation,
  Market,
  Patient,
  PatientSearch,
  PatientSearchFilters,
  Pet,
  PetInfo,
  PetOwner,
  PetOwnerSearch,
  Term,
  Vet,
  VetUser,
  VetWithInvitation,
  VetWithoutUser,
  Weight,
} from '@app/core/models';
import { ConsultationApiData } from '@app/core/models/consultation-api-data';
import { Constants, LifestageType, MeasureHelper, MeasurementCodeType, SpeciesCode } from '@app/shared/utils';
import { formatConsultation } from '@app/shared/utils/static-helpers/consultation-helper';
import { Helper } from '@app/shared/utils/static-helpers/helper';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { VetFacade } from '@app/store/vet/vet.facade';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { patientsFullPetFilter } from '../../utils/search-filters-helper';
import { ApiService } from './../api.service';
import { CoreFacade } from '@app/store/core/core.facade';
import { RCAlertType } from '@rc/ui';
import { buildPatientApiBody, formatPatientSearch } from '@app/shared/utils/static-helpers/patient-helper';
import { CountryCode } from '@app/shared/utils/enums/country-code.enum';
import { buildTermsApiBody, formatCollaborator, formatPetOwner, unFormatPetOwner } from '@app/shared/utils/static-helpers/vet-helper';
import { AuthService } from '../../auth/auth.service';
import { IGrowthChartStandardWeights, IWeightsChart } from '@app/weight-chart/weight-chart.types';
import { GrowthChartPetWeightsBodyApi, GrowthChartStandardWeightsBodyApi } from '@app/core/models/growth-chart';
import { SystemPreferenceCode } from '@app/shared/utils/enums/system-preference-code';

@Injectable()
export class VetService extends ApiService {
  constructor(
    private http: HttpClient,
    private _vetFacade: VetFacade,
    private nutritionService: NutritionService,
    private coreFacade: CoreFacade,
    private authService: AuthService
  ) {
    super();
  }

  public readonly maxPetWeight$ = this._vetFacade.preferredMaxPetWeight$;
  public readonly currentBigMeasurementUnit$ = this._vetFacade.currentBigMeasurementUnit$;
  public readonly vetId$ = this._vetFacade.vetId$;
  public readonly currentClinicCountry$ = this._vetFacade.currentClinicCountry$;
  public readonly currentSystemPreferenceCode$ = this._vetFacade.currentSystemPreferenceCode$;

  /**
   * Set deprecated vet preferences until we get rid of it
   */
  deprecatedSetVetPreference(vet: Vet): void {
    if (vet && typeof vet !== 'string') {
      this.updateUnitSystemPreference(vet.systemPreference);
      this._vetFacade.currentSystemPreferenceCode$
        .pipe(
          take(1),
          tap((currentSystemPreferenceCode: SystemPreferenceCode) =>
            this._vetFacade.setPreferredMaxPetWeight(
              MeasureHelper.weightToLocalizedValue(Constants.LIMIT_INPUT_WEIGHT, currentSystemPreferenceCode)
            )
          )
        )
        .subscribe();
    }
  }

  /*** PATIENT METHODS ***/

  /**
   * [POST] - Create patient
   * Used in CreatePatientPopin
   * @param pet: Pet
   * @param owner: PetOwner
   * @param consultation: Consultation
   */
  createPatient(pet: Pet, owner: PetOwner, consultation: Partial<Consultation>): Observable<Patient> {
    return this.vetId$.pipe(
      take(1),
      map((vetId) => {
        const newPatient = buildPatientApiBody(pet, owner, this._vetFacade.clinicId, vetId);
        return {
          ...newPatient,
          consultation: {
            /**
             * With new allowance tool vetId is already set inside consultation object, this is for compatibility with legacy flows
             */
            ...consultation,
            vetId,
          },
        };
      }),
      switchMap((body) => {
        // if pet-owner exist
        if (owner.id) {
          return this.http.post<Patient>(this.pathUrl.bff_patient, body).pipe(
            map((patient) => ({
              ...patient,
              consultation: formatConsultation(patient.consultation),
              owner,
            })),
            catchError(this.handleError.bind(this))
          );
        } else {
          // if pet-owner not exist create it first
          owner.organizationId = this._vetFacade.clinicId;
          return this.http.post<{ _id: string }>(this.pathUrl.contacts(this._vetFacade.clinicId), owner).pipe(
            mergeMap(({ _id }) => this.apiGetPetOwner(_id)),
            mergeMap((petOwner: PetOwner) => {
              const patientInputApi = {
                ...body,
                contactId: petOwner.id,
              };
              return this.http.post<Patient>(this.pathUrl.bff_patient, patientInputApi).pipe(
                map((patient) => ({
                  ...patient,
                  consultation: formatConsultation(patient.consultation),
                  owner: petOwner,
                })),
                catchError(this.handleError.bind(this))
              );
            }),
            catchError(this.handleError.bind(this))
          );
        }
      })
    );
  }

  createPatientWithoutConsultation(pet: Pet, owner: PetOwner): Observable<Patient> {
    return this.vetId$.pipe(
      take(1),
      switchMap((vetId) => {
        const newPatient = buildPatientApiBody(pet, owner, this._vetFacade.clinicId, vetId);
        // if pet-owner exist
        if (owner.id) {
          return this.http.post<Patient>(this.pathUrl.patients(this._vetFacade.clinicId), newPatient).pipe(
            map((patient) => ({
              ...patient,
              owner,
            })),
            catchError(this.handleError.bind(this))
          );
        } else {
          // if pet-owner not exist create it first
          owner.organizationId = this._vetFacade.clinicId;
          return this.http.post<{ _id: string }>(this.pathUrl.contacts(this._vetFacade.clinicId), owner).pipe(
            mergeMap(({ _id }) => this.apiGetPetOwner(_id)),
            mergeMap((petOwner: PetOwner) => {
              const patientInputApi = {
                ...newPatient,
                contactId: petOwner.id,
              };
              return this.http.post<Patient>(this.pathUrl.patients(this._vetFacade.clinicId), patientInputApi).pipe(
                map((patient) => ({
                  ...patient,
                  owner: petOwner,
                })),
                catchError(this.handleError.bind(this))
              );
            }),
            catchError(this.handleError.bind(this))
          );
        }
      })
    );
  }

  createOwner(owner: PetOwner): Observable<PetOwner> {
    return this.http
      .post<{ _id: string }>(this.pathUrl.contacts(this._vetFacade.clinicId), unFormatPetOwner(owner, this._vetFacade.clinicId))
      .pipe(
        mergeMap(({ _id }) => this.apiGetPetOwner(_id)),
        catchError(this.handleError.bind(this))
      );
  }

  deletePatient(patient: Patient): Observable<any> {
    return this.http.delete(this.pathUrl.patient(this._vetFacade.clinicId, patient.id)).pipe(catchError(this.handleError.bind(this)));
  }

  deletePetOwner(clinicId: string, petOwnerId: string): Observable<any> {
    return this.http.delete(this.pathUrl.bff_petOwner(clinicId, petOwnerId)).pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * [GET] - One patient
   * TODO use pathUrl.patient API to call a specific patient instead of using search API
   */
  fetchPatient(patientId: string): Observable<Patient> {
    const filterApi = {
      include: ['pet'],
      where: { id: patientId },
    };

    return this.deprecatedClinicPatients(filterApi, true).pipe(
      map((patients) => {
        if (patients && patients.length === 1) {
          return patients[0];
        }
        return null;
      })
    );
  }

  fetchPatientInformationForNewConsultation(patientId: string): Observable<{ patient: Patient; lastConsultation: Consultation }> {
    return combineLatest([this.fetchPatient(patientId), this.lastConsultationByPatient(patientId)]).pipe(
      concatMap(([patient, lastConsultation]) =>
        this.nutritionService
          .getBreedPetProfile({
            breedCode: patient?.pet?.breedCode,
            dateOfBirth: patient?.pet?.birth?.date,
          })
          .pipe(
            map(({ lifestage }: { lifestage: LifestageType }) => {
              return {
                patient: {
                  ...patient,
                  pet: { ...patient.pet, lifeStage: lifestage },
                  owner: patient?.owner,
                },
                lastConsultation,
              };
            })
          )
      )
    );
  }

  /**
   * [PUT] - Update one Pet
   * @param petId: pet - unique ID
   * @param data: Pet - pet's data to update
   */
  updatePet(petId: string, pet: Pet): Observable<Pet> {
    const formattedData = buildPatientApiBody(pet);
    return this.http
      .put<Pet>(this.pathUrl.pet(this._vetFacade.clinicId, petId), formattedData.pet)
      .pipe(catchError(this.handleError.bind(this)));
  }

  searchPatients<P extends PatientSearch>(filters: PatientSearchFilters): Observable<P[]> {
    let params = new HttpParams();
    if (filters.term && filters.term.length > 0) params = params.set('term', filters.term);
    if (filters.loadLastConsultation) params = params.set('loadLastConsultation', JSON.stringify(filters.loadLastConsultation));
    if (filters.searchBy) params = params.set('searchBy', filters.searchBy);
    if (filters.speciesCode && filters.speciesCode != SpeciesCode.Unknown) params = params.set('speciesCode', filters.speciesCode);

    return this.http
      .get(this.pathUrl.search(this._vetFacade.clinicId), {
        params,
      })
      .pipe(
        map((patients: any[]) => {
          return patients.map((patient) => formatPatientSearch(patient));
        }),
        catchError(this.handleError.bind(this))
      );
  }

  /**
   * [GET] - All clinic's patients
   */
  deprecatedClinicPatients<P extends Patient>(filterApi, includeOwners: false): Observable<P[]>;
  deprecatedClinicPatients<P extends Patient>(filterApi, includeOwners: true): Observable<(P & { owner: PetOwner })[]>;
  deprecatedClinicPatients<P extends Patient>(filterApi, includeOwners = false): Observable<P | (P & { owner: PetOwner })[]> {
    const params = new HttpParams().set(
      'filter',
      JSON.stringify({
        ...filterApi,
      })
    );

    return this.http
      .get<P[]>(this.pathUrl.patients(this._vetFacade.clinicId), {
        params,
      })
      .pipe(
        map((patients) => patients.filter((patient) => patient.contactId)),
        mergeMap((patients) => {
          if (!includeOwners || patients.length === 0) {
            return of(patients);
          }
          const contactIds = Helper.arrayRemoveDoubles(patients.map((p) => p.contactId));
          return this.searchPetOwners(contactIds).pipe(
            map((contacts) =>
              patients
                .map((patient) => ({
                  ...patient,
                  pet: {
                    ...patient.pet,
                    birth: {
                      ...patient.pet.birth,
                      date: typeof patient.pet.birth.date === 'string' ? new Date(patient.pet.birth.date) : patient.pet.birth.date,
                    },
                  },
                  owner: contacts.filter((c) => !!c).find((c) => c.id === patient.contactId),
                }))
                .filter((patient: P & { owner: PetOwner }) => patient.owner)
            )
          );
        }),
        catchError(this.handleError.bind(this))
      );
  }

  apiContactsByEmail(email: string): Observable<PetOwner[]> {
    const params = new HttpParams().set(
      'q',
      JSON.stringify({
        email,
      })
    );

    return this.http.get(this.pathUrl.contacts(this._vetFacade.clinicId), { params }).pipe(
      map((owners: any[]) => owners.map((owner) => formatPetOwner(owner))),
      catchError(this.handleError.bind(this))
    );
  }

  /**
   * Get all pets from specific owner
   */
  patientsByContactId(contactId: string): Observable<Patient[]> {
    return this.deprecatedClinicPatients(patientsFullPetFilter(contactId), false);
  }

  /*** CONSULTATION METHODS ***/

  /**
   * [POST] - Create consultation
   * @param patient - Patient object
   * @param consultation - Consultation object
   */
  createConsultation(patient: Patient, consultation: Partial<Consultation>): Observable<Consultation> {
    const url = this.pathUrl.consultations(this._vetFacade.clinicId, patient.id);
    /**
     * vetId added for compatibility with legacy tools, can be deleted after full ngrx migration
     */
    return this._vetFacade.vetId$.pipe(
      switchMap((vetId) =>
        this.http
          .post<any>(url, { ...consultation, vetId })
          .pipe(
            map((res) => formatConsultation(res)),
            catchError(this.handleError.bind(this))
          )
      )
    );
  }

  /**
   * [PUT] - Update consultation
   * @param patientId - Patient id attribute
   * @param consultationId - Consultation id attribute
   * @param data - Consultation object data to update
   */
  updateConsultationVisits(data: any, patientId: string, consultationId: string): Observable<Consultation> {
    const url = this.pathUrl.updateConsultationVisits(this._vetFacade.clinicId, patientId, consultationId);
    return this.http.put<Consultation>(url, data).pipe(
      map((consultation) => formatConsultation(consultation)),
      catchError(this.handleError.bind(this))
    );
  }

  /**
   * [GET] - Fetch all consultation from the patient id, with limit
   * @param patientId - Patient unique ID
   * @param apiFilters - Loopback API filters
   */
  consultationsWithLimit(patientId: string, apiFilters?: any): Observable<ConsultationApiData> {
    let params = new HttpParams();
    for (const key in apiFilters) {
      const value = Array.isArray(apiFilters[key]) ? JSON.stringify(apiFilters[key]) : apiFilters[key];
      params = params.set(key, value);
    }
    return this.http
      .get<ConsultationApiData[]>(this.pathUrl.consultations(this._vetFacade.clinicId, patientId), { params, observe: 'response' })
      .pipe(
        map((data) => ({
          count: data.headers.get('totalitems'),
          result: data.body.map((consultation) => formatConsultation(consultation)),
        })),
        catchError(this.handleError.bind(this))
      );
  }

  /**
   * [GET] - Fetch all consultation from the patient id
   * @param patientId - Patient unique ID
   * @param apiFilters - Loopback API filters
   */
  consultationsWithoutLimit(patientId: string, apiFilters?: any): Observable<Consultation[]> {
    let params = new HttpParams();
    for (const key in apiFilters) {
      const value = Array.isArray(apiFilters[key]) ? JSON.stringify(apiFilters[key]) : apiFilters[key];
      params = params.set(key, value);
    }

    return this.http
      .get<Consultation[]>(this.pathUrl.consultations(this._vetFacade.clinicId, patientId), { params })
      .pipe(
        map((consultations) => consultations.map((consultation) => formatConsultation(consultation))),
        catchError(this.handleError.bind(this))
      );
  }

  weights(petId: string): Observable<Weight[]> {
    return this.http.get<Weight[]>(this.pathUrl.petsWeight(this._vetFacade.clinicId, petId)).pipe(catchError(this.handleError.bind(this)));
  }

  //growth cart : standard weight
  growthChartStandardWeight(petId: string, body: GrowthChartStandardWeightsBodyApi): Observable<IGrowthChartStandardWeights> {
    return this.http.post<IWeightsChart>(this.pathUrl.growthChartStandardWeight(petId), body).pipe(
      map((data) => data.growCharts),
      catchError(this.handleError.bind(this))
    );
  }
  //growth chart : weights
  growthChartPetWeights(petId: string, body: GrowthChartPetWeightsBodyApi): Observable<IWeightsChart> {
    return this.http.post<IWeightsChart>(this.pathUrl.growthChartPetWeights(petId), body).pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * [GET]
   * Fetch consultations including Visit which includes Recommendation + Weight
   * Used in Pet-Page
   * @param patientId: string
   * @param skip: number
   */
  consultationsByPatient(patientId: string, skip = 0): Observable<ConsultationApiData> {
    const paramFilters = {
      populate: ['visit', 'visit.recommendation', 'visit.weight'],
      skip: skip,
      limit: Constants.LIMIT_CONSULTATIONS_PAGE,
      order: 'date DESC',
    };

    return this.consultationsWithLimit(patientId, paramFilters);
  }

  consultationsByPatientWithoutVisit(patientId: string): Observable<Consultation[]> {
    const paramFilters = {
      order: 'date DESC',
    };
    return this.consultationsWithoutLimit(patientId, paramFilters);
  }

  /**
   * [GET] - Fetch consultations including Visit which includes Recommendation + Weight
   * Used in Pet-Page
   * @param patientId: string
   */
  lastConsultationByPatient(patientId: string): Observable<Consultation> {
    const paramFilters = {
      populate: ['visit', 'visit.recommendation', 'visit.weight'],
      skip: 0,
      limit: 1,
      order: 'date DESC',
    };

    return this.consultationsWithLimit(patientId, paramFilters).pipe(map((consultationApiData) => consultationApiData.result[0]));
  }

  /**
   * [GET] - Fetch all consultations with the patient id and filter by period
   * Used in Weight-Management Health Tracking Graph
   * @param patientId - Patient unique ID
   */
  patientConsultationsByPeriod(patientId: string): Observable<Consultation[]> {
    const paramFilters = {
      populate: ['visit', 'visit.weight'],
      skip: 0,
      order: 'date ASC',
    };

    return this.consultationsWithoutLimit(patientId, paramFilters);
  }

  /**
   * [GET] - Fetch One consultation
   * @param patientId - Patient unique ID
   * @param consultationId - Consultation unique ID
   * @param apiFilters - Loopback API filters
   */
  fetchConsultationById(patientId: string, consultationId: string): Observable<Consultation> {
    const url = this.pathUrl.consultation(this._vetFacade.clinicId, patientId, consultationId);
    let params = new HttpParams();
    const apiFilters = {
      populate: ['nextVisit', 'targetVisit', 'visit.weight', 'visit.recommendation'],
      skip: 0,
      order: 'date ASC',
    };
    for (const key in apiFilters) {
      const value = Array.isArray(apiFilters[key]) ? JSON.stringify(apiFilters[key]) : apiFilters[key];
      params = params.set(key, value);
    }

    return this.http
      .get<Consultation>(url, { params })
      .pipe(
        map((consultation) => formatConsultation(consultation)),
        catchError(this.handleError.bind(this))
      );
  }

  apiGetPetOwner(contactId: string): Observable<PetOwner> {
    return this.http.get<any>(this.pathUrl.contactById(this._vetFacade.clinicId, contactId)).pipe(
      map((owner) => formatPetOwner(owner)),
      catchError((error) => {
        if (error.status === 404) {
          return of(undefined);
        } else {
          this.handleError(error);
        }
      })
    );
  }

  // TO DO : ask api to send us back the modify address list on succes
  apiUpdatePetOwner(petOwnerId: string, petOwner: PetOwner): Observable<PetOwner> {
    return this.http.put<any>(this.pathUrl.contactById(this._vetFacade.clinicId, petOwnerId), unFormatPetOwner(petOwner)).pipe(
      mergeMap(() => this.apiGetPetOwner(petOwnerId)),
      catchError(this.handleError.bind(this))
    );
  }

  searchPetOwners(petOwnerIds: string[]): Observable<PetOwnerSearch[]> {
    return this.http
      .post<any[]>(this.pathUrl.contactsSearch(this._vetFacade.clinicId), {
        filter: {
          _id: {
            $in: petOwnerIds,
          },
        },
      })
      .pipe(
        map((owners) => owners.map((owner) => formatPetOwner(owner))),
        catchError(this.handleError.bind(this))
      );
  }

  // TO DO : ask api to send us back the modify address list on succes
  apiDeletePetOwnerAddress(petOwnerId: string, addressId: string): Observable<any> {
    return this.http.delete(this.pathUrl.contactAddress(this._vetFacade.clinicId, petOwnerId, addressId)).pipe(
      mergeMap(() => this.apiGetPetOwner(petOwnerId)),
      catchError(this.handleError.bind(this))
    );
  }

  apiLookupClinic(customerId: string, countryCode: string): Observable<Clinic[]> {
    return this.http.get(this.pathUrl.lookupClinic(customerId, countryCode)).pipe(catchError(this.handleError.bind(this)));
  }

  getCollaborators(clinicId: string): Observable<CollaboratorUser[]> {
    return this.http.get(this.pathUrl.bff_vets(clinicId)).pipe(
      map((users: any[]) => users.map((user) => formatCollaborator(user))),
      shareReplay(1),
      catchError(this.handleError.bind(this))
    );
  }

  /**
   * Endpoint to connect a vet to a clinic
   * If vet exists but clinic has not been created yet, invitationId is not needed
   * If vet does not exist, it will be created there, invitationId is required
   * after vet is connected to new clinic, renew okta token to get up-to-date relations from JWT
   */
  apiConnectVetClinic(clinicId: string, vet: Partial<VetWithInvitation>): Observable<Vet> {
    return this.http
      .post<Vet>(this.pathUrl.connectVetClinic(clinicId), vet)
      .pipe(switchMap((vetData) => this.authService.renewToken$().pipe(map(() => vetData))));
  }

  acceptTerms(vetId: string, terms: Term[]): Observable<{ terms: Term[] } | string> {
    return this.http
      .post<{ terms: Term[] }>(this.pathUrl.terms(vetId), buildTermsApiBody(terms))
      .pipe(catchError((err) => this.handleAcceptTermsError(err, terms)));
  }

  apiUpdateVet(vetUpdates: Vet): Observable<Vet> {
    return this.vetId$.pipe(
      take(1),
      switchMap((vetId) => {
        return this.http.patch<Vet>(this.pathUrl.vet(vetId), vetUpdates).pipe(catchError(this.handleError.bind(this)));
      })
    );
  }

  getPurposeByCountry(countryCode: CountryCode) {
    return this.http.get(this.pathUrl.purpose(countryCode)).pipe(catchError(this.handleError.bind(this)));
  }

  updateOktaVet(vetId, user: VetUser) {
    const body = {
      profile: user,
    };
    return this.http.post(this.pathUrl.vetUpdate(vetId), body).pipe(catchError(this.handleError.bind(this)));
  }

  apiGetClinic(clinicId: string): Observable<Clinic> {
    return this.http.get<Clinic>(this.pathUrl.clinic(clinicId)).pipe(catchError(this.handleError.bind(this)));
  }

  apiUpdateClinic(clinicId: string, clinic: Partial<Clinic>): Observable<Clinic> {
    return this.http.patch<Clinic>(this.pathUrl.clinic(clinicId), clinic).pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * Get market data
   */
  getMarket = (countryCode: CountryCode): Observable<Market> => {
    return this.http.get<Market>(this.pathUrl.blobStorageData(countryCode), {
      headers: new HttpHeaders({
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Cache-Control#no-cache_2
        'Cache-Control': 'no-cache',
      }),
    });
  };

  /**
   * Get user with its related clinics
   */
  getVetWithClinics = (countryCode: string): Observable<VetWithoutUser> => {
    const params = new HttpParams().set('country', countryCode);

    return this.http.get<Vet>(this.pathUrl.userVet(), { params });
  };

  getSystemPreferenceCode = (countryCode: string) => {
    let systemPreference = SystemPreferenceCode.metricSystemCode;
    if (Constants.IMPERIAL_COUNTRIES.includes(countryCode)) {
      systemPreference = SystemPreferenceCode.imperialSystemCode;
    }
    return systemPreference;
  };

  public sendVetsourcesConsultation = (
    petOwnerEmail: string,
    { id: clinicId, customerId }: Clinic,
    petInfo: PetInfo,
    currentConsultation: Consultation
  ): Observable<void> => {
    const body = {
      customerId: customerId,
      petOwnerEmail,
      pet: {
        breedCode: petInfo.breedObject.breedCode,
        genderCode: petInfo.gender,
        neutered: petInfo.neutered,
        petActivityCode: petInfo.petActivity,
        reproductionStatusCode: petInfo.reproductionStatus,
        idealBodyWeight: {
          weightDate: currentConsultation.visit.weight.weightDate,
          bcs: currentConsultation.visit.weight.bcs,
          measure: currentConsultation.visit.weight.measure,
          measureUnit: currentConsultation.visit.weight.measureUnit,
          type: currentConsultation.visit.weight.type,
        },
        birth: { date: petInfo.birthdate },
        name: petInfo.name,
        speciesCode: petInfo.speciesCode,
      },
      consultation: currentConsultation,
    };
    return this.http.post<void>(this.pathUrl.sendPimsConsultationEvent(clinicId, 'vetSources'), body);
  };

  /**
   * Tolerate unhandled API or network errors
   * in order not to block the UX
   */
  private handleAcceptTermsError(err: HttpErrorResponse, terms: Term[]): Observable<{ terms: Term[] } | string> {
    return err.status === 401 || err.status === 400
      ? this.handleError(err)
      : of({ terms }).pipe(
          tap(() =>
            this.coreFacade.setAlert({
              message: translateKey('error_try-again-later-tc'),
              alertType: RCAlertType.ERROR,
            })
          )
        );
  }

  private updateUnitSystemPreference(unitSystemCode: SystemPreferenceCode) {
    this._vetFacade.setCurrentSystemPreferenceCode(unitSystemCode);
    switch (unitSystemCode) {
      case SystemPreferenceCode.metricSystemCode:
        this._vetFacade.setCurrentSmallMeasurementUnit(MeasurementCodeType.Gram);
        this._vetFacade.setCurrentBigMeasurementUnit(MeasurementCodeType.Kilogram);
        break;
      case SystemPreferenceCode.imperialSystemCode:
        this._vetFacade.setCurrentSmallMeasurementUnit(MeasurementCodeType.Ounce);
        this._vetFacade.setCurrentBigMeasurementUnit(MeasurementCodeType.Pound);
        break;
    }
  }
}
