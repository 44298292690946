import { Clinic, Coverage, Product, ProductRecommendationIndividualis, SmartRecoApiBody, Vet } from '@app/core/models';
import { EmailPopinType, GenderCode, MeasurementCodeType, ReproductionStatusCode, SpeciesCode, Tool } from '@app/shared/utils';

export interface RecommendationBodyEmail {
  tool: Tool;
  chronoVetLink: string;
  emailPopinType: EmailPopinType;
  user: {
    name: string;
    clinic_name: string;
    address: string;
    zip_code: string;
    city_name: string;
    clinic_logo?: string;
  };
  pet?: {
    name: string;
  };
  owner: {
    name: string;
  };
  eventData: EventDataBodyEmail;
  product1: ProductsBodyEmail;
  product2: ProductsBodyEmail;
  newPoLetter: boolean;
}

interface ProductsBodyEmail {
  name: string;
  url: string;
}

export interface EventDataBodyEmail {
  consultationId?: string;
  recommendationId?: string;
  clinicId: string;
}

export interface SignalRecommendationBodyEmail {
  product: Product | ProductRecommendationIndividualis['product'];
  coverage: Coverage | null;
  petInfo: {
    bcs: number;
    bigMeasurementUnit: MeasurementCodeType;
    birthdate: Date | null;
    breed: string;
    gender: GenderCode | null;
    IBW: number;
    lifestage: string | null;
    name: string;
    neutered: boolean;
    pathologies: { label: string; value: string }[];
    reproductionStatus: ReproductionStatusCode | null;
    sensitivities: { label: string; value: string }[];
    speciesCode: SpeciesCode | null;
    weight: number;
  };
  message: string;
  env: string;
  api_session_id: string;
  vet: Vet;
  clinic: Clinic;
  input: SmartRecoApiBody;
  technicalInformation: SignalRecommendationTechnicalInformation;
}

export interface SignalRecommendationTechnicalInformation {
  status: SignalRecommendationTechnicalStatus;
  errorMessage: string;
  retryCount: number;
  sessionId: string;
}

export enum SignalRecommendationTechnicalStatus {
  OK = 'OK',
  KO = 'KO',
}
