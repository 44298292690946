import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppLetDirective } from './app-let.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [AppLetDirective],
  exports: [AppLetDirective],
})
export class SharedDirectivesModule {}
