import { Tool } from './../../../shared/utils/enums/tool-type.enum';
import { AllowanceStep } from '@app/pages/allowance/allowance';
import { AllowanceCalculatorBlocks } from '@app/shared/utils/enums/allowance-calculator-blocks.enum';
import { Injectable } from '@angular/core';
import { POLetterFrom, ProductType } from '@app/shared/utils';
import { AppState } from '@app/store';
import { select, Store } from '@ngrx/store';
import {
  getAllowanceRecommendation,
  getAllowanceRecommendationAllWetQuantity,
  setAllowanceCurrentBlockEditing,
} from './actions/allowance-recommendation.actions';
import {
  selectAllowanceCurrentRecommendation,
  selectAllowanceDefaultRecommendation,
  selectAllowanceLastRecommendationKcalValue,
  selectAllowanceLoadingRecommendation,
  selectAllowancePreviousRecommendation,
  selectAllowanceRecommendationKcalValue,
  selectBlockCurrentlyEditing,
  selectAllowanceDefaultRecommendationKcalValue,
  selectAllowanceStep,
  selectAllowanceWetQuantityPerDay,
} from './selectors/allowance.selectors';
import { selectAllowanceBlockingErrors } from './selectors/allowance-error.selectors';
import { shareAllowanceRecommendation } from './actions/allowance-share.actions';
import { createAllowanceConsultation } from './actions/allowance-consultation.actions';
import { setAllowanceStep } from './actions/allowance-steps.actions';
import { selectAllowanceSummary } from './selectors/allowance-params.selectors';
import { Clinic, PetOwner } from '@app/core/models';
import { createAllowanceConsultationVetSource } from './actions/allowance-vetsource.actions';

@Injectable()
export class AllowanceFacade {
  currentRecommendation$ = this.store.pipe(select(selectAllowanceCurrentRecommendation));
  defaultRecommendation$ = this.store.pipe(select(selectAllowanceDefaultRecommendation));
  lastRecommendation$ = this.store.pipe(select(selectAllowancePreviousRecommendation));
  currentKcalAllocation$ = this.store.pipe(select(selectAllowanceRecommendationKcalValue));
  defaultKcalAllocation$ = this.store.pipe(select(selectAllowanceDefaultRecommendationKcalValue));
  lastKcalAllocation$ = this.store.pipe(select(selectAllowanceLastRecommendationKcalValue));
  blockCurrentlyEditing$ = this.store.pipe(select(selectBlockCurrentlyEditing));
  loadingRecommendation$ = this.store.pipe(select(selectAllowanceLoadingRecommendation));
  blockingErrors$ = this.store.pipe(select(selectAllowanceBlockingErrors));
  allowanceSummary$ = this.store.pipe(select(selectAllowanceSummary));
  allowanceStep$ = this.store.pipe(select(selectAllowanceStep));
  selectAllowanceWetQuantityPerDay$ = this.store.pipe(select(selectAllowanceWetQuantityPerDay));

  constructor(private store: Store<AppState>) {}

  getRecommendation(): void {
    this.store.dispatch(getAllowanceRecommendation({}));
  }

  getManualRecommendationWithAllocation(allocation: number): void {
    this.store.dispatch(getAllowanceRecommendation({ allocation }));
  }

  getManualRecommendationWithWetRation(value: number): void {
    this.store.dispatch(getAllowanceRecommendation({ manualRation: { productType: ProductType.Wet, value } }));
  }

  getAllowanceRecommendationAllWetQuantity(allocation?: number): void {
    this.store.dispatch(getAllowanceRecommendationAllWetQuantity({ allocation }));
  }

  setAllowanceCurrentBlockEditing(block: AllowanceCalculatorBlocks, value: boolean): void {
    this.store.dispatch(setAllowanceCurrentBlockEditing({ block, value }));
  }

  shareAllowanceRecommendation(value: POLetterFrom): void {
    this.store.dispatch(shareAllowanceRecommendation({ value }));
  }

  createAllowanceConsultation(): void {
    this.store.dispatch(createAllowanceConsultation({}));
  }

  createAllowanceConsultationVetSource(email: string, owner: PetOwner, clinic: Clinic): void {
    this.store.dispatch(createAllowanceConsultationVetSource({ email, owner, clinic }));
  }

  setAllowanceStep(step: AllowanceStep, tool?: Tool): void {
    this.store.dispatch(setAllowanceStep({ step, tool }));
  }
}
