import { Clinic, PetOwner } from '@app/core/models';
import { createAction, props } from '@ngrx/store';
/**
 * Action dispatched to prepare the Vet Source order.
 * It actually uses an effect to gather information and prepare the Vet Source order in the consultation store
 */
export const createAllowanceConsultationVetSource = createAction(
  '[Allowance] Create Consultation Vet Source',
  props<{ email: string; owner: PetOwner | null | undefined; clinic: Clinic }>()
);
