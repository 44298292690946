<app-rc-dialog-wrapper noClose="true">
  <div class="email-success-dialog">
    <div class="email-success-dialog__body">
      <!-- Image -->
      <div class="email-success-dialog__body-thumb">
        <ng-container *ngIf="isSuccessPopinType; else isFail">
          <svg viewBox="0 0 368 185" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd">
              <rect fill="#960011" x="38" width="19" height="4" rx="2" />
              <rect fill="#960011" x="11" y="48" width="19" height="4" rx="2" />
              <rect fill="#960011" x="107" y="181" width="241" height="4" rx="2" />
              <rect fill="#960011" x="44" y="87" width="10" height="4" rx="2" />
              <rect fill="#960011" x="27" width="8" height="4" rx="2" />
              <rect fill="#960011" y="48" width="8" height="4" rx="2" />
              <rect fill="#960011" x="78" y="181" width="26" height="4" rx="2" />
              <rect fill="#960011" x="33" y="87" width="8" height="4" rx="2" />
              <rect fill="#960011" x="49" y="101" width="8" height="4" rx="2" />
              <rect fill="#960011" x="30" y="130" width="8" height="4" rx="2" />
              <rect fill="#960011" x="46" y="26" width="8" height="4" rx="2" />
              <path
                d="M53.646 148.617h187.799V5a3.5 3.5 0 00-3.5-3.5H57.146a3.5 3.5 0 00-3.5 3.5v143.617zM242.301 27.234h9.863v55.617h-9.863zM308.759 27.234h2.002v55.617h-2.002zM316.187 26.502l11.643 54.376 1.87-.394-11.644-54.376-1.87.394z"
                stroke="#960011"
                stroke-width="3"
                fill="#E2001A"
              />
              <path
                d="M242.3 17.941v9.153h75.521c-.661-5.163-5.072-9.153-10.414-9.153h-65.106zM317.334 73.7v74.917h37.018V84.2c0-5.799-4.701-10.5-10.5-10.5h-26.518z"
                stroke="#960011"
                stroke-width="3"
                fill="#E2001A"
              />
              <path stroke="#960011" stroke-width="3" fill="#E2001A" d="M249.447 73.7h77.75v74.917h-77.75z" />
              <path d="M252.164 73.7H242.3v74.917h19.153V115.16h-9.29V73.7z" stroke="#960011" stroke-width="3" fill="#E2001A" />
              <path fill="#960011" d="M261.524 84.351h21.438v9.293h-21.438z" />
              <path
                d="M356.637 101.023v21.7a7.078 7.078 0 005.575-6.915v-7.87a7.078 7.078 0 00-5.575-6.915z"
                stroke="#960011"
                stroke-width="3"
                fill="#FFF"
              />
              <path
                d="M348.062 99.293h5.575v-5.578h-2.075a3.5 3.5 0 00-3.5 3.5v2.078zM348.062 101.578h5.575v5.578h-5.575zM348.062 109.441h5.575v5.578h-5.575zM348.062 117.304h5.575v5.578h-5.575zM348.062 125.168v2.078a3.5 3.5 0 003.5 3.5h2.075v-5.578h-5.575zM356.637 131.602v9.152h5.575v-5.652a3.5 3.5 0 00-3.5-3.5h-2.075zM356.637 141.61v19.16H363a3.5 3.5 0 003.5-3.5v-15.66h-9.863z"
                stroke="#960011"
                stroke-width="3"
                fill="#E2001A"
              />
              <path fill="#960011" d="M52.146 147.972h302.991v12.152H52.146z" />
              <g transform="matrix(-1 0 0 1 330.84 124.383)">
                <ellipse stroke="#960011" stroke-width="3" fill="#E2001A" cx="29.299" cy="29.309" rx="27.799" ry="27.809" />
                <ellipse stroke="#960011" stroke-width="3" fill="#F6F6F6" cx="28.98" cy="29.309" rx="14.742" ry="14.926" />
                <ellipse stroke="#960011" stroke-width="3" fill="#F6F6F6" cx="29.299" cy="28.987" rx="6.78" ry="6.874" />
                <ellipse fill="#960011" cx="29.299" cy="28.987" rx="3.822" ry="3.865" />
                <g transform="translate(15.923 16.104)" fill="#960011">
                  <ellipse cx="12.976" cy="1.31" rx="1.298" ry="1.31" />
                  <ellipse cx="12.976" cy="24.894" rx="1.298" ry="1.31" />
                  <ellipse cx="5.191" cy="4.586" rx="1.298" ry="1.31" />
                  <ellipse cx="5.191" cy="21.618" rx="1.298" ry="1.31" />
                  <ellipse cx="20.762" cy="4.586" rx="1.298" ry="1.31" />
                  <ellipse cx="20.762" cy="21.618" rx="1.298" ry="1.31" />
                  <ellipse cx="24.655" cy="13.102" rx="1.298" ry="1.31" />
                  <ellipse cx="1.298" cy="13.102" rx="1.298" ry="1.31" />
                </g>
              </g>
              <path
                d="M264.037 158.695a38.947 38.947 0 01-.369-5.361c0-21.122 16.957-38.244 37.874-38.244s37.874 17.122 37.874 38.244c0 1.82-.126 3.61-.37 5.361h-10.034c.287-1.631.437-3.31.437-5.026 0-15.748-12.643-28.515-28.24-28.515-15.595 0-28.238 12.767-28.238 28.515 0 1.715.15 3.395.437 5.026h-9.37z"
                fill="#960011"
              />
              <g transform="matrix(-1 0 0 1 161.48 124.383)">
                <ellipse stroke="#960011" stroke-width="3" fill="#E2001A" cx="29.299" cy="29.309" rx="27.799" ry="27.809" />
                <ellipse stroke="#960011" stroke-width="3" fill="#F6F6F6" cx="28.98" cy="29.309" rx="14.742" ry="14.926" />
                <ellipse stroke="#960011" stroke-width="3" fill="#F6F6F6" cx="29.299" cy="28.987" rx="6.78" ry="6.874" />
                <ellipse fill="#960011" cx="29.299" cy="28.987" rx="3.822" ry="3.865" />
                <g transform="translate(15.923 16.104)" fill="#960011">
                  <ellipse cx="12.976" cy="1.31" rx="1.298" ry="1.31" />
                  <ellipse cx="12.976" cy="24.894" rx="1.298" ry="1.31" />
                  <ellipse cx="5.191" cy="4.586" rx="1.298" ry="1.31" />
                  <ellipse cx="5.191" cy="21.618" rx="1.298" ry="1.31" />
                  <ellipse cx="20.762" cy="4.586" rx="1.298" ry="1.31" />
                  <ellipse cx="20.762" cy="21.618" rx="1.298" ry="1.31" />
                  <ellipse cx="24.655" cy="13.102" rx="1.298" ry="1.31" />
                  <ellipse cx="1.298" cy="13.102" rx="1.298" ry="1.31" />
                </g>
              </g>
              <path
                d="M94.677 158.695a38.947 38.947 0 01-.37-5.361c0-21.122 16.957-38.244 37.874-38.244 20.918 0 37.874 17.122 37.874 38.244 0 1.82-.126 3.61-.369 5.361h-10.035c.288-1.631.437-3.31.437-5.026 0-15.748-12.643-28.515-28.239-28.515s-28.24 12.767-28.24 28.515c0 1.715.15 3.395.438 5.026h-9.37z"
                fill="#960011"
              />
              <path d="M62.794 141.61v19.16h-6.363a3.5 3.5 0 01-3.5-3.5v-15.66h9.863z" stroke="#960011" stroke-width="3" fill="#E2001A" />
              <path
                d="M52.075 155.05v-25.593H50a3.5 3.5 0 00-3.5 3.5v18.594a3.5 3.5 0 003.5 3.5h2.075z"
                stroke="#960011"
                stroke-width="3"
                fill="#FFF"
              />
              <rect fill="#960011" x="111" y="117" width="13" height="4" rx="2" />
              <rect fill="#960011" x="99" y="117" width="8" height="4" rx="2" />
              <rect stroke="#960011" stroke-width="3" fill="#FFF" x="109.5" y="38.5" width="80" height="51" rx="8" />
              <path
                d="M120.168 87.88A6.5 6.5 0 01109.5 82.89V46a6.5 6.5 0 016.5-6.5h25a6.5 6.5 0 016.5 6.5v16.004a6.5 6.5 0 01-2.332 4.988l-25 20.888zM178.748 87.622a6.5 6.5 0 0010.752-4.916V45a6.5 6.5 0 00-6.5-6.5h-25a6.5 6.5 0 00-6.5 6.5v16.087a6.5 6.5 0 002.248 4.916l25 21.62z"
                stroke="#960011"
                stroke-width="3"
                fill="#FFF"
              />
              <path
                d="M153.047 73.55a6.5 6.5 0 01-7.476-.022l-32.824-23.222A6.5 6.5 0 01116.5 38.5h66.073a6.5 6.5 0 013.722 11.829L153.047 73.55z"
                stroke="#960011"
                stroke-width="3"
                fill="#FFF"
              />
              <g fill="#E2001A">
                <path
                  d="M143.682 56.919a.512.512 0 01-.415-.214.546.546 0 01.105-.748c1.374-1.061 3.478-1.695 5.628-1.695 2.141 0 4.24.634 5.614 1.695.23.177.276.511.104.747a.506.506 0 01-.724.108c-.928-.716-2.727-1.482-4.994-1.482-2.277 0-4.08.766-5.008 1.482a.502.502 0 01-.31.107"
                />
                <path
                  d="M144.155 57.831a.352.352 0 01-.274-.133.374.374 0 01.05-.514c1.188-1.008 3.083-1.61 5.07-1.61 1.976 0 3.865.602 5.054 1.61a.373.373 0 01.05.513.347.347 0 01-.499.05c-1.067-.903-2.788-1.442-4.606-1.442-1.799 0-3.57.553-4.62 1.443a.344.344 0 01-.225.083M141.38 50.144c-.21 0-.418.058-.6.17a1.25 1.25 0 00-.418 1.677c.21.367.599.597 1.016.601h.008c.212 0 .42-.059.603-.17a1.247 1.247 0 00.417-1.677 1.187 1.187 0 00-1.025-.601M148.993 51.086c-.655 0-1.189.55-1.189 1.224 0 .676.534 1.225 1.19 1.225.654 0 1.186-.55 1.186-1.225s-.532-1.224-1.187-1.224M148.993 48.019c-.655 0-1.189.55-1.189 1.224 0 .676.534 1.225 1.19 1.225.654 0 1.186-.55 1.186-1.225s-.532-1.224-1.187-1.224M145.834 51.512c-.105 0-.211.015-.314.043-.632.179-1.005.854-.832 1.505.14.525.608.896 1.14.901h.028a1.186 1.186 0 001.01-.618 1.25 1.25 0 00.114-.929 1.197 1.197 0 00-1.146-.902M142.895 52.783c-.212 0-.42.06-.602.17a1.213 1.213 0 00-.546.75c-.078.318-.033.647.128.929.21.366.6.596 1.018.6h.007c.212 0 .419-.059.603-.17a1.21 1.21 0 00.545-.75 1.25 1.25 0 00-.128-.927 1.19 1.19 0 00-1.025-.602M152.464 51.555a1.162 1.162 0 00-.313-.043c-.533 0-1.004.371-1.144.902-.173.651.2 1.326.83 1.504.104.03.21.044.315.044.532 0 1.004-.371 1.145-.902.172-.651-.201-1.326-.833-1.505M153.248 48.597a1.152 1.152 0 00-.313-.043c-.533 0-1.003.371-1.144.902-.173.65.201 1.326.832 1.504.102.029.208.044.313.044.533 0 1.004-.371 1.145-.902.173-.652-.2-1.326-.833-1.505M155.692 52.954a1.158 1.158 0 00-.603-.17c-.418 0-.81.23-1.023.6a1.25 1.25 0 00.417 1.678c.184.112.392.17.604.17.418 0 .81-.23 1.023-.6a1.252 1.252 0 00-.418-1.678M157.751 51.064a1.214 1.214 0 00-.546-.75 1.159 1.159 0 00-.602-.17c-.418 0-.81.23-1.023.6a1.247 1.247 0 00.417 1.678c.183.112.392.17.603.17.42 0 .811-.23 1.024-.6.16-.282.206-.611.127-.928M145.05 51.004c.107 0 .212-.015.314-.044.63-.178 1.004-.853.831-1.504a1.197 1.197 0 00-1.145-.902c-.106 0-.212.015-.314.043-.632.179-1.005.853-.833 1.505.14.525.61.896 1.14.901h.008z"
                />
              </g>
            </g>
          </svg>
        </ng-container>

        <ng-template #isFail>
          <svg viewBox="0 0 385 185" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd">
              <g transform="translate(60)">
                <rect fill="#960011" x="23" y="181" width="280" height="4" rx="2" />
                <path
                  d="M193.799 1.5c.966 0 1.841.392 2.475 1.025A3.489 3.489 0 01197.299 5h0v143.617H9.5V5c0-.966.392-1.841 1.025-2.475A3.489 3.489 0 0113 1.5h0z"
                  stroke="#960011"
                  stroke-width="3"
                  fill="#E2001A"
                />
                <circle stroke="#960011" stroke-width="3" fill="#FFF" cx="103.5" cy="57.5" r="37" />
                <path
                  stroke="#960011"
                  stroke-width="3"
                  fill="#E2001A"
                  d="M197.301 27.234h9.863v55.617h-9.863zM263.759 27.234h2.002v55.617h-2.002zM271.187 26.502l1.87-.394 11.642 54.376-1.87.394-11.642-54.376z"
                />
                <path
                  d="M262.407 17.941c2.9 0 5.525 1.176 7.425 3.076a10.471 10.471 0 012.97 5.928h0l-75.501.146v-9.15zM298.852 73.7c2.9 0 5.524 1.174 7.424 3.075 1.9 1.9 3.076 4.525 3.076 7.424h0v64.418h-37.018V73.7z"
                  stroke="#960011"
                  stroke-width="3"
                  fill="#E2001A"
                />
                <path stroke="#960011" stroke-width="3" fill="#E2001A" d="M204.447 73.7h77.75v74.917h-77.75z" />
                <path d="M207.164 73.7l-.001 41.46h9.29v33.457h-19.152V73.7h9.863z" stroke="#960011" stroke-width="3" fill="#E2001A" />
                <path fill="#960011" d="M216.524 84.351h21.438v9.293h-21.438z" />
                <path
                  d="M311.654 101.026a7.062 7.062 0 013.486 1.91 7.053 7.053 0 012.072 5.003h0v7.869a7.052 7.052 0 01-1.997 4.926 7.068 7.068 0 01-3.338 1.933h0z"
                  stroke="#960011"
                  stroke-width="3"
                  fill="#FFF"
                />
                <path
                  d="M308.637 93.715v5.578h-5.575v-2.078c0-.967.392-1.842 1.025-2.475a3.489 3.489 0 012.475-1.025h2.075zM303.062 101.578h5.575v5.578h-5.575zM303.062 109.441h5.575v5.578h-5.575zM303.062 117.304h5.575v5.578h-5.575zM308.637 125.168v5.578h-2.075a3.489 3.489 0 01-2.475-1.025 3.489 3.489 0 01-1.025-2.475h0v-2.078h5.575zM313.712 131.602c.967 0 1.842.391 2.475 1.025a3.489 3.489 0 011.025 2.475h0v5.652h-5.575v-9.152zM321.5 141.61v15.66c0 .966-.392 1.841-1.025 2.474A3.489 3.489 0 01318 160.77h0-6.363v-19.16h9.863z"
                  stroke="#960011"
                  stroke-width="3"
                  fill="#E2001A"
                />
                <path fill="#960011" d="M7.146 147.972h302.991v12.152H7.146z" />
                <g transform="matrix(-1 0 0 1 285.84 124.383)">
                  <ellipse stroke="#960011" stroke-width="3" fill="#E2001A" cx="29.299" cy="29.309" rx="27.799" ry="27.809" />
                  <ellipse stroke="#960011" stroke-width="3" fill="#F6F6F6" cx="28.98" cy="29.309" rx="14.742" ry="14.926" />
                  <ellipse stroke="#960011" stroke-width="3" fill="#F6F6F6" cx="29.299" cy="28.987" rx="6.78" ry="6.874" />
                  <ellipse fill="#960011" cx="29.299" cy="28.987" rx="3.822" ry="3.865" />
                  <g transform="translate(15.923 16.104)" fill="#960011">
                    <ellipse cx="12.976" cy="1.31" rx="1.298" ry="1.31" />
                    <ellipse cx="12.976" cy="24.894" rx="1.298" ry="1.31" />
                    <ellipse cx="5.191" cy="4.586" rx="1.298" ry="1.31" />
                    <ellipse cx="5.191" cy="21.618" rx="1.298" ry="1.31" />
                    <ellipse cx="20.762" cy="4.586" rx="1.298" ry="1.31" />
                    <ellipse cx="20.762" cy="21.618" rx="1.298" ry="1.31" />
                    <ellipse cx="24.655" cy="13.102" rx="1.298" ry="1.31" />
                    <ellipse cx="1.298" cy="13.102" rx="1.298" ry="1.31" />
                  </g>
                </g>
                <path
                  d="M256.542 115.09c20.917 0 37.874 17.122 37.874 38.244 0 1.82-.126 3.61-.37 5.362h-10.034c.287-1.632.437-3.312.437-5.027 0-15.748-12.643-28.515-28.24-28.515-15.595 0-28.238 12.767-28.238 28.515 0 1.715.15 3.395.437 5.026h-9.37a38.947 38.947 0 01-.37-5.361c0-21.122 16.957-38.244 37.874-38.244z"
                  fill="#960011"
                />
                <g transform="matrix(-1 0 0 1 116.48 124.383)">
                  <ellipse stroke="#960011" stroke-width="3" fill="#E2001A" cx="29.299" cy="29.309" rx="27.799" ry="27.809" />
                  <ellipse stroke="#960011" stroke-width="3" fill="#F6F6F6" cx="28.98" cy="29.309" rx="14.742" ry="14.926" />
                  <ellipse stroke="#960011" stroke-width="3" fill="#F6F6F6" cx="29.299" cy="28.987" rx="6.78" ry="6.874" />
                  <ellipse fill="#960011" cx="29.299" cy="28.987" rx="3.822" ry="3.865" />
                  <g transform="translate(15.923 16.104)" fill="#960011">
                    <ellipse cx="12.976" cy="1.31" rx="1.298" ry="1.31" />
                    <ellipse cx="12.976" cy="24.894" rx="1.298" ry="1.31" />
                    <ellipse cx="5.191" cy="4.586" rx="1.298" ry="1.31" />
                    <ellipse cx="5.191" cy="21.618" rx="1.298" ry="1.31" />
                    <ellipse cx="20.762" cy="4.586" rx="1.298" ry="1.31" />
                    <ellipse cx="20.762" cy="21.618" rx="1.298" ry="1.31" />
                    <ellipse cx="24.655" cy="13.102" rx="1.298" ry="1.31" />
                    <ellipse cx="1.298" cy="13.102" rx="1.298" ry="1.31" />
                  </g>
                </g>
                <path
                  d="M87.181 115.09c20.918 0 37.874 17.122 37.874 38.244 0 1.82-.126 3.61-.369 5.362h-10.035c.288-1.632.437-3.312.437-5.027 0-15.748-12.643-28.515-28.239-28.515s-28.24 12.767-28.24 28.515c0 1.715.15 3.395.438 5.026h-9.37a38.947 38.947 0 01-.37-5.361c0-21.122 16.957-38.244 37.874-38.244z"
                  fill="#960011"
                />
                <path
                  d="M7.931 141.61v15.66c0 .966.392 1.841 1.026 2.474a3.489 3.489 0 002.474 1.026h6.363v-19.16H7.931z"
                  stroke="#960011"
                  stroke-width="3"
                  fill="#E2001A"
                />
                <path
                  d="M7.075 129.457v25.594H5a3.489 3.489 0 01-2.475-1.025A3.489 3.489 0 011.5 151.55h0v-18.594c0-.966.392-1.841 1.025-2.475A3.489 3.489 0 015 129.457h2.075z"
                  stroke="#960011"
                  stroke-width="3"
                  fill="#FFF"
                />
              </g>
              <path
                d="M48 121a6 6 0 110 12 6 6 0 010-12zm127.698-76.032l.353.353a1.5 1.5 0 010 2.122l-10.075 10.075 10.075 10.077a1.5 1.5 0 010 2.122l-.353.353a1.5 1.5 0 01-2.122 0L163.5 59.994 153.424 70.07a1.5 1.5 0 01-2.122 0l-.353-.353a1.5 1.5 0 010-2.122l10.076-10.076-10.076-10.076a1.5 1.5 0 010-2.122l.353-.353a1.5 1.5 0 012.122 0L163.5 55.044l10.076-10.076a1.5 1.5 0 012.122 0z"
                fill="#960011"
              />
              <circle fill="#960011" cx="39" cy="148" r="6" />
              <circle fill="#960011" cx="41.5" cy="118.5" r="11.5" />
              <circle fill="#960011" cx="30.5" cy="145.5" r="11.5" />
              <circle fill="#960011" cx="19" cy="146" r="19" />
            </g>
          </svg>
        </ng-template>
      </div>

      <!-- Text -->
      <ng-container *ngIf="isSuccessPopinType; else isFailText">
        <h3 class="rc-title-gamma" i18n="@@email-success_title">On the way!</h3>
        <p class="rc-paragraph">{{ getPoppinTypeDescription() }}</p>
      </ng-container>
      <ng-template #isFailText>
        <h3 class="rc-title-gamma" i18n="@@error_try-again">Please try again</h3>
        <p class="rc-paragraph" i18n="@@error_send-email-text">Something went wrong when sending this email !</p>
      </ng-template>
    </div>

    <div *ngIf="isSuccessPopinType; else isFailFooter" class="email-success-dialog__footer">
      <button *ngIf="isSuccessPopinType; else isFailFooter" class="rc-button rc-button--filled-small" id="okContinue" (click)="close()">
        <span class="rc-button__title" i18n="@@action_continue">{{ 'continue' | titlecase }}</span>
      </button>
    </div>
    <ng-template #isFailFooter>
      <div class="email-error-dialog__footer">
        <button class="rc-button" (click)="close()" type="button">
          <span class="rc-button__title--link" i18n="">{{ 'close' | titlecase }}</span>
        </button>
        <span *ngIf="isRetryAllowed" class="rc-button__separator" i18n="@@action_separator">or</span>
        <button *ngIf="isRetryAllowed" class="rc-button rc-button--filled-small" id="emailRetry" (click)="retry()">
          <span class="rc-button__title" i18n="">{{ 'retry' | titlecase }}</span>
        </button>
      </div>
      <button class="rc-button" id="contactButton">
        <a
          class="rc-button__title--link"
          i18n="@@action_contact-support"
          href="mailto:{{ mailto.recipient }}?subject={{ mailto.subject }}&body={{ mailto.body }}"
          >Contact support</a
        >
      </button>
    </ng-template>
  </div>
</app-rc-dialog-wrapper>
