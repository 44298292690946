import { Patient, PetInfo, Product } from '@app/core/models';
import { Helper, NeuteredCodeEnum } from '@app/shared/utils';
import { differenceInCalendarYears } from 'date-fns';
import { TrackingRecommendationEvent } from '..';
import { EventEnum } from '../types/enums';
import { formatBirthdate } from '@app/shared/utils/static-helpers/gtm-helper';

export const recommendation = (patient?: Patient, pet?: PetInfo, products?: Product[]): TrackingRecommendationEvent => {
  return {
    event: EventEnum.NUTRITIONAL_RECOMMENDATION,
    pet: {
      activityLevel: pet.petActivity,
      age: `${differenceInCalendarYears(new Date(), pet.birthdate)}`,
      BCS: pet?.bcs,
      breed: pet?.breed,
      gender: pet?.gender,
      birthdate: formatBirthdate(pet?.birthdate),
      id: patient?.id,
      lifestage: pet?.lifestage,
      name: pet?.name,
      neutered: pet?.neutered ? NeuteredCodeEnum.Neutered : NeuteredCodeEnum.NotNeutered,
      newPet: !patient,
      newPetOwner: !patient,
      ownerId: patient?.owner?.id,
      pathology: pet?.pathologies?.join('|') || 'None',
      reproductionStatus: pet?.reproductionStatus,
      sensitivities: pet?.sensitivities?.join('|') || 'None',
      specie: pet?.speciesCode,
      weight: `${pet?.weight}`,
      neuteredToday: pet?.neuteredToday ? true : false,
    },
    ecommerce: {
      purchase: {
        actionField: {
          id: `V3_${Helper.randomId()}`,
          type: 'Recommendation',
        },
        products: products.map((product) => ({
          id: product?.id,
          name: product?.name,
          brand: 'Royal Canin',
          category: `${product?.speciesCode}/${product?.rangeCategory}/${product?.productType}`,
          hasPrice: Boolean(product.packages.find((pack) => !!pack?.productPrice?.clinicPrice && pack?.productPrice?.clinicPrice !== -1)),
          territory: product?.territory,
          specificNeeds: product?.specificNeeds.join('|'),
          quantity: 1,
          variant: `${product?.packages[0]?.weightInG * 0.001}`,
          isToolRecommended: product?.isRecommendedProduct === undefined ? true : product?.isRecommendedProduct,
        })),
      },
    },
  };
};
