import { TransportItemType } from '../../transports';
import { deepEqual, getCurrentTimestamp, isNull } from '../../utils';
import { defaultExceptionType } from './const';
let stacktraceParser;
export function initializeExceptionsAPI(_unpatchedConsole, internalLogger, config, metas, transports, tracesApi) {
  var _a;
  internalLogger.debug('Initializing exceptions API');
  let lastPayload = null;
  stacktraceParser = (_a = config.parseStacktrace) !== null && _a !== void 0 ? _a : stacktraceParser;
  const changeStacktraceParser = newStacktraceParser => {
    internalLogger.debug('Changing stacktrace parser');
    stacktraceParser = newStacktraceParser !== null && newStacktraceParser !== void 0 ? newStacktraceParser : stacktraceParser;
  };
  const getStacktraceParser = () => stacktraceParser;
  const pushError = (error, {
    skipDedupe,
    stackFrames,
    type,
    context,
    spanContext
  } = {}) => {
    type = type || error.name || defaultExceptionType;
    const item = {
      meta: metas.value,
      payload: {
        type,
        value: error.message,
        timestamp: getCurrentTimestamp(),
        trace: spanContext ? {
          trace_id: spanContext.traceId,
          span_id: spanContext.spanId
        } : tracesApi.getTraceContext(),
        context: context !== null && context !== void 0 ? context : {}
      },
      type: TransportItemType.EXCEPTION
    };
    stackFrames = stackFrames !== null && stackFrames !== void 0 ? stackFrames : error.stack ? stacktraceParser === null || stacktraceParser === void 0 ? void 0 : stacktraceParser(error).frames : undefined;
    if (stackFrames === null || stackFrames === void 0 ? void 0 : stackFrames.length) {
      item.payload.stacktrace = {
        frames: stackFrames
      };
    }
    const testingPayload = {
      type: item.payload.type,
      value: item.payload.value,
      stackTrace: item.payload.stacktrace,
      context: item.payload.context
    };
    if (!skipDedupe && config.dedupe && !isNull(lastPayload) && deepEqual(testingPayload, lastPayload)) {
      internalLogger.debug('Skipping error push because it is the same as the last one\n', item.payload);
      return;
    }
    lastPayload = testingPayload;
    internalLogger.debug('Pushing exception\n', item);
    transports.execute(item);
  };
  changeStacktraceParser(config.parseStacktrace);
  return {
    changeStacktraceParser,
    getStacktraceParser,
    pushError
  };
}
