import { setFaroOnGlobalObject } from './faroGlobalObject';
import { setInternalFaroOnGlobalObject } from './internalFaroGlobalObject';
export let faro = {};
export function registerFaro(unpatchedConsole, internalLogger, config, metas, transports, api, instrumentations) {
  internalLogger.debug('Initializing Faro');
  faro = {
    api,
    config,
    instrumentations,
    internalLogger,
    metas,
    pause: transports.pause,
    transports,
    unpatchedConsole,
    unpause: transports.unpause
  };
  setInternalFaroOnGlobalObject(faro);
  setFaroOnGlobalObject(faro);
  return faro;
}
