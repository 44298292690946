import { Component, Input } from '@angular/core';
import { IconName } from '@app/shared/utils/icon/icons';

@Component({
  selector: 'app-qr-code-display',
  templateUrl: './qr-code-display.component.html',
  styleUrls: ['./qr-code-display.component.scss'],
})
export class QrCodeDisplayComponent {
  @Input() qrCodeLink: string;
  @Input() fullWidth = false;
  @Input() instructions: string;
  public readonly IconName = IconName;
}
