import { Clinic, Consultation, Patient, PetInfo, PetOwner, RenalDetectRecommendation } from '@app/core/models';
import { NextVisitData } from '@app/core/models/visit-next';
import { AllowanceParams } from '@app/pages/allowance/allowance';
import { NutritionalChoice, Tool } from '@app/shared/utils';
import { createAction, props } from '@ngrx/store';

export const setConsultationAllowanceParams = createAction('[Consultation] Set Allowance Params', props<{ value: AllowanceParams }>());
export const setCurrentConsultationVisitDate = createAction('[Consultation] Set Current Visit Date', props<{ value: Date }>());
export const setConsultationPatient = createAction('[Consultation] Set Patient', props<{ value: Patient }>());
export const setConsultationRenalDetectRecommendation = createAction(
  '[Consultation] Set Renal Detect Recommendation',
  props<{ value: RenalDetectRecommendation }>()
);
export const setConsultationRenalDetectNutritionalChoice = createAction(
  '[Consultation] Set Renal Detect Nutritional Choice',
  props<{ value: NutritionalChoice }>()
);
export const setConsultationLastConsultation = createAction('[Consultation] Set Last Consultation', props<{ value: Consultation }>());

export const setConsultationSetSkipProductBook = createAction(
  '[Consultation] Set the fact to skip product book during a flow',
  props<{ value: boolean }>()
);
export const resetConsultationData = createAction('[Consultation] Reset Data');
/***
 * Create consultation action
 */
export const createCurrentConsultation = createAction(
  '[Consultation] Create Current Consultation',
  props<{
    consultationData: Partial<Consultation>;
    petInfo: PetInfo;
    tool: Tool;
    createPatientPopinTitle?: string;
    owner?: PetOwner;
    isRenewal?: boolean;
  }>()
);
export const createCurrentConsultationSuccess = createAction(
  '[Consultation] Create Current Consultation Success',
  props<{ consultation: Consultation; newPet: boolean }>()
);
export const createCurrentConsultationFail = createAction('[Consultation] Create Current Consultation Fail', props<{ error: string }>());
export const setCreatingCurrentConsultation = createAction('[Consultation] Creating Current Consultation', props<{ value: boolean }>());
export const resetCurrentConsultation = createAction('[Consultation] Reset Current Consultation');

/***
 * Pet owner actions
 */
export const setConsultationPetOwner = createAction('[Consultation] Set Pet Owner', props<{ value: PetOwner }>());
export const updatePetOwner = createAction('[Consultation] Update Pet Owner', props<{ value: PetOwner }>());
export const updatePetOwnerSuccess = createAction('[Consultation] Update Pet Owner Success', props<{ value: PetOwner }>());
export const deletePetOwnerAddress = createAction('[Consultation] Delete Pet Owner Address', props<{ id: string }>());
export const petOwnerFail = createAction('[Consultation] Pet Owner Fail', props<{ error: string }>());

/***
 * convert consultation flow
 */
export const convertConsultationFlow = createAction('[Consultation] convert consultation flow', props<{ flow: Tool }>());
export const resetConsultationFlow = createAction('[Consultation] reset consultation flow', props<{ coreTool: Tool }>());

/***
 * get & save Next visit localy in store
 */
export const getExpectedNextVisit = createAction('[Consultation] Get exptected next visit', props<{ value: PetInfo; nextDate: Date }>());

export const setExpectedNextVisit = createAction('[Consultation] Set exptected next visit', props<{ value: NextVisitData }>());

/***
 * put Next visit to backend
 */
export const updateConsultationNextVisit = createAction(
  '[Consultation] Update next visit',
  props<{ nextVisit: { expected: { visitDateTime: string } }; patientId: string; consultationId: string }>()
);

export const resetExpectedNextVisit = createAction('[Consultation] reset next visit');
export const updateConsultationNextVisitSuccess = createAction('[Consultation] Update next visit success');

export const checkForExistingOwner = createAction(
  '[Consultation] Check For Existing Owner',
  props<{ email: string; owner: PetOwner; clinic: Clinic; petInfo: PetInfo; currentConsultation: Consultation }>()
);
export const placeVetSourceOrder = createAction(
  '[Consultation] Place Vet Source Order',
  props<{ email: string; clinic: Clinic; petInfo: PetInfo; currentConsultation: Consultation }>()
);
export const placeVetSourceOrderSuccess = createAction('[Consultation] Place Vet Source Order Success');
export const placeVetSourceOrderError = createAction('[Consultation] Place Vet Source Order Error', props<{ error: string }>());
