export const PRODUCTS_FIELDS_FILTER = [
  '_id',
  'world_wide_main_item',
  'species',
  'bag_image',
  'product_title',
  'reference_energy_value_per_weight',
  'reference_energy_category',
  'vet_program',
  'lifestage',
  'specific_needs',
  'range',
  'retail_channel',
  'recommended_for',
  'not_recommended_for',
  'territory',
  'technology',
  'packs',
  'density',
  'pet_size',
  'product_pillar',
  'clinical_alliance',
  'wms_code',
];

export const PRODUCTS_FIELDS_FILTER_FR = [...PRODUCTS_FIELDS_FILTER, 'local_benefits'];
