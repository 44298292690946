import { TransportItemType, transportItemTypeToBodyKey } from './const';
export function mergeResourceSpans(traces, resourceSpans) {
  var _a, _b;
  if (resourceSpans === undefined) {
    return traces;
  }
  if (traces === undefined) {
    return {
      resourceSpans
    };
  }
  const currentResource = (_a = traces.resourceSpans) === null || _a === void 0 ? void 0 : _a[0];
  if (currentResource === undefined) {
    return traces;
  }
  const currentSpans = (currentResource === null || currentResource === void 0 ? void 0 : currentResource.scopeSpans) || [];
  const newSpans = ((_b = resourceSpans === null || resourceSpans === void 0 ? void 0 : resourceSpans[0]) === null || _b === void 0 ? void 0 : _b.scopeSpans) || [];
  return Object.assign(Object.assign({}, traces), {
    resourceSpans: [Object.assign(Object.assign({}, currentResource), {
      scopeSpans: [...currentSpans, ...newSpans]
    })]
  });
}
export function getTransportBody(item) {
  let body = {
    meta: {}
  };
  if (item[0] !== undefined) {
    body.meta = item[0].meta;
  }
  item.forEach(currentItem => {
    switch (currentItem.type) {
      case TransportItemType.LOG:
      case TransportItemType.EVENT:
      case TransportItemType.EXCEPTION:
      case TransportItemType.MEASUREMENT:
        const bk = transportItemTypeToBodyKey[currentItem.type];
        const signals = body[bk];
        body = Object.assign(Object.assign({}, body), {
          [bk]: signals === undefined ? [currentItem.payload] : [...signals, currentItem.payload]
        });
        break;
      case TransportItemType.TRACE:
        body = Object.assign(Object.assign({}, body), {
          traces: mergeResourceSpans(body.traces, currentItem.payload.resourceSpans)
        });
        break;
    }
  });
  return body;
}
