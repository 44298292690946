import { Programs } from '@app/shared/utils/enums/programs.enum';
import { getBasicDryRation, getCupDryRation, getBasicWetRation } from '@app/shared/utils/static-helpers/rationing-helper';
import { Consultation, NutritionDataProduct, NutritionRecommendation, Product, Recommendation, Prediction, Vet } from '@app/core/models';
import { MeasurementCodeType, ProductType, Tool } from '../enums';
import ProductHelper from './product-helper';
import { formatNutritionRations } from './rationing-helper';
import { ConsultationState } from '@app/store/consultation';
import { AllowanceState } from '@app/pages/allowance/store/allowance.state';
import { isNotNullOrUndefined } from '@app/shared/operators/not-null-or-undefined';

export function buildRecommendation(
  products: Product[],
  selectedPackIds: string[],
  nutritionalRecommendation: NutritionRecommendation
): Recommendation {
  const dryRations = nutritionalRecommendation?.dryRations || [];
  const wetRations = nutritionalRecommendation?.wetRations || [];
  return {
    diagnostic: nutritionalRecommendation?.diagnostic,
    nutritionData: products
      .filter((product) =>
        product.productType === ProductType.Dry
          ? nutritionalRecommendation?.basicDryRation?.quantityPerDay > 0
          : nutritionalRecommendation?.basicWetRation?.quantityPerDay > 0
      )
      .map((product) => {
        return {
          product: ProductHelper.formatProductIntoNutritionDataProduct(product, selectedPackIds),
          rations: product.productType === ProductType.Dry ? dryRations : wetRations,
          basicRation: product.productType === ProductType.Dry ? getBasicDryRation(dryRations) : getBasicWetRation(wetRations),
          cupRation: product.productType === ProductType.Dry ? getCupDryRation(dryRations) : null,
        };
      }),
  };
}

export function buildConsultationApiBody(consultation: Partial<Consultation> = {}, isRenewal = false): any {
  const { visit = {} } = consultation;
  const { weight, firstWeight } = visit;
  const weightToCreate = {
    bcs: weight?.bcs,
    measure: weight?.measure,
    measureUnit: weight?.measureUnit,
    weightDate: isRenewal ? new Date() : weight?.weightDate,
    type: weight?.type,
  };
  const firstWeightToCreate = {
    bcs: firstWeight?.bcs,
    measure: firstWeight?.measure,
    measureUnit: firstWeight?.measureUnit,
    weightDate: firstWeight?.weightDate,
    type: firstWeight?.type,
  };
  return {
    date: isRenewal ? new Date() : consultation.date,
    tool: consultation.tool,
    comments: consultation.comments,
    renalDetect: consultation.prediction || undefined,
    pathologies: consultation.pathologies,
    sensitivities: consultation.sensitivities,
    targetVisitId: isRenewal ? undefined : consultation.targetVisitId,
    visit: {
      id: isRenewal ? undefined : consultation.id,
      weight: weightToCreate,
      firstWeight: firstWeightToCreate,
      firstVisitDate: visit.firstVisitDate,
      visitDateTime: isRenewal ? new Date() : visit.visitDateTime,
      program: visit.program,
      recommendation: {
        diagnostic: consultation?.visit?.recommendation?.diagnostic,
        nutritionData: consultation?.visit?.recommendation?.nutritionData.map((item) => ({
          product: item.product,
          rations: item.rations,
        })),
      },
    },
  };
}
export function setUndefinedClinicPrice(consultation: Consultation): Consultation {
  return {
    ...consultation,
    visit: {
      ...consultation.visit,
      recommendation: {
        ...consultation.visit.recommendation,
        nutritionData: consultation.visit.recommendation.nutritionData.map((nutrition) => {
          if (isNotNullOrUndefined(nutrition.product.productDetail.package.productPrice.clinicPrice)) {
            return nutrition;
          }

          return {
            ...nutrition,
            product: {
              ...nutrition.product,
              productDetail: {
                ...nutrition.product.productDetail,
                package: {
                  ...nutrition.product.productDetail.package,
                  productPrice: {
                    ...nutrition.product.productDetail.package.productPrice,
                    clinicPrice: -1,
                  },
                },
              },
            },
          };
        }),
      },
    },
  };
}

export function buildConsultationData({
  consultationState,
  allowanceState,
  selectedPackIds,
  prediction,
  products,
  program,
  vet,
  tool,
  currentBigMeasurementUnit,
}: {
  consultationState: ConsultationState;
  allowanceState: AllowanceState;
  selectedPackIds: string[];
  prediction: Prediction | null;
  products: Product[];
  program: Programs;
  vet: Vet;
  currentBigMeasurementUnit: MeasurementCodeType;
  tool?: Tool;
}): Consultation {
  const { allowanceParams: params, lastConsultation, currentConsultationVisitDate } = consultationState;
  const { nutritionRecommendation } = allowanceState;
  if (!params?.deprecatedPetInfo || !nutritionRecommendation) return;
  const visitDate = currentConsultationVisitDate || new Date();
  const weight =
    (params.deprecatedPetInfo.weight && {
      weightDate: visitDate,
      bcs: params.deprecatedPetInfo.bcs,
      measure: params.deprecatedPetInfo.weight,
      measureUnit: currentBigMeasurementUnit,
      type: 0,
    }) ||
    null;

  return <Consultation>{
    date: visitDate,
    tool: tool || params.tool,
    vetId: vet?.id,
    visit: {
      /**
       * this visit id is defined by the previous consulation
       */
      id: lastConsultation?.nextVisitId,
      /**
       * If there is already a program started and it matches the current one,
       * we pass the first visit data to the new consultation.
       * It firstVisitDate does not exist in lastConsultation.visit, then it was the first.
       */
      ...(lastConsultation?.visit.program === program
        ? {
            firstVisitDate: lastConsultation.visit.firstVisitDate || lastConsultation.visit.visitDateTime,
            firstWeight: lastConsultation.visit.firstWeight || lastConsultation.visit.weight,
          }
        : /**
           * If the program is different or lastConsultation non existent, this visit becomes the first one
           */
          {
            firstVisitDate: visitDate,
            firstWeight: weight,
          }),
      recommendation: buildRecommendation(products, selectedPackIds, nutritionRecommendation),
      /**
       * API requires to force the program name if we're not in a weight manangement program
       */
      program,
      visitDateTime: visitDate,
      weight,
    },
    pathologies: params.deprecatedPetInfo.pathologies,
    sensitivities: params.deprecatedPetInfo.sensitivities,
    prediction,
    /**
     * id of the target visit, passed from lastConsultation
     */
    targetVisitId: lastConsultation?.targetVisitId,
  };
}

export function buildConsultationDataFromExistingConsultation(
  lastConsultation: Partial<Consultation>,
  products: Product[],
  selectedPackIds: string[]
): Partial<Consultation> {
  return {
    ...lastConsultation,
    visit: {
      ...lastConsultation.visit,
      recommendation: {
        ...lastConsultation.visit.recommendation,
        nutritionData: lastConsultation.visit.recommendation.nutritionData
          .filter((item) => !!products.find((product) => product.mainItemId === item.product.mainItemId))
          .map((item) => {
            const matchingProduct = products.find((product) => product.mainItemId === item.product.mainItemId);
            const selectedPack = matchingProduct.packages.find((pack) => selectedPackIds.includes(pack.sCode));
            return {
              rations: item.rations,
              basicRation: item.basicRation,
              cupRation: item.cupRation,
              product: {
                mainItemId: matchingProduct.mainItemId,
                productType: matchingProduct.productType,
                energyCategory: matchingProduct.energyCategory,
                productDetail: {
                  id: matchingProduct.id,
                  energy: matchingProduct.energy,
                  details: {
                    name: matchingProduct.name,
                    publicUrl: matchingProduct.image,
                  },
                  package: selectedPack && {
                    sCode: selectedPack.sCode,
                    ean: selectedPack.ean,
                    type: selectedPack.type,
                    text: selectedPack.text,
                    weight: selectedPack.weight,
                    measurementUnitCode: selectedPack.measurementUnitCode,
                    productPrice: selectedPack.productPrice,
                  },
                },
              },
            };
          }),
      },
    },
  };
}

export function formatConsultation(consultation: any): Consultation {
  const visit = consultation?.visit;
  const recommendation = visit?.recommendation;

  // TODO recommendation is now correctly formatted, we need to do the same with the rest of consultation
  return {
    ...consultation,
    prediction: consultation?.renalDetect,
    visit: {
      ...(visit || {}),
      recommendation: formatRecommendation(recommendation),
    },
  };
}

export function formatRecommendation(data: any = {}): Recommendation {
  const nutritionData = data?.nutritionData;
  return {
    diagnostic: data?.diagnostic || {
      kCalAllocation: 0,
      recommendedAllocation: 0,
    },
    nutritionData:
      nutritionData && nutritionData.length
        ? nutritionData.map((item) => {
            const product = formatNutritionProduct(item?.product);
            const rations = formatNutritionRations(item?.rations);
            return {
              ...item,
              product,
              rations,
              basicRation: product.productType === ProductType.Dry ? getBasicDryRation(rations) : getBasicWetRation(rations),
              cupRation: product.productType === ProductType.Dry ? getCupDryRation(rations) : null,
            };
          })
        : [],
  };
}

export function formatNutritionProduct(data: any = {}): NutritionDataProduct {
  const productDetail = data?.productDetail;
  const productDetailPackage = productDetail?.package;
  return {
    mainItemId: data.mainItemId || 0,
    productType: data.productType || ProductType.Unknown,
    energyCategory: data.energyCategory || 0,
    productDetail: {
      id: productDetail?.id || '',
      energy: productDetail?.energy || 0,
      details: {
        name: productDetail?.details?.name || '',
        publicUrl: productDetail?.details?.publicUrl || '',
      },
      package: {
        sCode: productDetailPackage?.sCode || '',
        ean: productDetailPackage?.ean || '',
        type: productDetailPackage?.type || '',
        text: productDetailPackage?.text || '',
        weight: productDetailPackage?.weight || 0,
        measurementUnitCode: productDetailPackage?.measurementUnitCode,
        productPrice: {
          clinicPrice: productDetailPackage?.clinicProductPrice,
          price: -1,
        },
      },
    },
  };
}
