import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Consultation, Package, Patient, PetInfo, Product, SmartRecoApiBody, Weight } from '@app/core/models';
import { RecommendationProgramApiBody } from '@app/core/models/program';
import { Logger } from '@app/core/services/utils/logger';
import { MeasurementCodeType, ReproductionStatusCode } from '@app/shared/utils';
import { MeasureHelper } from '@app/shared/utils/static-helpers/measure-helper';
import { EnumsService } from './enums/enums.service';

@Injectable()
export class ApiManagerService {
  constructor(private enumService: EnumsService, private logger: Logger, @Inject(LOCALE_ID) protected locale: string) {}

  buildRecommendationProgramBody(patient: Patient, lastConsultation: Consultation, currentWeight: Weight): RecommendationProgramApiBody {
    const currentVisitWeight = MeasureHelper.convertWeightObjInPlace(currentWeight, MeasurementCodeType.Gram);
    const lastVisitWeight = MeasureHelper.convertWeightObjInPlace(lastConsultation.visit.weight, MeasurementCodeType.Gram);
    const idealBodyWeight = MeasureHelper.convertWeightObjInPlace(patient.pet.idealBodyWeight, MeasurementCodeType.Gram);
    return {
      lastVisitWeight,
      currentVisitWeight,
      idealBodyWeight,
      firstVisitDate: lastConsultation.visit.firstVisitDate,
      lastVisitProgram: lastConsultation.visit.program,
    };
  }

  /**
   * Methods for getting pack information of a product
   * Used in calculator
   */
  getProductPack(product: Product, packId: string): Package {
    return product.packages.find((pack) => packId === pack.sCode);
  }

  /**
   * Build POST smart-reco recommendation/products input API
   */
  buildApiSmartRecoInput(petInfo: PetInfo): SmartRecoApiBody {
    const { birthdate, speciesCode, pathologies, sensitivities, breedObject, bcs, weight, neutered, reproductionStatus } = petInfo;

    if (!speciesCode || !birthdate || !pathologies || !sensitivities || !breedObject) {
      this.logger.warn('smart-reco-page > _buildApiGetProductsInput > undefined params.');
      return;
    }

    return {
      pet: {
        speciesCode,
        breedCode: breedObject.breedCode,
        dateOfBirth: birthdate,
        bcs,
        weight,
        pathologies,
        sensitivity: sensitivities,
        neutered,
        reproductionStatus: reproductionStatus && reproductionStatus != ReproductionStatusCode.None ? reproductionStatus : undefined,
      },
    };
  }

  /**
   * log an error if a required value is undefined
   * @param value: the value to check
   * @param log: additional information for log purposes
   */
  validateValue(value: any, log?: string): any {
    if (!value) {
      this.logger.errorString('undefined required value.', log);
    }
    return value;
  }
}
