import { Constants } from '../constants';

/**
 * Translate dynamically any key
 * Gentle hack of angular $localize global function
 * Params are replaced in output string
 * and are used for dynamic translation with variables.
 * Expected format in the key is {{param}}
 */
export function translateKey(id: string, params?: { [key: string]: unknown }): string {
  let translation = $localize(({
    '0': `:@@${id}:${id}`,
    raw: [':'],
  } as unknown) as TemplateStringsArray);

  params &&
    Object.entries(params).forEach(([key, value]) => {
      const regex = new RegExp(`{{[\s]{0,}${key}[\s]{0,}}}`);
      translation = translation.replace(regex, String(value));
    });

  return translation;
}

export function translationExist(key: string): boolean {
  return !translateKey(key).includes(key);
}

export function translateKeyFromCustomLanguage(
  translations: Record<string, string>,
  key: string,
  variables?: { variableKey: string; variableValue: string }[]
): string {
  let translation = translations[key] || translateKey(key);
  if (variables?.length) {
    variables.forEach(({ variableKey, variableValue }) => (translation = translation?.replace(`{{${variableKey}}}`, variableValue)));
  }
  return translation;
}

export function getTranslationKeyStartWith(translation_keys: string[], word: string): string[] {
  return translation_keys.filter((key) => key.startsWith(word));
}

export class TranslateHelper {
  static saveTranslation(data: { locale: string; translations: Record<string, string> }) {
    sessionStorage.setItem(Constants.TRANSLATION, JSON.stringify(data));
  }

  static saveTranslationBackup(data: { locale: string; translations: Record<string, string> }) {
    localStorage.setItem(Constants.TRANSLATION, JSON.stringify(data));
  }

  static getTranslation(): {
    locale: string;
    translations: Record<string, string>;
  } {
    return JSON.parse(sessionStorage.getItem(Constants.TRANSLATION));
  }

  static getTranslationBackup(): {
    locale: string;
    translations: Record<string, string>;
  } {
    return JSON.parse(localStorage.getItem(Constants.TRANSLATION));
  }
}
