import { createReducer, on } from '@ngrx/store';
import {
  clearTool,
  getLocalizationSuccess,
  removeAlert,
  resetAlerts,
  setAlert,
  setBreeds,
  setDeprecatedLoaderState,
  setPrevTool,
  setTool,
  toggleLoader,
  togglePopinLoader,
  toggleProgressBar,
} from './core.actions';
import { initialCoreState } from './core.state';

export const coreReducer = createReducer(
  initialCoreState,
  on(toggleLoader, (state, { value, transparent }) => ({
    ...state,
    showLoader: value,
    loaderTransparent: value ? !!transparent : false,
  })),
  on(togglePopinLoader, (state, { value }) => ({
    ...state,
    showPopinLoader: value,
  })),
  on(toggleProgressBar, (state, { value }) => ({
    ...state,
    showProgressBar: value,
  })),
  on(setDeprecatedLoaderState, (state, { value }) => ({
    ...state,
    deprecatedLoaderState: value,
  })),
  on(getLocalizationSuccess, (state, { localization }) => ({
    ...state,
    localization,
  })),
  on(setTool, (state, { tool, tool_flow_id }) => ({
    ...state,
    tool,
    tool_flow_id,
  })),
  on(setPrevTool, (state, { prevTool }) => ({
    ...state,
    prevTool,
  })),
  on(clearTool, (state) => ({
    ...state,
    tool: null,
    tool_flow_id: null,
  })),
  on(setBreeds, (state, { breeds }) => ({
    ...state,
    breeds,
  })),
  on(setAlert, (state, { alert }) => ({
    ...state,
    alerts: [...state.alerts, alert],
  })),
  on(removeAlert, (state, { alert }) => {
    const alerts = state.alerts.filter((a) => a.message !== alert.message);
    return {
      ...state,
      alerts,
    };
  }),
  on(resetAlerts, (state, { ids = [] }) => ({
    ...state,
    alerts: ids.length ? state.alerts.filter((item) => !ids.includes(item.id)) : [],
  }))
);
