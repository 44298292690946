import { Product, NutritionRecommendation } from '@app/core/models';
import { ProductType } from '@app/shared/utils';
import { AllowanceCalculatorBlocks } from '@app/shared/utils/enums/allowance-calculator-blocks.enum';
import { createAction, props } from '@ngrx/store';
/**
 * Actions dispatched to retrieve recommended products
 */
export const getAllowanceRecommendation = createAction(
  '[Allowance] Get Recommendation',
  props<{
    productPacks?: { productId: string; packId: string }[];
    manualRation?: { value: number; productType: ProductType };
    allocation?: number;
  }>()
);
export const getAllowanceRecommendationSuccess = createAction(
  '[Allowance] Get Recommendation Success',
  props<{ recommendation: NutritionRecommendation }>()
);

export const getAllowanceRecommendationFail = createAction('[Allowance] Get Recommendation Fail', props<{ error: string }>());
/**
 * Action dispatched to get all wet quatity
 */
export const getAllowanceRecommendationAllWetQuantity = createAction(
  '[Allowance] Get Recommendation wet quantity',
  props<{ allocation: number }>()
);

export const getAllWetRecommendationQuantitySuccess = createAction(
  '[Allowance] Get Recommendation wet quantity success',
  props<{ allWetQuantityPerDay?: number | null }>()
);

/**
 * Action dispatched to set default allowance recommendation when triggered without manual values
 */

export const setAllowanceDefaultRecommendation = createAction(
  '[Allowance] Set Default Recommendation',
  props<{ recommendation: NutritionRecommendation }>()
);
/**
 * Action dispatched when a dry product is selected
 */
export const setAllowanceDryProduct = createAction('[Allowance] Set Dry Product', props<{ product: Product }>());
/**
 * Actions dispatched automatically (from effects) after a dry product is set, if it has related products
 */
export const getAllowanceRelatedWetProductsSuccess = createAction('[Allowance] Set Wet Related Products', props<{ products: Product[] }>());
export const getAllowanceRelatedWetProductsFail = createAction('[Allowance] Set Wet Related Products', props<{ products: Product[] }>());
/**
 * Action dispatched when a wet product is selected
 */
export const setAllowanceWetProduct = createAction('[Allowance] Set Wet Product', props<{ product: Product }>());
/**
 * Action to toggle automatic ration calculation
 */
export const setAllowanceRationAutomaticCalculation = createAction(
  '[Allowance] Set Ration Automatic Calculation',
  props<{ value: boolean }>()
);

export const setAllowanceLoadingRecommendation = createAction(
  '[Allowance] Set Allowance Loading Recommendation',
  props<{ value: boolean }>()
);

export const setAllowanceCurrentBlockEditing = createAction(
  '[Allowance] Set Allowance Current Block Editing',
  props<{ block: AllowanceCalculatorBlocks; value: boolean }>()
);
