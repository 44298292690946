import { ChartData } from 'chart.js';
import { MeasureHelper } from '@app/shared/utils/static-helpers/measure-helper';
import { ChartConsultation } from '@app/core/models/chart.js';
import { DatePipe } from '@angular/common';
import { ConsultationWeight } from '@app/core/models/consultation-weight';
import { environment } from '@env/environment';
import { MeasurementCodeType } from '../enums';

export class ChartHelper {
  public static lineGraphTemplate = (currentBigMeasurementUnit: MeasurementCodeType) =>
    ({
      datasets: [
        {
          // Minimum and maximum weight curve
          label: MeasureHelper.measureUnitToSuffix(currentBigMeasurementUnit),
          data: [],
          borderDash: [10, 5],
          fill: false,
          borderWidth: 1,
          borderColor: 'red',
          lineTension: 0.5,
          pointRadius: 0,
          pointHoverRadius: 0,
          pointBackgroundColor: 'white',
        },
        {
          label: MeasureHelper.measureUnitToSuffix(currentBigMeasurementUnit),
          data: [],
          borderDash: [10, 5],
          fill: false,
          borderWidth: 1,
          borderColor: 'red',
          lineTension: 0.0001,
          pointRadius: 7,
          pointHoverRadius: 0,
        },

        // weight data curve
        {
          label: MeasureHelper.measureUnitToSuffix(currentBigMeasurementUnit),
          data: [],
          borderDash: false,
          fill: false,
          borderWidth: 4,
          borderColor: 'red',
          backgroundColor: 'black',
          pointBorderColor: environment.graphColor || 'rgb(226, 0, 26)',
          pointBackgroundColor: 'white',
          lineTension: 0.0001,
          pointRadius: 7,
          pointHoverRadius: 0,
        },
        // target weight
        {
          label: MeasureHelper.measureUnitToSuffix(currentBigMeasurementUnit),
          data: [],
          fill: false,
          borderDash: [5, 5],
          borderColor: 'green',
          lineTension: 0.5,
          pointBorderColor: '#E2001A1F',
          pointBackgroundColor: 'white',
          pointBorderWidth: 10,
          pointRadius: 0,
          borderWidth: 4,
          pointHoverRadius: 8,
          pointHoverBorderWidth: 3,
          pointHoverBackgroundColor: 'white',
        },
      ],
      labels: [],
    } as ChartData);

  public static formatDataSets(
    consultationWeight: ConsultationWeight,
    datePipe: DatePipe,
    currentBigMeasurementUnit: MeasurementCodeType
  ): ChartConsultation {
    const weight =
      Math.round(
        MeasureHelper.convertWeight(consultationWeight.weight.measure, consultationWeight.weight.measureUnit, currentBigMeasurementUnit)
          .measure * 100
      ) / 100;

    const IBW =
      Math.round(
        MeasureHelper.convertWeight(
          consultationWeight.idealBodyWeight.measure,
          consultationWeight.idealBodyWeight.measureUnit,
          currentBigMeasurementUnit
        ).measure * 100
      ) / 100;

    return {
      weight,
      ...this.getWeightMinMax(IBW),
      date: {
        graph: datePipe.transform(consultationWeight.weight.weightDate, 'shortDate'),
        list: datePipe.transform(consultationWeight.weight.weightDate, 'longDate'),
      },
    };
  }

  public static getWeightMinMax(IBW: number) {
    return {
      weightMin: IBW - (IBW * 5) / 100,
      weightMax: IBW + (IBW * 5) / 100,
    };
  }
}
