export enum RangeType {
  BHN = 'bhn',
  FBN = 'fbn',
  FCN = 'fcn',
  FHN = 'fhn',
  CCN = 'ccn',
  SHN = 'shn',
  VHN = 'vhn',
  LHN = 'lhn', // range for PSR/SPT
}
