import { CurrencyPipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import {
  Clinic,
  Consultation,
  ConversionConfiguration,
  Criteria,
  NutritionData,
  NutritionDataProduct,
  PetInfo,
  PetOwner,
  ProductRecommendation,
  ProductRecommendationIndividualis,
  Recommendation,
  SmartRecoApiBody,
  UtmParamsContext,
  Vet,
  Weight,
} from '@app/core/models';
import {
  RecommendationBodyEmail,
  SignalRecommendationBodyEmail,
  SignalRecommendationTechnicalInformation,
} from '@app/core/models/body-email';
import { SvgSize, WeightChart } from '@app/core/models/chart';
import { TechnicalParamsType } from '@app/core/models/market';
import { PathologyCategory } from '@app/core/models/pathology';
import { PATHOLOGIES } from '@app/shared/data/pathologies';
import { FractionCupPipe } from '@app/shared/pipes';
import { Constants, EmailPopinType, MeasureHelper, ProductType, roundSmartReco, SpeciesCode, Tool } from '@app/shared/utils';
import { getTranslationKeyStartWith, translateKey, translateKeyFromCustomLanguage } from '@app/shared/utils/static-helpers/translate';
import { environment } from '@env/environment';
import { QRCodeToDataURLOptions, toDataURL } from 'qrcode';
import { MeasureConversionEnum, MeasurementCodeType, NutritionalChoice, PackType, POLetterFrom, PoLetterType } from '../enums';
import { CriteriaType } from '../enums/criteria-type.enum';
import { Helper, resolvePath } from './helper';
import { PoProduct } from '@app/letters/interfaces/po-product';
import { isNullOrUndefined } from '@app/shared/operators/not-null-or-undefined';
import { SystemPreferenceCode } from '../enums/system-preference-code';

export function buildRecommendationBodyEmail({
  data: { vet, clinic, petInfo, productRecommendation, currentConsultation, tool, emailPopinType, owner, chronoVetLink },
}: {
  data: {
    vet: Vet;
    clinic: Clinic;
    petInfo?: PetInfo;
    productRecommendation: Recommendation;
    currentConsultation: Consultation;
    tool: Tool;
    emailPopinType: EmailPopinType;
    owner: PetOwner;
    chronoVetLink?: string;
  };
}): RecommendationBodyEmail {
  return {
    tool,
    emailPopinType,
    chronoVetLink,
    user: {
      name: `${vet.user?.firstName}${vet.user?.firstName && vet.user?.lastName ? ' ' : ''}${vet.user?.lastName}`,
      clinic_name: clinic?.legalName || '',
      clinic_logo: clinic?.logo.path || null,
      address: clinic?.companyAddress?.address1 || '',
      zip_code: `${clinic?.companyAddress?.zip || ''}`,
      city_name: clinic?.companyAddress?.city || '',
    },
    ...(petInfo && {
      pet: {
        name: petInfo.name,
      },
    }),
    owner: {
      name: owner ? `${owner.givenName} ${owner.familyName}` : null,
    },
    eventData: {
      consultationId: currentConsultation?.id,
      recommendationId: currentConsultation?.visit?.recommendationId,
      clinicId: clinic.id,
    },
    product1: formatProductsForEmail(productRecommendation?.nutritionData)[0],
    product2: formatProductsForEmail(productRecommendation?.nutritionData)[1],
    newPoLetter: true,
  };
}

function formatProductsForEmail(nutritionData: NutritionData[]) {
  return nutritionData.map((item) => ({
    name: item.product?.productDetail?.details?.name,
    url: item.product?.productDetail?.details?.publicUrl,
  }));
}

export function buildSignalRecommendationBodyEmail({
  clinic,
  vet,
  srApiBody,
  petInfo,
  message,
  tool_flow_id,
  technicalInformation,
  product,
  currentBigMeasurementUnit,
}: {
  clinic: Clinic;
  vet: Vet;
  srApiBody: SmartRecoApiBody;
  petInfo: PetInfo;
  message: string;
  tool_flow_id: string;
  technicalInformation: SignalRecommendationTechnicalInformation;
  product?: ProductRecommendation | ProductRecommendationIndividualis;
  currentBigMeasurementUnit: MeasurementCodeType;
}): SignalRecommendationBodyEmail {
  return {
    clinic,
    vet,
    input: srApiBody,
    product: product?.product,
    coverage: product?.coverage,
    petInfo: {
      bcs: petInfo.bcs || 0,
      birthdate: petInfo.birthdate || null,
      breed: petInfo.breed || '',
      gender: petInfo.gender || null,
      IBW: petInfo.IBW || 0,
      lifestage: petInfo.lifestage || null,
      name: petInfo.name || '',
      neutered: petInfo.neutered || false,
      speciesCode: petInfo.speciesCode || null,
      weight: petInfo.weight || 0,
      reproductionStatus: petInfo.reproductionStatus || null,
      bigMeasurementUnit: currentBigMeasurementUnit,
      pathologies: petInfo.pathologies
        ? petInfo.pathologies.map((value: string) => {
            return {
              label: translateKey(`pathology-${value}`),
              value,
            };
          })
        : [],
      sensitivities: petInfo.sensitivities
        ? petInfo.sensitivities.map((value: string) => {
            return {
              label: translateKey(`sensitivity-${value}`),
              value,
            };
          })
        : [],
    },
    message,
    env: environment.envName,
    api_session_id: tool_flow_id,
    technicalInformation,
  };
}

export const generateQrCode = (url: string): Promise<string> => {
  const qrCodeOptions: QRCodeToDataURLOptions = {
    errorCorrectionLevel: 'M',
    width: 58,
    type: 'image/jpeg',
    color: {
      dark: '#000000',
      light: '#FFFFFF',
    },
  };

  return toDataURL(url, qrCodeOptions);
};

function resolveTechnicalParam(
  itemConfig: { path: string; type: TechnicalParamsType; separator?: string },
  products: NutritionDataProduct[],
  clinic: Clinic,
  vet: Vet
): string | number | boolean {
  switch (itemConfig.type) {
    case TechnicalParamsType.CLINIC:
      return resolvePath(clinic, itemConfig.path);
    case TechnicalParamsType.VET:
      return resolvePath(vet, itemConfig.path);
    case TechnicalParamsType.FIRST_PRODUCT:
      return products[0] ? resolvePath(products[0], itemConfig.path) : '';
    case TechnicalParamsType.SECOND_PRODUCT:
      return products[1] ? resolvePath(products[1], itemConfig.path) : '';
    case TechnicalParamsType.PRODUCTS:
      return (products || []).map((product) => resolvePath(product, itemConfig.path)).join(itemConfig.separator);
    default:
      break;
  }
}

export function addRecommendationIdToParams(conversionConfiguration: ConversionConfiguration, params: HttpParams, randomId: string) {
  if (conversionConfiguration?.withRandomRecommendationId && randomId) {
    params = params.set('recoId', randomId);
  }
  return params;
}

export function getPurchaseLink(
  withPurchaseLink: boolean,
  conversionConfiguration: ConversionConfiguration,
  products: NutritionDataProduct[],
  clinic: Clinic,
  vet: Vet,
  isBodyEmail: boolean,
  utmParamsContextType: UtmParamsContext,
  randomRecommendationId?: string
): string | null {
  if (!withPurchaseLink || !conversionConfiguration) return null;

  let params = new HttpParams();
  const technicalParamsConfig = conversionConfiguration.linkTechnicalParams || [];
  const utmParamsConfig = (isBodyEmail && conversionConfiguration.linkUtmParamsEmail) || conversionConfiguration.linkUtmParamsPrint || [];

  params = addRecommendationIdToParams(conversionConfiguration, params, randomRecommendationId);

  technicalParamsConfig.forEach((item) => {
    params = params.set(item.key, resolveTechnicalParam(item, products, clinic, vet));
  });

  utmParamsConfig
    .filter((item) => item.context == utmParamsContextType || item.context == UtmParamsContext.ALL)
    .forEach((item) => {
      const paramValue = item.values
        .map((val) => ('type' in val ? resolveTechnicalParam(val, products, clinic, vet)?.toString()?.toLowerCase() : val.text))
        .join('');
      params = params.set(item.key, paramValue);
    });

  return `${conversionConfiguration.linkBaseUrl}?${params.toString()}`;
}

export const getTips = (
  recommendationsCriterias: Criteria[],
  poLetterTranslationsData: Record<string, string>,
  petInfo: PetInfo,
  hideDietBlockInPoLetter: boolean
) => {
  const pathologicalCriteriasFiltredByPmean = filteredCriteriasByPmean(recommendationsCriterias, 90);
  const tipsAdvice = getTipsOfCriterias(
    poLetterTranslationsData,
    getPathologicalCriterias(recommendationsCriterias),
    petInfo?.speciesCode,
    'tipsathome-',
    2
  );
  const tipsDiet = hideDietBlockInPoLetter
    ? []
    : getTipsOfCriterias(poLetterTranslationsData, pathologicalCriteriasFiltredByPmean, petInfo?.speciesCode, 'dietsupport-', 3);

  return { tipsAdvice, tipsDiet };
};

export const getPetActivityPerDayEnabled = (petInfo: PetInfo): boolean => {
  const mobilityPathosToExclude = PATHOLOGIES.filter(
    (patho) =>
      patho.categories.includes(PathologyCategory.CARDIOVASCULAR_DISEASES) ||
      patho.categories.includes(PathologyCategory.OSTEOARTICULAR_DISORDERS)
  ).map((patho) => patho.value);
  const pathologies = petInfo?.pathologies;
  const sensitivities = petInfo?.sensitivities;

  const petHasMobilityPathos = !!(sensitivities?.length || pathologies?.length)
    ? pathologies.concat(sensitivities).some((patho) => mobilityPathosToExclude.includes(patho))
    : false;
  const petIsOlderThan7 = Helper.getPetAgeObject(petInfo.birthdate).years >= 7;
  return petIsOlderThan7 || petHasMobilityPathos;
};

export const showPrice = (isRetailPriceActivated: boolean, nutritionData: NutritionData[]): boolean => {
  return (
    isRetailPriceActivated &&
    nutritionData[0]?.product?.productDetail?.package?.productPrice?.clinicPrice > 0 &&
    (isNullOrUndefined(nutritionData[1]?.product?.productDetail?.package?.productPrice?.clinicPrice) ||
      nutritionData[1]?.product?.productDetail?.package?.productPrice?.clinicPrice > 0)
  );
};

export const productsTotalPricePerDay = (
  getTotalPricePerDayValue: number,
  currency: string,
  currencyPipe: CurrencyPipe,
  poLetterTranslations: Record<string, string>
) => {
  return getTotalPricePerDayValue > 0
    ? translateKeyFromCustomLanguage(poLetterTranslations, 'po-letter_cost_day', [
        {
          variableKey: 'cost_value',
          variableValue: currencyPipe.transform(getTotalPricePerDayValue, currency),
        },
      ])
    : null;
};

export const formatRenalDetectStatus = (status) => {
  return status?.charAt(0).toUpperCase() + status?.substring(1);
};

const getPackTypeTranslation = (packType: PackType, quantityPerDay?: number, translations?: Record<string, string>): string => {
  switch (packType) {
    case PackType.SmallCanStr:
      return translateKeyFromCustomLanguage(translations, 'package-type_wet-small-can');

    case PackType.LargeCanStr:
      return translateKeyFromCustomLanguage(translations, 'package-type_wet-large-can');

    case PackType.CanStr:
      return quantityPerDay > 1
        ? translateKeyFromCustomLanguage(translations, 'package-type_wet-cans')
        : translateKeyFromCustomLanguage(translations, 'package-type_wet-can');

    case PackType.PouchStr:
      return translateKeyFromCustomLanguage(translations, 'po-letter_nutriRecoPouch');

    case PackType.BAG:
      return translateKeyFromCustomLanguage(translations, 'package-type_dry-bag');

    case PackType.Kibbles:
      return translateKeyFromCustomLanguage(translations, 'po-letter_nutriRecoKibbles');

    default:
      return '';
  }
};

function getFormattedDailyRation(product, ration, poLetterTranslations, currentSystemPreferenceCode: SystemPreferenceCode) {
  return currentSystemPreferenceCode === SystemPreferenceCode.imperialSystemCode && product.productType === ProductType.Dry
    ? `${
        Math.round(MeasureHelper.convert(ration?.quantityPerDay, MeasureConversionEnum.gramToOunce) * 10) / 10
      } ${MeasureHelper.measureUnitToSuffix(Constants.ounceSuffix)}`
    : `${ration?.quantityPerDay} ${getRationUnit(product, ration, poLetterTranslations)}`;
}

function getDailyRation(product, ration, currentSystemPreferenceCode: SystemPreferenceCode) {
  return currentSystemPreferenceCode === SystemPreferenceCode.imperialSystemCode && product.productType === ProductType.Dry
    ? Math.round(MeasureHelper.convert(ration?.quantityPerDay, MeasureConversionEnum.gramToOunce) * 10) / 10
    : ration?.quantityPerDay;
}

function getDailyRationUnit(product, ration, poLetterTranslations, currentSystemPreferenceCode: SystemPreferenceCode) {
  return currentSystemPreferenceCode === SystemPreferenceCode.imperialSystemCode && product.productType === ProductType.Dry
    ? `${MeasureHelper.measureUnitToSuffix(Constants.ounceSuffix)}`
    : `${getRationUnit(product, ration, poLetterTranslations)}`;
}

const getRationUnit = (product, ration, translations) => {
  return product.productType === ProductType.Wet
    ? getPackTypeTranslation(product?.productDetail?.package?.type, ration?.quantityPerDay, translations)
    : MeasureHelper.measureUnitToSuffix(ration?.unit);
};

const sortPoLetterProducts = (nutritionDatas: NutritionData[]): NutritionData[] => {
  return [...nutritionDatas].sort((product1) => {
    if (product1?.product?.productType === ProductType.Dry) {
      return -1;
    } else {
      return 1;
    }
  });
};

export function formatNutritionDataPOLetter(
  tool: Tool,
  nutritionDatas: NutritionData[],
  currency: string,
  currencyPipe: CurrencyPipe,
  fractionCupPipe: FractionCupPipe,
  poLetterTranslations: Record<string, string>,
  currentSystemPreferenceCode: SystemPreferenceCode,
  productType?: ProductType
): PoProduct[] {
  if (productType) {
    const productNutritionData = nutritionDatas.find((nutritionData) => nutritionData.product?.productType === productType);
    return [
      prepareNutritionDataPOLetter(
        tool,
        productNutritionData,
        currency,
        currencyPipe,
        fractionCupPipe,
        poLetterTranslations,
        currentSystemPreferenceCode
      ),
    ];
  }
  return sortPoLetterProducts(nutritionDatas).map((nutritionData) =>
    prepareNutritionDataPOLetter(
      tool,
      nutritionData,
      currency,
      currencyPipe,
      fractionCupPipe,
      poLetterTranslations,
      currentSystemPreferenceCode
    )
  );
}

function prepareNutritionDataPOLetter(
  tool: Tool,
  nutritionData: NutritionData,
  currency: string,
  currencyPipe: CurrencyPipe,
  fractionCupPipe: FractionCupPipe,
  poLetterTranslations: Record<string, string>,
  currentSystemPreferenceCode: SystemPreferenceCode
): PoProduct {
  const product = nutritionData.product;
  const ration = nutritionData.basicRation;
  const rationCup = nutritionData.cupRation;
  const dailyRation = {
    quantityPerDay: getDailyRation(product, ration, currentSystemPreferenceCode),
    unit: getDailyRationUnit(product, ration, poLetterTranslations, currentSystemPreferenceCode),
  };
  const species = product?.productDetail?.details?.speciesCode;
  const benefits =
    tool !== Tool.FastReco
      ? []
      : product?.productDetail?.details?.benefits?.map((benefit) => {
          if (!benefit.image) {
            const image =
              window.location.origin +
              (species === SpeciesCode.Unknown
                ? '/assets/other/placeholder-benefits.svg'
                : '/assets/other/placeholder-benefits-' + species + '.svg');
            return { ...benefit, image };
          }
          return benefit;
        });

  return {
    name: Helper.convertToTitleCase(product?.productDetail?.details.name),
    productType: product?.productType,
    formattedProductType:
      product?.productType === ProductType.Dry
        ? translateKeyFromCustomLanguage(poLetterTranslations, 'po-letter_nutriRecoKibbles')
        : dailyRation.unit,
    imageLink: `${product?.productDetail?.details?.publicUrl}?w=250&auto=compress`,
    buyLink: '',
    dailyRation,
    formattedDailyRation: getFormattedDailyRation(product, ration, poLetterTranslations, currentSystemPreferenceCode),
    formattedRationbyCup:
      product.productType === ProductType.Dry
        ? `(${fractionCupPipe.transform(rationCup?.quantityPerDay)} ${translateKeyFromCustomLanguage(
            poLetterTranslations,
            'po-letter_nutriRecoCup'
          )})`
        : '',
    formattedPackSize: product?.productDetail?.package?.text, // '85g'
    formattedRationbyDay: translateKeyFromCustomLanguage(poLetterTranslations, 'po-letter_day_bag', [
      {
        variableKey: 'number_of_days',
        variableValue: '≈ ' + ration?.nbDaysPerPack,
      },
    ]), // '(≈ 53 day(s) of food)'
    formattedPrice: environment.isStandalone
      ? null
      : product?.productDetail?.package?.productPrice?.clinicPrice && product?.productDetail?.package?.productPrice?.clinicPrice > 0
      ? currencyPipe.transform(product?.productDetail?.package?.productPrice?.clinicPrice, currency)
      : '--', // '24,99$'
    formattedCalories: `${Math.floor(ration?.kcalPerDay)} ${translateKeyFromCustomLanguage(
      poLetterTranslations,
      'Kcal'
    )}/${translateKeyFromCustomLanguage(poLetterTranslations, 'day')}`, // '423kCal/g'
    benefits,
  };
}

export function getPOLetterTemplateName({
  tool,
  petInfo,
  currentConsultation,
  poLetterFrom,
}: {
  tool: Tool;
  petInfo: PetInfo;
  currentConsultation: Consultation;
  poLetterFrom: POLetterFrom;
}): PoLetterType {
  if (poLetterFrom === POLetterFrom.FlowAllowanceWeightBlock) {
    return PoLetterType.WeightLoss;
  } else {
    switch (tool) {
      case Tool.Rationing:
      case Tool.PersonalizedBag:
        return PoLetterType.DailyAllowance;
      case Tool.SmartReco:
        return PoLetterType.SmartReco;
      case Tool.FastReco:
        return PoLetterType.FastReco;
      case Tool.RenalDetect:
        if (
          petInfo?.pathologies?.length > 0 ||
          currentConsultation?.pathologies?.length > 0 ||
          petInfo?.sensitivities?.length > 0 ||
          currentConsultation?.sensitivities?.length > 0
        ) {
          return PoLetterType.RenalSmartReco;
        } else {
          return PoLetterType.DailyRenalDetect;
        }
      case Tool.WeightManagement:
        return poLetterFrom === POLetterFrom.WeightTab ? PoLetterType.WeightLoss : PoLetterType.DailyAllowance;
      default:
        return PoLetterType.DailyAllowance;
    }
  }
}

export function getObservationMaxLength(
  tool: Tool,
  additionalInformation: { petInfo?: PetInfo; consultation?: Consultation; renalDetectNutritionalChoice?: NutritionalChoice } | null = null
): string {
  const petInfo = additionalInformation?.petInfo;
  const consultation = additionalInformation?.consultation;
  const renalDetectNutritionalChoice = additionalInformation?.renalDetectNutritionalChoice;
  const hasPathologies =
    petInfo?.pathologies?.length > 0 ||
    consultation?.pathologies?.length > 0 ||
    petInfo?.sensitivities?.length > 0 ||
    consultation?.sensitivities?.length > 0;

  switch (tool) {
    case Tool.SmartReco:
      if (!additionalInformation || (additionalInformation && hasPathologies)) {
        return '215';
      }
      return '415';
    case Tool.WeightManagement:
      return '215';
    case Tool.RenalDetect:
      if (
        !additionalInformation ||
        (additionalInformation && hasPathologies) ||
        renalDetectNutritionalChoice === NutritionalChoice.SmartReco
      ) {
        return '120';
      }
      return '204';
    default:
      return '415';
  }
}

export function formatWeightsToChart(
  weights: Weight[],
  formattedIbw: number,
  currentBigMeasurementUnit: MeasurementCodeType,
  currentSystemPreferenceCode: SystemPreferenceCode
): WeightChart[] {
  const lastWeights = getLastWeights(weights, currentBigMeasurementUnit, currentSystemPreferenceCode);
  const weightSvg = getWeightSvgSize(lastWeights, formattedIbw);
  return lastWeights.map(({ measure, measureUnit, weightDate }) => {
    // {measure:3,measureUnit:"kg",weightDate:"2022-11-02T23:00:00.000Z"},svgSize:{mini: true}
    return { measure: +measure.toFixed(2), measureUnit, weightDate, svgSize: weightSvg[measure] };
  });
}

function getWeightSvgSize(weights: Weight[], formattedIbw: number): Record<number, { [key in SvgSize]?: boolean }> {
  const weightAndSvg: Record<number, { [key in SvgSize]?: boolean }> = {};

  //case 1: measureWeights: [3,5,4] & formattedIbw:4
  //case 2: measureWeights: [7,9,8] & formattedIbw:4
  //case 3: measureWeights: [6,5,5] & formattedIbw:4
  const measureWeights = weights.map(({ measure }) => measure);
  //case 1: isAllWeightsGreaterIbw =false
  //case 2: isAllWeightsGreaterIbw =true
  //case 3: isAllWeightsGreaterIbw =true
  const isAllWeightsGreaterIbw = measureWeights.every((el) => el > formattedIbw);
  const svgGtIbw = [SvgSize.S, SvgSize.M, SvgSize.L];

  //case 1: weightWithoutOccurenceSorted=[3,4,5]
  //case 2: weightWithoutOccurenceSorted=[9,8,7]
  //case 2: weightWithoutOccurenceSorted=[6,5]
  const weightWithoutOccurenceSorted = [...new Set(measureWeights)].sort((a, b) =>
    isAllWeightsGreaterIbw ? Helper.compareFn(b, a) : Helper.compareFn(a, b)
  );

  weightWithoutOccurenceSorted.forEach((measure) => {
    if (measure < formattedIbw) {
      // case 1: measure=3    '3': { MINI: true }

      weightAndSvg[measure] = { [SvgSize.MINI]: true };
    } else if (measure === formattedIbw) {
      // case 1: measure=4    '4': { EQUAL: true }

      weightAndSvg[measure] = { [SvgSize.EQUAL]: true };
    } else {
      const selectedSvg = isAllWeightsGreaterIbw ? svgGtIbw.pop() : svgGtIbw.shift();
      weightAndSvg[measure] = { [selectedSvg]: true };
      // case 1: measure=5; selectedSvg= S; '4': { EQUAL: true }

      // case 2: measure=9; selectedSvg= L; '9': { L: true }
      // case 2: measure=8; selectedSvg= M; '8': { M: true }
      // case 2: measure=7; selectedSvg= S; '7': { S: true }

      // case 3: measure=6; selectedSvg= L; '4': { L: true }
      // case 3: measure=5; selectedSvg= L; '4': { M: true }
    }
  });
  // case 1: weightAndSvg= { '3': { MINI: true }, '4': { EQUAL: true }, '5': { S: true } }
  // case 2 weightAndSvg = { '7': { S: true }, '8': { M: true }, '9': { L: true } }
  // case 3 weightAndSvg ={ '5': { M: true }, '6': { L: true } }
  return weightAndSvg;
}

function getLastWeights(
  consultationWeights: Weight[],
  currentBigMeasurementUnit: MeasurementCodeType,
  currentSystemPreferenceCode: SystemPreferenceCode
): Weight[] {
  return consultationWeights
    .map((weight) => {
      const formatteWeight = MeasureHelper.convertWeight(
        weight.measure,
        weight.measureUnit,
        currentBigMeasurementUnit,
        currentSystemPreferenceCode
      );
      return { ...weight, measure: formatteWeight.measure, measureUnit: formatteWeight.measureUnit };
    })
    .sort(({ weightDate: a }, { weightDate: b }) => Helper.compareFn(a, b)) // sort by OLD Date
    .slice(-3);
}

export function ellipsis(myString: string, maxLength: number, toKeepLength: number): string {
  return myString?.length > maxLength ? `${myString.substring(0, toKeepLength)} ...` : myString;
}

function filteredCriteriasByPmean(recommendationsCriterias: Criteria[], pmeanMin: number): Criteria[] {
  return getPathologicalCriterias(recommendationsCriterias)?.filter((criteria: Criteria) => roundSmartReco(criteria.pmean) >= pmeanMin);
}

export function getTipsOfCriterias(
  poLetterTranslationsData: Record<string, string>,
  criterias: Criteria[],
  petSpecie: SpeciesCode,
  prefixKeyTrad: string,
  numberMaxOfCriteria: number
): { title: string; tips: string[] }[] {
  if (!criterias) return [];

  const result = [];
  const allAdviceTranslatedCriteria = []; // save each key for prevent occurrences
  const tipsAtHomeKeys = getTranslationKeyStartWith(Object.keys(poLetterTranslationsData), prefixKeyTrad);

  criterias.forEach((criteria) => {
    if (result.length === numberMaxOfCriteria) return;
    const currentAdviceCriteriaKeys = getAdviceFromCritera(criteria, tipsAtHomeKeys);
    const currentAdviceCriteriaKeysFilteredBySpecie = getFilteredKeysBySpecie(petSpecie, currentAdviceCriteriaKeys);

    const currentAdviceCriteriaTranslated = currentAdviceCriteriaKeysFilteredBySpecie
      .map((key) => translateKeyFromCustomLanguage(poLetterTranslationsData, key))
      .filter((translatedKey) => {
        if (allAdviceTranslatedCriteria.includes(translatedKey)) return false; // advice already pushed
        allAdviceTranslatedCriteria.push(translatedKey);
        return true;
      });

    if (currentAdviceCriteriaTranslated.length) {
      result.push({
        title: translateKeyFromCustomLanguage(
          poLetterTranslationsData,
          `${criteria.type === CriteriaType.Diseases ? 'pathology' : 'sensitivity'}-${criteria.key}`
        ),
        tips: currentAdviceCriteriaTranslated,
      });
    }
  });

  return result;
}

export function getPathologicalCriterias(criterias: Criteria[]): Criteria[] {
  return (
    criterias?.filter((criteria: Criteria) => [CriteriaType.Diseases, CriteriaType.RiskFactor].includes(criteria.type as CriteriaType)) ||
    null
  );
}

export function getFilteredKeysBySpecie(petSpecie: SpeciesCode, keys: string[]): string[] {
  return keys.filter((key) => {
    const specieOccurence = key.split('-').find((item) => ([SpeciesCode.Cat, SpeciesCode.Dog] as string[]).includes(item));
    return !specieOccurence || specieOccurence === petSpecie;
  });
}

export function getAdviceFromCritera(criteria: Criteria, keys: string[]): string[] {
  return keys.filter((key) => key.split('-').includes(criteria.key));
}
