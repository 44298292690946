import { VetActionTypes, VetActions } from './legacy-vet.actions';
import * as NewVetActions from './vet.actions';
import { initialVetState, VetState } from './vet.state';
import { replaceInArray } from '@app/shared/utils/static-helpers/array.utils';
import { createReducer, on, union } from '@ngrx/store';
/**
 * Vet reducer
 */
export const vetReducer = createReducer(
  initialVetState,
  on(NewVetActions.getVetSuccess, (state, { vet, currentClinic, oktaUser }) => ({
    ...state,
    vet,
    oktaUser,
    currentClinicId: currentClinic?.id,
  })),
  on(NewVetActions.setVetValidity, (state, { value }) => ({
    ...state,
    isVetValid: value,
  })),
  on(NewVetActions.setInvitation, (state, { invitation }) => ({
    ...state,
    invitation,
  })),
  on(NewVetActions.getMarketSuccess, (state, { market }) => ({
    ...state,
    market,
  })),
  on(NewVetActions.updateClinicSuccess, (state, { clinic }) => ({
    ...state,
    vet: {
      ...state.vet,
      clinics: replaceInArray(state.vet.clinics, clinic, (item) => item.id, false),
    },
  })),
  on(NewVetActions.setPreferredMaxPetWeight, (state, { weight }) => ({
    ...state,
    preferences: {
      ...state.preferences,
      maxPetWeight: weight,
    },
  })),
  on(NewVetActions.setCurrentBigMeasurementUnit, (state, { unit }) => ({
    ...state,
    preferences: {
      ...state.preferences,
      currentBigMeasurementUnit: unit,
    },
  })),
  on(NewVetActions.setCurrentSmallMeasurementUnit, (state, { unit }) => ({
    ...state,
    preferences: {
      ...state.preferences,
      currentSmallMeasurementUnit: unit,
    },
  })),
  on(NewVetActions.setCurrentSystemPreferenceCode, (state, { systemPreferenceCode }) => ({
    ...state,
    preferences: {
      ...state.preferences,
      currentSystemPreferenceCode: systemPreferenceCode,
    },
  }))
);
/**
 * Make new syntax actions compatible with legacy reducers
 */
const VetActionsUnion = union({ ...NewVetActions });
type Actions = VetActions | typeof VetActionsUnion;
/**
 * Ngrx old syntax
 * Do not add reducers here
 */
export function legacyVetReducer(state: VetState = initialVetState, action: Actions): VetState {
  switch (action.type) {
    case VetActionTypes.SetVetActionType: {
      return {
        ...state,
        isLoading: false,
        vet: Object.assign({}, state.vet, action.vet),
      };
    }
    case VetActionTypes.UpdateVetSuccessActionType: {
      return {
        ...state,
        vet: {
          ...state.vet,
          ...action.vet,
        },
      };
    }
    case VetActionTypes.UpdateVetUserSuccessActionType: {
      return {
        ...state,
        vet: {
          ...state.vet,
          user: {
            ...state.vet.user,
            ...action.vetUser,
          },
        },
      };
    }
    case VetActionTypes.SetCurrentClinicIdActionType: {
      return {
        ...state,
        currentClinicId: action.clinicId,
      };
    }
    default:
      return vetReducer(state, action);
  }
}
