import { TermScope } from '@app/core/models';
import { MeasurementSubstanceTypeCodeEnum, ShareRecommendationMethod } from '@app/shared/utils/enums';
import { CountryCode } from '@app/shared/utils/enums/country-code.enum';
import { AppFeature, ClinicFeature } from '@app/shared/utils/enums/feature.enum';
import { LanguageCode } from '@app/shared/utils/enums/language-code.enum';
import { PriceType } from '@app/shared/utils/enums/price-type.enum';

export interface MarketTerm {
  scope: TermScope;
  url?: string;
  language?: LanguageCode;
  translationKeyDesc?: string;
  translationKeyUrl?: string;
  additionalInfos?: {
    url?: string;
    translationKeyDesc: string;
    translationKeyUrl?: string;
  }[];
}
/**
 * Market interface
 * Group all data specific to a market/country
 * Markets are retrieved from blob storage
 */
export interface Market {
  countryCode: CountryCode;
  catalog: LanguageCode[];
  /**
   * forceCatalogCountry should not be set for most countries
   * Used only in cases where a country does not have a catalog
   * and wants to use the one from another country
   */
  forceCatalogCountry?: CountryCode;
  mailLanguages: LanguageCode[];
  hideSupportContactBlock?: boolean;
  skipRegisterClinicInfoPage?: boolean;
  features: (ClinicFeature | AppFeature)[];
  terms?: MarketTerm[];
  currency: string;
  renalUnits?: {
    creatinineUnit?: MeasurementSubstanceTypeCodeEnum;
    ureaUnit?: MeasurementSubstanceTypeCodeEnum;
  };

  /**
   * Authorized recommendation methods
   */
  shareRecommendationMethods?: ShareRecommendationMethod[];
  /**
   * Links to display in the header
   */
  quicklinks?: {
    /**
     * Only the file name and extension are specified
     */
    icon: string;
    /**
     * Phrase app key to display as label
     */
    text: string;
    /**
     * Phrase app key to display as description in home page
     */
    description?: string;
    /**
     * Full url
     */
    link: string;
    /**
     * set to true to display also quicklink in home
     */
    showInHome?: boolean;
    /**
     * module refers to module name for analytics
     */
    module: string;
    /**
     * Show only for specific okta user groups
     */
    onlyForGroups?: string[];
  }[];
  /**
   * List of products_sku for muf markets
   */
  products_sku?: Record<string, string>;
  /**
   * if set with minima value info popin allow to see
   * a message (ex: maintenance information.) on
   * the home page with a format type popin or rc-alert
   */
  info_popin?: PopinInformation;
  /**
   * If activatedPrices is not set, by default only retail prices are displayed
   */
  activatedPrices?: PriceType[];
  /**
   * TEMPORARY, will later be fetched from backend directly
   * give the TVA to apply and the list of wholesale prices for each ean
   */
  pricesVat?: number;
  wholesalePrices?: Record<string, number>;
  /**
   * allow possibility to see SPT product in catalog will be
   */
  enableSptRetail?: boolean;
  /**
   * allow possibility to see SOL products in VET catalog
   */
  enableSolProductsInVet?: boolean;
  /**
   * conversion configurations (Chronovet, FGS, PDV)
   */
  conversionConfigurations?: ConversionConfiguration[];
  /**
   * disable invitation link button in account page
   */
  disableOnboardingInvitation?: boolean;
  /**
   * enable block for annual support report & blob file
   */
  enableKpiFile?: boolean;
  /**
   * hide diet block in po letter
   */
  hideDietBlockInPoLetter?: boolean; // This appears to be unused

  /**
   * enable display of treats tooltip
   */
  enableDisplayTreatsTooltip?: boolean;

  /**
   * enable display of smart reco disclaimer
   */
  enableDisplaySmartRecoDisclaimer?: boolean;
}

export interface PopinInformation {
  id?: string;
  /* if not set default popin type will be type popin */
  type?: string;
  title?: string;
  description: string;
  /* formatted date should be YYYY-MM-DDTHH:MM ex: 2022-01-12T12:00 */
  startingDate?: string;
  endingDate?: string;
  /* by default will be false */
  showCheckbox?: boolean;
  link?: string;
  textlink?: string;
  /* by default will be true */
  displaySupport?: boolean;
}

export enum TechnicalParamsType {
  FIRST_PRODUCT = 'product.0',
  SECOND_PRODUCT = 'product.1',
  PRODUCTS = 'products',
  CLINIC = 'clinic',
  VET = 'vet',
}

export enum UtmParamsContext {
  BUTTON = 'button',
  QRCODE = 'qrCode',
  ALL = 'all',
  QRCODEPURCHASEPOPIN = 'qrCodePurchasePopin',
  QRCODEPURCHASEPOPIN_PRODUCTBOOK = 'qrCodePurchasePopinProductBook',
}

export interface ConversionConfiguration {
  type: string; //  same value from relations in JWT token when conversion is activated, example : 'VS_PDV' | 'VS_CV' | 'VS_FGS';
  checkboxLabels: string[]; // labels to display in order with the checkbox inside share reco popin
  poLetterTitle: string; // title in po letter
  poLetterName: string; // for qrcode description
  trackingCode: string; // key code for tracking
  linkBaseUrl: string; // base url for purchase link
  withRandomRecommendationId?: boolean;
  coupon?: Coupon; // coupon code & discount for IVO
  linkTechnicalParams: TechnicalParamsConfiguration[]; // query params for purchase link to enable the partner to display corresponding page
  linkUtmParamsEmail?: UtmParamsConfiguration[]; // query params for utms for link in body email
  linkUtmParamsPrint: UtmParamsConfiguration[]; // query params for utms for link in po letter
}

export interface UtmParamsConfiguration {
  context: UtmParamsContext;
  key: string; // key of the query param
  values: (
    | {
        text: string; // value of the query param (hard coded string)
      }
    | {
        type: TechnicalParamsType;
        path: string; // path to use to fetch data within object (product or clinic depending on type)
        separator?: string; // separator to use with path if type is "products" and we must concatenate information from all selected products
      }
  )[];
}

export interface TechnicalParamsConfiguration {
  type: TechnicalParamsType; // data can be retrieved either from products or clinic or first product or second product
  key: string; // key of the query param
  path: string; // path to use to fetch data within object (product or clinic depending on type)
  separator?: string; // separator to use if type is "products" and we must concatenate information from all selected products
}

export interface Coupon {
  code: string;
  discount: number;
}
