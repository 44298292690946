import { Env } from '@app/shared/utils/enums/environment.enum';
import { OktaAuthOptions } from '@okta/okta-auth-js';

export interface Environment {
  envName: Env;
  production: boolean;
  forceEnableFeatures?: boolean;
  isStandalone: boolean;
  standaloneType?: StandaloneType;
  scriptEnv: string;
  bffApiUrl: string;
  productApiUrl: string;
  vetApiUrl: string;
  clinicApiUrl: string;
  toolApiUrl: string;
  nutritionApiUrl: string;
  healthNutritionApiUrl: string;
  petOwnersApiUrl: string;
  coreApiUrl: string;
  myAccountUrl: string;
  ciam: {
    apiUrl: string;
    app: string;
    redirectCallback: string;
  };
  blobUrl: string;
  blobQueryParams: string;
  blobApiUrl: string;
  apimSubscriptionKey: string;
  appInsights: {
    instrumentationKey: string;
    role: string;
    roleInstance: string;
  };
  oktaConfig: OktaAuthOptions;
  marsFooterScript: string | null;
  oneTrustId: string;
  graphColor?: string;
  fgsUrl?: string;
  individualisUrl: string;
  faroConfig: {
    enabled: boolean;
    url: string;
    apiKey: string;
  };
}

export enum StandaloneType {
  VETMANAGER = 'vetmanager',
  GMVET = 'gmvet',
  MYVETE = 'myvete',
}
