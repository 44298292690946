import { RCAlertType } from '@rc/ui';
import { updateClinicSuccess } from './../vet.actions';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { setAlert, toggleLoader } from '@app/store/core';
import { of } from 'rxjs';

import {
  UpdateVet,
  UpdateVetFailed,
  UpdateVetSuccess,
  UpdateVetUser,
  UpdateVetUserFailed,
  UpdateVetUserSuccess,
  VetActionTypes,
} from '../legacy-vet.actions';
import { VetService } from '@app/core/services/network/vet/vet.service';
import { LocalizationService } from '@app/core/services/utils/localization/localization.service';
import { Clinic, Vet } from '@app/core/models';
import { updateClinic } from '../vet.actions';
import { Router } from '@angular/router';

@Injectable()
export class VetEffects {
  /**
   * Update vet using API call
   */
  updateVet$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateVet>(VetActionTypes.UpdateVetActionType),
      concatMap((action) =>
        this.vetService.apiUpdateVet(action.vet).pipe(
          map((vet: Vet) => {
            return new UpdateVetSuccess(vet);
          }),
          tap(() => {
            if (action.reload) {
              window.location.reload();
            }
          }),
          catchError((err) => {
            return of(new UpdateVetFailed(err));
          })
        )
      )
    )
  );
  /**
   * Launch update vet use API call
   */
  updateVetUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateVetUser>(VetActionTypes.UpdateVetUserActionType),
      concatMap((action) =>
        this.vetService.updateOktaVet(action.oktaId, action.vetUser).pipe(
          tap(() => {
            if (this.localeId !== action.vetUser.preferredLanguage) {
              LocalizationService.setLanguageAndReload(action.vetUser.preferredLanguage);
            }
          }),
          map(() => {
            return new UpdateVetUserSuccess(action.vetUser);
          }),
          catchError((err) => {
            return of(new UpdateVetUserFailed(err));
          })
        )
      )
    )
  );
  /**
   * Launch update vet use API call
   */
  updateVetClinic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateClinic),
      switchMap((action) =>
        this.vetService.apiUpdateClinic(action.clinicId, action.clinic).pipe(
          switchMap((clinic: Clinic) => [updateClinicSuccess({ clinic }), toggleLoader({ value: false })]),
          tap(() => action.redirectTo && this.router.navigate([...action.redirectTo])),
          catchError(() => [
            setAlert({
              alert: {
                message: $localize`:@@error_general_text:`,
                alertType: RCAlertType.ERROR,
              },
            }),
            toggleLoader({ value: false }),
          ])
        )
      )
    )
  );

  /*** UPDATE VET USER ***/

  constructor(
    private actions$: Actions,
    private vetService: VetService,
    @Inject(LOCALE_ID) protected localeId: string,
    private router: Router
  ) {}
}
