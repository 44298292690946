import { BaseInstrumentation, VERSION } from '@grafana/faro-core';
import { registerOnerror } from './registerOnerror';
import { registerOnunhandledrejection } from './registerOnunhandledrejection';
export class ErrorsInstrumentation extends BaseInstrumentation {
  constructor() {
    super(...arguments);
    this.name = '@grafana/faro-web-sdk:instrumentation-errors';
    this.version = VERSION;
  }
  initialize() {
    this.logDebug('Initializing');
    registerOnerror(this.api);
    registerOnunhandledrejection(this.api);
  }
}
