import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { Pet, Weight } from '@app/core/models';
import { Observable } from 'rxjs';
import { VetFacade } from '@app/store/vet';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PetBody } from '@app/core/models/pet-body';

@Injectable({
  providedIn: 'root',
})
export class PetService extends ApiService {
  public static readonly WEIGHT_OVERWRITE_GRACE_PERIOD = 1000 * 60 * 60 * 12; // this is the duration in which we overwrite a weight rather than adding a new one

  constructor(private http: HttpClient, private vetFacade: VetFacade) {
    super();
  }

  createPetDraft(pet: PetBody): Observable<Pet> {
    return this.http
      .post<Pet>(this.pathUrl.petsDraft(this.vetFacade.clinicId), { ...pet, hoursTimeout: 1 })
      .pipe(catchError(this.handleError.bind(this)));
  }

  getPetWeights(petId: string): Observable<Weight[]> {
    return this.http.get(this.pathUrl.petsWeight(this.vetFacade.clinicId, petId)).pipe(
      map((weights: Weight[]) => {
        return weights.map((weight) => {
          return { ...weight, weightDate: new Date(weight.weightDate) };
        });
      }),
      catchError(this.handleError.bind(this))
    );
  }

  editPetWeight(weight: Weight): Observable<Weight> {
    const weightBody: Weight = {
      weightDate: weight.weightDate,
      bcs: weight.bcs || 0,
      measure: weight.measure,
      measureUnit: weight.measureUnit,
      type: 0,
    };
    return this.http
      .patch(`${this.pathUrl.petsWeight(this.vetFacade.clinicId, weight.petId)}/${weight.id}`, weightBody)
      .pipe(catchError(this.handleError.bind(this)));
  }

  deletePetWeight(weight: { id: string; petId: string }): Observable<Weight> {
    return this.http
      .delete(`${this.pathUrl.petsWeight(this.vetFacade.clinicId, weight.petId)}/${weight.id}/`)
      .pipe(catchError(this.handleError.bind(this)));
  }

  addPetWeight(petId: string, weight: Weight): Observable<Weight> {
    return this.http
      .post<Weight>(this.pathUrl.petsWeight(this.vetFacade.clinicId, petId), weight)
      .pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * In some cases we may want ot overwrite a recent weight rather than create a new one, such as when we go back to the currenr
   * weight step of a flow, based on a grace period duration.
   */
  addOrOverwriteWeight(weightBody: Weight): Observable<Weight> {
    return this.getPetWeights(weightBody.petId).pipe(
      map((weights) => {
        return weights.find((weight) => {
          return Math.abs(weight.weightDate.getTime() - weightBody.weightDate.getTime()) <= PetService.WEIGHT_OVERWRITE_GRACE_PERIOD;
        });
      }),
      switchMap((weightToEdit) => {
        if (weightToEdit) {
          return this.editPetWeight({ ...weightBody, id: weightToEdit.id });
        } else {
          return this.addPetWeight(weightBody.petId, weightBody);
        }
      })
    );
  }
}
