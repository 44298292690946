import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Product, ProductBack, ProductPriceEdit, ProductV2 } from '@app/core/models';
import { Market } from '@app/core/models/market';
import { CountryCode } from '@app/shared/utils/enums/country-code.enum';
import { ProductPillar } from '@app/shared/utils/enums/pillar.enum';
import { buildPricesApiBody } from '@app/shared/utils/static-helpers/product-helper';
import { VetFacade } from '@app/store/vet';
import { environment } from '@env/environment';
import { combineLatest, Observable } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { ApiService } from './../api.service';
import { ProductMapperService } from '@app/core/services/network/product/product-mapper.service';
import { PRODUCTS_FIELDS_FILTER, PRODUCTS_FIELDS_FILTER_FR } from '@app/core/services/network/product/product-service.constants';
import { ServicesHelper } from '@app/core/services';
import { Helper, Tool } from '@app/shared/utils';

@Injectable()
export class ProductService extends ApiService {
  constructor(
    private http: HttpClient,
    private vetFacade: VetFacade,
    private productMapperService: ProductMapperService,
    @Inject(LOCALE_ID) protected locale: string
  ) {
    super();
  }

  fetchProducts(pillars: ProductPillar[], tool: Tool = undefined, locale: string = this.locale): Observable<Product[]> {
    if (!environment.isStandalone) {
      return this._fetchProductsV2(pillars, tool).pipe(
        map((response) => response.results),
        withLatestFrom(this.vetFacade.currentSystemPreferenceCode$),
        map(([products, currentSystemPreferenceCode]) =>
          products
            .map((product: ProductV2) => this.productMapperService.mapProductV2ToProduct(product, currentSystemPreferenceCode))
            .filter((product: Product) => product.packages?.length > 0)
        ),
        shareReplay(1),
        catchError(this.handleError.bind(this))
      );
    } else {
      return this._fetchProductsV1(locale, pillars).pipe(
        withLatestFrom(this.vetFacade.currentSystemPreferenceCode$),
        map(
          ([products, currentSystemPreferenceCode]) =>
            Array.isArray(products) && ServicesHelper.filterMapProducts(products, currentSystemPreferenceCode)
        ),
        shareReplay(1),
        catchError(this.handleError.bind(this))
      );
    }
  }

  fetchProductsByIds(productIds: string[], pillars: ProductPillar[], lang: string = this.locale): Observable<Product[]> {
    return this.fetchProducts(pillars, undefined, lang).pipe(
      map((products: Product[]) => products.filter((product) => productIds.includes(product.id)))
    );
  }

  deleteClinicPrices = (): Observable<null> =>
    this.vetFacade.currentClinicId$.pipe(
      switchMap((currentClinicId) => {
        return this.http.delete(this.pathUrl.productsV1(currentClinicId));
      }),
      catchError(this.handleError.bind(this))
    );

  updateClinicPrices = (newPricesList: ProductPriceEdit[] = []): Observable<string | unknown> => {
    return this.vetFacade.currentClinicId$.pipe(
      switchMap((currentClinicId) => this.http.put(this.pathUrl.productsV1(currentClinicId), buildPricesApiBody(newPricesList))),
      catchError(this.handleError.bind(this))
    );
  };

  fetchProductById(productId: string): Observable<Product> {
    if (!environment.isStandalone) {
      return combineLatest([this.vetFacade.currentClinicId$, this.vetFacade.currentSystemPreferenceCode$]).pipe(
        switchMap(([currentClinicId, currentSystemPreferenceCode]) => {
          return this.http.get<ProductV2>(this.pathUrl.productDetailV2(currentClinicId, productId)).pipe(
            map((product: ProductV2) => this.productMapperService.mapProductV2ToProduct(product, currentSystemPreferenceCode)),
            catchError(this.handleError.bind(this))
          );
        })
      );
    } else {
      return this.http.get<ProductBack>(this.pathUrl.productDetailSrs(productId)).pipe(
        withLatestFrom(this.vetFacade.currentSystemPreferenceCode$),
        map(([product, currentSystemPreferenceCode]) => ServicesHelper.mapProductBackToFront(product, currentSystemPreferenceCode)),
        catchError(this.handleError.bind(this))
      );
    }
  }

  private _fetchProductsV2 = (pillars: ProductPillar[], tool: Tool): Observable<{ results: ProductV2[] }> =>
    combineLatest([this.vetFacade.market$]).pipe(
      take(1),
      filter(([market]) => !!market),
      map(([market]) => {
        const fields: string = (tool === Tool.FastReco ? PRODUCTS_FIELDS_FILTER_FR : PRODUCTS_FIELDS_FILTER).join(',');
        return {
          locale: Helper.catalogLang(this.locale, market).replace('-', '_'),
          product_pillar: pillars,
          fields: fields,
          limit: 10000,
        };
      }),
      withLatestFrom(this.vetFacade.currentClinicId$),
      switchMap(([body, clinicId]) =>
        this.http
          .post<{ results: ProductV2[] }>(this.pathUrl.productsV2(clinicId), body)
          .pipe(shareReplay(1), catchError(this.handleError.bind(this)))
      )
    );

  private _fetchProductsV1 = (lang: string, pillars: ProductPillar[]): Observable<string | ProductBack[]> =>
    combineLatest([this.vetFacade.currentClinicCountry$, this.vetFacade.market$]).pipe(
      filter(([country, market]) => !!country && !!market),
      take(1),
      map(([country, market]) => {
        const catalogCountry = this.getProductCatalogCountry(country, market);
        const parameters = {
          country: catalogCountry.toLowerCase(),
          pillar: pillars,
          language: Helper.catalogLang(lang, market),
        };
        const params = new HttpParams();
        return params.set('where', JSON.stringify(parameters));
      }),
      withLatestFrom(this.vetFacade.currentClinicId$),
      switchMap(([params]) =>
        this.http
          .get<ProductBack[]>(this.pathUrl.productsSrs(), {
            params,
          })
          .pipe(shareReplay(1), catchError(this.handleError.bind(this)))
      )
    );

  /**
   * Get product catalog country
   */
  private getProductCatalogCountry(country: CountryCode | string, market: Market): string {
    const catalogCountry = market.forceCatalogCountry;
    return catalogCountry || country;
  }
}
