import { ProductPrice } from '@app/core/models';
import { MeasurementCodeType } from '@app/shared/utils';

export interface Package {
  measurementUnitCode: MeasurementCodeType;
  sCode: string;
  ean: string;
  sku?: string;
  type: string;
  weight: number;
  weightInG?: number;
  convertedWeight: ConvertedWeight;
  text: string;
  productPrice: ProductPrice;
  id?: string;
}

export interface ConvertedWeight {
  measure: number;
  unitSuffix: string;
  measureUnit: string;
}

export interface Pack {
  scode: string;
  ean: string;
  format: PackFormatV2;
  weight: string;
  converted_weight: string;
  measurementUnitCode: MeasurementCodeType;
  productPrice: ProductPrice;
  number_of_unit: number;
}

export enum PackFormatV2 {
  SLR_P15 = 'SLR P15',
  SLR_E2 = 'SLR E2',
  SLR_C4 = 'SLR C4',
  POUCH = 'POUCH',
  SQ_W8 = 'SQ W8',
  CAN = 'CAN',
  SLR_X1 = 'SLR X1',
  SLR_B4 = 'SLR B4',
  SLR_A4 = 'SLR A4',
  SLR_P12 = 'SLR P12',
  SLR_E2_mono_PE = 'SLR E2 mono-PE',
  SLR_CX4 = 'SLR CX4',
  SQ_J8 = 'SQ J8',
  SQ_M10 = 'SQ M10',
  SLR_K4_500 = 'SLR K4-500',
  SQ_N10 = 'SQ N10',
  SQUARE = 'SQUARE',
  SLR_H2 = 'SLR H2',
  SQ_P10 = 'SQ P10',
  SQ_E8 = 'SQ E8',
  SLR_Z1 = 'SLR Z1',
  SLR_Y1 = 'SLR Y1',
  SLR_P18 = 'SLR P18',
  BOX370_180_x_950 = '370 +180 x 950',
  gal = 'gal',
  SLR_T4_500 = 'SLR T4-500',
  BOX370_100_x_900 = '370 + 100 x 900',
  BOX370_150_x_950 = '370 + 150 x 950',
  SINGLE_LAYER = 'SINGLE LAYER',
  SLR_A4_mono_PE = 'SLR A4 mono-PE',
  SLR_L4_500 = 'SLR L4-500',
  SQ_I15 = 'SQ I15',
  SQ_O15 = 'SQ O15',
  BOX370_120_x_950 = '370 + 120 x 950',
  DOYPACK = 'DOYPACK',
  SQ_F15 = 'SQ F15',
  SQ_TS10 = 'SQ TS10',
  SLR_B4_mono_PE = 'SLR B4 mono-PE',
  SLR_CX4_mono_PE = 'SLR CX4 mono-PE',
  SLR_W15 = 'SLR W15',
  SLR_G2 = 'SLR G2',
  FOLDING_BOX = 'FOLDING BOX',
  TRAY_CAN_85_120 = 'TRAY CAN 85-120',
  CAN_LABEL_PEELABLE = 'can_label_peelable',
  PINCH = 'PINCH',
  SLR_J8 = 'SLR J8',
  PILL_ASSIST_1_6oz = 'PILL ASSIST 1.6oz',
  BOX370_120_x_900 = '370 + 120 x 900',
  StarterKit = 'starter_kit', // this was added on
}
