import { CountryCode } from './enums/country-code.enum';
import { MeasurementCodeType } from '@app/shared/utils/enums/measurement-type-code.enum';
import { Weight } from '@app/core/models';

export class Constants {
  /* Translation */
  public static readonly TRANSLATION = 'translation';

  /* Loggin */
  public static readonly POPIN_INFORMATION_KEY = 'popin-information-key';

  public static readonly INTERNAL_REDIRECT_KEY = 'internal-redirect-flow';

  /* Google tag manager */
  public static readonly googleTagSiteId = 'RCGlobalVS1';
  public static readonly globalContainerId = 'GTM-W54LCFP';
  public static readonly vetContainerId = 'GTM-NCWZQ3';

  /* Google Optimize */
  public static readonly optContainerIdProd = 'GTM-5DQH4DK';
  public static readonly optContainerId = 'GTM-NQ8G33N';

  /* Weight tracking */
  public static readonly IMPERIAL_COUNTRIES: string[] = [CountryCode.US, CountryCode.PR];
  // TODO move in the blob
  public static readonly COUNTRIES_WITH_FOOTER: string[] = [CountryCode.FR];
  public static readonly maxGram = 1000;
  public static readonly maxOunce = 16;
  public static readonly gramCode = 11;
  public static readonly kilogramCode = 12;
  public static readonly poundCode = 21;
  public static readonly ounceCode = 22;
  public static readonly ounceToGram = 28.3495;
  public static readonly poundToGram = 453.592;
  public static readonly gramSuffix = 'g';
  public static readonly kilogramSuffix = 'Kg';
  public static readonly poundSuffix = 'lbs';
  public static readonly ounceSuffix = 'oz';
  public static readonly ORIGINAL_PRODUCT = 'originalProduct';
  public static readonly DEFAULT = 'default';
  public static readonly OUNCE_TO_GRAM_MAPPING = {
    2: 57,
    3: 85,
    4: 114,
    5.1: 145,
    5.2: 150,
    5.8: 165,
    7.7: 220,
    12: 341,
    12.5: 355,
    13: 370,
    13.4: 380,
    13.5: 385,
    13.6: 386,
    14.7: 390,
    14.4: 410,
    17.6: 500,
  };
  public static readonly GRAM_TO_OUNCE_MAPPING = Object.fromEntries(
    Object.keys(Constants.OUNCE_TO_GRAM_MAPPING).map((key) => [Constants.OUNCE_TO_GRAM_MAPPING[key], parseInt(key, 10)])
  );
  public static readonly POUND_TO_GRAM_MAPPING = {
    1.3: 600,
    1.4: 650,
    1.5: 700,
  };
  public static readonly GRAM_TO_POUND_MAPPING = Object.fromEntries(
    Object.keys(Constants.POUND_TO_GRAM_MAPPING).map((key) => [Constants.POUND_TO_GRAM_MAPPING[key], parseInt(key, 10)])
  );
  public static readonly POUND_TO_KGRAM_MAPPING = {
    3: 1.37,
    3.3: 1.5,
    3.5: 1.59,
    4.4: 2,
    5.5: 2.5,
    6: 2.73,
    6.6: 3,
    7: 3.18,
    7.7: 3.5,
    8.8: 4,
    10: 4.54,
    15: 6.81,
    15.4: 7,
    16.5: 7.5,
    17.6: 8,
    18: 8.17,
    18.7: 8.5,
    19.8: 9,
    20: 9.1,
    20.9: 9.5,
    22: 10,
    24.2: 11,
    25: 11.34,
    25.3: 11.5,
    26.4: 12,
    28.6: 13,
    30: 13.61,
    30.8: 14,
    32: 14.52,
    33: 15,
    35: 15.88,
  };
  public static readonly KGRAM_TO_POUND_MAPPING = Object.fromEntries(
    Object.keys(Constants.POUND_TO_KGRAM_MAPPING).map((key) => [Constants.POUND_TO_KGRAM_MAPPING[key], Number(key)])
  );

  public static readonly weightSuffixes = {
    [Constants.gramCode]: Constants.gramSuffix,
    [Constants.kilogramCode]: Constants.kilogramSuffix,
    [Constants.ounceCode]: Constants.ounceSuffix,
    [Constants.poundCode]: Constants.poundSuffix,
  };
  public static readonly unitNumberToEnum = {
    [Constants.gramCode]: MeasurementCodeType.Gram,
    [Constants.kilogramCode]: MeasurementCodeType.Kilogram,
    [Constants.ounceCode]: MeasurementCodeType.Ounce,
    [Constants.poundCode]: MeasurementCodeType.Pound,
  };
  public static readonly weightCodes = {
    [MeasurementCodeType.Gram]: Constants.gramCode,
    [MeasurementCodeType.Kilogram]: Constants.kilogramCode,
    [MeasurementCodeType.Ounce]: Constants.ounceCode,
    [MeasurementCodeType.Pound]: Constants.poundCode,
  };

  /* Images */
  public static readonly placeholderImgDog = 'assets/other/placeholder_dog.svg';
  public static readonly placeholderImgCat = 'assets/other/placeholder_cat.svg';
  public static readonly placeholderImgVet = 'assets/other/placeholder_vet.svg';
  public static readonly placeholderImgPetOwner = 'assets/other/placeholder_pet-owner.svg';
  public static readonly imgWeightProgramMaintenance = 'assets/other/weight_programs/Maintenance.svg';
  public static readonly imgWeightProgramStabilization = 'assets/other/weight_programs/Stabilization.svg';
  public static readonly imgWeightProgramLoss = 'assets/other/weight_programs/Loss.svg';
  public static readonly blobStorageUrlId = 'rcvetfileblob';

  /* Mixed / Undefined breeds */
  public static readonly mixedBreedCode = 'mixed_breed';
  public static readonly undefinedBreedCode = 'undefined';

  /* Products / Categories */
  public static readonly pediatricSpecificNeed = 'growth';
  public static readonly weightLossSpecificNeed = 'weight_management';

  /* Pagination */
  public static readonly LIMIT_PET_RECORD_COUNT = 30;
  public static readonly MORE_PET_RECORD_COUNT = 20;
  public static readonly LIMIT_AUTOCOMPLETE = 5;
  public static readonly LIMIT_CONSULTATIONS_PAGE = 5; // should be in each component using it.

  /* Truncate String */
  public static readonly LIMIT_STRING = 50;

  /* On-Boarding */
  public static readonly VET_NOT_FOUND_ERROR = 'Veterinary Not Found';
  public static readonly VET_NOT_LINKED_ERROR = 'User not linked to a clinic';

  public static readonly LIMIT_INPUT_WEIGHT: Weight = {
    measure: 100,
    measureUnit: MeasurementCodeType.Kilogram,
  };

  /* Browsers */

  /* Patthologies */
  public static readonly bcsPathologies = {
    1: 'underweight_bcs_1_(out_of_9)',
    2: 'underweight_bcs_2_(out_of_9)',
    3: 'underweight_bcs_3_(out_of_9)',
    6: 'overweight_bcs_6_(out_of_9)',
    7: 'obesity_bcs_7_(out_of_9)',
    8: 'obesity_bcs_8_(out_of_9)',
    9: 'obesity_bcs_9_(out_of_9)',
  };
  public static readonly weightPathologies = ['underweight', 'overweight', 'obesity'].concat(Object.values(Constants.bcsPathologies));

  /* API workarounds */
  public static readonly rationV2Apierros = [
    'rationing for puppy/kitten is not allowed due to pet age',
    'rationing not applicable for your adult target weight',
  ];

  public static readonly axPostalCodes = [
    '22840',
    '22910',
    '22920',
    '22930',
    '22940',
    '22950',
    '22270',
    '22220',
    '22310',
    '22330',
    '22410',
    '22710',
    '22340',
    '22240',
    '22130',
    '22140',
    '22150',
    '22810',
    '22820',
    '22830',
    '22730',
    '22610',
    '22630',
    '22100',
    '22110',
    '22120',
    '22160',
    '22320',
    '22430',
    '22720',
    '22520',
    '22530',
    '22550',
  ];

  /* supported browsers => key: browserName, value: browserVersion*/
  public static readonly supportedBrowsers = new Map<string, number>([
    ['Edge', 79],
    ['Firefox', 72],
    ['Chrome', 80],
    ['Safari', 13],
    ['Opera', 66],
    ['Samsung Internet', 11.2],
    ['QQ Browser', 10.4],
    ['Baidu Browser', 7.12],
    ['KaiOS Browser', 2.5],
  ]);

  /* renal detect product */
  public static readonly PRODUCT_NOT_AT_RISK = [2724, 4090, 2725];
  public static readonly PRODUCT_AT_RISK_EARLY = [1242, 1243, 1244];
  public static readonly PRODUCT_AT_RISK = [2726, 4091, 2727];
  public static readonly PRODUCT_CDK = [1246, 1352, 3900, 6531, 3949, 4160, 4030, 4031, 4067, 4166, 4167, 4168, 4171];
  /**
   * General noreply sender email address
   */
  public static readonly NO_REPLY_MAIL = 'noreply@vet.royalcanin.com';

  /**
   * Link to the PDF worker script
   */

  public static readonly PDF_WORKER_SCRIPT = '/assets/pdfjs-worker/pdf.worker.min.js';
}
