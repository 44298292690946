<app-rc-dialog-wrapper (closePopin)="close()">
  <div class="loggin-popin">
    <div class="loggin-popin__image">
      <app-icon class="daily-input-icon" [name]="IconName.light_bulb"></app-icon>
    </div>
    <h1 class="loggin-popin__title rc-title-beta product__title">
      {{ data.title | translateKey }}
    </h1>
    <p class="loggin-popin__description">
      {{ data.description }}
    </p>
    <ng-container *ngIf="data.link">
      <a class="loggin-popin__local-support-button rc-button__title rc-button__title--link link" href="{{ data.link }}">{{
        data.textlink
      }}</a>
    </ng-container>
    <ng-container *ngIf="data.displaySupport">
      <p class="loggin-popin__support">
        <span i18n="@@dialog_loggedin_can_contact">You can contact your</span>
        <span
          (click)="redirectToSupport()"
          class="loggin-popin__local-support-button rc-button__title rc-button__title--link"
          i18n="@@action_local_support"
          >local support</span
        >
        <span i18n="@@dialog_loggedin_further_enquiries">for any further enquiries.</span>
      </p>
    </ng-container>
    <ng-container *ngIf="data.showCheckbox">
      <app-checkbox
        id="dontshowthismessage"
        class="show-message"
        (checked)="definitelyDisabledMessage($event)"
        [label]="'sr-tutorial_dontshowthismessageagain' | translateKey"
        [multiLine]="true"
      ></app-checkbox>
    </ng-container>
  </div>
</app-rc-dialog-wrapper>
