import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import { Buffer } from 'buffer';
import { Constants } from '@app/shared/utils/constants';

@Component({
  selector: 'app-see-preview-popin',
  templateUrl: './see-preview-popin.component.html',
  styleUrls: ['./see-preview-popin.component.scss'],
})
export class SeePreviewPopinComponent implements OnInit {
  @ViewChild('myCanvas', { static: false }) myCanvas: ElementRef<HTMLDivElement>;

  public pdfPrepared = false;

  constructor(
    public dialogRef: MatDialogRef<SeePreviewPopinComponent>,
    @Inject(DIALOG_DATA) public data: { pdf: { data: string; fileUrl?: string } }
  ) {}

  ngOnInit(): void {
    this.renderPdf(this.data.pdf.data);
  }

  renderPdf(pdfBase64: string): void {
    const pdfData = Buffer.from(pdfBase64, 'base64');
    GlobalWorkerOptions.workerSrc = Constants.PDF_WORKER_SCRIPT;
    const loadingTask = getDocument({ data: pdfData });
    const scale = 1.2;
    loadingTask.promise.then((pdf) => {
      const renderPage = (pageNumber, canvas) => {
        pdf.getPage(pageNumber).then((page) => {
          const viewport = page.getViewport({ scale: scale });
          if (canvas) {
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            const context = canvas.getContext('2d');

            // Render PDF page into canvas context
            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            };

            page.render(renderContext);
          }
        });
      };

      for (let page = 1; page <= pdf.numPages; page++) {
        const canvas = document.createElement('canvas');
        this.myCanvas.nativeElement.appendChild(canvas);
        canvas.className = 'pdf-page-canvas';
        renderPage(page, canvas);

        if (page === pdf.numPages) {
          this.pdfPrepared = true;
        }
      }
    });
  }

  /**
   * Close modal
   */
  dismiss(): void {
    this.pdfPrepared = false;
    this.dialogRef.close();
  }
}
