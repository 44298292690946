import { NgModule } from '@angular/core';
import { IconRegistryService } from './utils/icon/icon-registry.service';
import { IconModule } from './utils/icon/icon.module';
import {
  iconActions,
  iconAddAddress,
  iconAddressBook,
  iconAlert,
  iconArrowPanel,
  iconBloodCreatinine,
  iconBloodUrea,
  iconBoth,
  iconCalculator,
  iconCalendar,
  iconCalendarSmall,
  iconCat,
  iconCatBcs1Large,
  iconCatBcs1Medium,
  iconCatBcs2Large,
  iconCatBcs2Medium,
  iconCatBcs3Large,
  iconCatBcs3Medium,
  iconCatBcs4Large,
  iconCatBcs4Medium,
  iconCatBcs5Large,
  iconCatBcs5Medium,
  iconCatBcs6Large,
  iconCatBcs6Medium,
  iconCatBcs7Large,
  iconCatBcs7Medium,
  iconCatBcs8Large,
  iconCatBcs8Medium,
  iconCatBcs9Large,
  iconCatBcs9Medium,
  iconChevronDown,
  iconClose,
  iconDelete,
  iconDocuments,
  iconDog,
  iconDogBcs1Large,
  iconDogBcs1Medium,
  iconDogBcs2Large,
  iconDogBcs2Medium,
  iconDogBcs3Large,
  iconDogBcs3Medium,
  iconDogBcs4Large,
  iconDogBcs4Medium,
  iconDogBcs5Large,
  iconDogBcs5Medium,
  iconDogBcs6Large,
  iconDogBcs6Medium,
  iconDogBcs7Large,
  iconDogBcs7Medium,
  iconDogBcs8Large,
  iconDogBcs8Medium,
  iconDogBcs9Large,
  iconDogBcs9Medium,
  iconEdit,
  iconEmptyStateDesktop,
  iconEmptyStateMobile,
  iconExclamation,
  iconExternalLink,
  iconFemale,
  iconFood2,
  iconFood3,
  iconGrid,
  iconIndoor,
  iconInfo,
  iconLightBulb,
  iconLink,
  iconList,
  iconLoading,
  iconLogoWithText,
  iconMale,
  iconMinus,
  iconNoProducts,
  iconOutdoor,
  iconPlus,
  iconProductSearch,
  iconRangeBhn,
  iconRangeCcn,
  iconRangeFbn,
  iconRangeShn,
  iconRefresh,
  iconSearch,
  iconShop,
  iconSpecieCat,
  iconSpecieDog,
  iconSpecificGravity,
  iconTerritoryBirthAndGrowth,
  iconTerritoryDermatology,
  iconTerritoryGastrointestinalTract,
  iconTerritoryHealthManagement,
  iconTerritoryUrinary,
  iconTerritoryVitalSupport,
  iconTerritoryWeightManagement,
  iconToolDaActive,
  iconToolMufActive,
  iconToolRdActive,
  iconToolSrActive,
  iconToolFrActive,
  iconToolWmActive,
  iconTrash,
  iconUnlink,
  iconWarning,
  iconWeightLossProgram,
  iconWetProducts,
  iconSpinnerCircle,
  iconSuccess,
  iconArrowGo,
  iconFail,
  iconPrice16,
  iconPrice24,
  iconShop16,
  iconNewWindow16,
  iconSuccessBig,
  iconView,
  iconHide,
  iconTick,
  iconWarningCross,
  iconPetChart,
  iconCentils,
  iconWeight,
  iconFilter,
  iconTerritorySportingLife,
} from './utils/icon/icons';
import { iconInformation } from '@app/shared/utils/icon/icons/icons/icon-information.icon';

@NgModule({
  imports: [IconModule],
  declarations: [],
  exports: [IconModule],
})
export class SharedRegisterIconsModule {
  constructor(private registry: IconRegistryService) {
    this.registry.registerIcons([
      iconActions,
      iconAlert,
      iconAddressBook,
      iconBloodCreatinine,
      iconBloodUrea,
      iconBoth,
      iconCalculator,
      iconCat,
      iconChevronDown,
      iconClose,
      iconDelete,
      iconDocuments,
      iconDog,
      iconEmptyStateDesktop,
      iconEmptyStateMobile,
      iconFemale,
      iconGrid,
      iconInfo,
      iconInformation,
      iconIndoor,
      iconLink,
      iconList,
      iconLightBulb,
      iconMale,
      iconMinus,
      iconSpecieDog,
      iconSpecieCat,
      iconPlus,
      iconOutdoor,
      iconAddAddress,
      iconShop,
      iconSpecificGravity,
      iconUnlink,
      iconWarning,
      iconWetProducts,
      iconWeightLossProgram,
      iconProductSearch,
      iconRangeBhn,
      iconRangeCcn,
      iconRangeFbn,
      iconRangeShn,
      iconRefresh,
      iconEdit,
      iconFood2,
      iconFood3,
      iconCatBcs1Large,
      iconCatBcs2Large,
      iconCatBcs3Large,
      iconCatBcs4Large,
      iconCatBcs5Large,
      iconCatBcs6Large,
      iconCatBcs7Large,
      iconCatBcs8Large,
      iconCatBcs9Large,
      iconDogBcs1Large,
      iconDogBcs2Large,
      iconDogBcs3Large,
      iconDogBcs4Large,
      iconDogBcs5Large,
      iconDogBcs6Large,
      iconDogBcs7Large,
      iconDogBcs8Large,
      iconDogBcs9Large,
      iconCatBcs1Medium,
      iconCatBcs2Medium,
      iconCatBcs3Medium,
      iconCatBcs4Medium,
      iconCatBcs5Medium,
      iconCatBcs6Medium,
      iconCatBcs7Medium,
      iconCatBcs8Medium,
      iconCatBcs9Medium,
      iconDogBcs1Medium,
      iconDogBcs2Medium,
      iconDogBcs3Medium,
      iconDogBcs4Medium,
      iconDogBcs5Medium,
      iconDogBcs6Medium,
      iconDogBcs7Medium,
      iconDogBcs8Medium,
      iconDogBcs9Medium,
      iconTrash,
      iconCalendar,
      iconExclamation,
      iconSearch,
      iconArrowPanel,
      iconCalendarSmall,
      iconLoading,
      iconTerritoryWeightManagement,
      iconTerritoryBirthAndGrowth,
      iconTerritoryDermatology,
      iconTerritoryGastrointestinalTract,
      iconTerritoryHealthManagement,
      iconTerritoryUrinary,
      iconTerritoryVitalSupport,
      iconTerritorySportingLife,
      iconToolDaActive,
      iconToolSrActive,
      iconToolFrActive,
      iconToolWmActive,
      iconToolRdActive,
      iconToolMufActive,
      iconExternalLink,
      iconNoProducts,
      iconLogoWithText,
      iconPlus,
      iconMinus,
      iconSpinnerCircle,
      iconSuccess,
      iconSuccessBig,
      iconFail,
      iconArrowGo,
      iconPrice16,
      iconPrice24,
      iconShop16,
      iconNewWindow16,
      iconView,
      iconHide,
      iconTick,
      iconWarningCross,
      iconWarning,
      iconPetChart,
      iconCentils,
      iconWeight,
      iconFilter,
    ]);
  }
}
