/**
 *
 * @license Royal Canin
 * @contributors :
 *  - Amar YASSA
 */

import { StateNamesEnum } from '@app/shared/utils/enums/state-names.enum';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ShareRecommendationState } from './share-recommendation.state';
import { selectToolInfo } from '@app/store/core';
import { petHasGrowthChart } from '@app/shared/utils/static-helpers/growth-chart-helper';
import { SystemPreferenceCode } from '@app/shared/utils/enums/system-preference-code';

const selectShareRecommendationState = createFeatureSelector<ShareRecommendationState>(StateNamesEnum.SHARE_RECOMMENDATION_STATE_NAME);

export const selectShareRecommendationData = createSelector(
  selectShareRecommendationState,
  (state: ShareRecommendationState) => state?.data
);

export const selectRecommendationId = (productIds: string[]) => {
  const id = [...productIds].sort().join('');
  return createSelector(
    selectShareRecommendationState,
    selectToolInfo,
    (recommendationState, toolInfo) =>
      recommendationState.randomRecommendationIds.find((recommendationId) => {
        return recommendationId.id === id && recommendationId.flowId === toolInfo.tool_flow_id;
      })?.randomId
  );
};

export const selectPetHasGrowthChart = (currentSystemPreferenceCode: SystemPreferenceCode) =>
  createSelector(selectShareRecommendationState, (state) => {
    return petHasGrowthChart(
      state.data?.petInfo?.lifestage,
      state.data?.petInfo?.speciesCode,
      state.data?.petInfo?.targetWeight,
      currentSystemPreferenceCode
    );
  });
