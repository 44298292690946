import { Tool } from '@app/shared/utils';
import { createAction, props } from '@ngrx/store';
import { AllowanceStep } from '../../allowance';
/**
 * Action dispatched to complete a step and navigate to the next in line
 */
export const goToNextStep = createAction('[Allowance] Complete Step', props<{ tool: Tool }>());
/**
 * Action dispatched to set the current step
 */
export const setAllowanceStep = createAction('[Allowance] Set Step', props<{ tool?: Tool; step: AllowanceStep }>());
/**
 * Action dispatched to reset to the first step after leaving rationing but without reseting information (for personalised bag)
 * and without routing side effects that are triggered by the setAllowanceStep action
 */
export const resetAllowanceStepToFirst = createAction('[Allowance] Reset Step To First');
/**
 * Action dispatched to set steps
 */
export const setAllowanceSteps = createAction('[Allowance] Set Steps', props<{ steps: AllowanceStep[] }>());
/**
 * Reset allowance store data
 */
export const resetAllowance = createAction('[Allowance] Reset');
/**
 * Reset allowance nutrition recommendation and default nutrition recommendation
 */
export const resetAllowanceNutritionRecommendation = createAction('[Allowance] Reset Nutrition Recommendation');
