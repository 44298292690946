import { globalObject } from '../globalObject';
import { internalGlobalObjectKey } from './const';
export function getInternalFromGlobalObject() {
  return globalObject[internalGlobalObjectKey];
}
export function setInternalFaroOnGlobalObject(faro) {
  if (!faro.config.isolate) {
    faro.internalLogger.debug('Registering internal Faro instance on global object');
    Object.defineProperty(globalObject, internalGlobalObjectKey, {
      configurable: false,
      enumerable: false,
      writable: false,
      value: faro
    });
  } else {
    faro.internalLogger.debug('Skipping registering internal Faro instance on global object');
  }
}
export function isInternalFaroOnGlobalObject() {
  return internalGlobalObjectKey in globalObject;
}
