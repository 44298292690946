import { Consultation, Weight } from '@app/core/models';

export interface PetState {
  consultationsWithoutVisit: Consultation[];
  growChartData: GrowChartData;
}

export const initialPetState: PetState = {
  consultationsWithoutVisit: [],
  growChartData: { chartImage: null, chartWeights: [], notSavedPastWeights: [] },
};

export interface GrowChartData {
  chartImage: string;
  chartWeights?: Weight[];
  notSavedPastWeights?: Weight[];
}
