import { createReducer, on } from '@ngrx/store';
import {
  getConsultationsWithoutVisitSuccess,
  resetPet,
  resetPetGrowthChartData,
  setPetGrowthChart,
  setPetNotSavedPastWeights,
} from './pet.actions';
import { initialPetState } from './pet.state';

export const petReducer = createReducer(
  initialPetState,
  on(getConsultationsWithoutVisitSuccess, (state, { consultations }) => ({
    ...state,
    consultationsWithoutVisit: consultations,
  })),
  on(setPetNotSavedPastWeights, (state, { weight }) => ({
    ...state,
    growChartData: { ...state.growChartData, notSavedPastWeights: [...state.growChartData.notSavedPastWeights, weight] },
  })),
  on(setPetGrowthChart, (state, { chartImage, chartWeights }) => ({
    ...state,
    growChartData: { ...state.growChartData, chartImage, chartWeights },
  })),
  on(resetPet, () => initialPetState),
  on(resetPetGrowthChartData, (state) => ({ ...state, growChartData: initialPetState.growChartData }))
);
