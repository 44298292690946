import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IconRegistryService } from './icon-registry.service';
import { IconName } from './icons';

@Component({
  selector: 'app-icon',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnChanges {
  @Input() name: IconName;

  private parser = new DOMParser();
  private icon: HTMLElement;

  constructor(private registry: IconRegistryService, private element: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.name?.currentValue) {
      this.clearPreviousIcon();
      const svgContent = this.registry.getIcon(this.name);
      if (svgContent) this.renderIcon(svgContent);
    }
  }
  /**
   * Clear previous icon from dom if already rendered
   */
  private clearPreviousIcon(): void {
    if (this.icon) {
      this.element.nativeElement.removeChild(this.icon);
    }
  }
  /**
   * Render icon
   */
  private renderIcon(svgContent: string): void {
    this.icon = this.parser.parseFromString(svgContent, 'image/svg+xml').documentElement;
    this.element.nativeElement.appendChild(this.icon);
  }
}
