import { Influencer } from '@app/shared/components/nutrients-list/nutrients';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Coverage,
  Criteria,
  PetInfo,
  Product,
  ProductRecommendation,
  ProductRecommendationBack,
  ProductRecommendationErrorTypes,
} from '@app/core/models';
import { MeasurementCodeType, NotRecommendedProductStatus, NotRecommendedReasons, ProductPrograms, Programs, SpeciesCode } from '../enums';
import { translationMappingSameSensitivityAndPatho } from '../enums/criteria-type.enum';
import { Helper } from './helper';
import { CRITERIAS_INDIVIDUALIS_CAT, CRITERIAS_INDIVIDUALIS_DOG, IGNORED_CRITERIAS_FOR_RCI } from '@app/shared/data/pathologies';
import { MeasureHelper } from '@app/shared/utils';
import { SystemPreferenceCode } from '../enums/system-preference-code';

/**
 * Round to integer the smart reco score (pmean)
 * @param pmean Reco score
 * Static util
 */
export function roundSmartReco(pmean: number): number {
  if (pmean < 0) {
    return -1;
  }
  return Math.round(pmean * 100);
}

export function areProductsForWeightLoss(products: Product[]): boolean {
  return products?.some((product) => product?.programs.includes(ProductPrograms.WEIGHT_LOSS));
}

export function getProgramFromPetInfoAndProducts(products: Product[], petInfo: PetInfo, targetProgram?: Programs): Programs {
  if (targetProgram) return targetProgram;

  if (petInfo.lifestage && Helper.hasAdultTargetWeight(petInfo.lifestage)) {
    return Programs.GROWTH;
  }

  if (petInfo.bcs > 5 && areProductsForWeightLoss(products)) {
    return Programs.WEIGHT_LOSS;
  }

  return Programs.NORMAL_ADULT;
}

export function filterAndFormatCoverageCriterias(
  coverage: Coverage,
  petInfo: PetInfo,
  notRecommendedReasons?: NotRecommendedReasons[]
): Coverage {
  return {
    ...coverage,
    criterias: coverage.criterias
      .filter((criteria) => {
        const isAdult = !Helper.hasAdultTargetWeight(petInfo.lifestage);
        const isHealthy = petInfo.pathologies.length === 0;
        const isSizeOrLifestageCriteria = ['size_lifestage', 'lifestage'].includes(criteria.type);
        const isBreedCriteria = ['breed_dog', 'breed_cat'].includes(criteria.type);

        // remove the breed related criterias that must never be displayed
        // remove the size/lifestage criteria if the pet is a sick adult
        return !isBreedCriteria && !(isAdult && !isHealthy && isSizeOrLifestageCriteria);
      })
      .map((criteria) => {
        const isNotRecommendedCriteria = !!notRecommendedReasons?.find(
          (reason) => criteria.type.includes(reason) || criteria.key.includes(reason)
        );
        return isNotRecommendedCriteria ? { ...criteria, isNotRecommended: isNotRecommendedCriteria } : criteria;
      }),
  };
}

export function formatNotRecommendedProductCoverageAndReasons(
  notRecommendedProduct: Pick<ProductRecommendationBack, 'coverage' | 'productsFilterPossibilities'>,
  petInfo: PetInfo
): Pick<ProductRecommendation, 'coverage' | 'notRecommendedReasons'> {
  const notRecommendedReasons = Object.keys(notRecommendedProduct.productsFilterPossibilities?.case_all?.notRecommendedReason || {})
    .map((key) => (key.includes(NotRecommendedReasons.LIFESTAGE) ? NotRecommendedReasons.LIFESTAGE : key))
    .filter((key) => Object.values(NotRecommendedReasons).includes(key as NotRecommendedReasons)) as NotRecommendedReasons[];
  return {
    coverage: notRecommendedProduct.coverage
      ? filterAndFormatCoverageCriterias(notRecommendedProduct.coverage, petInfo, notRecommendedReasons)
      : null,
    notRecommendedReasons,
  };
}

export function getNotRecommendedProductStatus(product: ProductRecommendation): NotRecommendedProductStatus {
  if (product.coverage?.pmean >= 0.65) {
    return NotRecommendedProductStatus.REQUIREMENTS_OK;
  }
  if (product.coverage?.pmean < 0.65 && product.coverage?.pmean >= 0.4) {
    return NotRecommendedProductStatus.REQUIREMENTS_PARTIAL;
  }
  if (product.coverage?.pmean < 0.4) {
    return NotRecommendedProductStatus.REQUIREMENTS_KO;
  }
}

export function getProductRecommendationErrorType(error: HttpErrorResponse, retryCount: number): ProductRecommendationErrorTypes {
  return error.status === 404 || retryCount >= 2
    ? ProductRecommendationErrorTypes.INDISPONIBILITY
    : ProductRecommendationErrorTypes.TECHNICAL;
}

export function getCriteriaTitleTranslationKey(criteria: Criteria | Influencer): string {
  return `sr-criteria_${translationMappingSameSensitivityAndPatho[criteria?.type + ':' + criteria?.key] || criteria?.key}`;
}

export function isIndividualis(
  products: ProductRecommendation[],
  petInfo: PetInfo,
  currentSystemPreferenceCode: SystemPreferenceCode
): boolean {
  if (!products?.length || !petInfo?.pathologies.length) {
    return false;
  }

  const isImperialSystem = currentSystemPreferenceCode === SystemPreferenceCode.imperialSystemCode;
  const currentWeightInKg = isImperialSystem
    ? MeasureHelper.convertWeight(petInfo?.weight, MeasurementCodeType.Pound, MeasurementCodeType.Kilogram).measure
    : petInfo?.weight;

  if (currentWeightInKg > 25) {
    return false;
  }

  return (
    products[0].coverage.criterias.length > 0 &&
    products[0].coverage.criterias
      .filter(({ type }) => !['breed_dog', 'breed_cat'].includes(type))
      .map(({ type, key }) => `${type}:${key}`)
      .filter((criteria) => {
        return !IGNORED_CRITERIAS_FOR_RCI.includes(criteria);
      })
      .every((criteria) => {
        if (petInfo.speciesCode === SpeciesCode.Cat) {
          return CRITERIAS_INDIVIDUALIS_CAT.includes(criteria);
        }

        if (petInfo.speciesCode === SpeciesCode.Dog) {
          return CRITERIAS_INDIVIDUALIS_DOG.includes(criteria);
        }
        return false;
      })
  );
}
