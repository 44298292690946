import { Injectable } from '@angular/core';
import { PetProfileFormValues } from '@app/core/models';
import { Pathology } from '@app/core/models/pathology';
import { WeightFormValue } from '@app/shared/components/weight-form/weight-form';
import { PATHOLOGIES } from '@app/shared/data/pathologies';
import { StandaloneCompany, StandaloneData, StandaloneDataKey, StandalonePet } from './standalone-data';

@Injectable({
  providedIn: 'root',
})
export class StandaloneService {
  /**
   * Set SRS data on application startup
   * We save pet data & company data separately as the will
   * be used in different places
   */
  set data(rawData: string) {
    try {
      const data: StandaloneData = JSON.parse(rawData);
      data.company && localStorage.setItem(StandaloneDataKey.COMPANY, JSON.stringify(data.company));
      data.pet && localStorage.setItem(StandaloneDataKey.PET, JSON.stringify(data.pet));
    } catch (err) {
      console.error('SRS initial values could not be parsed');
    }
  }
  /**
   * Get Srs Company data
   * Used in effects
   */
  get company(): StandaloneCompany {
    const data = localStorage.getItem(StandaloneDataKey.COMPANY);
    return data && JSON.parse(data);
  }
  /**
   * Get Srs Pet data
   * Used to prefill form
   */
  get pet(): StandalonePet {
    const data = localStorage.getItem(StandaloneDataKey.PET);
    return data && JSON.parse(data);
  }
  /**
   * Clear pet data from localstorage so it is not reset on page refresh
   */
  clearPetData(): void {
    localStorage.removeItem(StandaloneDataKey.PET);
  }

  /**
   * Get Srs initial profile
   */
  getStandaloneProfile(petData: StandalonePet): PetProfileFormValues {
    return {
      name: petData.name,
      gender: petData.gender,
      speciesCode: petData.species,
      breed: petData.breed,
      birthdate: petData.birthdate ? new Date(petData.birthdate) : null,
      mixed: petData.mixed,
      neutered: petData.neutered,
      reproductionStatus: petData.reproductionStatus,
      lifestage: null,
      neuteredToday: null,
      sterilizationType: null,
    };
  }
  /**
   * Get Srs initial weight
   */
  getStandaloneWeight(petData: StandalonePet): WeightFormValue {
    return {
      bcs: petData.bcs,
      currentWeight: petData.weight,
      targetWeight: null,
      ibw: null,
      petActivity: petData.activity,
    };
  }
  /**
   * Get Srs initial pathologies
   */
  getStandalonePathologies(petData: StandalonePet): Pathology[] {
    return PATHOLOGIES.filter(
      (p) => (!p.isRiskFactor && petData.pathologies?.includes(p.value)) || (p.isRiskFactor && petData.sensitivities?.includes(p.value))
    );
  }
}
