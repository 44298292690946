import { Pipe, PipeTransform } from '@angular/core';

import { Weight } from '@app/core/models';
import { MeasureHelper } from '@app/shared/utils/static-helpers/measure-helper';
import { SystemPreferenceCode } from '../utils/enums/system-preference-code';

@Pipe({ name: 'weight' })
export class WeightPipe implements PipeTransform {
  transform(weight: Weight, currentSystemPreferenceCode: SystemPreferenceCode, precision?: number): string {
    return MeasureHelper.weightToLocalizedString(weight, currentSystemPreferenceCode, precision);
  }
}
