import { selectConsultationLastConsultation } from '@app/store/consultation/consultation.selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { allowanceFeatureKey } from '../allowance-feature-key';
import { AllowanceState } from '../allowance.state';
import { selectSelectedProducts } from '@app/store/products/products.selectors';

export const selectAllowance = createFeatureSelector<AllowanceState>(allowanceFeatureKey);

/**
 * Select previous visit full recommendation
 */
export const selectAllowancePreviousRecommendation = createSelector(
  selectConsultationLastConsultation,
  (previousConsultation) => previousConsultation?.visit?.recommendation
);
/**
 * Select current visit nutrition recommendation
 */
export const selectAllowanceCurrentRecommendation = createSelector(selectAllowance, (state) => state.nutritionRecommendation);
/**
 * Select default visit nutrition recommendation (calculated when arriving on the DAC page when nothing is set manually)
 */
export const selectAllowanceDefaultRecommendation = createSelector(selectAllowance, (state) => state.defaultNutritionRecommendation);
/**
 * Select previous visit recommendation nutrition data if the products are the same than the current one
 */
export const selectAllowanceLastNutritionData = createSelector(
  selectAllowancePreviousRecommendation,
  selectSelectedProducts,
  (previousRecommendation, currentProducts) => {
    const previousNutritionProductIds = previousRecommendation?.nutritionData?.map((n) => n.product.productDetail.id);
    const currentNutritionProductIds = currentProducts?.map((product) => product.id);
    return previousNutritionProductIds && JSON.stringify(previousNutritionProductIds) === JSON.stringify(currentNutritionProductIds)
      ? previousRecommendation.nutritionData
      : null;
  }
);

/**
 * Select current recommendation kcal value
 */
export const selectAllowanceRecommendationKcalValue = createSelector(
  selectAllowanceCurrentRecommendation,
  (recommendation) => recommendation?.diagnostic.kCalAllocation
);
/**
 * Select recommendation kcal value for default recommendation (without manual ration or allocation)
 */
export const selectAllowanceDefaultRecommendationKcalValue = createSelector(
  selectAllowanceDefaultRecommendation,
  (recommendation) => recommendation?.diagnostic.kCalAllocation
);
/**
 * Select last recommendation kcal value
 */
export const selectAllowanceLastRecommendationKcalValue = createSelector(
  selectAllowancePreviousRecommendation,
  (recommendation) => recommendation?.diagnostic.kCalAllocation
);
/**
 * Select loading recommendation;
 * To know if we're waiting for a new recommendation or an update
 */
export const selectAllowanceLoadingRecommendation = createSelector(selectAllowance, (state) => state.loadingRecommendation);

export const selectBlockCurrentlyEditing = createSelector(selectAllowance, (state) => state.blockCurrentlyEditing);

export const selectAllowanceStep = createSelector(selectAllowance, (state) => state?.step);

export const selectAllowanceWetQuantityPerDay = createSelector(selectAllowance, (state) => state?.allWetQuantityPerDay);
