/**
 *
 * @license Royal Canin
 * @contributors :
 *  - Amar YASSA
 */

import { ShareRecommendationData } from '@app/core/models/share-recommendation-data';

export interface ShareRecommendationState {
  data: ShareRecommendationData | null;
  randomRecommendationIds: { id: string; flowId: string; randomId: string }[];
}

export const initialShareRecommendationState: ShareRecommendationState = {
  data: null,
  randomRecommendationIds: [],
};
