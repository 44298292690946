import { Injectable } from '@angular/core';
import { Clinic, Invitation, Vet, VetUser } from '@app/core/models';
import { CountryCode } from '@app/shared/utils/enums/country-code.enum';
import {
  selectCanShareRecoByEmail,
  selectClinic,
  selectClinics,
  selectCurrentClinicCountry,
  selectCurrentClinicCurrency,
  selectCurrentClinicId,
  selectIsClinicAddressComplete,
  selectConversionConfiguration,
  selectIsRetailPriceActivated,
  selectIsSolProductsInVetEnable,
  selectIsSptRetailEnable,
  selectIsVetValid,
  selectIsWholesalePriceActivated,
  selectLanguageListener,
  selectMarket,
  selectMarketQuicklinks,
  selectMeasureUnitListener,
  selectOktaUser,
  selectPricesVat,
  selectVet,
  selectWholesalePrices,
  selectIsInvitationOnboardingEnable,
  selectMarketMailLanguage,
  selectCanShareRecoByQrcode,
  selectCanShareRecoByPrint,
  selectIsKpiFileEnable,
  selectHideDietBlockInPoLetter,
  selectCompanyAddress,
  selectIsIndividualisEnabled,
  selectIsGrowthChartEnabled,
  selectIsVetSourceEnabled,
  selectIsPostSterilizationEnabled,
  selectIsSrCriteriaGenericEnabled,
  selectIsClinicalAllianceEnabled,
  selectIsTreatsEnabled,
  selectPreferredMaxPetWeight,
  selectCurrentBigMeasurementUnit,
  selectCurrentSmallMeasurementUnit,
  selectVetId,
  selectCurrentSystemPreferenceCode,
} from '@app/store/vet/vet.selectors';
import { select, Store } from '@ngrx/store';
import { switchMap, tap } from 'rxjs/operators';
import { AppState } from '..';
import { SetCurrentClinicId, SetVet, UpdateVet, UpdateVetUser } from './legacy-vet.actions';
import {
  getMarket,
  getVet,
  setCurrentBigMeasurementUnit,
  setCurrentSmallMeasurementUnit,
  setCurrentSystemPreferenceCode,
  setInvitation,
  setPreferredMaxPetWeight,
  setVetValidity,
  updateClinic,
} from './vet.actions';
import { MeasurementCodeType } from '@app/shared/utils';
import { SystemPreferenceCode } from '@app/shared/utils/enums/system-preference-code';

@Injectable()
export class VetFacade {
  vet: Vet;
  clinicId: string;
  vet$ = this.store.pipe(select(selectVet)).pipe(
    tap((vet: Vet) => {
      if (vet) {
        this.vet = vet;
      }
    })
  );
  oktaUser$ = this.store.pipe(select(selectOktaUser));
  isVetValid$ = this.store.pipe(select(selectIsVetValid));
  clinic$ = this.store.pipe(select(selectClinic)).pipe(tap((clinic: Clinic) => (this.clinicId = clinic?.id)));
  clinics$ = this.store.pipe(select(selectClinics));
  companyAddress$ = this.store.pipe(select(selectCompanyAddress));
  currentClinicId$ = this.store.pipe(select(selectCurrentClinicId));
  currentClinicCountry$ = this.store.pipe(select(selectCurrentClinicCountry));
  currentClinicCurrency$ = this.store.pipe(select(selectCurrentClinicCurrency));
  market$ = this.store.pipe(select(selectMarket));
  isIndividualisEnabled$ = this.store.pipe(select(selectIsIndividualisEnabled));
  isGrowthChartEnabled$ = this.store.pipe(select(selectIsGrowthChartEnabled));
  isPostSterilizationEnabled$ = this.store.pipe(select(selectIsPostSterilizationEnabled));
  isSrCriteriaGenericEnabled$ = this.store.pipe(select(selectIsSrCriteriaGenericEnabled));
  marketQuicklinks$ = this.store.pipe(select(selectMarketQuicklinks));
  canShareRecoByEmail$ = this.store.pipe(select(selectCanShareRecoByEmail));
  canShareRecoByQrcode$ = this.store.pipe(select(selectCanShareRecoByQrcode));
  canShareRecoByPrint$ = this.store.pipe(select(selectCanShareRecoByPrint));
  language$ = this.store.pipe(select(selectLanguageListener));
  preferenceSystem$ = this.store.pipe(select(selectMeasureUnitListener));
  isClinicAddressComplete$ = this.store.pipe(select(selectIsClinicAddressComplete));
  isRetailPriceActivated$ = this.store.pipe(select(selectIsRetailPriceActivated));
  isWholesalePriceActivated$ = this.store.pipe(select(selectIsWholesalePriceActivated));
  wholesalePrices$ = this.store.pipe(select(selectWholesalePrices));
  pricesVat$ = this.store.pipe(select(selectPricesVat));
  enableSptRetail$ = this.store.pipe(select(selectIsSptRetailEnable));
  enableSolProductsInVet$ = this.store.pipe(select(selectIsSolProductsInVetEnable));
  conversionConfiguration$ = this.store.pipe(select(selectConversionConfiguration));
  enableInvitationOnboarding$ = this.store.pipe(select(selectIsInvitationOnboardingEnable));
  marketMailLanguage$ = this.store.pipe(select(selectMarketMailLanguage));
  enableKpiFile$ = this.store.pipe(select(selectIsKpiFileEnable));
  hideDietBlockInPoLetter$ = this.store.pipe(select(selectHideDietBlockInPoLetter));
  isVetSourceEnabled$ = this.clinic$.pipe(switchMap(({ id }) => this.store.select(selectIsVetSourceEnabled(id))));
  isClinicalAllianceEnabled$ = this.store.select(selectIsClinicalAllianceEnabled);
  isTreatsEnabled$ = this.store.select(selectIsTreatsEnabled);
  preferredMaxPetWeight$ = this.store.select(selectPreferredMaxPetWeight);
  currentBigMeasurementUnit$ = this.store.select(selectCurrentBigMeasurementUnit);
  currentSmallMeasurementUnit$ = this.store.select(selectCurrentSmallMeasurementUnit);
  vetId$ = this.store.select(selectVetId);
  currentSystemPreferenceCode$ = this.store.select(selectCurrentSystemPreferenceCode);

  constructor(private store: Store<AppState>) {}

  loadVet() {
    this.store.dispatch(getVet());
  }

  updateVet(vet: Vet, reload?: boolean): void {
    this.store.dispatch(new UpdateVet(vet, reload));
  }
  updateVetUser(oktaId: string, vetUser: VetUser) {
    this.store.dispatch(new UpdateVetUser(oktaId, vetUser));
  }
  updateVetObject(vet: Vet) {
    this.store.dispatch(new SetVet(vet));
  }
  updateVetClinic(clinicId: string, clinic: Partial<Clinic>, redirectTo?: string[]) {
    this.store.dispatch(updateClinic({ clinicId, clinic, redirectTo }));
  }
  /**
   * Set vet validity
   * See vet.effects for validity criteria
   */
  setVetValidity(value: boolean) {
    this.store.dispatch(setVetValidity({ value }));
  }
  /**
   * Set current clinic id
   */
  setCurrentClinicId(clinicId: string) {
    this.store.dispatch(new SetCurrentClinicId(clinicId));
  }
  /**
   * Set invitation
   */
  setInvitation(invitation: Invitation) {
    this.store.dispatch(setInvitation({ invitation }));
  }
  /**
   * Load market
   * Used during onboarding when user can change country
   */
  loadMarket(countryCode: CountryCode): void {
    this.store.dispatch(getMarket({ countryCode }));
  }

  setPreferredMaxPetWeight(weight: number): void {
    this.store.dispatch(setPreferredMaxPetWeight({ weight }));
  }

  setCurrentBigMeasurementUnit(unit: MeasurementCodeType): void {
    this.store.dispatch(setCurrentBigMeasurementUnit({ unit }));
  }

  setCurrentSmallMeasurementUnit(unit: MeasurementCodeType): void {
    this.store.dispatch(setCurrentSmallMeasurementUnit({ unit }));
  }
  setCurrentSystemPreferenceCode(systemPreferenceCode: SystemPreferenceCode): void {
    this.store.dispatch(setCurrentSystemPreferenceCode({ systemPreferenceCode }));
  }
}
