import { Helper, ProductPillar, ProductType, RangeType, RetailChannel } from '@app/shared/utils';
import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import { ProductsState } from '@app/store/products/products.state';
import { ProductCategoriesFilters } from '@app/core/models/territories-filters';
import { TerritoryType } from '@app/shared/utils/enums/territoryType';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { territories } from '@app/shared/data/territories-filters-content';
import { rangeCategory } from '@app/shared/data/range-filters-content';

const productsState = (state: AppState) => state.products;

export const selectPriceListToUpdate = createSelector(productsState, (state) => state.priceListToUpdate);

export const selectPriceListUpdateLoading = createSelector(productsState, (state) => state.priceListUpdateLoading);

export const selectShowPriceListActions = createSelector(productsState, (state) => state.showPriceListActions);

export const selectAllProductsSuccess = createSelector(productsState, (state) => state.allProductList);

export const selectHasWetInAllProducts = createSelector(
  productsState,
  (state) => !!state.allProductList.filter((products) => products.productType === ProductType.Wet).length
);
export const selectHasDryInAllProducts = createSelector(
  productsState,
  (state) => !!state.allProductList.filter((products) => products.productType === ProductType.Dry).length
);
export const selectFilteredProducts = createSelector(productsState, (state) => state.filteredProductList);

export const selectFilteredProductsFlatten = createSelector(productsState, (state) =>
  Object.values(state.filteredProductList).reduce((a, b) => [...a, ...b], [])
);

export const selectFilteredCAProductsFlatten = createSelector(selectFilteredProductsFlatten, (products) => {
  return products.filter((product) => product.clinicalAlliance);
});

export const selectSpecificSpecieCode = createSelector(productsState, (state) => state.specificSpecieCode);

export const selectSpecificLifestageType = createSelector(productsState, (state) => state.specificLifestageType);

export const selectProductsLoading = createSelector(productsState, (state) => state.productsLoading);

export const selectFilteredProductsLength = createSelector(productsState, (state) => state.filteredProductLength);

export const selectFiltersValues = createSelector(productsState, (state) => state.filtersValues);

export const selectSelectedCategories = createSelector(productsState, (state) => state.selectedCategories);

export const selectCurrentViewType = createSelector(productsState, (state) => state.currentViewType);

export const selectCurrentPillar = createSelector(productsState, (state) => state.filtersValues?.pillar);

export const selectGetAllProductsFail = createSelector(productsState, (state) => state.getAllProductsFail);

export const selectMufProductsBySpecie = createSelector(selectAllProductsSuccess, selectFiltersValues, (allProducts, filtersValues) =>
  allProducts.filter((product) => product.retailChannel === RetailChannel.MULTIFUNCTION && product.speciesCode === filtersValues.specie)
);

export const selectSelectedDryProduct = createSelector(productsState, (state: ProductsState) => {
  return state?.selectedProducts?.find((product) => product?.product?.productType === ProductType.Dry)?.product;
});
export const selectSelectedWetProduct = createSelector(
  productsState,
  (state: ProductsState) => state?.selectedProducts?.find((product) => product?.product?.productType === ProductType.Wet)?.product
);

export const selectSelectedProducts = createSelector(productsState, (state: ProductsState) =>
  state?.selectedProducts?.map((selectedProduct) => selectedProduct?.product)
);
export const selectedSelectedProductsAndPacks = createSelector(productsState, (state: ProductsState) => state?.selectedProducts);

export const selectSelectedPackIds = createSelector(productsState, (state: ProductsState) =>
  state?.selectedProducts?.map((item) => item.packId).filter((item) => !!item)
);

export const selectSelectedDryPackId = createSelector(
  productsState,
  (state) => state?.selectedProducts?.find((selectedProduct) => selectedProduct?.product?.productType === ProductType.Dry)?.packId
);

export const selectSelectedWetPackId = createSelector(
  productsState,
  (state) => state?.selectedProducts?.find((selectedProduct) => selectedProduct?.product?.productType === ProductType.Wet)?.packId
);

export const selectSelectedDryPack = createSelector(selectSelectedDryProduct, selectSelectedDryPackId, (product, packId) =>
  product?.packages?.find((item) => item.sCode === packId)
);

export const selectSelectedWetPack = createSelector(selectSelectedWetProduct, selectSelectedWetPackId, (product, packId) =>
  product?.packages?.find((item) => item.sCode === packId)
);

export const selectProductBookCategories = (isSolProductsInVetEnable: boolean) =>
  createSelector(selectFilteredProducts, selectCurrentPillar, (products, pillar) => {
    const unsortedCategories =
      pillar === ProductPillar.VET
        ? getSortedCategories(territories, [TerritoryType.BirthAndGrowth])
        : getSortedCategories(rangeCategory, [TerritoryType.BirthAndGrowth]);
    const filteredUnsortedCategories = unsortedCategories
      .filter((cat) => products && Object.keys(products).includes(cat.code) && products[cat.code].length)
      .filter((cat) => (pillar === ProductPillar.VET && !isSolProductsInVetEnable ? cat.code !== TerritoryType.BirthAndGrowth : true));
    if (filteredUnsortedCategories.length) {
      return filteredUnsortedCategories;
    }
    return unsortedCategories.map((category) => ({
      ...category,
      disabled: true,
    }));
  });

function getSortedCategories(
  categories: ProductCategoriesFilters[],
  firstDisplayedCategories?: (TerritoryType | RangeType)[]
): ProductCategoriesFilters[] {
  const firstCategories = categories
    .filter((item) => firstDisplayedCategories?.includes(item.code))
    .sort((a, b) => Helper.alphabeticalOrder(translateKey(a.translationCode), translateKey(b.translationCode)));
  const otherCategories = categories
    .filter((item) => !firstDisplayedCategories?.includes(item.code))
    .sort((a, b) => Helper.alphabeticalOrder(translateKey(a.translationCode), translateKey(b.translationCode)));

  return [...firstCategories, ...otherCategories];
}
