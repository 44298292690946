import { HttpContextToken, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { VetFacade } from '@app/store/vet';
import { Observable, combineLatest } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { AuthService } from '@app/core/services';
import { CoreFacade } from '@app/store/core/core.facade';
import { ProductsFacade } from '@app/store/products/products.facade';

export const USER_SESSION_ANALYTICS_TOKEN = new HttpContextToken(() => false);

export function UserSessionAnalyticsInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  if (req.context.has(USER_SESSION_ANALYTICS_TOKEN) && req.context.get(USER_SESSION_ANALYTICS_TOKEN)) {
    const vetFacade = inject(VetFacade);
    const auth = inject(AuthService);
    const coreFacade = inject(CoreFacade);
    const productsFacade = inject(ProductsFacade);

    return combineLatest([
      auth.isAuthenticated(),
      vetFacade.vet$,
      vetFacade.clinic$,
      coreFacade.toolInformation$,
      productsFacade.selectedProducts$,
    ]).pipe(
      take(1),
      mergeMap(([isAuth, vet, clinic, { tool, tool_flow_id }, selectedProducts]) => {
        const productsId = selectedProducts?.map(({ id }) => id).filter((id) => !!id) || [];

        const eventData = {
          ...(vet?.id && { vetId: vet?.id }),
          ...(clinic?.id && { clinicId: clinic?.id }),
          ...(clinic?.pimsId && { pimsId: clinic?.pimsId }),
          ...(tool && { recoType: tool, userSessionId: tool_flow_id }),
          ...(productsId.length && { productId: productsId }),
        };

        const oldBody = req.body as Record<string, unknown>;
        const newBody = { ...oldBody, eventData };
        const newReq = req.clone({
          body: newBody,
        });
        return isAuth ? next(newReq) : next(req);
      })
    );
  }
  return next(req);
}
