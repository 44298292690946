/* 🤖 this file was generated by svg-to-ts*/

export type IconName =
  | 'actions'
  | 'add_address'
  | 'address_book'
  | 'alert'
  | 'arrow_panel'
  | 'arrow_go'
  | 'blood_creatinine'
  | 'blood_urea'
  | 'both'
  | 'calculator'
  | 'calendar_small'
  | 'calendar'
  | 'cat_bcs_1_large'
  | 'cat_bcs_1_medium'
  | 'cat_bcs_2_large'
  | 'cat_bcs_2_medium'
  | 'cat_bcs_3_large'
  | 'cat_bcs_3_medium'
  | 'cat_bcs_4_large'
  | 'cat_bcs_4_medium'
  | 'cat_bcs_5_large'
  | 'cat_bcs_5_medium'
  | 'cat_bcs_6_large'
  | 'cat_bcs_6_medium'
  | 'cat_bcs_7_large'
  | 'cat_bcs_7_medium'
  | 'cat_bcs_8_large'
  | 'cat_bcs_8_medium'
  | 'cat_bcs_9_large'
  | 'cat_bcs_9_medium'
  | 'cat'
  | 'centils'
  | 'chart_placeholder'
  | 'chart'
  | 'check_validated'
  | 'chevron_down'
  | 'close'
  | 'delete'
  | 'disorder_category_adverse'
  | 'disorder_category_body_weight'
  | 'disorder_category_cardiovascular_diseases'
  | 'disorder_category_endocrine'
  | 'disorder_category_gastrointestinal'
  | 'disorder_category_liver'
  | 'disorder_category_oral_dental'
  | 'disorder_category_osteo'
  | 'disorder_category_other_conditions'
  | 'disorder_category_renal'
  | 'disorder_category_skin_coat'
  | 'disorder_category_stress_anxiety'
  | 'disorder_category_urinary'
  | 'documents'
  | 'dog_bcs_1_large'
  | 'dog_bcs_1_medium'
  | 'dog_bcs_2_large'
  | 'dog_bcs_2_medium'
  | 'dog_bcs_3_large'
  | 'dog_bcs_3_medium'
  | 'dog_bcs_4_large'
  | 'dog_bcs_4_medium'
  | 'dog_bcs_5_large'
  | 'dog_bcs_5_medium'
  | 'dog_bcs_6_large'
  | 'dog_bcs_6_medium'
  | 'dog_bcs_7_large'
  | 'dog_bcs_7_medium'
  | 'dog_bcs_8_large'
  | 'dog_bcs_8_medium'
  | 'dog_bcs_9_large'
  | 'dog_bcs_9_medium'
  | 'dog'
  | 'edit'
  | 'empty_state_desktop'
  | 'empty_state_mobile'
  | 'exclamation'
  | 'external_link'
  | 'fail'
  | 'female'
  | 'filter'
  | 'food_2'
  | 'food_3'
  | 'green_pencil'
  | 'grid'
  | 'hide'
  | 'indoor'
  | 'info'
  | 'info_2'
  | 'information'
  | 'light_bulb'
  | 'link'
  | 'list'
  | 'loading'
  | 'logo_with_text'
  | 'long_arrow_up'
  | 'male'
  | 'minus'
  | 'new_window_16'
  | 'no_products'
  | 'outdoor'
  | 'pet_chart'
  | 'plus'
  | 'price_16'
  | 'price_24'
  | 'price_32'
  | 'product_search'
  | 'range_bhn'
  | 'range_ccn'
  | 'range_fbn'
  | 'range_shn'
  | 'refresh'
  | 'search'
  | 'shop'
  | 'shop_16'
  | 'specie_cat'
  | 'specie_dog'
  | 'specific_gravity'
  | 'spinner_circle'
  | 'success'
  | 'success_big'
  | 'territory_weight_management'
  | 'territory_birth_and_growth'
  | 'territory_dermatology'
  | 'territory_gastrointestinal_tract'
  | 'territory_health_management'
  | 'territory_sporting_life'
  | 'territory_urinary'
  | 'territory_vital_support'
  | 'tick'
  | 'tool_da_active'
  | 'tool_da_inactive'
  | 'tool_fr_active'
  | 'tool_fr_inactive'
  | 'tool_muf_active'
  | 'tool_rd_active'
  | 'tool_sr_active'
  | 'tool_sr_inactive'
  | 'tool_wm_active'
  | 'trash'
  | 'unlink'
  | 'view'
  | 'warning_cross'
  | 'warning'
  | 'weight'
  | 'weight_loss_program'
  | 'wet_products';
export const IconName = {
  actions: 'actions' as IconName,
  add_address: 'add_address' as IconName,
  address_book: 'address_book' as IconName,
  alert: 'alert' as IconName,
  arrow_panel: 'arrow_panel' as IconName,
  arrow_go: 'arrow_go' as IconName,
  blood_creatinine: 'blood_creatinine' as IconName,
  blood_urea: 'blood_urea' as IconName,
  both: 'both' as IconName,
  calculator: 'calculator' as IconName,
  calendar_small: 'calendar_small' as IconName,
  calendar: 'calendar' as IconName,
  cat_bcs_1_large: 'cat_bcs_1_large' as IconName,
  cat_bcs_1_medium: 'cat_bcs_1_medium' as IconName,
  cat_bcs_2_large: 'cat_bcs_2_large' as IconName,
  cat_bcs_2_medium: 'cat_bcs_2_medium' as IconName,
  cat_bcs_3_large: 'cat_bcs_3_large' as IconName,
  cat_bcs_3_medium: 'cat_bcs_3_medium' as IconName,
  cat_bcs_4_large: 'cat_bcs_4_large' as IconName,
  cat_bcs_4_medium: 'cat_bcs_4_medium' as IconName,
  cat_bcs_5_large: 'cat_bcs_5_large' as IconName,
  cat_bcs_5_medium: 'cat_bcs_5_medium' as IconName,
  cat_bcs_6_large: 'cat_bcs_6_large' as IconName,
  cat_bcs_6_medium: 'cat_bcs_6_medium' as IconName,
  cat_bcs_7_large: 'cat_bcs_7_large' as IconName,
  cat_bcs_7_medium: 'cat_bcs_7_medium' as IconName,
  cat_bcs_8_large: 'cat_bcs_8_large' as IconName,
  cat_bcs_8_medium: 'cat_bcs_8_medium' as IconName,
  cat_bcs_9_large: 'cat_bcs_9_large' as IconName,
  cat_bcs_9_medium: 'cat_bcs_9_medium' as IconName,
  cat: 'cat' as IconName,
  centils: 'centils' as IconName,
  chart_placeholder: 'chart_placeholder' as IconName,
  chart: 'chart' as IconName,
  check_validated: 'check_validated' as IconName,
  chevron_down: 'chevron_down' as IconName,
  close: 'close' as IconName,
  delete: 'delete' as IconName,
  disorder_category_adverse: 'disorder_category_adverse' as IconName,
  disorder_category_body_weight: 'disorder_category_body_weight' as IconName,
  disorder_category_cardiovascular_diseases: 'disorder_category_cardiovascular_diseases' as IconName,
  disorder_category_endocrine: 'disorder_category_endocrine' as IconName,
  disorder_category_gastrointestinal: 'disorder_category_gastrointestinal' as IconName,
  disorder_category_liver: 'disorder_category_liver' as IconName,
  disorder_category_oral_dental: 'disorder_category_oral_dental' as IconName,
  disorder_category_osteo: 'disorder_category_osteo' as IconName,
  disorder_category_other_conditions: 'disorder_category_other_conditions' as IconName,
  disorder_category_renal: 'disorder_category_renal' as IconName,
  disorder_category_skin_coat: 'disorder_category_skin_coat' as IconName,
  disorder_category_stress_anxiety: 'disorder_category_stress_anxiety' as IconName,
  disorder_category_urinary: 'disorder_category_urinary' as IconName,
  documents: 'documents' as IconName,
  dog_bcs_1_large: 'dog_bcs_1_large' as IconName,
  dog_bcs_1_medium: 'dog_bcs_1_medium' as IconName,
  dog_bcs_2_large: 'dog_bcs_2_large' as IconName,
  dog_bcs_2_medium: 'dog_bcs_2_medium' as IconName,
  dog_bcs_3_large: 'dog_bcs_3_large' as IconName,
  dog_bcs_3_medium: 'dog_bcs_3_medium' as IconName,
  dog_bcs_4_large: 'dog_bcs_4_large' as IconName,
  dog_bcs_4_medium: 'dog_bcs_4_medium' as IconName,
  dog_bcs_5_large: 'dog_bcs_5_large' as IconName,
  dog_bcs_5_medium: 'dog_bcs_5_medium' as IconName,
  dog_bcs_6_large: 'dog_bcs_6_large' as IconName,
  dog_bcs_6_medium: 'dog_bcs_6_medium' as IconName,
  dog_bcs_7_large: 'dog_bcs_7_large' as IconName,
  dog_bcs_7_medium: 'dog_bcs_7_medium' as IconName,
  dog_bcs_8_large: 'dog_bcs_8_large' as IconName,
  dog_bcs_8_medium: 'dog_bcs_8_medium' as IconName,
  dog_bcs_9_large: 'dog_bcs_9_large' as IconName,
  dog_bcs_9_medium: 'dog_bcs_9_medium' as IconName,
  dog: 'dog' as IconName,
  edit: 'edit' as IconName,
  empty_state_desktop: 'empty_state_desktop' as IconName,
  empty_state_mobile: 'empty_state_mobile' as IconName,
  exclamation: 'exclamation' as IconName,
  external_link: 'external_link' as IconName,
  fail: 'fail' as IconName,
  female: 'female' as IconName,
  filter: 'filter' as IconName,
  food_2: 'food_2' as IconName,
  food_3: 'food_3' as IconName,
  green_pencil: 'green_pencil' as IconName,
  grid: 'grid' as IconName,
  hide: 'hide' as IconName,
  indoor: 'indoor' as IconName,
  info: 'info' as IconName,
  info_2: 'info_2' as IconName,
  information: 'information' as IconName,
  light_bulb: 'light_bulb' as IconName,
  link: 'link' as IconName,
  list: 'list' as IconName,
  loading: 'loading' as IconName,
  logo_with_text: 'logo_with_text' as IconName,
  long_arrow_up: 'long_arrow_up' as IconName,
  male: 'male' as IconName,
  minus: 'minus' as IconName,
  new_window_16: 'new_window_16' as IconName,
  no_products: 'no_products' as IconName,
  outdoor: 'outdoor' as IconName,
  pet_chart: 'pet_chart' as IconName,
  plus: 'plus' as IconName,
  price_16: 'price_16' as IconName,
  price_24: 'price_24' as IconName,
  price_32: 'price_32' as IconName,
  product_search: 'product_search' as IconName,
  range_bhn: 'range_bhn' as IconName,
  range_ccn: 'range_ccn' as IconName,
  range_fbn: 'range_fbn' as IconName,
  range_shn: 'range_shn' as IconName,
  refresh: 'refresh' as IconName,
  search: 'search' as IconName,
  shop: 'shop' as IconName,
  shop_16: 'shop_16' as IconName,
  specie_cat: 'specie_cat' as IconName,
  specie_dog: 'specie_dog' as IconName,
  specific_gravity: 'specific_gravity' as IconName,
  spinner_circle: 'spinner_circle' as IconName,
  success: 'success' as IconName,
  success_big: 'success_big' as IconName,
  territory_weight_management: 'territory_weight_management' as IconName,
  territory_birth_and_growth: 'territory_birth_and_growth' as IconName,
  territory_dermatology: 'territory_dermatology' as IconName,
  territory_gastrointestinal_tract: 'territory_gastrointestinal_tract' as IconName,
  territory_health_management: 'territory_health_management' as IconName,
  territory_sporting_life: 'territory_sporting_life' as IconName,
  territory_urinary: 'territory_urinary' as IconName,
  territory_vital_support: 'territory_vital_support' as IconName,
  tick: 'tick' as IconName,
  tool_da_active: 'tool_da_active' as IconName,
  tool_da_inactive: 'tool_da_inactive' as IconName,
  tool_fr_active: 'tool_fr_active' as IconName,
  tool_fr_inactive: 'tool_fr_inactive' as IconName,
  tool_muf_active: 'tool_muf_active' as IconName,
  tool_rd_active: 'tool_rd_active' as IconName,
  tool_sr_active: 'tool_sr_active' as IconName,
  tool_sr_inactive: 'tool_sr_inactive' as IconName,
  tool_wm_active: 'tool_wm_active' as IconName,
  trash: 'trash' as IconName,
  unlink: 'unlink' as IconName,
  view: 'view' as IconName,
  warning_cross: 'warning_cross' as IconName,
  warning: 'warning' as IconName,
  weight: 'weight' as IconName,
  weight_loss_program: 'weight_loss_program' as IconName,
  wet_products: 'wet_products' as IconName,
};
export interface Icon {
  name: IconName;
  data: string;
}
