/**
 * Enum representing features available for the clinic (RD/DA/SR/FR/WM/MUFBG/MUFSK)
 * those features are retrieved from the relations inside the okta JWT access token
 * and then merged in each clinic in the NGRX store
 */
export enum NewClinicFeature {
  DA = 'VS_DA',
  RD = 'VS_RD',
  WM = 'VS_WM',
  SR = 'VS_SR',
  FR = 'VS_FR',
  MF_PB = 'VS_MF_PB',
  MF_SK = 'VS_MF_SK',
}

export enum ClinicUtilities {
  growthChart = 'VS_GC',
  individualis = 'VS_RCI',
  vetSources = 'VS_VSRC',
  clinicalAlliance = 'VS_CA',
  srCriteriaGeneric = 'VS_CRT',
  postSterilizationLetter = 'VS_PSL',
  treats = 'VS_TRT',
}
// TODO #339837 remove ClinicFeature and AppFeature and rename NewClinicFeature to ClinicFeature
/**
 * Enum representing features present in clinic.features
 * mostly used for market feature toggle
 * values are matching backend values
 * they should not be changed
 */
export enum ClinicFeature {
  DA = 'daily_allowance',
  RD = 'renal_detect',
  WM = 'weight_management',
  SR = 'smart_reco',
  FR = 'fast_reco',
  MUF = 'muf',
}
/**
 * Enum representing features not present in clinic.features
 * mostly used for market feature toggle
 * Values are app scoped
 * they can be changed
 */
export enum AppFeature {
  MUF_SK = 'muf_starter_kits',
  MUF_PB = 'muf_personalized_bags',
}
