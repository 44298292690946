import { NutritionDataProduct, Package, Product, ProductPriceEdit, ProductPriceEditApi } from '@app/core/models';
import { MeasureHelper } from '@app/shared/utils';
import * as objectPath from 'object-path';
import { SpeciesCode } from '../enums';
import { ProductEnergyCategory } from '../enums/product-energy-category.enum';
import { SystemPreferenceCode } from '../enums/system-preference-code';

export default class ProductHelper {
  static getPriceFromSelectedProductPack(product: Product, packId: string, units = 1): number | null {
    if (!product) console.error('No product was provided');
    const clinicPrice = product?.packages?.find((_pack) => _pack.sCode === packId)?.productPrice?.clinicPrice * units;
    return clinicPrice && clinicPrice > 0 ? clinicPrice : null;
  }

  static formatNutritionDataProductIntoProduct(
    nutritionDataProduct: NutritionDataProduct,
    currentSystemPreferenceCode: SystemPreferenceCode
  ): Product {
    const currentPackage = nutritionDataProduct.productDetail.package;
    return {
      id: nutritionDataProduct.productDetail.id,
      mainItemId: nutritionDataProduct.mainItemId,
      name: nutritionDataProduct.productDetail.details.name,
      image: nutritionDataProduct.productDetail.details.publicUrl,
      productType: nutritionDataProduct.productType,
      energy: nutritionDataProduct.productDetail.energy,
      packages: [
        {
          ...currentPackage,
          convertedWeight: MeasureHelper.convertWeightWithCustomMapping(
            currentPackage.weight,
            currentPackage.measurementUnitCode,
            null,
            currentSystemPreferenceCode
          ),
        },
      ],
      programs: [],
      specificNeeds: [],
      relatedProductsCode: [],
      rangeCategory: '',
      density: '',
      energyCategory: ProductEnergyCategory.Unknown,
      speciesCode: SpeciesCode.Unknown,
    };
  }

  static formatProductIntoNutritionDataProduct(product: Product, selectedPackIds: string[]): NutritionDataProduct {
    const pack = getPackFromProductAndSelectedPacks(product, selectedPackIds);
    return {
      mainItemId: product.mainItemId,
      productType: product.productType,
      energyCategory: product.energyCategory,
      productDetail: {
        id: product.id,
        energy: product.energy,
        details: {
          name: product.name,
          publicUrl: product.image,
          isClinicalAlliance: product.clinicalAlliance,
          ...(product.benefits && { benefits: product.benefits }),
          speciesCode: product.speciesCode,
        },
        package: pack && {
          sCode: pack.sCode,
          ean: pack.ean,
          type: pack.type,
          text: pack.text,
          weight: pack.weight,
          measurementUnitCode: pack.measurementUnitCode,
          productPrice: pack.productPrice,
        },
      },
    };
  }

  /**
   * Functions to search a text inside all elements of a product
   */
  static containsStr(text: string, search: string): boolean {
    return text.toLowerCase().includes(search);
  }
  static objContains(obj: any, path: string, search: string) {
    const text = objectPath.get(obj, path, '');
    const words = search.split(' ');
    for (const word of words) {
      if (!this.containsStr(text, word)) {
        return false;
      }
    }
    return true;
  }

  static productTitleContainsSearch(product: Product, search: string): boolean {
    return ProductHelper.objContains(product, 'name', search);
  }
}

export function getPriceListMergedWithNewPrices(
  priceList: ProductPriceEdit[] = [],
  newPrices: ProductPriceEdit[] = []
): ProductPriceEdit[] {
  const newPriceListToUpdate = [...priceList];
  newPrices.forEach((newPrice) => {
    const matchingIndex = priceList.findIndex((item) => item.pack.sCode === newPrice.pack.sCode);
    if (matchingIndex !== -1) {
      newPriceListToUpdate[matchingIndex] = newPrice;
    } else {
      newPriceListToUpdate.push(newPrice);
    }
  });
  return newPriceListToUpdate;
}

export function getProductsMergedWithNewPrices(products: Product[] = [], priceList: ProductPriceEdit[] = []): Product[] {
  return products.map((item) => getProductMergedWithNewPrices(item, priceList));
}

export function getProductMergedWithNewPrices(product: Product, priceList: ProductPriceEdit[] = []): Product {
  const newPrices = priceList.filter((editedPrice) => editedPrice.productId === product.id);
  if (newPrices.length) {
    return {
      ...product,
      packages: product.packages.map((pack) => {
        const newPrice = newPrices.find((item) => item.pack.sCode === pack.sCode);
        return newPrice
          ? {
              ...pack,
              productPrice: {
                ...pack.productPrice,
                clinicPrice: newPrice.price ? newPrice.price : -1,
              },
            }
          : pack;
      }),
    };
  }
  return product;
}

export function getPackFromProductAndSelectedPacks(product: Product, selectedPackIds: string[]): Package | undefined {
  return product?.packages.find((packageItem) => !!selectedPackIds.find((packId) => packId === packageItem.sCode));
}

export function getTotalPrice(packages: Package[]): number {
  return packages
    .filter((pack) => !!pack && !!pack?.productPrice?.clinicPrice && pack?.productPrice?.clinicPrice !== -1)
    .reduce((prev, curr) => prev + curr?.productPrice?.clinicPrice, 0);
}

export function buildPricesApiBody(prices: ProductPriceEdit[]): ProductPriceEditApi[] {
  return prices.map((item) => ({
    productId: item.productId,
    sCode: item.pack.sCode,
    price: item.price ? item.price : null,
    id: item.pack.id,
  }));
}
