import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LanguageCode } from '@app/shared/utils/enums/language-code.enum';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LANGUAGES } from '../utils/localization/localization';

@Injectable()
export class PhraseService {
  private httpClient: HttpClient;
  constructor(private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }
  getTranslateFileByLanguage(languageId: LanguageCode): Observable<Record<string, string>> {
    /**
     * Credentials
     */
    const phraseAccessToken = '436b4093e476cc95bcdf0cb09c1b174e6328f6515e6ff7f42dc043ddfd79b8a9';
    const phraseProjectId = '4d8906558ee909282c6da4f59dd78f4e';
    const phraseLocalId = LANGUAGES.find((language) => {
      return language.code === languageId;
    });
    const url = `https://api.phrase.com/v2/projects/${phraseProjectId}/locales/${phraseLocalId.phraseId}/download?file_format=simple_json&include_empty_translations=true&fallback_locale_id=1e152e8a9188aed43d9f1b364a6044d5&access_token=${phraseAccessToken}`;
    return this.httpClient.get<Record<string, string>>(url).pipe(
      catchError(() => {
        return of({});
      })
    );
  }
}
