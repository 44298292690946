import { Pipe, PipeTransform } from '@angular/core';
import { EnumsService } from '@app/core/services/network/enums/enums.service';
import { Observable } from 'rxjs';

@Pipe({
  name: 'breed',
})
export class BreedPipe implements PipeTransform {
  constructor(private enumsService: EnumsService) {}

  transform(breedCode: string): Observable<string> {
    return this.enumsService.fetchBreedName(breedCode);
  }
}
