export interface ExitPopinInfoData {
  title: string;
  subTitle: string;
  buttons: {
    [PopinButtonActionEnum.PRIMARY]?: {
      name: string;
      autoCloseAfterClick: boolean;
      status: PopinButtonStatus;
      style: PopinButtonStyle;
    };
    [PopinButtonActionEnum.SECONDARY]?: {
      name: string;
      autoCloseAfterClick: boolean;
      status: PopinButtonStatus;
      style: PopinButtonStyle;
    };
    [PopinButtonActionEnum.CLEAR]: {
      autoCloseAfterClick: boolean;
    };
    [PopinButtonActionEnum.BACKDROP]: {
      autoCloseAfterClick: boolean;
    };
  };
  inverseButtons?: boolean;
}

export enum PopinButtonActionEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  CLEAR = 'clear',
  BACKDROP = 'backdrop',
}

export enum PopinButtonStatus {
  DEFAULT = 'default',
  DISABLED = 'disabled',
  LOADING = 'loading',
}
export enum PopinButtonStyle {
  PRIMARY = 'primary',
  OUTLINE = 'outline',
  LINK = 'link',
}
