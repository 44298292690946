export function initializeMetaAPI(_unpatchedConsole, internalLogger, _config, metas, _transports) {
  internalLogger.debug('Initializing meta API');
  let metaSession = undefined;
  let metaUser = undefined;
  let metaView = undefined;
  const setUser = user => {
    if (metaUser) {
      metas.remove(metaUser);
    }
    metaUser = {
      user
    };
    metas.add(metaUser);
  };
  const setSession = session => {
    if (metaSession) {
      metas.remove(metaSession);
    }
    metaSession = {
      session
    };
    metas.add(metaSession);
  };
  const getSession = () => metas.value.session;
  const setView = view => {
    var _a;
    if (((_a = metaView === null || metaView === void 0 ? void 0 : metaView.view) === null || _a === void 0 ? void 0 : _a.name) === (view === null || view === void 0 ? void 0 : view.name)) {
      return;
    }
    const previousView = metaView;
    metaView = {
      view
    };
    metas.add(metaView);
    if (previousView) {
      metas.remove(previousView);
    }
  };
  const getView = () => metas.value.view;
  return {
    setUser,
    resetUser: setUser,
    setSession,
    resetSession: setSession,
    getSession,
    setView,
    getView
  };
}
