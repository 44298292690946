import { RenalDetectRecommendation } from '@app/core/models';
import { AllowanceParams } from '@app/pages/allowance/allowance';
import { StateNamesEnum } from '@app/shared/utils/enums/state-names.enum';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConsultationState } from './consultation.state';
import { petHasGrowthChart } from '@app/shared/utils/static-helpers/growth-chart-helper';
import { SystemPreferenceCode } from '@app/shared/utils/enums/system-preference-code';

export const selectConsultation = createFeatureSelector<ConsultationState>(StateNamesEnum.CONSULTATION_STATE_NAME);

export const selectConsultationPatient = createSelector(selectConsultation, (state: ConsultationState) => state.patient);
export const selectConsultationPetOwner = createSelector(selectConsultation, (state: ConsultationState) => state.petOwner);
export const selectConsultationRenalDetectRecommendation = createSelector(
  selectConsultation,
  (state: ConsultationState) => state.renalDetectRecommendation
);
export const selectConsultationRenalDetectNutritionalChoice = createSelector(
  selectConsultation,
  (state: ConsultationState) => state.renalDetectNutritionalChoice
);
export const selectConsultationRenalDetectPrediction = createSelector(
  selectConsultationRenalDetectRecommendation,
  (recommendation: RenalDetectRecommendation) => recommendation?.prediction
);
export const selectConsultationLastConsultation = createSelector(selectConsultation, (state: ConsultationState) => state.lastConsultation);
export const selectConsultationCurrentConsultation = createSelector(
  selectConsultation,
  (state: ConsultationState) => state.currentConsultation
);

export const selectConsultationAllowanceParams = createSelector(
  selectConsultation,
  (state: ConsultationState) => state.allowanceParams || ({} as AllowanceParams)
);
export const selectConsultationAllowanceParamsPetHasGrowthChart = (currentSystemPreferenceCode: SystemPreferenceCode) =>
  createSelector(selectConsultationAllowanceParams, (state: AllowanceParams) => {
    return petHasGrowthChart(
      state.deprecatedPetInfo.lifestage,
      state.deprecatedPetInfo.speciesCode,
      state.deprecatedPetInfo.targetWeight,
      currentSystemPreferenceCode
    );
  });

export const selectConsultationCreatingConsultation = createSelector(
  selectConsultation,
  (state: ConsultationState) => state.creatingCurrentConsultation
);

export const selectExpectedNextVisit = createSelector(selectConsultation, (state: ConsultationState) => state.expectedNextVisit);

export const selectConsultationSkipProductBook = createSelector(selectConsultation, (state: ConsultationState) => state.skipProductBook);
export const selectVetSourceOrderStatus = createSelector(selectConsultation, (state) => state.vetSourceOrderStatus);
export const selectIsNeuteredToday = createSelector(
  selectConsultationAllowanceParams,
  (allowanceParams) => allowanceParams.deprecatedPetInfo?.neuteredToday
);
export const selectSterilizationType = createSelector(
  selectConsultationAllowanceParams,
  (allowanceParams) => allowanceParams.deprecatedPetInfo?.sterilizationType
);

export const selectDeprecatedPetInfo = createSelector(
  selectConsultationAllowanceParams,
  (allowanceParams: AllowanceParams) => allowanceParams.deprecatedPetInfo
);
