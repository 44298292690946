import { isString } from '@grafana/faro-core';
import { unknownString } from './const';
import { getErrorDetails } from './getErrorDetails';
import { getValueAndTypeFromMessage } from './getValueAndTypeFromMessage';
import { buildStackFrame } from './stackFrames';
export function registerOnerror(api) {
  const oldOnerror = window.onerror;
  window.onerror = (...args) => {
    try {
      const [evt, source, lineno, colno, error] = args;
      let value;
      let type;
      let stackFrames = [];
      const eventIsString = isString(evt);
      const initialStackFrame = buildStackFrame(source, unknownString, lineno, colno);
      if (error || !eventIsString) {
        [value, type, stackFrames] = getErrorDetails(error !== null && error !== void 0 ? error : evt);
        if (stackFrames.length === 0) {
          stackFrames = [initialStackFrame];
        }
      } else if (eventIsString) {
        [value, type] = getValueAndTypeFromMessage(evt);
        stackFrames = [initialStackFrame];
      }
      if (value) {
        api.pushError(new Error(value), {
          type,
          stackFrames
        });
      }
    } finally {
      oldOnerror === null || oldOnerror === void 0 ? void 0 : oldOnerror.apply(window, args);
    }
  };
}
