import { Component, Inject } from '@angular/core';
import { IconName } from '@app/shared/utils/icon/icons';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-loggin-information-popin',
  templateUrl: './loggin-information-popin.component.html',
  styleUrls: ['./loggin-information-popin.component.scss'],
})
export class LogginInformationPopinComponent {
  public readonly IconName = IconName;
  constructor(
    // TODO use PopinInformation when migrating to angular material modal
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cookieService: CookieService,
    public router: Router,
    public dialogRef: MatDialogRef<LogginInformationPopinComponent>
  ) {}

  definitelyDisabledMessage(value): void {
    value
      ? this.cookieService.set('popin_info_definitely_disabled', this.data.id)
      : this.cookieService.delete('popin_info_definitely_disabled');
  }

  redirectToSupport(): void {
    this.dialogRef.close();
    this.router.navigateByUrl('/support');
  }

  close(): void {
    this.dialogRef.close();
  }
}
