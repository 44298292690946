import { Injectable } from '@angular/core';
import { ShareRecommendationData } from '@app/core/models/share-recommendation-data';
import { Store } from '@ngrx/store';
import { AppState } from '../index';
import {
  requestGenerateNewRecommendationRandomId,
  resetRecommendationRandomIds,
  resetShareRecommendation,
  setShareRecommendation,
} from './share-recommendation.actions';
import { selectPetHasGrowthChart, selectRecommendationId, selectShareRecommendationData } from './share-recommendation.selector';
import { SystemPreferenceCode } from '@app/shared/utils/enums/system-preference-code';

@Injectable()
export class ShareRecommendationFacade {
  public selectShareRecommendationData$ = this.store.select(selectShareRecommendationData);
  public selectPetHasGrowthChart$ = (currentSystemPreferenceCode: SystemPreferenceCode) =>
    this.store.select(selectPetHasGrowthChart(currentSystemPreferenceCode));

  constructor(private store: Store<AppState>) {}

  public setShareRecommendation(data: ShareRecommendationData): void {
    this.store.dispatch(setShareRecommendation({ data }));
  }

  public resetShareRecommendation(): void {
    this.store.dispatch(resetShareRecommendation());
  }

  public selectRecommendationId$(productIds: string[]) {
    return this.store.select(selectRecommendationId(productIds));
  }

  public requestGenerateRandomRecommendationId(productIds: string[]): void {
    this.store.dispatch(requestGenerateNewRecommendationRandomId({ productIds }));
  }

  public resetRecommendationRandomIds(): void {
    this.store.dispatch(resetRecommendationRandomIds());
  }
}
