import { isFunction } from '../utils';
export function initializeMetas(_unpatchedConsole, internalLogger, _config) {
  let items = [];
  let listeners = [];
  const getValue = () => items.reduce((acc, item) => Object.assign(acc, isFunction(item) ? item() : item), {});
  const notifyListeners = () => {
    if (listeners.length) {
      const value = getValue();
      listeners.forEach(listener => listener(value));
    }
  };
  const add = (...newItems) => {
    internalLogger.debug('Adding metas\n', newItems);
    items.push(...newItems);
    notifyListeners();
  };
  const remove = (...itemsToRemove) => {
    internalLogger.debug('Removing metas\n', itemsToRemove);
    items = items.filter(currentItem => !itemsToRemove.includes(currentItem));
    notifyListeners();
  };
  const addListener = listener => {
    internalLogger.debug('Adding metas listener\n', listener);
    listeners.push(listener);
  };
  const removeListener = listener => {
    internalLogger.debug('Removing metas listener\n', listener);
    listeners = listeners.filter(currentListener => currentListener !== listener);
  };
  return {
    add,
    remove,
    addListener,
    removeListener,
    get value() {
      return getValue();
    }
  };
}
