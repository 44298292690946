import { AllowanceParams } from '@app/pages/allowance/allowance';
import {
  Consultation,
  Diagnostic,
  FollowUpAction,
  NutritionRecommendation,
  NutritionRecommendationRation,
  Product,
  RationingApiBody,
  RationingApiBodyProduct,
  RationingApiBodyVisit,
  Weight,
  PetInfo,
  PetProfileFormValues,
} from '@app/core/models';
import { ActionCode, ActivityCode, Helper, MeasurementCodeType, ProductType, Programs } from '@app/shared/utils';
import { getPackFromProductAndSelectedPacks } from './product-helper';
import { translateKey } from './translate';
import { WeightFormValue } from '@app/shared/components/weight-form/weight-form';

export function buildRationingApiBody({
  currentProgram,
  products,
  selectedPackIds,
  currentRecommendation,
  currentConsultationVisitDate,
  lastConsultation,
  petInfo,
  manualRation,
  allocation,
  followUpAction,
  currentBigMeasurementUnit,
}: {
  currentProgram: Programs;
  products: Product[];
  selectedPackIds: string[];
  currentRecommendation: NutritionRecommendation | null;
  currentConsultationVisitDate: Date | null;
  lastConsultation: Consultation;
  petInfo: PetInfo;
  manualRation?: { value: number; productType: ProductType };
  allocation?: number;
  followUpAction?: FollowUpAction;
  currentBigMeasurementUnit: MeasurementCodeType;
}): RationingApiBody {
  const isAdult = Helper.lifestageOld(petInfo.lifestage);
  return {
    pet: {
      speciesCode: petInfo.speciesCode,
      breedCode: petInfo.breed,
      genderCode: petInfo.gender,
      neutered: petInfo.neutered,
      birthDate: petInfo.birthdate,
      petActivityCode: isAdult ? petInfo?.petActivity : getActivityRationingFromBcs(petInfo?.bcs),
      weight: {
        measure: petInfo.weight,
        measurementUnitCode: currentBigMeasurementUnit,
      },
      idealBodyWeight: petInfo.IBW && {
        measure: petInfo.IBW,
        measurementUnitCode: currentBigMeasurementUnit,
      },
      bcs: petInfo.bcs,
      lifestage: petInfo?.lifestage,
    },
    wetProduct: getRationingApiBodyProduct(products, ProductType.Wet, selectedPackIds),
    dryProduct: getRationingApiBodyProduct(products, ProductType.Dry, selectedPackIds),
    wetQuantityPerDay: manualRation?.productType === ProductType.Wet ? manualRation.value : undefined,
    kCalAllocation: manualRation?.value || allocation ? +allocation || currentRecommendation?.diagnostic?.recommendedAllocation : undefined,
    visit: getRationingApiBodyVisit(lastConsultation, followUpAction, currentProgram, currentConsultationVisitDate),
  };
}

export function getIbwFromBcsAndLifeStage(weightFormValue: WeightFormValue, profileFormValues: PetProfileFormValues): number | null {
  const isAdult = Helper.lifestageOld(profileFormValues.lifestage);
  const bcsGte6 = weightFormValue?.bcs >= 6;

  if (isAdult && bcsGte6 && weightFormValue?.ibw) {
    return weightFormValue.ibw;
  } else if (weightFormValue?.targetWeight) {
    return weightFormValue.targetWeight;
  } else {
    return null;
  }
}

function getActivityRationingFromBcs(bcs: number): ActivityCode {
  if (bcs < 4) {
    return ActivityCode.High;
  } else if (bcs > 5) {
    return ActivityCode.Low;
  }
  return ActivityCode.Moderate;
}

function getRationingApiBodyVisit(
  lastConsultation: Consultation,
  followUpAction: FollowUpAction,
  currentProgram: Programs,
  currentConsultationVisitDate: Date | null
): RationingApiBodyVisit | undefined {
  const withVisit =
    lastConsultation &&
    [Programs.WEIGHT_LOSS, Programs.WEIGHT_STABILISATION_STEP_1, Programs.WEIGHT_STABILISATION_STEP_2].includes(currentProgram) &&
    followUpAction?.actionCode !== ActionCode.restartWL;

  const visit = lastConsultation?.visit;

  return withVisit
    ? {
        lastKcalPerDay: visit?.recommendation?.diagnostic?.kCalAllocation,
        firstVisitDate: visit?.firstVisitDate,
        currentVisitDate: currentConsultationVisitDate || new Date(),
        lastVisitDate: visit?.visitDateTime,
        lastWeight: {
          measure: visit?.weight?.measure,
          measurementUnitCode: visit?.weight?.measureUnit,
        },
        lastProgram: visit?.program,
      }
    : undefined;
}

function getRationingApiBodyProduct(
  products: Product[],
  productType: ProductType,
  selectedPackIds: string[]
): RationingApiBodyProduct | undefined {
  const product = products.find((item) => item.productType === productType);
  const pack = getPackFromProductAndSelectedPacks(product, selectedPackIds);
  const density = product?.density && parseFloat(product.density);
  return (
    product &&
    pack && {
      energyCategory: product.energyCategory,
      referenceEnergyValue: product.energy,
      density: density || undefined,
      pack: {
        weight: pack.weight,
        measurementUnitCode: pack.measurementUnitCode,
        price: pack.productPrice?.clinicPrice === -1 ? undefined : pack.productPrice?.clinicPrice,
      },
    }
  );
}

export function getBasicDryRation(rations: NutritionRecommendationRation[]): NutritionRecommendationRation | null {
  return rations.find((ration) => ![MeasurementCodeType.Cups].includes(ration.unit)) || null;
}

export function getCupDryRation(rations: NutritionRecommendationRation[]): NutritionRecommendationRation | null {
  return rations.find((ration) => ration.unit === MeasurementCodeType.Cups) || null;
}

export function getBasicWetRation(rations: NutritionRecommendationRation[]): NutritionRecommendationRation | null {
  return rations?.length ? rations[0] : null;
}

export function formatNutritionRecommendation({ diagnostic, dryRation, wetRation }: any = {}): NutritionRecommendation {
  const dryRations = formatNutritionRations(dryRation);
  const wetRations = formatNutritionRations(wetRation);

  return {
    diagnostic: {
      recommendedAllocation: diagnostic?.recommendedAllocation ? Math.round(diagnostic?.recommendedAllocation * 100) / 100 : 0,
      unbornedRecommendedAllocation: diagnostic?.unbornedRecommendedAllocation
        ? Math.round(diagnostic?.unbornedRecommendedAllocation * 100) / 100
        : 0,
      kCalAllocation: diagnostic?.kCalAllocation ? Math.round(diagnostic?.kCalAllocation * 100) / 100 : 0,
    },
    dryRations,
    wetRations,
    basicDryRation: getBasicDryRation(dryRations),
    cupDryRation: getCupDryRation(dryRations),
    basicWetRation: getBasicWetRation(wetRations),
  };
}

export function formatNutritionRations(rations: any[] = []): NutritionRecommendationRation[] {
  return rations.map((item) => ({
    kcalPerDay: item.kcalPerDay || 0,
    quantityPerDay: item.quantityPerDay || 0,
    unit: item.unit,
    nbDaysPerPack: item.nbDaysPerPack || 0,
    costPerDay: item.costPerDay || null,
  }));
}

export function getWMWarning(
  allowanceParams: AllowanceParams,
  diagnostic: Diagnostic,
  previousConsultationExpectedWeight: Weight
): string | null {
  const previousConsultationExpectedMinWeight = previousConsultationExpectedWeight?.minWeight;
  const previousConsultationExpectedMaxWeight = previousConsultationExpectedWeight?.maxWeight;
  const currentWeightGrams = allowanceParams?.deprecatedPetInfo?.weight * 1000;
  if (allowanceParams?.followUpAction?.targetProgram !== Programs.WEIGHT_LOSS) return null;

  if (diagnostic.unbornedRecommendedAllocation < diagnostic.recommendedAllocation) {
    return translateKey('messages_calculator_weight-loss-ko');
  }

  if (previousConsultationExpectedMinWeight < currentWeightGrams && currentWeightGrams < previousConsultationExpectedMaxWeight) {
    return translateKey('messages_calculator_goal-reached');
  }
  if (currentWeightGrams > previousConsultationExpectedMaxWeight) {
    return translateKey('messages_calculator_weight-loss-ok');
  }
  if (currentWeightGrams < previousConsultationExpectedMinWeight) {
    return translateKey('messages_calculator_weight-loss-too-quick');
  }
}

export function getTotalPricePerDay(rations: NutritionRecommendationRation[]): number {
  return rations.filter((ration) => !!ration).reduce((prev, curr) => prev + curr?.costPerDay, 0);
}
