import { Address, CollaboratorUser, CollaboratorUserFromApi, Market, PetOwner, Term, Vet, VetUser } from '@app/core/models';
import { OktaUserLoginType } from '@app/core/models/okta-user';
import { environment } from '@env/environment';
import { UserClaims } from '@okta/okta-auth-js';
import { translateKey, translateKeyFromCustomLanguage } from './translate';
import { CompanyAddress } from '@app/core/models/company-address';

// The api will return data inside the terms array only if there are terms to validate
export function areTermsValid(vet: Vet): boolean {
  return !vet?.purposes?.terms || vet?.purposes?.terms.length === 0;
}

// temporary code for retro-compatibility that will be deleted once back and front are in production
export function buildTermsApiBody(terms: Term[]): any {
  return {
    terms: terms.map((term) => ({
      purpose: term.Id,
      name: term.name,
    })),
  };
}

export function isOktaUserMissingInfo(user: UserClaims<{ userinfo: Record<string, string> }>): boolean {
  return !user?.userinfo?.countryCode || !user?.userinfo?.lastName || !user?.userinfo?.firstName;
}

export function formatCollaborator(user: CollaboratorUserFromApi): CollaboratorUser {
  return {
    id: user?.id || '',
    status: user?.status || '',
    email: user?.profile?.loginType === OktaUserLoginType.USERNAME ? user?.profile?.secondEmail || '' : user?.profile?.email || '',
  };
}

export function formatPetOwner(owner: any): PetOwner {
  return {
    id: owner?._id || owner?.id || '',
    organizationId: owner?.organizationId || '',
    givenName: owner?.givenName || '',
    familyName: owner?.familyName || '',
    telephone: owner?.telephone || '',
    email: owner?.email || '',
    addresses: owner?.addresses || [],
    createdAt: owner?.createdAt || null,
    updatedAt: owner?.updatedAt || null,
  };
}

export function unFormatPetOwner(owner: PetOwner, organizationId?: string): any {
  return {
    ...owner,
    organizationId: organizationId || undefined,
    _id: owner?.id,
  };
}

export function buildVetUser(user: UserClaims<{ userinfo: Record<string, string>; groups?: string[] }>): VetUser {
  const oktaUser = user.userinfo;
  return {
    uuid: oktaUser.externalId,
    oktaId: user.sub,
    countryCode: oktaUser.countryCode,
    email: oktaUser.loginType === OktaUserLoginType.USERNAME ? oktaUser.secondEmail : oktaUser.email,
    identifier: oktaUser.loginType === OktaUserLoginType.USERNAME ? oktaUser.login : oktaUser.email,
    firstName: oktaUser.firstName,
    lastName: oktaUser.lastName,
    title: oktaUser.title,
    avatarUrl: oktaUser.avatarUrl,
    state: oktaUser.state,
    primaryPhone: oktaUser.primaryPhone,
    preferredLanguage: oktaUser.preferredLanguage,
    groups: user?.groups || [],
  };
}

export function getMarketQuicklinks(marketQuicklinks: Market['quicklinks'], vetUser: VetUser) {
  const vetUserGroups = vetUser?.groups;

  return marketQuicklinks
    .filter((quickLink) =>
      quickLink.onlyForGroups?.length ? quickLink.onlyForGroups.some((group) => vetUserGroups.includes(group)) : true
    )
    .map((quickLink) => ({
      ...quickLink,
      icon: `${environment.blobUrl}/quicklinks_icons/${quickLink.icon}?${environment.blobQueryParams}`,
      text: translateKey(quickLink.text),
    }));
}

export function getTranslateCivility(civility: string, translations?): string {
  switch (civility?.toLocaleLowerCase()) {
    case 'dr':
      return translateKeyFromCustomLanguage(translations || {}, 'User.doctorTitle');
    case 'mr':
      return translateKeyFromCustomLanguage(translations || {}, 'User.misterTitle');
    case 'mrs':
      return translateKeyFromCustomLanguage(translations || {}, 'User.madameTitle');
    case 'miss':
      return translateKeyFromCustomLanguage(translations || {}, 'User.missTitle');
    case 'ms':
      return translateKeyFromCustomLanguage(translations || {}, 'User.msTitle');
    default:
      return civility;
  }
}

export function mapCompanyAddress(address: CompanyAddress): Address | null {
  if (!address) {
    return null;
  }
  const address1 = !!address.streetNumber && !!address.street1 ? `${address.streetNumber} ${address.street1}` : address.street1;
  return {
    company: address.company,
    address1,
    address2: address.street2,
    zip: address.postalCode,
    city: address.city,
    country: address.country,
    phone: address.phone,
  };
}
