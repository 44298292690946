import { Clinic } from '@app/core/models';
import { ShareRecommendationMethod } from '@app/shared/utils';
import { ClinicUtilities } from '@app/shared/utils/enums/feature.enum';
import { PriceType } from '@app/shared/utils/enums/price-type.enum';
import { createSelector } from '@ngrx/store';
import { VetState } from '.';
import { AppState } from '..';
import { mapCompanyAddress } from '@app/shared/utils/static-helpers/vet-helper';

const vetState = (state: AppState) => state.vet;

export const selectVet = createSelector(vetState, (state) => state.vet);
/**
 * Select okta user
 * Raw data from Okta
 */
export const selectOktaUser = createSelector(vetState, (state) => state.oktaUser);
/**
 * Select vet validity state
 * See vet.effects for criteria
 */
export const selectIsVetValid = createSelector(vetState, (state) => state.isVetValid);

export const selectCurrentClinicId = createSelector(vetState, (state) => state.currentClinicId);

/**
 * Select current clinic
 */
export const selectClinic = createSelector(vetState, selectCurrentClinicId, (state, currentClinicId) =>
  state.vet?.clinics?.find((c) => c.id === currentClinicId)
);

export const selectCurrentClinicCountry = createSelector(selectClinic, (clinic) => clinic?.companyAddress?.country);
/**
 * Select market data
 */
export const selectMarket = createSelector(vetState, selectCurrentClinicCountry, (state, country) => {
  if (!country || state.market?.countryCode === country) {
    return state.market;
  }
});

export const selectIsIndividualisEnabled = createSelector(selectClinic, (clinic) => {
  return clinic?.newFeatures.indexOf(ClinicUtilities.individualis) > -1 ? true : false;
});

export const selectIsGrowthChartEnabled = createSelector(selectClinic, (clinic) => {
  return clinic?.newFeatures.indexOf(ClinicUtilities.growthChart) > -1 ? true : false;
});

export const selectIsPostSterilizationEnabled = createSelector(selectClinic, (clinic) => {
  return clinic?.newFeatures.indexOf(ClinicUtilities.postSterilizationLetter) > -1 ? true : false;
});

export const selectIsSrCriteriaGenericEnabled = createSelector(selectClinic, (clinic) => {
  return clinic?.newFeatures.indexOf(ClinicUtilities.srCriteriaGeneric) > -1 ? true : false;
});

export const selectIsClinicalAllianceEnabled = createSelector(selectClinic, (clinic) => {
  return clinic?.newFeatures.indexOf(ClinicUtilities.clinicalAlliance) > -1 ? true : false;
});

export const selectIsTreatsEnabled = createSelector(vetState, selectClinic, (state, clinic) => {
  return clinic?.newFeatures.indexOf(ClinicUtilities.treats) > -1 ? true : false;
});

export const selectMarketQuicklinks = createSelector(vetState, selectMarket, (state, market) => {
  return market?.quicklinks || [];
});

/**
 * Select share recommendation by email is authorized for this market
 */
export const selectCanShareRecoByEmail = createSelector(vetState, selectMarket, (state, market) => {
  return !market?.shareRecommendationMethods ? true : market.shareRecommendationMethods.includes(ShareRecommendationMethod.EMAIL);
});

/**
 * Select share recommendation by qrcode is authorized for this market
 */
export const selectCanShareRecoByQrcode = createSelector(vetState, selectMarket, (state, market) => {
  return !market?.shareRecommendationMethods ? true : market.shareRecommendationMethods.includes(ShareRecommendationMethod.QRCODE);
});

/**
 * Select share recommendation by print is authorized for this market
 */
export const selectCanShareRecoByPrint = createSelector(vetState, selectMarket, (state, market) => {
  return !market?.shareRecommendationMethods ? true : market.shareRecommendationMethods.includes(ShareRecommendationMethod.PRINT);
});
/**
 * Select current clinic currency code based on country
 */
export const selectCurrentClinicCurrency = createSelector(selectClinic, selectMarket, (clinic, market) => {
  if (market?.countryCode === clinic?.companyAddress?.country) {
    return market?.currency;
  }
});
export const selectClinics = createSelector(vetState, (state) => (state.vet && state.vet.clinics ? state.vet.clinics : []));

export const selectLanguageListener = createSelector(vetState, (state) =>
  state.vet && state.vet.user ? state.vet.user.preferredLanguage : null
);

export const selectMeasureUnitListener = createSelector(vetState, (state: VetState) => (state.vet ? state.vet.systemPreference : null));
/**
 * Select invitation
 */
export const selectInvitation = createSelector(vetState, (state) => state.invitation);

export const selectIsClinicAddressComplete = createSelector(
  selectClinic,
  ({ companyAddress }: Clinic = <Clinic>{}) => !!(companyAddress && companyAddress.address1 && companyAddress.zip && companyAddress.city)
);

export const selectConversionConfiguration = createSelector(
  vetState,
  selectClinic,
  selectMarket,
  // uncomment to test IVO during development mode
  // (state, clinic, market) => market?.conversionConfigurations?.find((config) => config.type === 'VS_CV') || null
  (state, clinic, market) => market?.conversionConfigurations?.find((config) => clinic?.newFeatures?.includes(config.type)) || null
);

/**
 * Check wheter retail prices are activated, by default true if nothing is set
 */
export const selectIsRetailPriceActivated = createSelector(vetState, selectMarket, (state, market) =>
  !market?.activatedPrices ? true : market.activatedPrices.includes(PriceType.RETAIL)
);
/**
 * Check wheter wholesage prices are activated, by default false if nothing is set
 */
export const selectIsWholesalePriceActivated = createSelector(vetState, selectMarket, (state, market) =>
  !market?.activatedPrices ? false : market.activatedPrices.includes(PriceType.WHOLESALE)
);

export const selectWholesalePrices = createSelector(vetState, selectMarket, (state, market) => market?.wholesalePrices);
export const selectPricesVat = createSelector(vetState, selectMarket, (state, market) => market?.pricesVat);
export const selectIsSptRetailEnable = createSelector(vetState, selectMarket, (state, market) => market?.enableSptRetail);
export const selectIsSolProductsInVetEnable = createSelector(vetState, selectMarket, (state, market) => market?.enableSolProductsInVet);
export const selectIsKpiFileEnable = createSelector(vetState, selectMarket, (state, market) => market?.enableKpiFile);

export const selectIsInvitationOnboardingEnable = createSelector(
  vetState,
  selectMarket,
  (_, market) => !(market?.disableOnboardingInvitation ?? false)
);

export const selectMarketMailLanguage = createSelector(selectMarket, (market) => market?.mailLanguages);
export const selectHideDietBlockInPoLetter = createSelector(selectMarket, (market) => market?.hideDietBlockInPoLetter ?? false);

export const selectCompanyAddress = createSelector(vetState, (state) => mapCompanyAddress(state.vet?.company?.addresses[0]));
export const selectIsVetSourceEnabled = (clinicId: string) =>
  createSelector(vetState, (state) =>
    state.vet.clinics.filter(({ id }) => id === clinicId).every(({ newFeatures }) => newFeatures.includes(ClinicUtilities.vetSources))
  );

export const selectPreferences = createSelector(vetState, (state) => state.preferences);
export const selectPreferredMaxPetWeight = createSelector(selectPreferences, (preferences) => preferences?.maxPetWeight);
export const selectCurrentBigMeasurementUnit = createSelector(selectPreferences, (preferences) => preferences?.currentBigMeasurementUnit);
export const selectCurrentSmallMeasurementUnit = createSelector(
  selectPreferences,
  (preferences) => preferences?.currentSmallMeasurementUnit
);
export const selectVetId = createSelector(selectVet, (vet) => vet?.id);
export const selectCurrentSystemPreferenceCode = createSelector(
  selectPreferences,
  (preferences) => preferences?.currentSystemPreferenceCode
);
