import { TransportItemType } from '../../transports';
import { deepEqual, getCurrentTimestamp, isNull } from '../../utils';
export function initializeEventsAPI(_unpatchedConsole, internalLogger, config, metas, transports, tracesApi) {
  let lastPayload = null;
  const pushEvent = (name, attributes, domain, {
    skipDedupe,
    spanContext
  } = {}) => {
    try {
      const item = {
        meta: metas.value,
        payload: {
          name,
          domain: domain !== null && domain !== void 0 ? domain : config.eventDomain,
          attributes,
          timestamp: getCurrentTimestamp(),
          trace: spanContext ? {
            trace_id: spanContext.traceId,
            span_id: spanContext.spanId
          } : tracesApi.getTraceContext()
        },
        type: TransportItemType.EVENT
      };
      const testingPayload = {
        name: item.payload.name,
        attributes: item.payload.attributes,
        domain: item.payload.domain
      };
      if (!skipDedupe && config.dedupe && !isNull(lastPayload) && deepEqual(testingPayload, lastPayload)) {
        internalLogger.debug('Skipping event push because it is the same as the last one\n', item.payload);
        return;
      }
      lastPayload = testingPayload;
      internalLogger.debug('Pushing event\n', item);
      transports.execute(item);
    } catch (err) {
      internalLogger.error('Error pushing event', err);
    }
  };
  return {
    pushEvent
  };
}
