import { isString } from '../utils';
import { BatchExecutor } from './batchExecutor';
import { TransportItemType } from './const';
export function shouldIgnoreEvent(patterns, msg) {
  return patterns.some(pattern => {
    return isString(pattern) ? msg.includes(pattern) : !!msg.match(pattern);
  });
}
export function createBeforeSendHookFromIgnorePatterns(patterns) {
  return item => {
    if (item.type === TransportItemType.EXCEPTION && item.payload) {
      const evt = item.payload;
      const msg = `${evt.type}: ${evt.value}`;
      if (shouldIgnoreEvent(patterns, msg)) {
        return null;
      }
    }
    return item;
  };
}
export function initializeTransports(unpatchedConsole, internalLogger, config, metas) {
  var _a;
  internalLogger.debug('Initializing transports');
  const transports = [];
  let paused = config.paused;
  let beforeSendHooks = [];
  const add = (...newTransports) => {
    internalLogger.debug('Adding transports');
    newTransports.forEach(newTransport => {
      internalLogger.debug(`Adding "${newTransport.name}" transport`);
      const exists = transports.some(existingTransport => existingTransport === newTransport);
      if (exists) {
        internalLogger.warn(`Transport ${newTransport.name} is already added`);
        return;
      }
      newTransport.unpatchedConsole = unpatchedConsole;
      newTransport.internalLogger = internalLogger;
      newTransport.config = config;
      newTransport.metas = metas;
      transports.push(newTransport);
    });
  };
  const addBeforeSendHooks = (...newBeforeSendHooks) => {
    internalLogger.debug('Adding beforeSendHooks\n', beforeSendHooks);
    newBeforeSendHooks.forEach(beforeSendHook => {
      if (beforeSendHook) {
        beforeSendHooks.push(beforeSendHook);
      }
    });
  };
  const addIgnoreErrorsPatterns = (...ignoreErrorsPatterns) => {
    internalLogger.debug('Adding ignoreErrorsPatterns\n', ignoreErrorsPatterns);
    ignoreErrorsPatterns.forEach(ignoreErrorsPattern => {
      if (ignoreErrorsPattern) {
        beforeSendHooks.push(createBeforeSendHookFromIgnorePatterns(ignoreErrorsPattern));
      }
    });
  };
  const applyBeforeSendHooks = items => {
    let filteredItems = items;
    for (const hook of beforeSendHooks) {
      const modified = filteredItems.map(hook).filter(Boolean);
      if (modified.length === 0) {
        return [];
      }
      filteredItems = modified;
    }
    return filteredItems;
  };
  const batchedSend = items => {
    const filteredItems = applyBeforeSendHooks(items);
    if (filteredItems.length === 0) {
      return;
    }
    for (const transport of transports) {
      internalLogger.debug(`Transporting item using ${transport.name}\n`, filteredItems);
      if (transport.isBatched()) {
        transport.send(filteredItems);
      }
    }
  };
  const instantSend = item => {
    var _a, _b;
    // prevent all beforeSend hooks being executed twice if batching is enabled.
    if (((_a = config.batching) === null || _a === void 0 ? void 0 : _a.enabled) && transports.every(transport => transport.isBatched())) {
      return;
    }
    const [filteredItem] = applyBeforeSendHooks([item]);
    if (filteredItem === undefined) {
      return;
    }
    for (const transport of transports) {
      internalLogger.debug(`Transporting item using ${transport.name}\n`, filteredItem);
      if (!transport.isBatched()) {
        transport.send(filteredItem);
      } else if (!((_b = config.batching) === null || _b === void 0 ? void 0 : _b.enabled)) {
        transport.send([filteredItem]);
      }
    }
  };
  let batchExecutor;
  if ((_a = config.batching) === null || _a === void 0 ? void 0 : _a.enabled) {
    batchExecutor = new BatchExecutor(batchedSend, {
      sendTimeout: config.batching.sendTimeout,
      itemLimit: config.batching.itemLimit,
      paused
    });
  }
  // Send a signal to the appropriate transports
  //
  // 1. If SDK is paused, early return
  // 2. If batching is not enabled send the signal to all transports
  //    instantly.
  // 3i. If batching is enabled, enqueue the signal
  // 3ii. Send the signal instantly to all un-batched transports
  const execute = item => {
    var _a;
    if (paused) {
      return;
    }
    if ((_a = config.batching) === null || _a === void 0 ? void 0 : _a.enabled) {
      batchExecutor === null || batchExecutor === void 0 ? void 0 : batchExecutor.addItem(item);
    }
    instantSend(item);
  };
  const getBeforeSendHooks = () => [...beforeSendHooks];
  const isPaused = () => paused;
  const pause = () => {
    internalLogger.debug('Pausing transports');
    batchExecutor === null || batchExecutor === void 0 ? void 0 : batchExecutor.pause();
    paused = true;
  };
  const remove = (...transportsToRemove) => {
    internalLogger.debug('Removing transports');
    transportsToRemove.forEach(transportToRemove => {
      internalLogger.debug(`Removing "${transportToRemove.name}" transport`);
      const existingTransportIndex = transports.indexOf(transportToRemove);
      if (existingTransportIndex === -1) {
        internalLogger.warn(`Transport "${transportToRemove.name}" is not added`);
        return;
      }
      transports.splice(existingTransportIndex, 1);
    });
  };
  const removeBeforeSendHooks = (...beforeSendHooksToRemove) => {
    beforeSendHooks.filter(beforeSendHook => !beforeSendHooksToRemove.includes(beforeSendHook));
  };
  const unpause = () => {
    internalLogger.debug('Unpausing transports');
    batchExecutor === null || batchExecutor === void 0 ? void 0 : batchExecutor.start();
    paused = false;
  };
  return {
    add,
    addBeforeSendHooks,
    addIgnoreErrorsPatterns,
    getBeforeSendHooks,
    execute,
    isPaused,
    pause,
    remove,
    removeBeforeSendHooks,
    get transports() {
      return [...transports];
    },
    unpause
  };
}
