import { initializeEventsAPI } from './events';
import { initializeExceptionsAPI } from './exceptions';
import { initializeLogsAPI } from './logs';
import { initializeMeasurementsAPI } from './measurements';
import { initializeMetaAPI } from './meta';
import { initializeTracesAPI } from './traces';
export function initializeAPI(unpatchedConsole, internalLogger, config, metas, transports) {
  internalLogger.debug('Initializing API');
  const tracesApi = initializeTracesAPI(unpatchedConsole, internalLogger, config, metas, transports);
  return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, tracesApi), initializeExceptionsAPI(unpatchedConsole, internalLogger, config, metas, transports, tracesApi)), initializeMetaAPI(unpatchedConsole, internalLogger, config, metas, transports)), initializeLogsAPI(unpatchedConsole, internalLogger, config, metas, transports, tracesApi)), initializeMeasurementsAPI(unpatchedConsole, internalLogger, config, metas, transports, tracesApi)), initializeEventsAPI(unpatchedConsole, internalLogger, config, metas, transports, tracesApi));
}
