import { map, filter } from 'rxjs/operators';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { clearTool } from './../core.actions';
import { RoutesInFlow } from '@app/shared/utils/enums/routes.enum';

@Injectable()
export class ToolEffects {
  /**
   * Each time we leave a consultation process
   * We need to clear the tool information from the store
   */

  ClearTool$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      filter((action) => RoutesInFlow.every((url) => action.payload.routerState.url.search(url) === -1)),
      map(() => clearTool())
    )
  );

  constructor(private actions$: Actions) {}
}
