export function initializeInstrumentations(unpatchedConsole, internalLogger, config, metas, transports, api) {
  internalLogger.debug('Initializing instrumentations');
  const instrumentations = [];
  const add = (...newInstrumentations) => {
    internalLogger.debug('Adding instrumentations');
    newInstrumentations.forEach(newInstrumentation => {
      internalLogger.debug(`Adding "${newInstrumentation.name}" instrumentation`);
      const exists = instrumentations.some(existingInstrumentation => existingInstrumentation.name === newInstrumentation.name);
      if (exists) {
        internalLogger.warn(`Instrumentation ${newInstrumentation.name} is already added`);
        return;
      }
      newInstrumentation.unpatchedConsole = unpatchedConsole;
      newInstrumentation.internalLogger = internalLogger;
      newInstrumentation.config = config;
      newInstrumentation.metas = metas;
      newInstrumentation.transports = transports;
      newInstrumentation.api = api;
      instrumentations.push(newInstrumentation);
      newInstrumentation.initialize();
    });
  };
  const remove = (...instrumentationsToRemove) => {
    internalLogger.debug('Removing instrumentations');
    instrumentationsToRemove.forEach(instrumentationToRemove => {
      var _a, _b;
      internalLogger.debug(`Removing "${instrumentationToRemove.name}" instrumentation`);
      const existingInstrumentationIndex = instrumentations.reduce((acc, existingInstrumentation, existingTransportIndex) => {
        if (acc === null && existingInstrumentation.name === instrumentationToRemove.name) {
          return existingTransportIndex;
        }
        return null;
      }, null);
      if (!existingInstrumentationIndex) {
        internalLogger.warn(`Instrumentation "${instrumentationToRemove.name}" is not added`);
        return;
      }
      (_b = (_a = instrumentations[existingInstrumentationIndex]).destroy) === null || _b === void 0 ? void 0 : _b.call(_a);
      instrumentations.splice(existingInstrumentationIndex, 1);
    });
  };
  return {
    add,
    get instrumentations() {
      return [...instrumentations];
    },
    remove
  };
}
