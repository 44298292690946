import { Address, DeliveryAddress } from '@app/core/models';
import { AddressFormValues } from '@app/shared/components/address-form-popin/address-form-popin';
import { v4 as uuidv4 } from 'uuid';
import { DeliveryChoice } from '../enums/delivery-choice.enum';

export class AddressHelper {
  static validatePoDeliveryAddressFormat(addressToValidate: any): boolean {
    return (
      addressToValidate.givenName &&
      addressToValidate.familyName &&
      addressToValidate.telephone &&
      (addressToValidate.id || addressToValidate._id) &&
      addressToValidate.addressCity &&
      addressToValidate.defaultAddress !== undefined &&
      addressToValidate.postalCode &&
      addressToValidate.streetAddress &&
      addressToValidate.createdAt
    );
  }

  static validateClinicDeliveryAddressFormat(addressToValidate: any): boolean {
    return (
      addressToValidate.name &&
      addressToValidate.addressCity &&
      addressToValidate.telephone &&
      addressToValidate._id &&
      addressToValidate.defaultAddress !== undefined &&
      addressToValidate.createdAt &&
      addressToValidate.postalCode &&
      addressToValidate.streetAddress &&
      (!addressToValidate.type || addressToValidate.type === 'company')
    );
  }

  static companyToDeliveryAddress(clinicAddress: Address, clinicName: string): DeliveryAddress {
    if (
      !clinicName ||
      !clinicAddress.city ||
      !clinicAddress.country ||
      !clinicAddress.address1 ||
      !clinicAddress.zip ||
      !(clinicAddress.mobile || clinicAddress.phone)
    ) {
      return undefined;
    }
    return {
      _id: uuidv4(),
      defaultAddress: true,
      name: clinicName,
      type: 'company',
      addressCity: clinicAddress.city,
      addressCountry: clinicAddress.country,
      additionalInformation: clinicAddress.address2,
      streetNumberAddress: '',
      streetAddress: clinicAddress.address1,
      addressLocality: clinicAddress.district || '',
      addressRegion: clinicAddress.region || '',
      postalCode: `${clinicAddress.zip}`,
      telephone: `${clinicAddress.mobile || clinicAddress.phone}`,
      createdAt: new Date(),
    };
  }
  static parseExternalPhoneNumber(phone: string, defaultDialCode: string): string {
    if (!phone.startsWith('+') && !phone.includes(' ') && defaultDialCode) {
      return `${defaultDialCode} ${phone}`;
    } else if (!phone.startsWith('+') && phone.includes(' ')) {
      return `+${phone}`;
    }
    return phone;
  }

  static compareByCreatedDateAncientFirst(address1: DeliveryAddress, address2: DeliveryAddress): number {
    return address1.createdAt > address2.createdAt ? 1 : -1;
  }

  static compareByCreatedDateRecentFirst(address1: DeliveryAddress, address2: DeliveryAddress): number {
    return address1.createdAt > address2.createdAt ? -1 : 1;
  }

  /*
    setting clinic addresses list display order
    if type = company then display it first
    else display addresses according to the creation date
   */
  static orderClinicAddresses(address1: DeliveryAddress, address2: DeliveryAddress): number {
    if (address1.type && address1.type === 'company' && (!address2.type || address2.type !== 'company')) {
      return -1;
    } else {
      return AddressHelper.compareByCreatedDateAncientFirst(address1, address2);
    }
  }
}

export function updateEntitieAddress<T extends { addresses?: DeliveryAddress[] }>(
  entity: T,
  address: DeliveryAddress,
  isNewAddress: boolean,
  indexInAddresses: number | null
): T {
  const addressToCreate = isNewAddress && !entity.addresses?.length ? { ...address, defaultAddress: true } : address;
  return {
    ...entity,
    addresses: isNewAddress
      ? [...(entity.addresses || []), addressToCreate]
      : (entity.addresses || []).map((item, index) => (index === indexInAddresses ? addressToCreate : item)),
  };
}

export function isDeliveryAddressIncomplete(address: DeliveryAddress, deliveryChoice: DeliveryChoice): boolean {
  if (!address.streetAddress || !address.postalCode || !address.addressCity || !address.telephone) {
    return true;
  }
  if (deliveryChoice === DeliveryChoice.AtClinic) {
    return !address.name;
  } else if (deliveryChoice === DeliveryChoice.AtPetOwnerPlace) {
    return !address.familyName || !address.givenName;
  }
}

export function formatAddressFormValuesToAddress(values: AddressFormValues, initialAddress?: DeliveryAddress): DeliveryAddress {
  return {
    createdAt: initialAddress?.createdAt || new Date(),
    _id: initialAddress?._id || undefined,
    streetAddress: values.streetAddress,
    addressCity: values.addressCity,
    additionalInformation: values.additionalInformation,
    familyName: values.familyName,
    givenName: values.givenName,
    name: values.name,
    telephone: values.phone.phone,
    postalCode: values.postalCode,
    addressCountry: values.countryCode,
  };
}
