import { selectConsultationLastConsultation, selectConsultationPatient } from '@app/store/consultation/consultation.selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { smartRecoFeatureKey } from '../smart-reco-feature-key';
import { SmartRecoState } from '../smart-reco.state';
import { petHasGrowthChart } from '@app/shared/utils/static-helpers/growth-chart-helper';
import { SystemPreferenceCode } from '@app/shared/utils/enums/system-preference-code';

const selectSmartReco = createFeatureSelector<SmartRecoState>(smartRecoFeatureKey);
/**
 * Select forms values
 */
export const selectSmartRecoProfile = createSelector(selectSmartReco, (state) => state.profile);
export const selectSmartRecoWeight = createSelector(selectSmartReco, (state) => state.weight);
export const selectSmartRecoPathologies = createSelector(selectSmartReco, (state) => state.pathologies);

/**
 * Select patient Ideal Body Weight
 */
export const selectSmartRecoPatientIbw = createSelector(
  selectConsultationPatient,
  selectConsultationLastConsultation,
  (patient, lastConsultation) => {
    return patient?.pet?.idealBodyWeight || lastConsultation?.visit?.weight;
  }
);

export const selectPetHasGrowthChart = (currentSystemPreferenceCode: SystemPreferenceCode) =>
  createSelector(selectSmartReco, (state) => {
    return petHasGrowthChart(state.profile?.lifestage, state.profile?.speciesCode, state.weight?.targetWeight, currentSystemPreferenceCode);
  });

export const selectIsNewPet = createSelector(selectSmartReco, (state) => {
  return state.isNewPet;
});

export const selectOngoingPet = createSelector(selectSmartReco, (state) => {
  return state.ongoingPet;
});
