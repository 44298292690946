import { GenderCode, SpeciesCode, LifestageType, ReproductionStatusCode } from '@app/shared/utils';
import { Sterilization } from '@app/shared/utils/types/sterilization.enum';

export interface PetShortProfileFormValues {
  name: string;
  gender: GenderCode;
  speciesCode: SpeciesCode;
  breed: string;
  birthdate: Date;
  lifestage: LifestageType;
  mixed: boolean;
  weight: number;
  adultTargetWeight: number;
  petActivity: string;
  neutered: boolean;
}

export interface PetDetailsPageFormValues extends PetShortProfileFormValues {
  reproductionStatus: ReproductionStatusCode;
  bcs: number;
  IBW: number;
  pathologies: string[];
  sensitivities: string[];
  observation: string;
}

export enum PetProfileFormValueKeys {
  NAME = 'name',
  SPECIES_CODE = 'speciesCode',
  GENDER = 'gender',
  NEUTERED = 'neutered',
  NEUTERED_TODAY = 'neuteredToday',
  STERILIZATION_TYPE = 'sterilizationType',
  REPRODUCTION_STATUS = 'reproductionStatus',
  BREED = 'breed',
  MIXED = 'mixed',
  BIRTHDATE = 'birthdate',
  PET_ACTIVITY = 'petActivity',
  LIFESTAGE = 'lifestage',
  UNKNOWN_BIRTHDATE = 'unknownBirthdate',
  AGE_COUNTER = 'ageCounter',
  AGE_UNIT = 'ageUnit',
}

export interface PetProfileFormValues {
  name: string;
  gender: GenderCode;
  speciesCode: SpeciesCode;
  breed: string;
  birthdate: Date;
  mixed: boolean;
  neutered: boolean;
  neuteredToday: boolean;
  sterilizationType: Sterilization;
  reproductionStatus: ReproductionStatusCode;
  lifestage: LifestageType;
}

export interface LifestageInfo {
  code: LifestageType | null;
  message: string | null;
  error: string | null;
}

export enum PET_PROFILE_NOTIFICATION_ID {
  WM_LIFESTAGE = 'wmLifestageAlert',
  WM_REPRODUCTION_STATUS = 'wmReproductionStatusAlert',
  RD_SPECIE_BIRTHDATE = 'rdSpecieBirthdateAlert',
  MUF_PB_SPECIE_BIRTHDATE = 'pbSpecieBirthdateAlert',
}
