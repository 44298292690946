export function createPromiseBuffer(options) {
  const {
    size,
    concurrency
  } = options;
  const buffer = []; // pending, not-yet-started tasks
  let inProgress = 0; // counter for tasks currently in progress
  const work = () => {
    // if there's space for a task and buffer is not empty,
    // take one task from buffer and run it
    if (inProgress < concurrency && buffer.length) {
      const {
        producer,
        resolve,
        reject
      } = buffer.shift();
      inProgress++;
      producer().then(result => {
        inProgress--;
        work();
        resolve(result);
      }, reason => {
        inProgress--;
        work();
        reject(reason);
      });
    }
  };
  const add = promiseProducer => {
    if (buffer.length + inProgress >= size) {
      throw new Error('Task buffer full');
    }
    return new Promise((resolve, reject) => {
      buffer.push({
        producer: promiseProducer,
        resolve,
        reject
      });
      work();
    });
  };
  return {
    add
  };
}
