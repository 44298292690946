import { Consultation, Patient, Pet, PetInfo, Product, ProductRecommendation } from '@app/core/models';
import {
  ProductCatalogFilterBreedSizevalues,
  ProductCatalogFilterValues,
} from '@app/shared/components/product-catalog-filters/product-catalog-filters';
import { BreedSize, LifestageType, MeasurementCodeType, ProductPillar, ProductType, SpeciesCode, Tool } from '@app/shared/utils/enums';
import { RangeType } from '@app/shared/utils/enums/rangeType.enum';
import { SearchChoice } from '@app/shared/utils/enums/search-choice.enum';
import { TerritoryType } from '@app/shared/utils/enums/territoryType';
import {
  ConsultationNotSavedButtonActionGtm,
  DailyAllocationButtonsActionGtm,
  EventEnum,
  LabelEnum,
  ModuleNameEnum,
  OrderConfirmationButtonActionGtm,
  PageBlockEnum,
  PageTypeEnum,
  ProductBookButtonActionGtm,
  ShareRecommendationButtonActionGtm,
  TopNavLinkGtmEnum,
  WeightLossPlanButtonActionGtm,
} from '../types/enums';
import { GTMInteraction, PetInteraction } from '../types/interfaces';
import {
  formatBirthdate,
  getValueOrUndefined,
  gtmPetInfoToPetInteraction,
  gtmVisitType,
  isNeutered,
} from '@app/shared/utils/static-helpers/gtm-helper';
import { Helper } from '@app/shared/utils';

export const continueToProducts = (
  name: ModuleNameEnum,
  petInfo: PetInfo,
  patient: Patient,
  lastConsultation: Consultation | null,
  currentBigMeasurementUnit: MeasurementCodeType
): GTMInteraction => ({
  event: EventEnum.CONTINUE_TO_PRODUCTS,
  module: { name },
  pet: gtmPetInfoToPetInteraction(patient, lastConsultation, currentBigMeasurementUnit, petInfo),
});

export const continueToRation = (name: ModuleNameEnum): GTMInteraction => ({
  event: EventEnum.CONTINUE_TO_RATION,
  module: { name },
});

export const printOrderSummary = (name: ModuleNameEnum, type: PageTypeEnum, block: PageBlockEnum, language?: string): GTMInteraction => ({
  event: EventEnum.PRINT,
  module: { name },
  page: { block, type },
  language,
});

export const shareRecoTracking = (
  action: ShareRecommendationButtonActionGtm,
  name: ModuleNameEnum,
  purchaseLink: string,
  language?: string,
  medicalInstructions?: {
    tearGel: boolean;
    antibiotics: boolean;
    painRelief: boolean;
    wireRemoval: boolean;
  },
  hasGrowthChart?: boolean
): GTMInteraction => ({
  event: EventEnum.SHARE_RECOMMENDATION_BUTTON,
  shareRecommendationButton: { action },
  module: { name },
  ...(purchaseLink && { purchaseLink }),
  language,
  medicalInstructions,
  hasGrowthChart,
});

export const productEditPrices = (): GTMInteraction => ({
  event: EventEnum.PRODUCT_EDIT_PRICES,
});

export const productSavePrices = (): GTMInteraction => ({
  event: EventEnum.PRODUCT_SAVE_PRICES,
});

export const homeCardInteraction = (label: LabelEnum): GTMInteraction => ({
  event: EventEnum.MODULE_CLICK,
  interaction: {
    label,
  },
});

export const quickLinksInteraction = (label: LabelEnum): GTMInteraction => ({
  event: EventEnum.QUICK_LINK_CLICK,
  interaction: {
    label,
  },
});

export const homeSearchInteraction = (): GTMInteraction => ({
  event: EventEnum.HOME_SEARCH_CLICK,
});

export const startDetection: GTMInteraction = {
  event: EventEnum.START_DETECTION,
  module: { name: ModuleNameEnum.RENAL_DETECT },
};

export const criteriaModalDisplayed = ({ name, relatedProduct }): GTMInteraction => ({
  event: EventEnum.CRITERIA_MODAL_DISPLAYED,
  module: {
    name: ModuleNameEnum.SMART_RECOMMENDATION,
  },
  criteria: {
    name,
    relatedProduct,
  },
});

export const productDetailsDisplayed = ({ name, id }: Product): GTMInteraction => ({
  event: EventEnum.PRODUCT_DETAILS_DISPLAYED,
  product: {
    name,
    id,
  },
});

export const petRecordSearchClick = (filter_by: SearchChoice, filter_species: SpeciesCode): GTMInteraction => ({
  event: EventEnum.PET_RECORD_SEARCH_CLICK,
  search: {
    activeFilters: [
      {
        By: filter_by,
        Species: filter_species === SpeciesCode.Unknown ? 'All' : filter_species,
      },
    ],
  },
});

export const smartRecoSearchAddProduct: GTMInteraction = {
  event: EventEnum.PRODUCT_ADD_CLICK,
};

export const addProductClick: GTMInteraction = {
  event: EventEnum.PRODUCT_ADD_CLICK,
};

export const productSearchClick = (currentFilters?: ProductCatalogFilterValues & ProductCatalogFilterBreedSizevalues): GTMInteraction => {
  const technologyFilter = [];
  if (currentFilters.dry) technologyFilter.push(ProductType.Dry);
  if (currentFilters.wet) technologyFilter.push(ProductType.Wet);
  if (!currentFilters.wet && !currentFilters.dry) technologyFilter.push(ProductType.Unknown);

  const currLifestage = [];
  if (currentFilters.adult) {
    currLifestage.push(LifestageType.Adult);
  }
  if (currentFilters.birthAndGrowth) {
    currLifestage.push('Birth & Growth');
  }
  if (currentFilters.mature) {
    currLifestage.push(LifestageType.Mature);
  }

  const breedSizes: BreedSize[] = [];
  Object.entries(BreedSize).forEach(([, breedSize]) => {
    if (currentFilters[breedSize]) {
      breedSizes.push(breedSize);
    }
  });
  return {
    event: EventEnum.PRODUCT_SEARCH_CLICK,
    search: {
      query: currentFilters?.search?.length > 2 ? currentFilters?.search : '',
      activeFilters: [
        {
          Technology: technologyFilter,
          Lifestage: currLifestage,
          Size: breedSizes,
          Species: currentFilters.specie === SpeciesCode.Unknown ? 'All' : currentFilters.specie,
          Range: currentFilters.pillar === ProductPillar.VET ? 'Veterinary' : 'Health nutrition',
        },
      ],
    },
  };
};

export const weightManagementFromTool = (): GTMInteraction => ({
  event: EventEnum.WEIGHT_MANAGEMENT_FROM_TOOL,
});

export const territoryNavigationClick = (selectedTerritoy: TerritoryType | RangeType): GTMInteraction => ({
  event: EventEnum.TERRITORY_NAVIGATION_CLICK,
  territory: selectedTerritoy,
});

export const smartRecoRetry = (): GTMInteraction => ({
  event: EventEnum.RETRY_CLICK,
});

export const smartRecoFeedback = (nbProductResults: number, productName: string | undefined): GTMInteraction => ({
  event: EventEnum.SEND_FEEDBACK_CLICK,
  nbProductResults,
  productName,
});

export const petPanelClick = (editActionType: LabelEnum): GTMInteraction => ({
  event: EventEnum.PET_PANEL_CLICK,
  interaction: {
    label: editActionType,
  },
});

export const warningAutomaticRationing: GTMInteraction = {
  event: EventEnum.WARNING_AUTOMATIC_RATIONING,
};

export const automaticRationingCalculation = (value): GTMInteraction => ({
  event: EventEnum.AUTOMATIC_RATIONING_CALCULATION,
  automaticRationingCalculation: value ? 'enable' : 'disable',
});

export const wetAllowanceSelection = (value): GTMInteraction => ({
  event: EventEnum.WET_ALLOWANCE_SELECTION,
  wetAllowanceSelection: {
    allowance: value,
  },
});

export const resetDailyRation = {
  event: EventEnum.RESET_DAILY_RATION,
};

export const patientBlockDisplay = ({
  block,
  petInfo,
  growthChart,
}: {
  block: PageBlockEnum;
  petInfo?: PetInfo;
  growthChart?: { nbWeight: number };
}): GTMInteraction => {
  const interaction = {
    event: EventEnum.PATIENT_BLOCK_DISPLAY,
    page: {
      block,
    },
  };

  if (block === PageBlockEnum.WEIGHT_AND_ACTIVITY_LEVEL) {
    return {
      ...interaction,
      pet: {
        lifestage: petInfo?.lifestage,
        reproductionStatus: petInfo?.reproductionStatus,
        breed: petInfo?.breed,
        gender: petInfo?.gender,
        birthdate: formatBirthdate(petInfo?.birthdate),
        age: Helper._petAge({ birth: { date: petInfo?.birthdate } } as Pet),
        neutered: isNeutered(petInfo?.neutered),
        specie: getValueOrUndefined(petInfo?.speciesCode),
      },
    };
  } else if (block === PageBlockEnum.GROWTH_CHART) {
    return {
      ...interaction,
      growthChart,
    };
  } else {
    return interaction;
  }
};

export const dailyAllocationButton = (action: DailyAllocationButtonsActionGtm, purchaseRedirect?: string): GTMInteraction => ({
  event: EventEnum.DAILYALLOCATIONBUTTON,
  dailyAllocationButton: purchaseRedirect ? { action, purchaseRedirect } : { action },
});

export const topNavClickGtm = (name: TopNavLinkGtmEnum): GTMInteraction => ({
  event: EventEnum.TOP_NAV_CLICK,
  topNavClick: { name },
});

export const productBookButton = (action: ProductBookButtonActionGtm, purchaseRedirect: string): GTMInteraction => ({
  event: EventEnum.PRODUCT_BOOK_BUTTON,
  productBookButton: { action, purchaseRedirect },
});

export const startConsultationExistingPatientGtm = ({
  tool,
  patient,
  lastConsultation,
  currentBigMeasurementUnit,
}: {
  tool: Tool;
  patient: Patient;
  lastConsultation: Consultation | null;
  currentBigMeasurementUnit: MeasurementCodeType;
}): GTMInteraction => {
  return {
    event: EventEnum.CONSULTATION_STARTED,
    consultation: { type: gtmVisitType(tool) },
    pet: gtmPetInfoToPetInteraction(patient, lastConsultation, currentBigMeasurementUnit),
  };
};

export const startConsultationGtm = (tool: Tool): GTMInteraction => {
  return {
    event: EventEnum.CONSULTATION_STARTED,
    consultation: { type: gtmVisitType(tool) },
    pet: undefined,
  };
};

export const sendPetIdentifiedGtm = ({
  patient,
  lastConsultation,
  currentBigMeasurementUnit,
}: {
  patient: Patient | null;
  lastConsultation: Consultation | null;
  currentBigMeasurementUnit: MeasurementCodeType;
}): GTMInteraction => {
  return {
    event: EventEnum.PET_IDENTIFIED,
    pet: gtmPetInfoToPetInteraction(patient, lastConsultation, currentBigMeasurementUnit),
  };
};

export const weightLossPlanButton = (action: WeightLossPlanButtonActionGtm): GTMInteraction => {
  return {
    event: EventEnum.WEIGHT_LOSS_PLAN_BUTTON,
    weightLossPlanButton: { action },
  };
};

export const planNextVisitClicks = (action: string): GTMInteraction => ({
  event: EventEnum.PLAN_NEXT_VISIT_BUTTON,
  planNextVisitButton: { action },
});

export const consultationNotSavedButton = (action: ConsultationNotSavedButtonActionGtm): GTMInteraction => ({
  event: EventEnum.CONSULTATION_NOT_SAVED_BUTTON,
  consultationNotSavedButton: { action },
});

export const orderConfirmationButton = (action: OrderConfirmationButtonActionGtm): GTMInteraction => ({
  event: EventEnum.ORDER_CONFIRMATION_BUTTON,
  orderConfirmationButton: { action },
});

export const continueToIndividualisReco = (): GTMInteraction => ({
  event: EventEnum.CONTINUE_TO_INDIVIDUALIS_RECO,
});

export const individualisProductSelectionReco = (): GTMInteraction => {
  const brand = 'Royal Canin';

  return {
    event: EventEnum.INDIVIDUALIS_PRODUCT_SELECTION,
    individualisProductSelection: {
      name: 'Individualis TM',
      brand,
    },
  };
};

export const growthChartModalDisplayed = (name: string): GTMInteraction => ({
  event: EventEnum.GROW_CHART_MODAL_DISPLAYED,
  growthChartModalDisplayed: { name },
});

export const growthChartModalClick = (name: string, action: string): GTMInteraction => ({
  event: EventEnum.GROW_CHART_MODAL_CLICK,
  growthChartModalClick: { name, action },
});

export const productImpression = (productRecommendations: ProductRecommendation[]): GTMInteraction => {
  const brand = 'Royal Canin';

  const individualisProduct = {
    name: 'Individualis TM',
    brand,
  };

  const standardProducts = productRecommendations.map(({ product }) => ({
    id: `${product?.id}`,
    name: product?.name,
    brand,
    category: `${product?.speciesCode}/${product?.rangeCategory}/${product?.productType}`,
    hasPrice: Boolean(product?.packages.find((pack) => !!pack?.productPrice?.clinicPrice && pack?.productPrice?.clinicPrice !== -1)),
    territory: product?.territory,
    specificNeeds: product?.specificNeeds.join('|'),
  }));

  const productImpression = [individualisProduct, ...standardProducts];

  return {
    event: EventEnum.PRODUCT_IMPRESSION,
    productImpression,
  };
};

export const individualisEligibilityData = (isIndividualisEligible?: boolean): GTMInteraction => ({
  event: EventEnum.INDIVIDUALIS_ELIGIBILITY_DATA,
  pet: { isIndividualisEligible } as PetInteraction,
});
