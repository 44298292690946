import { Country } from '@app/core/models';
import { LocaleData } from 'i18n-iso-countries';

// Mind the fact that the european market includes non EU countries. ie. Great Britain and Switzerland
const EUROPEAN_MARKET_COUNTRY_CODES: string[] = [
  'FR', //	France
  'GB', //	Great Britain
  'IE', //	Ireland
  'BE', //	Belgium
  'NL', //	Netherlands
  'FI', //	Finland
  'NO', //	Norway
  'SE', //	Sweden
  'DK', //	Danemark
  'IT', //	Italy
  'DE', //	Germany
  'AT', //	Austria
  'CH', //	Switzerland
  'ES', //	Spain
  'PT', //	Portugal
  'PL', //	Poland
  'SK', //	Slovakia
  'LT', //	Lithuania
  'LV', //	Latvia
  'EE', //	Estonia
  'CZ', //	Czech Republic
  'GR', //	Greece
  'HU', //	Hungary
  'RO', //	Romania
];

export const mapToCountries = (localeData: LocaleData): Country[] => {
  return Object.keys(localeData.countries).map((code) => {
    const countryNames = localeData.countries[code];
    return {
      value: code,
      label: Array.isArray(countryNames) ? countryNames[0] : countryNames,
    };
  });
};

export const getCountry = (countries: Country[], countryCode: string): Country | null => {
  return countries?.find((country) => country.value === countryCode) ?? null;
};

export const isCountryEuropeanMarket = (countryCode: string): boolean => {
  return EUROPEAN_MARKET_COUNTRY_CODES.includes(countryCode);
};
