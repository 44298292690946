import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '@env/environment';
import { Observable, throwError } from 'rxjs';
import { CountryCode } from '@app/shared/utils/enums/country-code.enum';

@Injectable()
export class ApiService {
  readonly baseUrl = {
    bff: env.bffApiUrl,
    product: env.productApiUrl,
    vet: env.vetApiUrl,
    clinic: env.clinicApiUrl,
    tool: env.toolApiUrl,
    nutrition: env.nutritionApiUrl,
    healthNutrition: env.healthNutritionApiUrl,
    petOwners: env.petOwnersApiUrl,
    core: env.coreApiUrl,
    ciam: env.ciam.apiUrl,
    blob: env.blobUrl,
    blobApi: env.blobApiUrl,
    myAccountUrl: env.myAccountUrl,
    domain: env.ciam.redirectCallback,
  };

  readonly pathUrl = {
    // BFF
    bff_patient: `${this.baseUrl.bff}/api/patient`,
    bff_petOwner: (clinicId: string, petOwnerId: string) => `${this.baseUrl.bff}/api/petOwner/${clinicId}/${petOwnerId}`,
    bff_vets: (clinicId: string) => `${this.baseUrl.bff}/api/vets/${clinicId}`,

    // Products
    productsV1: (clinicId: string): string => `${this.baseUrl.vet}/Clinics/${clinicId}/products`,
    productsV2: (clinicId: string): string => `${this.baseUrl.clinic}/Clinics/${clinicId}/V2/products`,
    productsSrs: (): string => `${this.baseUrl.core}/products`,
    productDetailV2: (clinicId: string, productId: string): string => `${this.baseUrl.clinic}/Clinics/${clinicId}/V2/products/${productId}`,
    productDetailSrs: (id: string) => `${this.baseUrl.core}/products/${id}`,

    // Contact
    contacts: (clinicId: string) => `${this.baseUrl.vet}/Clinics/${clinicId}/contacts/`,
    contactById: (clinicId: string, contactId: string) => `${this.baseUrl.vet}/Clinics/${clinicId}/contacts/${contactId}`,
    contactAddress: (clinicId: string, contactId: string, addressId: string) =>
      `${this.baseUrl.vet}/Clinics/${clinicId}/contacts/${contactId}/addresses/${addressId}`,
    contactsSearch: (clinicId: string) => `${this.baseUrl.vet}/Clinics/${clinicId}/contacts/searches`,

    // smart reco
    smartRecoProducts: (clinicId) => `${this.baseUrl.vet}/Clinics/${clinicId}/products/product-recommendations`,
    smartRecoProductsV2: (clinicId) => `${this.baseUrl.clinic}/Clinics/${clinicId}/v2/products/product-recommendations`,
    nutritionalAssessment: () => `${this.baseUrl.vet}/nutritional-xray`,
    smartRecoNotRecommendedProductV2: (clinicId: string, productId: string) =>
      `${this.baseUrl.clinic}/Clinics/${clinicId}/v2/products/product-recommendations/${productId}`,
    smartRecoProductsSrs: () => `${this.baseUrl.core}/product-recommendations`,

    // health nutrition
    recommendationPrograms: `${this.baseUrl.healthNutrition}/Recommendations/programs`,
    ibw: `${this.baseUrl.healthNutrition}/digital/diagnostics/ibw`,
    ibwCheck: `${this.baseUrl.healthNutrition}/digital/diagnostics/ibw/check`,
    tbwCheck: `${this.baseUrl.healthNutrition}/digital/diagnostics/tbw/check`,
    digitalRationing: `${this.baseUrl.healthNutrition}/digital/rationing/ration`,
    planNextVisit: `${this.baseUrl.healthNutrition}/visits/nextVisits`,

    // Vet
    vet: (vetId) => `${this.baseUrl.vet}/Vets/${vetId}`,
    clinic: (clinicId) => `${this.baseUrl.vet}/Clinics/${clinicId}`,
    patient: (clinicId, patientId) => `${this.baseUrl.vet}/Clinics/${clinicId}/patients/${patientId}`,
    patients: (clinicId) => `${this.baseUrl.vet}/Clinics/${clinicId}/patients/`,
    pet: (clinicId, petId) => `${this.baseUrl.vet}/Clinics/${clinicId}/pets/${petId}`,
    consultations: (clinicId: string, patientId: string) => `${this.baseUrl.vet}/Clinics/${clinicId}/patients/${patientId}/consultations`,
    consultation: (clinicId: string, patientId: string, consultationId: string) =>
      `${this.baseUrl.vet}/Clinics/${clinicId}/patients/${patientId}/consultations/${consultationId}`,
    updateConsultationVisits: (clinicId: string, patientId: string, consultationId: string) =>
      `${this.baseUrl.vet}/Clinics/${clinicId}/patients/${patientId}/consultations/${consultationId}/visits`,
    invitations: (clinicId: string) => `${this.baseUrl.vet}/Clinics/${clinicId}/invitations`,
    search: (clinicId: string) => `${this.baseUrl.vet}/Clinics/${clinicId}/patients/search`,
    petProfile: (breedCode: string): string => `${this.baseUrl.vet}/metadata/breeds/${breedCode}/profiles`,

    // growth chart
    growthChartStandardWeight: (petId: string) => `${this.baseUrl.vet}/v2/pets/${petId}/weights/walthamCharts`,
    growthChartPetWeights: (petId: string) => `${this.baseUrl.vet}/v2/pets/${petId}/weights/walthamCharts`,

    // Order
    orders: (clinicId: string) => `${this.baseUrl.vet}/Clinics/${clinicId}/orders`,
    order: (clinicId: string, orderId: string) => `${this.baseUrl.vet}/Clinics/${clinicId}/orders/${orderId}`,

    // CIAM
    vetUpdate: (vetId) => `${this.baseUrl.ciam}/directory/users/${vetId}`,

    // Onboarding
    userVet: () => `${this.baseUrl.vet}/vets`,
    lookupClinic: (customerId: string, countryCode: string) =>
      `${this.baseUrl.vet}/Clinics?customerId=${customerId}&country=${countryCode}`,
    connectVetClinic: (clinicId: string) => `${this.baseUrl.vet}/Clinics/${clinicId}/vets`,

    // T&Cs
    terms: (vetId: string) => `${this.baseUrl.vet}/Vets/${vetId}/termsconditions`,
    purpose: (countryCode: CountryCode): string => `${this.baseUrl.vet}/purposes?country=${countryCode}`,

    // Vet Mails
    contactUs: `${this.baseUrl.vet}/Contacts/mail/contact-us`,
    mailRecommendation: `${this.baseUrl.vet}/Contacts/mail/recommendation`,
    signalRecommendation: `${this.baseUrl.vet}/Contacts/mail/ununderstood-recommendation`,
    uploadPictures: (vetId: string) => `${this.baseUrl.vet}/Vets/${vetId}/pictures`,

    // Renal-detect
    renalDetectRecommendation: `${this.baseUrl.core}/api/predictions`,

    // Blob storage
    blobStorageData: (countryCode: string) => `${this.baseUrl.blob}/data_${countryCode}.json?${env.blobQueryParams}`,

    // Breeds
    breeds: `${this.baseUrl.vet}/metadata/breeds`,

    myAccount: {
      fromProfile: `${this.baseUrl.myAccountUrl}/manager?redirect_uri=${this.baseUrl.domain}/account`,
      editPersonalInformation: `${this.baseUrl.myAccountUrl}/manager/personal-infos?redirect_uri=${this.baseUrl.domain}/account`,
      editContactInformation: `${this.baseUrl.myAccountUrl}/manager/personal-infos#contact?redirect_uri=${this.baseUrl.domain}/account`,
      editSecurity: `${this.baseUrl.myAccountUrl}/manager/security?redirect_uri=${this.baseUrl.domain}/account`,
    },

    // PO letter
    poLetter: (templateName: string) => `${this.baseUrl.vet}/contacts/po-letter/${templateName}/save-pdf`,
    poLetterPreview: (templateName: string) => `${this.baseUrl.vet}/contacts/po-letter/${templateName}/pdf`,

    // Vet Source
    sendPimsConsultationEvent: (clinicId: string, pimsName: 'vetSources') =>
      `${this.baseUrl.vet}/Clinics/${clinicId}/sendPimsConsultationEvent?pimsName=${pimsName}`,

    // Pets
    petsWeight: (clinicId: string, petId: string) => `${this.baseUrl.clinic}/Clinics/${clinicId}/pets/${petId}/weights`,
    petsDraft: (clinicId: string) => `${this.baseUrl.clinic}/Clinics/${clinicId}/pets/draft`,

    // Analytics
    sendShareRecoEvent: (clinicId: string) => `${this.baseUrl.clinic}/Clinics/${clinicId}/sendSharedRecoEvent`,
  };

  params(parameters: any): HttpParams {
    let params = new HttpParams();
    Object.keys(parameters).forEach(function (key) {
      params = params.append(key, parameters[key]);
    });
    return params;
  }

  handleError(httpError: HttpErrorResponse): Observable<string> {
    if (httpError?.error?.errorCode === 'GROWTH_PROGRAM_TARGET_WEIGHT_NOT_APPLICABLE') {
      return throwError(() => new Error('DAC_adult-target-weight-outside-range_no-ration-provided'));
    }

    if (httpError.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', httpError.error.message);
    } else if (httpError.error && httpError.error.error) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(httpError.error, `Backend returned code ${httpError.status}, ` + `body was: ${httpError.error.error}`);

      return throwError(() => new Error(httpError.error.error.message));
    }
    // return an observable with a user-facing error message
    return throwError(() => new Error($localize`:@@error_try-again-later:`));
  }
}
