import { defaultInternalLogger } from '../internalLogger';
import { defaultUnpatchedConsole } from '../unpatchedConsole';
export class BaseExtension {
  constructor() {
    this.unpatchedConsole = defaultUnpatchedConsole;
    this.internalLogger = defaultInternalLogger;
    this.config = {};
    this.metas = {};
  }
  logDebug(...args) {
    this.internalLogger.debug(`${this.name}\n`, ...args);
  }
  logInfo(...args) {
    this.internalLogger.info(`${this.name}\n`, ...args);
  }
  logWarn(...args) {
    this.internalLogger.warn(`${this.name}\n`, ...args);
  }
  logError(...args) {
    this.internalLogger.error(`${this.name}\n`, ...args);
  }
}
