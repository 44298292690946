import { VERSION } from '../version';
export function registerInitialMetas(faro) {
  var _a, _b;
  const initial = {
    sdk: {
      version: VERSION
    }
  };
  const session = (_a = faro.config.sessionTracking) === null || _a === void 0 ? void 0 : _a.session;
  if (session) {
    faro.api.setSession(session);
  }
  if (faro.config.app) {
    initial.app = faro.config.app;
  }
  if (faro.config.user) {
    initial.user = faro.config.user;
  }
  if (faro.config.view) {
    initial.view = faro.config.view;
  }
  faro.metas.add(initial, ...((_b = faro.config.metas) !== null && _b !== void 0 ? _b : []));
}
