import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { Consultation } from '@app/core/models/consultation';
import { Weight, SummaryWeightManagement, FormattedWeight } from '@app/core/models';
import { Constants } from '@app/shared/utils/constants';
import { MeasureConversionEnum } from '@app/shared/utils/enums/measure-conversion-enum';
import { MeasurementCodeType } from '@app/shared/utils/enums/measurement-type-code.enum';
import { DatePipe } from '@angular/common';
import { measurementUnitsSuffixesTranslationCodes } from '@app/shared/data/localized-content';
import { Programs } from '../enums';
import { NextVisitFromBack, NextVisitData } from '@app/core/models/visit-next';
import { SystemPreferenceCode } from '../enums/system-preference-code';

export class MeasureHelper {
  // Used in weight pipe
  static weightToLocalizedString(weight: Weight, currentSystemPreferenceCode: SystemPreferenceCode, precision?: number): string {
    if (!weight) {
      return '--';
    }
    // Filter out Folding Box product packages
    if (Number.isNaN(weight.measure)) return;

    const convertedWeight = this.convertWeightWithCustomMapping(+weight.measure, weight.measureUnit, null, currentSystemPreferenceCode);

    return this.weightToString(convertedWeight, precision);
  }

  static weightToLocalizedValue(weight: Weight, currentSystemPreferenceCode: SystemPreferenceCode, precision?: number): number {
    if (!weight) {
      return;
    }
    const convertedWeight = this.convertWeight(weight.measure, weight.measureUnit, null, currentSystemPreferenceCode);

    return +convertedWeight.measure.toFixed(precision);
  }

  static convertWeightWithCustomMapping(
    weight: number,
    oldUnitCode: number | string,
    newUnitCode: number | string,
    unitPreference?: number
  ): FormattedWeight {
    const oldUnitCodeNumber = this.convertUnitCodeToNumber(oldUnitCode);
    const newUnitCodeNumber = this.convertUnitCodeToNumber(newUnitCode);
    const oldSystemCode = Math.floor(oldUnitCodeNumber / 10);
    const newSystemCode = unitPreference || Math.floor(newUnitCodeNumber / 10);

    /** For conversions from one system to another (imperial/metric)
     * If there is a matching weight from the conversion mappings (OUNCE_TO_GRAM_MAPPING, POUND_TO_KGRAM_MAPPING ...)
     * return the result from the mapping, else, keep doing a manual conversion
     **/

    const conversionFromMapping =
      newSystemCode !== oldSystemCode ? this.convertFromMapping(weight, oldUnitCodeNumber, newSystemCode) : null;
    if (conversionFromMapping) return conversionFromMapping;

    return this.convertWeight(weight, oldUnitCode, newUnitCode, unitPreference);
  }

  static convertWeight(
    weight: number,
    oldUnitCode: number | string,
    newUnitCode: number | string,
    unitPreference?: number
  ): FormattedWeight {
    const oldUnitCodeNumber = this.convertUnitCodeToNumber(oldUnitCode);
    let newUnitCodeNumber = this.convertUnitCodeToNumber(newUnitCode);
    const oldSystemCode = Math.floor(oldUnitCodeNumber / 10);
    const newSystemCode = unitPreference || Math.floor(newUnitCodeNumber / 10);

    /** Base weight is the weight in the old system, in the lowest unit (gram or ounce) **/
    let baseWeight = weight;
    if (oldUnitCodeNumber === Constants.kilogramCode) {
      baseWeight = this.convert(baseWeight, MeasureConversionEnum.kilogramToGram);
    } else if (oldUnitCodeNumber === Constants.poundCode) {
      baseWeight = this.convert(baseWeight, MeasureConversionEnum.poundToOunce);
    }

    /** Converted weight is the weight in the new system, in the lowest unit (gram or ounce) **/
    let convertedWeight = baseWeight;
    if (newSystemCode !== oldSystemCode) {
      if (newSystemCode === SystemPreferenceCode.metricSystemCode) {
        convertedWeight = this.convert(convertedWeight, MeasureConversionEnum.ounceToGram);
      } else if (newSystemCode === SystemPreferenceCode.imperialSystemCode) {
        convertedWeight = this.convert(convertedWeight, MeasureConversionEnum.gramToOunce);
      }
    }

    /** Calculate the new unit in the new system, depending on how big is the new weight **/
    if (unitPreference) {
      if (unitPreference === SystemPreferenceCode.metricSystemCode) {
        newUnitCodeNumber = Constants.gramCode;
        if (convertedWeight >= Constants.maxGram) {
          newUnitCodeNumber = Constants.kilogramCode;
        }
      } else if (unitPreference === SystemPreferenceCode.imperialSystemCode) {
        newUnitCodeNumber = Constants.ounceCode;
        if (convertedWeight >= Constants.maxOunce) {
          newUnitCodeNumber = Constants.poundCode;
        }
      }
    }

    /** New weight is the weight in the new system, in the required unit **/
    let newWeight = convertedWeight;
    if (newUnitCodeNumber === Constants.kilogramCode) {
      newWeight = this.convert(newWeight, MeasureConversionEnum.gramToKilogram);
    } else if (newUnitCodeNumber === Constants.poundCode) {
      newWeight = this.convert(newWeight, MeasureConversionEnum.ounceToPound);
    }
    const unitSuffix = Constants.weightSuffixes[newUnitCodeNumber];
    const measureUnit = Constants.unitNumberToEnum[newUnitCodeNumber];

    return { measure: newWeight, unitSuffix, measureUnit };
  }

  static convertWeightToMeasure(weight: Weight, newUnitCode: MeasurementCodeType, unitPreference?: number): number {
    return this.convertWeight(weight.measure, weight.measureUnit, newUnitCode, unitPreference).measure;
  }

  static convertUnitCodeToNumber(unitCode: number | string): number {
    let unitCodeNumber: number;
    if (+unitCode) {
      unitCodeNumber = +unitCode;
    } else if (typeof unitCode === 'string') {
      unitCodeNumber = Constants.weightCodes[unitCode];
    }
    return unitCodeNumber;
  }

  static convertFromMapping(weight: number, oldUnitCodeNumber: number, unitPreference?: number): FormattedWeight | null {
    /** If the weight is given in kilogram and the conversion to imperial system is needed **/
    /** Search a corresponding value in pound from the mapping object **/
    if (oldUnitCodeNumber === Constants.kilogramCode && unitPreference === SystemPreferenceCode.imperialSystemCode) {
      const convertedWeight = Constants.KGRAM_TO_POUND_MAPPING[weight];
      if (convertedWeight) {
        return {
          measure: convertedWeight,
          unitSuffix: Constants.weightSuffixes[Constants.poundCode],
          measureUnit: Constants.unitNumberToEnum[Constants.poundCode],
        };
      }
    }

    /** If the weight is given in gram and the conversion to imperial system is needed **/
    /** Search a corresponding value in pound OR in ounce from the mapping objects **/
    if (oldUnitCodeNumber === Constants.gramCode && unitPreference === SystemPreferenceCode.imperialSystemCode) {
      const convertedWeightPound = Constants.GRAM_TO_POUND_MAPPING[weight];
      if (convertedWeightPound) {
        return {
          measure: convertedWeightPound,
          unitSuffix: Constants.weightSuffixes[Constants.poundCode],
          measureUnit: Constants.unitNumberToEnum[Constants.poundCode],
        };
      }

      const convertedWeightOunce = Constants.GRAM_TO_OUNCE_MAPPING[weight];
      if (convertedWeightOunce) {
        return {
          measure: convertedWeightOunce,
          unitSuffix: Constants.weightSuffixes[Constants.ounceCode],
          measureUnit: Constants.unitNumberToEnum[Constants.ounceCode],
        };
      }
    }

    /** If the weight is given in pound and the conversion to metric system is needed **/
    /** Search a corresponding value in kgram OR in gram from the mapping objects **/
    if (oldUnitCodeNumber === Constants.poundCode && unitPreference === SystemPreferenceCode.metricSystemCode) {
      const convertedWeightInKg = Constants.POUND_TO_KGRAM_MAPPING[weight];
      if (convertedWeightInKg) {
        return {
          measure: convertedWeightInKg,
          unitSuffix: Constants.weightSuffixes[Constants.kilogramCode],
          measureUnit: Constants.unitNumberToEnum[Constants.kilogramCode],
        };
      }

      const convertedWeightInGram = Constants.POUND_TO_GRAM_MAPPING[weight];
      if (convertedWeightInGram) {
        return {
          measure: convertedWeightInGram,
          unitSuffix: Constants.weightSuffixes[Constants.gramCode],
          measureUnit: Constants.unitNumberToEnum[Constants.gramCode],
        };
      }
    }

    /** If the weight is given in ounce and the conversion to metric system is needed **/
    /** Search a corresponding value in gram from the mapping objects **/
    if (oldUnitCodeNumber === Constants.ounceCode && unitPreference === SystemPreferenceCode.metricSystemCode) {
      const convertedWeight = Constants.OUNCE_TO_GRAM_MAPPING[weight];
      if (convertedWeight) {
        return {
          measure: convertedWeight,
          unitSuffix: Constants.weightSuffixes[Constants.gramCode],
          measureUnit: Constants.unitNumberToEnum[Constants.gramCode],
        };
      }
    }

    return null;
  }

  static convert(weight: number, converter: MeasureConversionEnum): number {
    switch (converter) {
      case MeasureConversionEnum.gramToKilogram:
        weight /= Constants.maxGram;
        break;
      case MeasureConversionEnum.gramToPound:
        weight /= Constants.poundToGram;
        break;
      case MeasureConversionEnum.gramToOunce:
        weight /= Constants.ounceToGram;
        break;
      case MeasureConversionEnum.kilogramToGram:
        weight *= Constants.maxGram;
        break;
      case MeasureConversionEnum.ounceToPound:
        weight /= Constants.maxOunce;
        break;
      case MeasureConversionEnum.ounceToGram:
        weight *= Constants.ounceToGram;
        break;
      case MeasureConversionEnum.poundToOunce:
        weight *= Constants.maxOunce;
        break;
      case MeasureConversionEnum.poundToGram:
        weight *= Constants.poundToGram;
        break;
    }
    return weight;
  }

  static summarizeWeight(
    weightOb: Weight,
    datePipe: DatePipe,
    currentBigMeasurementUnit: MeasurementCodeType
  ): { weightValue: number; weight: string; date: string } {
    const convertedWeight = this.convertWeight(weightOb.measure, weightOb.measureUnit, currentBigMeasurementUnit);
    const weightValue = convertedWeight.measure;
    const weight = this.weightToString(convertedWeight, 2);
    const date = datePipe.transform(weightOb.weightDate, 'shortDate');

    return { weightValue, weight, date };
  }

  // Map into the right format. Used in Weight-Loss-Summary child component
  static getWeightLossSummaryData(
    lastConsultation: Consultation,
    datePipe: DatePipe,
    program: Programs,
    currentBigMeasurementUnit: MeasurementCodeType
  ): SummaryWeightManagement | null {
    const currentWeight = lastConsultation.visit?.weight;
    const nextWeight = lastConsultation.nextVisit?.expected?.weight;
    const targetWeight = lastConsultation.targetVisit?.expected?.weight;

    if (!currentWeight || !nextWeight || !targetWeight) {
      console.error('SummaryWeightManagement in _getSummaryData was unable to be calculated due to missing consultation values');
      return null;
    }

    return {
      today: MeasureHelper.summarizeWeight(currentWeight, datePipe, currentBigMeasurementUnit),
      next: MeasureHelper.summarizeWeight(nextWeight, datePipe, currentBigMeasurementUnit),
      goal: [Programs.NORMAL_ADULT, Programs.WEIGHT_STABILISATION_STEP_2, Programs.WEIGHT_STABILISATION_STEP_1].includes(program)
        ? undefined
        : MeasureHelper.summarizeWeight(targetWeight, datePipe, currentBigMeasurementUnit),
    };
  }

  static convertWeightObjInPlace(weight: Weight, newUnitCode: number | string, unitPreference?: number): Weight {
    const convertedWeight = this.convertWeight(weight.measure, weight.measureUnit, newUnitCode, unitPreference);
    return {
      ...weight,
      measure: convertedWeight.measure,
      measureUnit: convertedWeight.measureUnit,
    };
  }

  static measureUnitToSuffix(measureUnit: MeasurementCodeType | string): string {
    const content = measurementUnitsSuffixesTranslationCodes.find((e) => e.code === measureUnit);
    return content ? translateKey(content.value) : '--';
  }

  static weightToString(weight: Weight, precision?: number): string {
    if (weight && weight.measure) {
      const unitSuffix = this.measureUnitToSuffix(weight.measureUnit);
      if (precision) {
        return `${weight.measure.toFixed(precision)} ${unitSuffix}`;
      } else {
        return `${+weight.measure.toFixed(2)} ${unitSuffix}`;
      }
    }
    return '';
  }

  static roundWeight(weight: Weight, precision = 2): Weight {
    if (!weight) {
      return null;
    }
    return {
      ...weight,
      measure: +weight.measure.toFixed(precision),
    };
  }

  static getWeightLimitInput(currentBigMeasurementUnit: MeasurementCodeType): number {
    return +MeasureHelper.convertWeightToMeasure(Constants.LIMIT_INPUT_WEIGHT, currentBigMeasurementUnit).toFixed(2) || 100;
  }

  static convertWeightWithBigMeasurementUnit(
    weight: Weight,
    currentBigMeasurementUnit: MeasurementCodeType,
    currentSystemPreferenceCode: SystemPreferenceCode
  ): Weight {
    const convertWeight = MeasureHelper.convertWeight(
      weight.measure,
      weight.measureUnit,
      currentBigMeasurementUnit,
      currentSystemPreferenceCode
    );
    return {
      measure: +convertWeight.measure.toFixed(2),
      measureUnit: convertWeight.measureUnit,
      weightDate: weight.weightDate,
    };
  }

  static formattedExpectedWeightFromBackToFront(
    expectedNextVisit: NextVisitFromBack[],
    currentBigMeasurementUnit: MeasurementCodeType,
    currentSystemPreferenceCode: SystemPreferenceCode
  ): NextVisitData {
    if (expectedNextVisit?.length) {
      const intermediateWeight = expectedNextVisit[0];
      const targetWeight = expectedNextVisit[expectedNextVisit.length - 1];

      return {
        firstVisitDate: expectedNextVisit[0].firstVisitDate,
        intermediateVisit: this.convertWeightWithBigMeasurementUnit(
          {
            measure: intermediateWeight.expectedWeight.measure,
            measureUnit: intermediateWeight.expectedWeight.measureUnit,
            weightDate: intermediateWeight.expectedVisitDateTime,
          },
          currentBigMeasurementUnit,
          currentSystemPreferenceCode
        ),
        targetVisit: this.convertWeightWithBigMeasurementUnit(
          {
            measure: targetWeight.expectedWeight.measure,
            measureUnit: targetWeight.expectedWeight.measureUnit,
            weightDate: targetWeight.expectedVisitDateTime,
          },
          currentBigMeasurementUnit,
          currentSystemPreferenceCode
        ),
      };
    }
    return null;
  }
}
