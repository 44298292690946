/**
 *
 * @license Royal Canin
 * @contributors :
 *  - Amar YASSA
 */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ofType, Actions, createEffect, concatLatestFrom } from '@ngrx/effects';
import { filter, map, tap } from 'rxjs/operators';
import {
  generateNewRecommendationRandomId,
  requestGenerateNewRecommendationRandomId,
  setShareRecommendation,
} from './share-recommendation.actions';
import { AppRoutes } from '@app/shared/utils/enums/routes.enum';
import { Helper } from '@app/shared/utils';
import { Store } from '@ngrx/store';
import { selectRecommendationId } from '@app/store/share-recommendation/share-recommendation.selector';
import { selectToolInfo } from '@app/store/core';

@Injectable({ providedIn: 'root' })
export class ShareRecommendationEffects {
  setShareRecommendation$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(setShareRecommendation),
        tap(() => {
          this.router.navigate([AppRoutes.SHARE_RECOMMENDATION]);
        })
      );
    },
    { dispatch: false }
  );

  generateRandomRecommendationId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(requestGenerateNewRecommendationRandomId),
      concatLatestFrom(({ productIds }) => [this.store$.select(selectRecommendationId(productIds)), this.store$.select(selectToolInfo)]),
      filter(([, recommendationId]) => !recommendationId),
      map(([{ productIds }, , toolInfo]) => {
        const id = [...productIds].sort().join('');
        const randomId = Helper.randomId();
        return generateNewRecommendationRandomId({ id, flowId: toolInfo.tool_flow_id, randomId });
      })
    );
  });

  constructor(private store$: Store, private actions$: Actions, private router: Router) {}
}
