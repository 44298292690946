import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PoLetterType } from '@app/shared/utils';
import { catchError } from 'rxjs/operators';
import { ApiService } from './../api.service';
import { CoreFacade } from '@app/store/core/core.facade';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { RCAlertType } from '@rc/ui';
import { Observable, of } from 'rxjs';
import { PoLetterBody } from '@app/letters/interfaces/po-letter-body';

@Injectable()
export class POLetterService extends ApiService {
  constructor(private http: HttpClient, private coreFacade: CoreFacade) {
    super();
  }

  getPOLetterPdf(templateName: PoLetterType, data: PoLetterBody, preview = false): Observable<{ data: string; fileUrl?: string }> {
    const templateData = {
      ...data,
      pageSizes: {
        width: '595px',
        height: '842px',
      },
    };
    const url = preview ? this.pathUrl.poLetterPreview(templateName) : this.pathUrl.poLetter(templateName);
    return this.http.post<{ data: string; fileUrl?: string }>(url, templateData).pipe(
      catchError(() => {
        this.coreFacade.setAlert({ message: translateKey('error_general_text'), alertType: RCAlertType.ERROR });
        return of(null);
      })
    );
  }
}
