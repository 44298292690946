import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Picture } from '@app/core/models';
import { VetService } from '..';

@Injectable()
export class UploadService extends ApiService {
  constructor(private http: HttpClient, private vetService: VetService) {
    super();
  }

  uploadPicture(data: any): Observable<Picture> {
    return this.vetService.vetId$.pipe(
      switchMap((vetId) => {
        const url = this.pathUrl.uploadPictures(vetId);
        const formData: FormData = new FormData();
        formData.append('file', data);
        const headers = new HttpHeaders();
        headers.set('Content-Type', 'multipart/form-data');

        return this.http.post(url, formData, { headers: headers }).pipe(
          map((result) => result),
          catchError(this.handleError.bind(this))
        );
      })
    );
  }
}
