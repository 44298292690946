import { UntypedFormControl } from '@angular/forms';
import isEmail from 'validator/lib/isEmail';

/**
 * Custom Email Validator to match API validation
 */
export function customEmailValidator(control: UntypedFormControl) {
  return !control.value || isEmail(control.value)
    ? null
    : {
        invalidEmail: true,
      };
}
