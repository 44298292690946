export enum CriteriaType {
  RiskFactor = 'risk_factor',
  Diseases = 'diseases',
  Lifestage = 'lifestage',
  SizeLifestage = 'size_lifestage',
}

export const criteriaTypeTradMapping: Record<CriteriaType, string> = {
  [CriteriaType.RiskFactor]: 'sensitivity',
  [CriteriaType.Diseases]: 'pathology',
  [CriteriaType.Lifestage]: '',
  [CriteriaType.SizeLifestage]: 'sr-criteria',
};

/* this issue here is that some pathologies and sensitivities have the same key
 * for instance, for tartar issues we get from the API :
 * { type: diseases, key: tartar } for the pathologie and { type: risk_factor, key: tartar } for the sensitivity
 * some translation keys are built by doing "sr-criteria" + criteria.key, so we have an issue
 * because that means that the translation key for both pathology and sensitivity is "sr-criteria-tartar"
 * refactoring all the translation keys to use something like "sr-criteria-disease-tartar" or "sr-criteria-risk_factor-tartar" (so "sr-criteria" + criteria.type + criteria.key)
 * is too much work hence the following mapping, for diseases the translation key to use for tartar is "sr-criteria-tartar1"
 */
export const translationMappingSameSensitivityAndPatho: Record<string, string> = {
  'diseases:tartar': 'tartar1',
};
