/**
 *
 * @license Royal Canin
 * @contributors :
 *  - Amar YASSA
 */

import { ShareRecommendationData } from '@app/core/models/share-recommendation-data';
import { createAction, props } from '@ngrx/store';

export const SET_SHARE_RECOMMENDATION = '[SHARE RECOMMENDATION] set share recommendation';
export const RESET_SHARE_RECOMMENDATION = '[SHARE RECOMMENDATION] reset share recommendation';
export const RESET_SHARE_RECOMMENDATION_DATA = '[SHARE RECOMMENDATION] reset share recommendation data';
export const REQUEST_GENERATE_NEW_RECOMMENDATION_RANDOM_ID = '[SHARE RECOMMENDATION] request generate new recommendation random id';
export const GENERATE_NEW_RECOMMENDATION_RANDOM_ID = '[SHARE RECOMMENDATION] generate new recommendation random id';
export const RESET_RECOMMENDATION_RANDOM_IDS = '[SHARE RECOMMENDATION] reset recommendation random ids';

export const setShareRecommendation = createAction(SET_SHARE_RECOMMENDATION, props<{ data: ShareRecommendationData }>());
export const resetShareRecommendationData = createAction(RESET_SHARE_RECOMMENDATION_DATA);
export const resetShareRecommendation = createAction(RESET_SHARE_RECOMMENDATION);
export const requestGenerateNewRecommendationRandomId = createAction(
  REQUEST_GENERATE_NEW_RECOMMENDATION_RANDOM_ID,
  props<{ productIds: string[] }>()
);
export const generateNewRecommendationRandomId = createAction(
  GENERATE_NEW_RECOMMENDATION_RANDOM_ID,
  props<{ id: string; flowId: string; randomId: string }>()
);

export const resetRecommendationRandomIds = createAction(RESET_RECOMMENDATION_RANDOM_IDS);
