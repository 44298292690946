import { Product, ProductPriceEdit } from '@app/core/models';
import { FetchProductDataFilters } from '@app/core/models/fetch-product-data-filters';
import {
  ProductCatalogFilterBreedSizevalues,
  ProductCatalogFilterLifestageValues,
  ProductCatalogFilterValues,
} from '@app/shared/components/product-catalog-filters/product-catalog-filters';
import { SpeciesCode, Tool, ViewType } from '@app/shared/utils';
import { RangeType } from '@app/shared/utils/enums/rangeType.enum';
import { TerritoryType } from '@app/shared/utils/enums/territoryType';
import { createAction, props } from '@ngrx/store';

/**
 * Price Action
 */
export const setPriceListToUpdate = createAction('[Products] Set Price List To Update', props<{ priceListToUpdate: ProductPriceEdit[] }>());

export const addPricesToUpdate = createAction('[Products] Add Prices To Update', props<{ prices: ProductPriceEdit[] }>());

export const setShowPriceListActions = createAction('[Products] Set Show Price List Actions', props<{ value: boolean }>());

export const updatePrices = createAction('[Products] Update Prices', props<{ withTracking: boolean }>());

export const resetProductsPricesState = createAction('[Products] Reset Prices State');

/**
 * All Product Action
 */
export const getAllProducts = createAction(
  '[Products] get all products ',
  props<{ filterParams?: FetchProductDataFilters; openFilledCategories?: boolean; tool?: Tool }>()
);
export const getAllProductsSuccess = createAction('[Products] get all products Success', props<{ allProductList: Product[] }>());
export const getAllProductsFail = createAction('[Products] get all products Fail');

export const setFilteredProducts = createAction(
  '[Products] get filtered products',
  props<{ openFilledCategories?: boolean; skipUpdateSelectedCategories?: boolean }>()
);
export const setFilteredProductsSuccess = createAction(
  '[Products] get filtered products Success',
  props<{ filteredProductList: Record<TerritoryType | string, Product[]>; filteredProductLength: number }>()
);
/**
 * Products Filters Action
 */
export const setProductFilters = createAction(
  '[Products Filters] update products filters',
  props<{ filtersValues: ProductCatalogFilterValues & ProductCatalogFilterBreedSizevalues }>()
);

export const updateSelectedCategories = createAction(
  '[Products Filters] update products catalogue selected category',
  props<{ selectedCategories: (TerritoryType | RangeType)[] | null }>()
);

export const addSelectedCategories = createAction(
  '[Products Filters] update products catalogue selected category add a category',
  props<{ category: TerritoryType | RangeType }>()
);

export const removeSelectedCategories = createAction(
  '[Products Filters] update products catalogue selected category remove a category',
  props<{ category: TerritoryType | RangeType }>()
);

export const setForcedProductFilters = createAction(
  '[Products Filters] update products filters specie',
  props<{ specieCode?: SpeciesCode; lifestageType?: ProductCatalogFilterLifestageValues }>()
);

export const setProductTypeFilters = createAction(
  '[Products Filters] update products filters productType',
  props<{ dry: boolean; wet: boolean }>()
);

/**
 * Products Catalog Action
 */
export const updateSelectedCatalogViewType = createAction(
  '[Products Catalog] update selected catalog view type',
  props<{ currentViewType: ViewType }>()
);

export const resetProductsData = createAction('[Products Catalog] Reset Data');
export const setSelectedDryProductPackId = createAction('[Products Catalog] Set Dry Product Pack Id', props<{ packId: string }>());

export const setSelectedWetProductPackId = createAction('[Products Catalog] Set Wet Product Pack Id', props<{ packId: string }>());

export const setSelectedProduct = createAction(
  '[Products Catalog] Set Selected Product',
  props<{ selectedProduct: { product: Product; packId?: string }; setProductOnly?: boolean; allowMultipleSelection?: boolean }>()
);

export const setSelectedProducts = createAction('[Products Catalog] Set Selected Products', props<{ products: Product[] }>());

export const resetSelectedProduct = createAction('[Products Catalog] Reset Selected Product', props<{ productId: string }>());
export const resetAllSelectedProducts = createAction('[Products Catalog] Reset all Selected Products');
