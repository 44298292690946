<div class="qrCode-container" [ngClass]="{ 'qrCode-container--full-width': fullWidth }">
  <div class="qrCode-container__instructions">
    <div class="qrCode-container__instructions__title">{{ 'po-letter_settings_instructions' | translateKey }}:</div>
    <div class="qrCode-container__instructions__text">
      {{ 'po-letter_settings_instructions_text' | translateKey }}
    </div>
    <div *ngIf="instructions" class="qrCode-container__instructions__downloading">
      <app-icon [name]="IconName.alert"></app-icon>
      <span> {{ instructions }} </span>
    </div>
  </div>
  <div class="qrCode-container__image">
    <img class="qrCode-container__img" src="{{ qrCodeLink }}" alt="" />
  </div>
</div>
