import { AlertNotification, Breed } from '@app/core/models';
import { Localization } from '@app/core/models/localization';
import { Tool } from '@app/shared/utils';

export interface CoreState {
  showLoader: boolean;
  showPopinLoader: boolean;
  loaderTransparent: boolean;
  showProgressBar: boolean;
  deprecatedLoaderState: boolean;
  localization: Localization | undefined;
  tool: Tool | null;
  prevTool: Tool | null;
  tool_flow_id: string | null;
  breeds: Breed[];
  alerts: AlertNotification[];
}

export const initialCoreState: CoreState = {
  showLoader: false,
  showPopinLoader: false,
  loaderTransparent: false,
  showProgressBar: false,
  deprecatedLoaderState: false,
  localization: undefined,
  tool: null,
  prevTool: null,
  tool_flow_id: null,
  breeds: [],
  alerts: [],
};
