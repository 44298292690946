import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID, OnDestroy } from '@angular/core';
import { Breed } from '@app/core/models';
import { Helper } from '@app/shared/utils';
import { CoreFacade } from '@app/store/core/core.facade';
import { CountryCode } from '@app/shared/utils/enums/country-code.enum';
import { LanguageCode } from '@app/shared/utils/enums/language-code.enum';
import { VetFacade } from '@app/store/vet';
import * as objectPath from 'object-path';
import { Observable, Subject, of } from 'rxjs';
import { shareReplay, catchError, map, takeUntil, take, tap, filter, switchMap } from 'rxjs/operators';
import { LANGUAGES } from '../../utils/localization/localization';
import { ApiService } from '../api.service';

@Injectable()
export class EnumsService extends ApiService implements OnDestroy {
  cache = {};
  private _destroyed$ = new Subject<void>();

  private static readonly defaultLanguages = {
    [CountryCode.FI]: {
      [LanguageCode.svSE]: 'Swedish (Finland)',
    },
    [CountryCode.HK]: {
      [LanguageCode.enGB]: 'English (Hong Kong)',
      [LanguageCode.enUS]: 'English (Hong Kong)',
      [LanguageCode.enCA]: 'English (Hong Kong)',
      [LanguageCode.enAU]: 'English (Hong Kong)',
    },
    [CountryCode.CA]: {
      [LanguageCode.enGB]: 'English (Canada)',
      [LanguageCode.enUS]: 'English (Canada)',
      [LanguageCode.enCA]: 'English (Canada)',
      [LanguageCode.enAU]: 'English (Canada)',
      [LanguageCode.frCA]: 'French (Canada)',
      [LanguageCode.frBE]: 'French (Canada)',
      [LanguageCode.frCH]: 'French (Canada)',
      [LanguageCode.frFR]: 'French (Canada)',
      [LanguageCode.frRE]: 'French (Canada)',
    },
    [CountryCode.BE]: {
      [LanguageCode.frBE]: 'French (Belgium)',
      [LanguageCode.frCA]: 'French (Belgium)',
      [LanguageCode.frCH]: 'French (Belgium)',
      [LanguageCode.frFR]: 'French (Belgium)',
      [LanguageCode.frRE]: 'French (Belgium)',
      [LanguageCode.enGB]: 'English (Belgium)',
      [LanguageCode.enUS]: 'English (Belgium)',
      [LanguageCode.enCA]: 'English (Belgium)',
      [LanguageCode.enAU]: 'English (Belgium)',
      [LanguageCode.nlBE]: 'Dutch (Belgium)',
      [LanguageCode.nlNL]: 'Dutch (Belgium)',
    },
    [CountryCode.CH]: {
      [LanguageCode.frBE]: 'French (Switzerland)',
      [LanguageCode.frCA]: 'French (Switzerland)',
      [LanguageCode.frCH]: 'French (Switzerland)',
      [LanguageCode.frFR]: 'French (Switzerland)',
      [LanguageCode.frRE]: 'French (Switzerland)',
      [LanguageCode.deDE]: 'German (Switzerland)',
      [LanguageCode.itIT]: 'Italian (Switzerland)',
    },
  };

  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) protected locale: string,
    private coreFacade: CoreFacade,
    private vetFacade: VetFacade
  ) {
    super();
  }

  getCacheOrFetch(path, fetchFn) {
    if (!objectPath.get(this.cache, path)) {
      objectPath.set(this.cache, path, fetchFn().pipe(shareReplay(1)));
    }
    return objectPath.get(this.cache, path);
  }

  /**
   * [GET] BreedContent
   */
  fetchBreed(breedCode: string): Observable<Breed> {
    return this.fetchBreeds().pipe(
      map((breeds) => {
        const filtered = breeds.find((breed) => breed.breedCode === breedCode);
        if (filtered) {
          return filtered;
        } else {
          if (breedCode) {
            console.error('Breed not found:', this.locale, breedCode);
            return null;
          }
          return undefined;
        }
      })
    );
  }

  fetchBreedName(breedCode: string): Observable<string> {
    return this.fetchBreed(breedCode).pipe(
      take(1),
      takeUntil(this._destroyed$),
      map((breed) => {
        if (breed) {
          return breed.localName;
        } else if (breed === undefined) {
          return '--';
        } else {
          return '?';
        }
      })
    );
  }

  // @deprecated
  fetchBreedFromSpecies(speciesCode: string, breedCode: string): Observable<Breed> {
    return this.fetchBreeds().pipe(
      map((breeds) => {
        const filtered = breeds.find((breed) => breed.speciesCode === speciesCode && breed.breedCode === breedCode);
        if (filtered) {
          return filtered;
        } else {
          if (speciesCode && breedCode) {
            console.error('Breed not found:', this.locale, speciesCode, breedCode);
            return null;
          }
          return undefined;
        }
      })
    );
  }

  // @deprecated
  fetchBreedNameFromSpecies(speciesCode: string, breedCode: string): Observable<string> {
    return this.fetchBreedFromSpecies(speciesCode, breedCode).pipe(
      take(1),
      takeUntil(this._destroyed$),
      map((breed) => {
        if (breed) {
          return breed.localName;
        } else if (breed === undefined) {
          return '--';
        } else {
          return '?';
        }
      })
    );
  }

  fetchBreeds(): Observable<Breed[]> {
    return this.getCacheOrFetch([this.locale, 'breeds'], () => {
      return this.vetFacade.currentClinicCountry$.pipe(
        filter((currentClinicCountry) => !!currentClinicCountry),
        take(1),
        switchMap((country) => {
          /**
           * Retrieve weshare custome locale code
           */
          let locale = LANGUAGES.find((l) => l.code === this.locale)?.weShareLocale || '';
          /**
           * Some countries need specific mapping for weshare languages
           */
          locale = (EnumsService.defaultLanguages[country] && EnumsService.defaultLanguages[country][this.locale]) || locale;

          return this.http.get<Breed[]>(`${this.pathUrl.breeds}?where[country]=${country.toLowerCase()}&locale=${locale}`);
        }),
        map((breeds) =>
          breeds
            .filter((breed) => !!breed && !!breed.speciesCode && !!breed.localName && !!breed.breedCode)
            .map((breed) => {
              breed.sizeCategory = Helper.cleanSizeCategory(breed.sizeCategory);
              return breed;
            })
        ),
        map((breeds) => {
          /** Put the mixed breeds on top of the list + Hide the undefined breeds */
          const mixedBreeds = [];
          const filteredBreeds = breeds.filter((breed) => {
            if (Helper.isMixed(breed)) {
              mixedBreeds.push(breed);
              return false;
            } else if (Helper.isUndefined(breed)) {
              return false;
            }
            return true;
          });
          filteredBreeds.sort((b1, b2) => Helper.alphabeticalOrder(b1.localName, b2.localName));
          return mixedBreeds.concat(filteredBreeds).map((breed) => {
            return {
              ...breed,
              localName: Helper.capitalize(breed.localName),
            };
          });
        }),
        tap((breeds) => this.coreFacade.setBreeds(breeds)),
        catchError(this.handleError.bind(this))
      );
    });
  }

  getLocaleBreed(languageCode: string, countryCode: string, breedCode: string): Observable<Breed> {
    const locale = LANGUAGES.find((l) => l.code == languageCode)?.weShareLocale || '';
    const country = countryCode.toLowerCase();
    return this.http.get<Breed[]>(`${this.pathUrl.breeds}?where[country]=${country}&locale=${locale}`).pipe(
      map((breeds: Breed[]) => {
        return breeds.find((breed) => breed.breedCode == breedCode);
      }),
      catchError((error) => {
        console.error(error);
        return of(null);
      })
    );
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
