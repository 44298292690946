import { faro } from '@grafana/faro-core';
import { throttle } from '../../../utils';
import { getItem, removeItem, setItem, webStorageType } from '../../../utils/webStorage';
import { isSampled } from './sampling';
import { STORAGE_KEY, STORAGE_UPDATE_DELAY } from './sessionConstants';
import { addSessionMetadataToNextSession, createUserSessionObject, getUserSessionUpdater } from './sessionManagerUtils';
export class VolatileSessionsManager {
  constructor() {
    this.updateSession = throttle(() => this.updateUserSession(), STORAGE_UPDATE_DELAY);
    this.updateUserSession = getUserSessionUpdater({
      fetchUserSession: VolatileSessionsManager.fetchUserSession,
      storeUserSession: VolatileSessionsManager.storeUserSession
    });
    this.init();
  }
  static removeUserSession() {
    removeItem(STORAGE_KEY, VolatileSessionsManager.storageTypeSession);
  }
  static storeUserSession(session) {
    setItem(STORAGE_KEY, JSON.stringify(session), VolatileSessionsManager.storageTypeSession);
  }
  static fetchUserSession() {
    const storedSession = getItem(STORAGE_KEY, VolatileSessionsManager.storageTypeSession);
    if (storedSession) {
      return JSON.parse(storedSession);
    }
    return null;
  }
  init() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.updateSession();
      }
    });
    // Users can call the setSession() method, so we need to sync this with the local storage session
    faro.metas.addListener(function syncSessionIfChangedExternally(meta) {
      const session = meta.session;
      const sessionFromSessionStorage = VolatileSessionsManager.fetchUserSession();
      if (session && session.id !== (sessionFromSessionStorage === null || sessionFromSessionStorage === void 0 ? void 0 : sessionFromSessionStorage.sessionId)) {
        const userSession = addSessionMetadataToNextSession(createUserSessionObject({
          sessionId: session.id,
          isSampled: isSampled()
        }), sessionFromSessionStorage);
        VolatileSessionsManager.storeUserSession(userSession);
        faro.api.setSession(userSession.sessionMeta);
      }
    });
  }
}
VolatileSessionsManager.storageTypeSession = webStorageType.session;
