import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID, OnDestroy } from '@angular/core';
import { InvitationTemplateData, RecommendationBodyEmail, Vet } from '@app/core/models';
import { Attachments, Contact } from '@app/core/models/contact';
import { Market } from '@app/core/models/market';
import { GtmEventsService, GTMService } from '@app/core/services/tracking';
import { Logger } from '@app/core/services/utils/logger';
import { EmailResponsePopinComponent } from '@app/shared/components/email-response-popin/email-response-popin.component';
import { Constants, EmailPopinType } from '@app/shared/utils';
import { LanguageCode } from '@app/shared/utils/enums/language-code.enum';
import { VetFacade } from '@app/store/vet';
import { Observable, Subject, Subscription } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class EmailService extends ApiService implements OnDestroy {
  private destroyed$ = new Subject<void>();
  private vet: Vet;
  private currentClinicCountry: string;
  private market: Market | undefined = undefined;
  /**
   * Get user full name
   */
  get userFullName(): string {
    return `${this.vet?.user.firstName} ${this.vet?.user.lastName}`.trim();
  }

  constructor(
    private http: HttpClient,
    private gtmEventsService: GtmEventsService,
    private logger: Logger,
    private vetFacade: VetFacade,
    @Inject(LOCALE_ID) protected localeId: LanguageCode,
    private trackingService: GTMService,
    private _dialog: MatDialog
  ) {
    super();
    this.vetFacade.vet$
      .pipe(
        takeUntil(this.destroyed$),
        tap((vet) => (this.vet = vet))
      )
      .subscribe();
    this.vetFacade.currentClinicCountry$
      .pipe(
        takeUntil(this.destroyed$),
        tap((currentClinicCountry) => (this.currentClinicCountry = currentClinicCountry))
      )
      .subscribe();
    this.vetFacade.market$
      .pipe(
        takeUntil(this.destroyed$),
        tap((market) => (this.market = market))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  sendEmail(payload: Contact): Observable<any> {
    const url = this.pathUrl.contactUs;
    return this.http.post(url, payload).pipe(
      map((data) => data),
      catchError(this.handleError.bind(this))
    );
  }

  signalRecommendationEmail(body): Observable<any> {
    return this.http.post(this.pathUrl.signalRecommendation, body).pipe(catchError(this.handleError.bind(this)));
  }

  sendRecommendationByEmail(
    ownerEmail: string,
    emailBody: RecommendationBodyEmail,
    attachments: Attachments[],
    seletecLanguage?: string
  ): Observable<void> {
    const { tool, eventData, ...data } = emailBody;
    const payload = {
      ...this.getEmailLocalizationData(seletecLanguage),
      from: { email: Constants.NO_REPLY_MAIL, name: this.userFullName },
      subject: 'Recommendation',
      to: ownerEmail,
      data,
      tool,
      eventData,
      attachments,
    };
    return this.http.post(this.pathUrl.mailRecommendation, payload).pipe(catchError(this.handleError.bind(this)));
  }

  sendInvitation(clinicId: string, data: { email: string; data: InvitationTemplateData }): Observable<any> {
    const body = {
      ...this.getEmailLocalizationData(),
      ...data,
    };
    return this.http.post(this.pathUrl.invitations(clinicId), body).pipe(
      catchError((err) => {
        this.logger.error(err);
        return this.handleError.bind(this);
      })
    );
  }

  sendByEmail(
    emailPopinType: EmailPopinType,
    email: string,
    body?,
    attachments?: Attachments[],
    seletecLanguage?: string,
    isRetry = false
  ): Subscription | void {
    const retryCallback = () => this.sendByEmail(emailPopinType, email, body, attachments, seletecLanguage, true);

    switch (emailPopinType) {
      case EmailPopinType.Recommendation:
        return this.sendRecommendationByEmail(email, body, attachments, seletecLanguage).subscribe(
          () => {
            this.openResponseEmailPopin(emailPopinType, true, isRetry);
          },

          (err) => {
            this.openResponseEmailPopin(emailPopinType, false, isRetry, err, retryCallback);
          }
        );

      case EmailPopinType.Collaborators:
        return this.sendInvitation(body.clinicId, body.data).subscribe(
          () => {
            this.openResponseEmailPopin(emailPopinType, true, isRetry);
          },
          (err) => {
            this.openResponseEmailPopin(emailPopinType, false, isRetry, err, retryCallback);
          }
        );

      case EmailPopinType.Contact:
        return this.sendEmail(body).subscribe(
          () => {
            // gtm event is deprecated ?
            this.gtmEventsService.sendPopinResult('contactUs', 'Contact Us', true);
            this.openResponseEmailPopin(emailPopinType, true, isRetry);
          },
          (err) => {
            // gtm event is deprecated ?
            this.gtmEventsService.sendPopinResult('contactUs', 'Contact Us', true);
            this.openResponseEmailPopin(emailPopinType, false, isRetry, err, retryCallback);
          }
        );

      default:
        return;
    }
  }

  openResponseEmailPopin(emailPopinType, isSuccessPopinType, isRetry, error?, retryCallback?): void {
    this._dialog.open(EmailResponsePopinComponent, {
      data: {
        emailPopinType,
        isSuccessPopinType,
        isRetryAllowed: !isRetry,
        error,
        retryCallback,
      },
    });
  }

  /**
   * Get email localization data
   * Method to get existing combination of countryCode & locale to use in sendgrid
   * Use user language if available, first language declared if not and en-GB if templateLanguages are not found
   */
  private getEmailLocalizationData(seletecLanguage?: string): { locale: string; countryCode: string } {
    const templateLanguages = this.market?.mailLanguages;

    let locale = LanguageCode.enGB;
    if (templateLanguages?.length) {
      if (seletecLanguage && templateLanguages.includes(seletecLanguage as LanguageCode)) {
        locale = seletecLanguage as LanguageCode;
      } else if (templateLanguages.includes(this.localeId)) {
        locale = this.localeId;
      } else {
        locale = templateLanguages[0];
      }
    }

    return {
      countryCode: this.currentClinicCountry,
      locale,
    };
  }
}
