import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { triggerNavigation } from '../core.actions';
import { Router } from '@angular/router';

@Injectable()
export class RoutingEffects {
  /**
   * Create an effect to trigger routing in order to manage triggering
   * redirection from other ngrx effects emitting multiple actions after all previous actions are emitted and thus dispatched
   * (exemple of use in all navigateToAllowance$ effects)
   */
  triggerNavigation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(triggerNavigation),
        tap((action) => {
          this.router.navigate(action.paths);
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private router: Router) {}
}
