import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@app/shared/utils/icon/icon.module';
import { QrCodeDisplayComponent } from './qr-code-display.component';
import { SharedPipesModule } from '@app/shared/pipes/shared-pipes.module';

@NgModule({
  imports: [CommonModule, IconModule, SharedPipesModule],
  declarations: [QrCodeDisplayComponent],
  exports: [QrCodeDisplayComponent],
})
export class QrCodeDisplayModule {}
