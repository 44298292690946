import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { AppRoutes } from '@app/shared/utils/enums/routes.enum';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Logger } from '../utils';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard {
  constructor(private router: Router, private authService: AuthService, private logger: Logger, private activatedRoute: ActivatedRoute) {}

  canActivate() {
    /**
     * WARNING ! This is a hack, canActivate is used only for pages where we are supposed to *NOT* be logged in to access (landing page)
     */
    return this.authService.isAuthenticated().pipe(
      first(),
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          this.logger.log('The user is not longer authenticated and will be redirected to the login page');
          return true;
        }
        return this.router.createUrlTree([AppRoutes.HOME]);
      })
    );
  }

  canActivateChild(childRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isAuthenticated().pipe(
      first(),
      map((loggedIn) => {
        if (loggedIn) {
          return true;
        }
        localStorage.setItem('redirectUrl', routerStateSnapshot.url);
        this.authService.login(routerStateSnapshot.url);
        return false;
      })
    );
  }
}
