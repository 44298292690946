import { BreedSize, SpeciesCode } from '@app/shared/utils';
import { Measure } from '@app/core/models/weight';

export interface Breed {
  id: number;
  breedCode: string;
  localName: string;
  speciesCode: SpeciesCode;
  sizeCategory?: BreedSize;
  lifeStage: Record<string, { [key in 'mini' | 'maxi']: { value?: number; unit: string } }>;
  standardWeights?: StandardWeights;
}

export interface StandardWeights {
  male: StandardWeight;
  female: StandardWeight;
}

export interface StandardWeight {
  max: Measure | null;
  min: Measure | null;
}

export const mixedBreedSizesOrder = [
  'mixed_breed_xsmall_1_4kg',
  'mixed_breed_mini_4_10kg',
  'mixed_breed_medium_11_25kg',
  'mixed_breed_maxi_26_44kg',
  'mixed_breed_giant_45kg',
];

export const BREED_SIZE_MAP = {
  mixed_breed_xsmall_1_4kg: 'xsmall',
  mixed_breed_mini_4_10kg: 'small',
  mixed_breed_medium_11_25kg: 'medium',
  mixed_breed_maxi_26_44kg: 'large',
  mixed_breed_giant_45kg: 'giant',
};
