import { ValidationErrors } from '@angular/forms';
import { translateKey } from '@app/shared/utils/static-helpers/translate';

export function getRequiredErrorMessage(fieldKeyTrad: string): string {
  return `${translateKey(fieldKeyTrad)} ${translateKey('required-key')}`;
}

export function getDateErrorMessage(errors: ValidationErrors, fieldKeyTrad: string, required: boolean): string {
  if (required) {
    if (errors.matDatepickerParse) {
      return translateKey('rc-input_wrong_format');
    }
    if (errors.required) {
      return `${translateKey(fieldKeyTrad)} ${translateKey('required-key')}`;
    }
  }
  if (errors.matDatepickerParse) {
    return translateKey('rc-input_wrong_format');
  }
  if (errors.pastDate) {
    return `${translateKey(fieldKeyTrad)} ${translateKey('rc-input_must-be-past')}`;
  }
  if (errors.futureDate) {
    return `${translateKey(fieldKeyTrad)} ${translateKey('rc-input_must-be-future')}`;
  }
  if (errors.minDate) {
    return `${translateKey(fieldKeyTrad)} ${translateKey('rc-input_must-be-later')}`;
  }
  if (errors.maxDate) {
    return `${translateKey(fieldKeyTrad)} ${translateKey('rc-input_must-be-before')}`;
  }
  return '';
}

export function getPhoneErrorMessage(errors: ValidationErrors, fieldKeyTrad: string): string {
  if (errors?.minlength) {
    return `${translateKey(fieldKeyTrad)} ${translateKey('rc-input_must-be-longer')}`;
  }
  if (errors?.maxlength) {
    return `${translateKey(fieldKeyTrad)} ${translateKey('rc-input_must-be-shorter')}`;
  }
  return '';
}

export function getEmailErrorMessage(errors: ValidationErrors, fieldKeyTrad: string): string {
  if (errors?.invalidEmail) {
    return `${translateKey(fieldKeyTrad)} ${translateKey('rc-input_email-format')}`;
  }
  return '';
}

export function getInputErrorMessage(errors: ValidationErrors, fieldKeyTrad: string): string {
  if (errors) {
    if (errors?.validatePhoneNumber && !errors?.validatePhoneNumber?.valid) {
      return `${translateKey('rc-input_wrong_format')}`;
    }
    if (errors?.required) {
      return `${translateKey(fieldKeyTrad)} ${translateKey('required-key')}`;
    }
    if (errors?.minlength) {
      return `${translateKey(fieldKeyTrad)} ${translateKey('rc-input_must-be-longer')}`;
    }
    if (errors?.maxlength) {
      return `${translateKey(fieldKeyTrad)} ${translateKey('rc-input_must-be-shorter')}`;
    }
    if (errors?.invalidEmail) {
      return `${translateKey(fieldKeyTrad)} ${translateKey('rc-input_email-format')}`;
    }
    return translateKey('error_general_title');
  }

  return '';
}
