import { TransportItemType } from '../../transports';
export function initializeTracesAPI(_unpatchedConsole, internalLogger, _config, metas, transports) {
  internalLogger.debug('Initializing traces API');
  let otel = undefined;
  const initOTEL = (trace, context) => {
    internalLogger.debug('Initializing OpenTelemetry');
    otel = {
      trace,
      context
    };
  };
  const getTraceContext = () => {
    const ctx = otel === null || otel === void 0 ? void 0 : otel.trace.getSpanContext(otel.context.active());
    return !ctx ? undefined : {
      trace_id: ctx.traceId,
      span_id: ctx.spanId
    };
  };
  const pushTraces = payload => {
    try {
      const item = {
        type: TransportItemType.TRACE,
        payload,
        meta: metas.value
      };
      internalLogger.debug('Pushing trace\n', item);
      transports.execute(item);
    } catch (err) {
      internalLogger.error('Error pushing trace\n', err);
    }
  };
  const getOTEL = () => otel;
  const isOTELInitialized = () => !!otel;
  return {
    getOTEL,
    getTraceContext,
    initOTEL,
    isOTELInitialized,
    pushTraces
  };
}
