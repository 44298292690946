import { Injectable, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RCAppInsightsService {
  private readonly router = inject(Router);
  private appInsights: ApplicationInsights;

  constructor() {
    this.initAppInsights();
  }

  public setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }
  public clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  /**
   * Method to manually track events, error, if needed for a more custom experience
   */

  /**
   * Method to manually track the pageView
   * @param name of the page
   * @param url of the page
   */
  public monitorPage(name?: string, url?: string) {
    this.appInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  /**
   * Method to manually track an Metric
   * @param name of the metric
   * @param average value to send
   * @param properties additional properties regarding the metric
   */
  public monitorMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  /**
   * Method to manually track an Exception
   * @param exception
   * @param severityLevel
   */
  public monitorException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({
      exception: exception,
      severityLevel: severityLevel,
    });
  }

  /**
   * Method to manually track an application Trace
   * @param message
   * @param properties additional properties regarding the trace
   */
  public logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }

  /**
   * method to enable cookies tracking, is usefull to call it when user accepts the cookies consents
   * @returns void
   */
  public enableCookiesTracking(): void {
    this.appInsights.getCookieMgr().setEnabled(true);
  }

  private loadCustomTelemetryProperties() {
    this.appInsights.addTelemetryInitializer((envelope) => {
      const item = envelope.baseData;
      item.properties = item.properties || {};
      envelope.tags['ai.cloud.role'] = environment.appInsights.role;
      envelope.tags['ai.cloud.roleInstance'] = environment.appInsights.roleInstance;
      item.properties['ApplicationPlatform'] = 'WEB';
      item.properties['ApplicationName'] = 'My.Application.Name';
    });
  }

  private initAppInsights(): void {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
        disableCookiesUsage: true,
        excludeRequestFromAutoTrackingPatterns: [environment.faroConfig.url],
        correlationHeaderExcludedDomains: [
          environment.faroConfig.url,
          '*.cookielaw.org',
          '*.onetrust.com',
          '*.google-analytics.com',
          '*.doubleclick.net',
        ],
      },
    });
    this.appInsights.loadAppInsights();
    this.loadCustomTelemetryProperties();
    this.buildRouterSubscription();
  }

  private buildRouterSubscription() {
    this.router.events?.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.monitorPage(null, event.urlAfterRedirects);
    });
  }
}
