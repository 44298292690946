import { Language } from '@app/core/models/language';
import { Env } from '@app/shared/utils/enums/environment.enum';
import { LanguageCode } from '@app/shared/utils/enums/language-code.enum';
/**
 * CONSTANTS
 */
export const INIT_LANGUAGE_KEY = 'app:language';
export const PHRASE_SDK_KEY = 'app:phrase_sdk';
export const DEFAULT_LANGUAGE = LanguageCode.enGB;
/**
 * Env package declaration for recurring values
 */
const allEnvsButProd = [Env.DEV, Env.UAT, Env.STG, Env.DEMO];
/*
 * Because we do not build for every locale, we can also manually import the locales.
 * Rollup doesn't support dynamically importing locales. The reason we have them in the LANGUAGES
 * constant is to ensure coupling between the imports and the supported languages. the locale property
 * in the constant is not actually used anywhere. It just needs to exist.
 */

/**
 * LANGUAGES
 */
export const LANGUAGES: Language[] = [
  {
    code: LanguageCode.zhCN,
    phraseId: 'f5564c219846e121e3ef47ed2cf008ad',
    envs: allEnvsButProd,
    weShareLocale: 'Chinese (PRC)',
    locale: import('@angular/common/locales/global/zh'),
  },
  {
    code: LanguageCode.ukUA,
    phraseId: '1484c4becbfb1aa52ce151f562b899c3',
  },
  { code: LanguageCode.zhHant, fallback: LanguageCode.zhTW },
  {
    code: LanguageCode.zhHK,
    phraseId: '8a9951d5e8bd60195f022e41d42e9b6a',
    locale: import('@angular/common/locales/global/zh-Hans-HK'),
  },
  {
    code: LanguageCode.zhTW,
    phraseId: '66528f69b27b1b6403afcb39420f9144',
    weShareLocale: 'Chinese (Taiwan)',
    locale: import('@angular/common/locales/global/zh-Hant'),
  },
  {
    code: LanguageCode.csCZ,
    phraseId: 'f2a017387b7d6f54c8a9a5beda30287e',
    weShareLocale: 'Czech (Czech Republic)',
    locale: import('@angular/common/locales/global/cs'),
  },
  {
    code: LanguageCode.daDK,
    phraseId: '5df869bced798eca2e3d24f23ef16c92',
    weShareLocale: 'Danish (Denmark)',
    locale: import('@angular/common/locales/global/da'),
  },
  {
    code: LanguageCode.nlBE,
    phraseId: '48e2f12259598cc27b228ac7b6ae97a9',
    weShareLocale: 'Dutch (Belgium)',
    locale: import('@angular/common/locales/global/nl-BE'),
  },
  {
    code: LanguageCode.nlNL,
    phraseId: '57392d3bfa082b7fb40eb3ed1b66dc0b',
    weShareLocale: 'Dutch (Netherlands)',
    locale: import('@angular/common/locales/global/nl'),
  },
  {
    code: LanguageCode.enAU,
    phraseId: 'b2b0ea125b453829677029f7c8506c04',
    weShareLocale: 'English (New Zealand)',
    locale: import('@angular/common/locales/global/en-AU'),
  },
  {
    code: LanguageCode.enGB,
    phraseId: '1e152e8a9188aed43d9f1b364a6044d5',
    weShareLocale: 'English (Great Britain)',
    locale: import('@angular/common/locales/global/en-GB'),
  },
  {
    code: LanguageCode.enCA,
    phraseId: 'fae84e6c2651f793bb15c2f029d27684',
    weShareLocale: 'English (Canada)',
    locale: import('@angular/common/locales/global/en-CA'),
  },
  {
    code: LanguageCode.enUS,
    phraseId: 'e8327da82209516251bb6db5099c78f1',
    weShareLocale: 'English (United States)',
    locale: import('@angular/common/locales/global/en'),
  },
  {
    code: LanguageCode.etEE,
    phraseId: '0fc7da2c32d7e333f2848cc3cf04b610',
    weShareLocale: 'Estonian (Estonia)',
    locale: import('@angular/common/locales/global/et'),
  },
  {
    code: LanguageCode.fiFI,
    phraseId: '89e2b4536192921c9dbea891c16eaa24',
    weShareLocale: 'Finnish (Finland)',
    locale: import('@angular/common/locales/global/fi'),
  },
  {
    code: LanguageCode.frFR,
    phraseId: 'aca862fabeccb48aadad63f8e4524098',
    weShareLocale: 'French',
    locale: import('@angular/common/locales/global/fr'),
  },
  {
    code: LanguageCode.frCA,
    phraseId: '7a71cf96fceb520ed04cdbe6f926f8ff',
    weShareLocale: 'French (Canada)',
    locale: import('@angular/common/locales/global/fr-CA'),
  },
  {
    code: LanguageCode.frBE,
    phraseId: 'f912666b19bd4532ce7064f6fa79c1f5',
    weShareLocale: 'French (Belgium)',
    locale: import('@angular/common/locales/global/fr-BE'),
  },
  {
    code: LanguageCode.deDE,
    phraseId: '9c0678801d3558a500c32175fe7c755e',
    weShareLocale: 'German (Germany)',
    locale: import('@angular/common/locales/global/de'),
  },
  {
    code: LanguageCode.elGR,
    phraseId: '1bf3bb1e93c3a471d14a6b2bcce602e0',
    weShareLocale: 'Greek (Greece)',
    locale: import('@angular/common/locales/global/el'),
  },
  {
    code: LanguageCode.huHU,
    phraseId: 'a14d974425286666af10be95784c7543',
    weShareLocale: 'Hungarian (Hungary)',
    locale: import('@angular/common/locales/global/hu'),
  },
  {
    code: LanguageCode.itIT,
    phraseId: '458328648a75a58097d1a810fdfb9e1b',
    weShareLocale: 'Italian (Italy)',
    locale: import('@angular/common/locales/global/it'),
  },
  {
    code: LanguageCode.koKR,
    phraseId: 'd71833d4476cb01ed232c5a760a9bd89',
    weShareLocale: 'Korean (Korea)',
    locale: import('@angular/common/locales/global/ko'),
  },
  {
    code: LanguageCode.lvLV,
    phraseId: 'd1d19ca7e0b165521d846c9e2f9231c3',
    weShareLocale: 'Latvian (Latvia)',
    locale: import('@angular/common/locales/global/lv'),
  },
  {
    code: LanguageCode.ltLT,
    phraseId: '5836c81907a40e10e07993d77392ff3f',
    weShareLocale: 'Lithuanian (Lithuania)',
    locale: import('@angular/common/locales/global/lt'),
  },
  {
    code: LanguageCode.nbNO,
    phraseId: '9111ef566f0f1f6cfc0012900728f6b3',
    weShareLocale: 'Norwegian Bokmål (Norway)',
    locale: import('@angular/common/locales/global/nb'),
  },
  {
    code: LanguageCode.plPL,
    phraseId: '62d8e866f8d6904db5477eaedfac2402',
    weShareLocale: 'Polish (Poland)',
    locale: import('@angular/common/locales/global/pl'),
  },
  {
    code: LanguageCode.ptPT,
    phraseId: 'd19b2a9eacdf19575080e796d04ac709',
    weShareLocale: 'Portuguese (Portugal)',
    locale: import('@angular/common/locales/global/pt'),
  },
  {
    code: LanguageCode.ruRU,
    phraseId: 'ee321af8f193c7cb0aaf8f163ee0c109',
    weShareLocale: 'Russian (Russia)',
    locale: import('@angular/common/locales/global/ru'),
  },
  {
    code: LanguageCode.skSK,
    phraseId: 'd516c46ee704b6fb28fccc06c716b271',
    weShareLocale: 'Slovak (Slovakia)',
    locale: import('@angular/common/locales/global/sk'),
  },
  {
    code: LanguageCode.esAR,
    phraseId: 'b46f2992cd13e1119282ed8f237e59b0',
    weShareLocale: 'Spanish (Argentina)',
    locale: import('@angular/common/locales/global/es-AR'),
  },
  {
    code: LanguageCode.esES,
    phraseId: '2ed68de55592ea1c858c6a52a259e492',
    weShareLocale: 'Spanish (Castilian)',
    locale: import('@angular/common/locales/global/es'),
  },
  {
    code: LanguageCode.esMX,
    phraseId: 'e238756d1a30498ec02c64a04afed71d',
    weShareLocale: 'Spanish (Mexico)',
    locale: import('@angular/common/locales/global/es-MX'),
  },
  {
    code: LanguageCode.svSE,
    phraseId: 'd6535628d90f709bff37a66368d93d62',
    weShareLocale: 'Swedish (Sweden)',
    locale: import('@angular/common/locales/global/sv'),
  },
  {
    code: LanguageCode.trTR,
    phraseId: 'c32b2934fa4a6bc84059ff0dbcb7b14e',
    weShareLocale: 'Turkish (Turkey)',
    locale: import('@angular/common/locales/global/tr'),
  },
  {
    code: LanguageCode.enIE,
    fallback: LanguageCode.enGB,
  },
  {
    code: LanguageCode.enNZ,
    fallback: LanguageCode.enAU,
  },
  {
    code: LanguageCode.roRO,
    phraseId: '20d34f0359957b0d82cdf1b428e7cd67',
    locale: import('@angular/common/locales/global/ro'),
  },
];
export const AVAILABLE_LANGUAGES = LANGUAGES.filter((l) => !!l.phraseId);
export const FALLBACK_LANGUAGES = LANGUAGES.filter((l) => !!l.fallback);
export const VERTICAL_TOP_TO_BOTTOM_LANGUAGES = [
  LanguageCode.koKR,
  LanguageCode.zhCN,
  LanguageCode.zhHant,
  LanguageCode.zhHK,
  LanguageCode.zhTW,
];
