import { Injectable } from '@angular/core';
import packageInfo from '@package.json';
import { environment } from '@env/environment';
import { getWebInstrumentations, initializeFaro, WebVitalsInstrumentation } from '@grafana/faro-web-sdk';

@Injectable({
  providedIn: 'root',
})
export class RCGrafanaService {
  constructor() {
    this.initGrafana();
  }

  private initGrafana(): void {
    if (environment.faroConfig.enabled) {
      initializeFaro({
        instrumentations: [...getWebInstrumentations({ enablePerformanceInstrumentation: true }), new WebVitalsInstrumentation()],
        apiKey: environment.faroConfig.apiKey,
        url: `${environment.faroConfig.url}/collect`,
        app: { version: packageInfo.version, name: 'vetservices-webapp', environment: `rc-df-af-${environment.envName}-frontend` },
      });
    }
  }
}
