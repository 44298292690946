import { Consultation, Weight } from '@app/core/models';
import { createAction, props } from '@ngrx/store';

/**
 * Get consultations actions
 */
/**
 * Action to load consultations with renal information
 */
export const getConsultationsWithoutVisit = createAction('[Pet] Get Consultations Without Visit', props<{ patientId: string }>());
export const getConsultationsWithoutVisitSuccess = createAction(
  '[Pet] Get Consultations Without Visit Success',
  props<{ consultations: Consultation[] }>()
);
export const resetPet = createAction('[Pet] Reset');

export const setPetNotSavedPastWeights = createAction('[Pet] set growth chart not saved past weights', props<{ weight: Weight }>());

export const setPetGrowthChart = createAction(
  '[Pet] set growth chart chartImage and chartWeightsLength',
  props<{ chartImage: string; chartWeights: Weight[] }>()
);

export const resetPetGrowthChartData = createAction('[Pet] reset growth chart data');
