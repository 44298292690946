import { createFeatureSelector, createSelector } from '@ngrx/store';
import { allowanceFeatureKey } from '../allowance-feature-key';
import { AllowanceState } from '../allowance.state';

const selectAllowance = createFeatureSelector<AllowanceState>(allowanceFeatureKey);
/**
 * Blocking errors prevent the user from saving the consultation
 */
export const selectAllowanceBlockingErrors = createSelector(selectAllowance, (state) => state.errors.filter((e) => e.blocking));
/**
 * Non blocking errors don't prevent the user from saving the consultaiton
 */
export const selectAllowanceNonBlockingErrors = createSelector(selectAllowance, (state) => state.errors.filter((e) => !e.blocking));
