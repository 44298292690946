var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { BaseInstrumentation, VERSION } from '@grafana/faro-core';
import { getNavigationTimings } from './navigation';
import { onDocumentReady, performanceObserverSupported } from './performanceUtils';
import { observeResourceTimings } from './resource';
export class PerformanceInstrumentation extends BaseInstrumentation {
  constructor() {
    super(...arguments);
    this.name = '@grafana/faro-web-sdk:instrumentation-performance';
    this.version = VERSION;
  }
  initialize() {
    if (!performanceObserverSupported()) {
      this.logDebug('performance observer not supported. Disable performance instrumentation.');
      return;
    }
    onDocumentReady(() => __awaiter(this, void 0, void 0, function* () {
      const pushEvent = this.api.pushEvent;
      const ignoredUrls = this.getIgnoreUrls();
      const {
        faroNavigationId
      } = yield getNavigationTimings(pushEvent, ignoredUrls);
      if (faroNavigationId != null) {
        observeResourceTimings(faroNavigationId, pushEvent, ignoredUrls);
      }
    }));
  }
  getIgnoreUrls() {
    var _a;
    return (_a = this.transports.transports) === null || _a === void 0 ? void 0 : _a.flatMap(transport => transport.getIgnoreUrls());
  }
}
