/**
 *
 * @license Royal Canin
 * @contributors :
 *  - Amar YASSA
 */

import { createReducer, Action, on } from '@ngrx/store';
import {
  generateNewRecommendationRandomId,
  resetRecommendationRandomIds,
  resetShareRecommendation,
  resetShareRecommendationData,
  setShareRecommendation,
} from './share-recommendation.actions';
import { initialShareRecommendationState, ShareRecommendationState } from './share-recommendation.state';

const _shareRecommendationReducer = createReducer(
  initialShareRecommendationState,
  on(
    setShareRecommendation,
    (state, { data }): ShareRecommendationState => ({
      ...state,
      data,
    })
  ),
  on(resetShareRecommendation, () => initialShareRecommendationState),
  on(resetShareRecommendationData, (state) => {
    return { ...state, data: null };
  }),
  on(generateNewRecommendationRandomId, (state, { id, flowId, randomId }) => {
    const recommendationId = { id, flowId, randomId };
    return {
      ...state,
      randomRecommendationIds: [...state.randomRecommendationIds, recommendationId],
    };
  }),
  on(resetRecommendationRandomIds, (state) => {
    return {
      ...state,
      randomRecommendationIds: [],
    };
  }),
  on(resetShareRecommendation, () => initialShareRecommendationState)
);

export function shareRecommendationReducer(state: ShareRecommendationState | undefined, action: Action) {
  return _shareRecommendationReducer(state, action);
}
