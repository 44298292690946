import { formatDate } from '@angular/common';
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  Clinic,
  Coverage,
  FollowUpSelector,
  NutritionRecommendation,
  PetInfo,
  Product,
  ProductRecommendationBack,
  ProductRecommendationV2,
  ProductV2,
  RationingApiBody,
  SmartRecoApiBody,
} from '@app/core/models';
import { Market } from '@app/core/models/market';
import { RecommendationProgramApiBody } from '@app/core/models/program';
import { Helper, LifestageType, MeasureHelper, MeasurementCodeType, Programs } from '@app/shared/utils';
import { formatNutritionRecommendation } from '@app/shared/utils/static-helpers/rationing-helper';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { BreedPetProfileModel, BreedPetProfileParameters, defaultLifestagesPlanDuration } from '../models/BreedController';
import { ApiService } from './../api.service';
import { environment } from '@env/environment';
import { formatNutritionalAssessment, formatPetInfoToNextVisit } from '@app/core/services/utils/nutrition-helper';
import { USER_SESSION_ANALYTICS_TOKEN } from '../../tracking';
import { NextVisitFromBack, NextVisitData } from '@app/core/models/visit-next';
import { ProductMapperService } from '../product/product-mapper.service';
import { ServicesHelper } from '../../utils';
import { SystemPreferenceCode } from '@app/shared/utils/enums/system-preference-code';

@Injectable()
export class NutritionService extends ApiService {
  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) protected localeId: string,
    private readonly productMapperService: ProductMapperService
  ) {
    super();
  }

  createRecommendation(
    program: Programs,
    body: RationingApiBody,
    productIds: string[],
    withCups: boolean,
    country: string,
    withAnalytics = true
  ): Observable<NutritionRecommendation> {
    let params = new HttpParams();

    //Add other http params
    const parameters = {
      withCups,
      countryCode: country.toLowerCase(),
    };
    Object.keys(parameters).forEach((key) => {
      params = params.append(key, parameters[key]);
    });

    //Create http context
    const context = withAnalytics ? new HttpContext().set(USER_SESSION_ANALYTICS_TOKEN, true) : new HttpContext();

    //Create http headers
    const headersProductIds = productIds.join(';');
    const headers = new HttpHeaders({ product_id: headersProductIds });

    return this.http.post(`${this.pathUrl.digitalRationing}/${program}`, body, { params, headers, context }).pipe(
      map((data) => formatNutritionRecommendation(data)),
      catchError(this.handleError.bind(this))
    );
  }

  getIBW(currentWeight: number, bcs: number): Observable<number> {
    let params = new HttpParams();
    params = params.set('bodyWeight', JSON.stringify(currentWeight));
    params = params.set('bcs', JSON.stringify(bcs));

    return this.http
      .get<{ idealBodyWeight: number }>(this.pathUrl.ibw, { params })
      .pipe(
        map((res) => res.idealBodyWeight),
        catchError(this.handleError.bind(this))
      );
  }

  checkIBWValidity(bcs: number, idealBodyWeight: number, bodyWeight: number): Observable<boolean> {
    let params = new HttpParams();
    params = params.set('bcs', JSON.stringify(bcs));
    params = params.set('idealBodyWeight', JSON.stringify(idealBodyWeight));
    params = params.set('bodyWeight', JSON.stringify(bodyWeight));

    return this.http
      .get<{ idealBodyWeightValidity: boolean }>(this.pathUrl.ibwCheck, { params })
      .pipe(
        map((res) => res.idealBodyWeightValidity),
        catchError(this.handleError.bind(this))
      );
  }

  public checkTBWValidity(speciesKey: LifestageType, targetBodyWeight: number): Observable<boolean> {
    let params = new HttpParams();
    params = params.set('speciesKey', speciesKey).set('targetBodyWeight', targetBodyWeight);

    return this.http
      .get<{ targetBodyWeightValidity: boolean }>(this.pathUrl.tbwCheck, { params })
      .pipe(
        map((res) => res.targetBodyWeightValidity),
        catchError(this.handleError.bind(this))
      );
  }

  /**
   * [POST]
   */
  planNextVisit(
    petInfo: PetInfo,
    firstDate: Date,
    program: Programs,
    currentBigMeasurementUnit: MeasurementCodeType,
    currentSystemPreferenceCode: SystemPreferenceCode,
    nextDate?: Date
  ): Observable<NextVisitData> {
    const startDate = firstDate ?? new Date();
    let params = new HttpParams()
      .set('startDate', new Date(startDate).toISOString())
      .set('currentDate', new Date().toISOString())
      .set('program', program);

    if (nextDate) params = params.set('nextDate', new Date(nextDate).toISOString());

    const body = formatPetInfoToNextVisit(petInfo, currentBigMeasurementUnit);

    return this.http
      .post<NextVisitFromBack[]>(this.pathUrl.planNextVisit, body, { params })
      .pipe(
        map((response) =>
          MeasureHelper.formattedExpectedWeightFromBackToFront(response, currentBigMeasurementUnit, currentSystemPreferenceCode)
        ),
        catchError(this.handleError.bind(this))
      );
  }

  recommendationPrograms(body: RecommendationProgramApiBody): Observable<FollowUpSelector> {
    return this.http.post<FollowUpSelector>(this.pathUrl.recommendationPrograms, body).pipe(catchError(this.handleError.bind(this)));
  }

  getSmartRecoProductsV2(
    body: SmartRecoApiBody,
    clinic: Clinic,
    market: Market,
    systemPreference: SystemPreferenceCode,
    limit?: number
  ): Observable<{ coverage: Coverage; product: Product }[]> {
    const parameters = {
      locale: Helper.catalogLang(this.localeId, market),
      countryCode: clinic?.companyAddress?.country.toLowerCase(),
    };
    if (limit) {
      parameters['limit'] = limit;
    }
    const params: HttpParams = this.params(parameters);
    if (environment.isStandalone) {
      return this.http
        .post<any>(this.pathUrl.smartRecoProductsSrs(), body, { params })
        .pipe(map((products) => ServicesHelper.filterMapProductRecommendations(products, systemPreference)));
    } else {
      return this.http
        .post<any>(this.pathUrl.smartRecoProductsV2(clinic?.id), body, { params })
        .pipe(
          map((dto: { results: { coverage: Coverage; product: ProductV2 }[] }) => dto.results),
          map((recommendations) => {
            return recommendations
              .filter(({ product }) => this.productMapperService.isProductV2Complete(product))
              .map((reco) => {
                return {
                  ...reco,
                  coverage: reco.coverage,
                  product: this.productMapperService.mapProductV2ToProduct(reco.product, systemPreference),
                };
              })
              .filter((reco) => reco.product.packages?.length > 0);
          })
        );
    }
  }

  /**
   * [GET]
   */
  getSmartRecoNutritionalAssessment(body: SmartRecoApiBody, clinic: Clinic, market: Market, limit?: number): Observable<any> {
    const parameters = {
      locale: Helper.catalogLang(this.localeId, market),
      countryCode: clinic?.companyAddress?.country.toLowerCase(),
    };
    const params = this.params(parameters);
    const path = this.pathUrl.nutritionalAssessment();
    return this.http
      .post<any>(path, body, { params })
      .pipe(
        tap((assessment) => {
          if (!assessment || assessment.macroNutrients?.length < 1 || assessment.otherNutrients?.length < 1) {
            catchError(this.handleError.bind(this));
          }
        }),
        map((data) => formatNutritionalAssessment(data)),
        catchError(this.handleError.bind(this))
      );
  }

  /**
   * [GET]
   */
  getSmartRecoNotRecommendedProductV2(id: string, body: SmartRecoApiBody, clinic: Clinic): Observable<ProductRecommendationV2> {
    const parameters = {
      countryCode: clinic?.companyAddress?.country.toLowerCase(),
    };

    const params: HttpParams = this.params(parameters);
    return this.http
      .post<ProductRecommendationBack>(this.pathUrl.smartRecoNotRecommendedProductV2(clinic.id, id), body, { params })
      .pipe(catchError(this.handleError.bind(this)));
  }

  getBreedPetProfile = ({
    breedCode,
    dateOfBirth,
    genderCode,
    lifestagesPlanDuration,
  }: BreedPetProfileParameters): Observable<BreedPetProfileModel> => {
    return this.http.get<BreedPetProfileModel>(this.pathUrl.petProfile(breedCode), {
      params: {
        ...(dateOfBirth && {
          dateOfBirth: formatDate(dateOfBirth, 'yyyy-MM-dd', this.localeId),
        }),
        ...(genderCode && { genderCode }),
        lifestagesPlanDuration: lifestagesPlanDuration?.toString() || defaultLifestagesPlanDuration,
      },
    });
  };
}
