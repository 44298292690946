import { ModuleNameEnum, ModuleTypeEnum, PageTypeEnum } from '../types/enums';
import { GTMPageView, ModuleNameArg } from '../types/interfaces';

export const petDetails = ({ name }: ModuleNameArg): GTMPageView => ({
  page: {
    type: PageTypeEnum.PET_DETAILS,
  },
  module: {
    name,
    type: ModuleTypeEnum.CONSULTATION,
  },
});

export const nutritionalRecommendation = ({ name }: ModuleNameArg): GTMPageView => ({
  page: {
    type: PageTypeEnum.NUTRITIONAL_RECOMMENDATION,
  },
  module: {
    name,
    type: ModuleTypeEnum.CONSULTATION,
  },
});

export const productSelection = ({
  name,
  type = ModuleTypeEnum.CONSULTATION,
}: {
  name: ModuleNameEnum;
  type?: ModuleTypeEnum;
}): GTMPageView => ({
  page: {
    type: PageTypeEnum.PRODUCT_SELECTION,
  },
  module: {
    name,
    type,
  },
});

export const orderDetails = ({
  name,
  type = ModuleTypeEnum.CONSULTATION,
}: {
  name: ModuleNameEnum;
  type?: ModuleTypeEnum;
}): GTMPageView => ({
  page: {
    type: PageTypeEnum.CHECKOUT,
  },
  module: {
    name,
    type,
  },
});

export const shareReco = ({ name }: ModuleNameArg): GTMPageView => ({
  page: {
    type: PageTypeEnum.SHARE_RECOMMENDATION,
  },
  module: {
    name,
  },
});

export const weightManagement = ({ name, type }: { name: ModuleNameEnum; type: ModuleTypeEnum }): GTMPageView => ({
  page: {
    type: PageTypeEnum.PET_WEIGHT_MANAGEMENT,
  },
  module: {
    name,
    type,
  },
});

export const planNextVisitPageScreenDisplay = (): GTMPageView => ({
  page: { type: PageTypeEnum.PLAN_NEXT_VISIT_PAGE },
  module: { name: ModuleNameEnum.WEIGHT_MANAGEMENT },
});
