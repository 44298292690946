import { Tool } from '@app/shared/utils';
import { createSelector } from '@ngrx/store';
import { PetState } from '.';
import { AppState } from '..';

const petState = (state: AppState) => state.pet;

export const selectRenalConsultationsWithoutVisit = createSelector(petState, (state: PetState) =>
  state.consultationsWithoutVisit.filter((item) => item.tool === Tool.RenalDetect)
);
export const selectGrowthChartData = createSelector(petState, (state: PetState) => state.growChartData);
