/**
 * Tail based throttle which caches the args of the last call and updates
 */
export function throttle(callback, delay) {
  let pause = false;
  let lastPending;
  const timeoutBehavior = () => {
    if (lastPending == null) {
      pause = false;
      return;
    }
    callback(...lastPending);
    lastPending = null;
    setTimeout(timeoutBehavior, delay);
  };
  return (...args) => {
    if (pause) {
      lastPending = args;
      return;
    }
    callback(...args);
    pause = true;
    setTimeout(timeoutBehavior, delay);
  };
}
