/**
 * Term scope enum
 */
export enum TermScope {
  APP = 'app',
  RD = 'Renal Detect',
  MUF = 'Multifunction',
  PRIVACY_KR_COLLECT = 'privacy_kr_collect',
  PRIVACY_KR_TRANSFER = 'privacy_kr_transfer',
  APP_IT = 'app_it',
  APP_TR = 'app_tr',
}
/**
 * Term interface
 */
export interface Term {
  Id: string;
  name: string;
  url: string;
  scope: TermScope;
  options?: {
    additionalInfos?: {
      url?: string;
      translationKeyDesc: string;
      translationKeyUrl?: string;
    }[];
  };
}
