import { RangeType, SpeciesCode, Tool, ViewType } from '@app/shared/utils/enums';
import { Injectable } from '@angular/core';
import { FetchProductDataFilters } from '@app/core/models/fetch-product-data-filters';
import {
  ProductCatalogFilterBreedSizevalues,
  ProductCatalogFilterLifestageValues,
  ProductCatalogFilterValues,
} from '@app/shared/components/product-catalog-filters/product-catalog-filters';
import { Store } from '@ngrx/store';
import { AppState } from '..';
import {
  addPricesToUpdate,
  getAllProducts,
  resetProductsData,
  setProductFilters,
  setShowPriceListActions,
  updatePrices,
  updateSelectedCatalogViewType,
  resetProductsPricesState,
  setForcedProductFilters,
  updateSelectedCategories,
  setSelectedProduct,
  resetSelectedProduct,
  setSelectedDryProductPackId,
  setSelectedWetProductPackId,
} from './products.actions';
import {
  selectCurrentPillar,
  selectCurrentViewType,
  selectFilteredProducts,
  selectGetAllProductsFail,
  selectSelectedCategories,
  selectProductsLoading,
  selectFilteredProductsLength,
  selectPriceListToUpdate,
  selectShowPriceListActions,
  selectPriceListUpdateLoading,
  selectFiltersValues,
  selectHasWetInAllProducts,
  selectHasDryInAllProducts,
  selectFilteredProductsFlatten,
  selectMufProductsBySpecie,
  selectSelectedPackIds,
  selectSelectedProducts,
  selectSelectedDryProduct,
  selectSelectedWetProduct,
  selectSelectedDryPack,
  selectSelectedWetPack,
  selectFilteredCAProductsFlatten,
  selectProductBookCategories,
} from './products.selectors';
import { ProductPriceEdit, Product } from '@app/core/models';
import { TerritoryType } from '@app/shared/utils/enums/territoryType';
import { selectRouter } from '../core';

@Injectable()
export class ProductsFacade {
  selectedCategories$ = this.store$.select(selectSelectedCategories);
  currentViewType$ = this.store$.select(selectCurrentViewType);
  currentPillar$ = this.store$.select(selectCurrentPillar);
  filteredProducts$ = this.store$.select(selectFilteredProducts);
  filteredCAProductsFlatten$ = this.store$.select(selectFilteredCAProductsFlatten);
  filteredProductsFlatten$ = this.store$.select(selectFilteredProductsFlatten);
  priceListToUpdate$ = this.store$.select(selectPriceListToUpdate);
  priceListUpdateLoading$ = this.store$.select(selectPriceListUpdateLoading);
  selectShowPriceListActions$ = this.store$.select(selectShowPriceListActions);
  selectFilteredProductsLength$ = this.store$.select(selectFilteredProductsLength);
  productsLoading$ = this.store$.select(selectProductsLoading);
  filtersValue$ = this.store$.select(selectFiltersValues);
  hasWetProductInAllProducts$ = this.store$.select(selectHasWetInAllProducts);
  hasDryProductInAllProducts$ = this.store$.select(selectHasDryInAllProducts);
  getAllProductsFail$ = this.store$.select(selectGetAllProductsFail);
  mufProductsBySpecie$ = this.store$.select(selectMufProductsBySpecie);
  selectedProducts$ = this.store$.select(selectSelectedProducts);
  selectedPackIds$ = this.store$.select(selectSelectedPackIds);
  selectedDryProduct$ = this.store$.select(selectSelectedDryProduct);
  selectedWetProduct$ = this.store$.select(selectSelectedWetProduct);
  selectedDryPack$ = this.store$.select(selectSelectedDryPack);
  selectedWetPack$ = this.store$.select(selectSelectedWetPack);
  selectRouter$ = this.store$.select(selectRouter);
  selectProductBookCategories$ = (isSolProductsInVetEnable: boolean) => {
    return this.store$.select(selectProductBookCategories(isSolProductsInVetEnable));
  };

  constructor(private store$: Store<AppState>) {}

  setViewType(viewType: ViewType): void {
    this.store$.dispatch(updateSelectedCatalogViewType({ currentViewType: viewType }));
  }

  getAllProducts(filterParams: FetchProductDataFilters, openFilledCategories?: boolean, tool?: Tool): void {
    this.store$.dispatch(getAllProducts({ filterParams, openFilledCategories, tool }));
  }

  resetProductsData(): void {
    this.store$.dispatch(resetProductsData());
  }

  updatePrices(withTracking = true): void {
    this.store$.dispatch(updatePrices({ withTracking }));
  }

  setShowPriceListActions(value: boolean): void {
    this.store$.dispatch(setShowPriceListActions({ value }));
  }

  resetProductsPricesState(): void {
    this.store$.dispatch(resetProductsPricesState());
  }

  addPricesToUpdate(prices: ProductPriceEdit[]): void {
    this.store$.dispatch(addPricesToUpdate({ prices }));
  }

  setProductFilters(filtersValues: ProductCatalogFilterValues & ProductCatalogFilterBreedSizevalues): void {
    this.store$.dispatch(setProductFilters({ filtersValues }));
  }

  setForcedProductFilters(specieCode?: SpeciesCode, lifestageType?: ProductCatalogFilterLifestageValues): void {
    this.store$.dispatch(setForcedProductFilters({ specieCode: specieCode || null, lifestageType: lifestageType || null }));
  }

  updateSelectedCategories(selectedCategories: (TerritoryType | RangeType)[] | null): void {
    this.store$.dispatch(updateSelectedCategories({ selectedCategories }));
  }

  setSelectedProduct({ product, packId }: { product: Product; packId?: string }, allowMultipleSelection = false): void {
    this.store$.dispatch(setSelectedProduct({ selectedProduct: { product, packId }, allowMultipleSelection }));
  }

  setSelectedDryProductPackId(packId: string): void {
    this.store$.dispatch(setSelectedDryProductPackId({ packId }));
  }

  setSelectedWetProductPackId(packId: string): void {
    this.store$.dispatch(setSelectedWetProductPackId({ packId }));
  }

  resetSelectedProduct(productId: string): void {
    this.store$.dispatch(resetSelectedProduct({ productId }));
  }
}
