/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Localization } from '@app/core/models/localization';
import { ApiService } from '@app/core/services/network/api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { getLocalization, getLocalizationSuccess } from '../core.actions';

@Injectable()
export class GetLocalizationEffects {
  /**
   * Get localization fail effect
   */
  constructor(private actions$: Actions, private http: HttpClient, private apiService: ApiService) {}

  /**
   * Get localization data
   */
  private getLocalization = (): Observable<Localization> =>
    this.http.get<Localization>(this.apiService.pathUrl.blobStorageData('localization'));

  /**
   * Get localization effect
   */
  getLocalization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLocalization),
      switchMap(() => this.getLocalization()),
      switchMap((localization) => [getLocalizationSuccess({ localization })])
    )
  );
}
