import { Injectable } from '@angular/core';
import { Benefit, LocalBenefit, Pack, PackFormatV2, Package, Product, ProductV2 } from '@app/core/models';
import { Helper, MeasureHelper, MeasurementCodeType, ProductPillar, ProductPrograms, ProductType } from '@app/shared/utils';
import { SystemPreferenceCode } from '@app/shared/utils/enums/system-preference-code';

@Injectable({
  providedIn: 'root',
})
export class ProductMapperService {
  mapProductV2ToProduct(productV2: ProductV2, systemPreference: SystemPreferenceCode): Product {
    return {
      id: productV2._id.toString(),
      mainItemId: productV2.world_wide_main_item,
      speciesCode: productV2.species?.at(0).code,
      image: productV2.bag_image?.url,
      name: productV2.product_title,
      energy: productV2.reference_energy_value_per_weight?.amount,
      energyCategory: productV2.reference_energy_category?.code,
      programs: [productV2.vet_program?.code?.replaceAll('_', ' ') as ProductPrograms].filter((program) => !!program),
      lifestagesCode: productV2.lifestage?.map((lifeStage) => lifeStage.code) ?? [],
      specificNeeds: productV2.specific_needs?.map((s) => s.code) ?? [],
      rangeCategory: productV2.range?.label,
      range: productV2.range?.code,
      retailChannel: productV2.retail_channel,
      recommendedFor: productV2.recommended_for,
      notRecommendedFor: productV2.not_recommended_for,
      territory: productV2.territory?.code?.replaceAll('_', ' '),
      productType: productV2.technology?.code,
      packages: this.mapPackages(
        this.filterPacksForWetProducts(productV2.packs, productV2.technology.code === ProductType.Wet),
        systemPreference
      ),
      relatedProductsCode: productV2.mix_feeding ?? [],
      density: productV2.density?.toString(),
      weightCategoriesCode: productV2.pet_size?.map((size) => size.code)?.map(Helper.cleanSizeCategory) ?? [],
      pillar: productV2.product_pillar?.at(0).code,
      benefits: productV2.local_benefits?.length ? this.mapBenefits(productV2.local_benefits) : [],
      composition: productV2.composition?.length ? this.mapCompositions(productV2.composition) : [],
      clinicalAlliance: productV2.clinical_alliance ?? false,
      wms_code: productV2.wms_code,
      description: [
        productV2.product_description_short_audience_veterinary,
        productV2.product_description_short_audience_pet_owner,
        productV2.product_description_short_audience_breeder,
      ]
        .filter((desc) => !!desc)
        .slice(0, 1),
    };
  }

  isProductV2Complete(productV2: ProductV2): boolean {
    const stringIsNull = (str: string) => !str || str === '';
    const vetWithTerritory = productV2.product_pillar.some((pillar) => pillar.code === ProductPillar.VET) ? !!productV2.territory : true;
    return (
      !!productV2.lifestage &&
      productV2.lifestage.length > 0 &&
      !!productV2.vet_program &&
      !!productV2.technology &&
      [ProductType.Dry, ProductType.Wet].includes(productV2.technology.code) &&
      !!productV2.species &&
      !stringIsNull(productV2.product_title) &&
      !!productV2.reference_energy_value_per_weight &&
      !!productV2.packs &&
      productV2.packs.filter((p) => !!p).length > 0 &&
      vetWithTerritory
    );
  }

  private mapPackages(packs: Pack[], currentSystemPreferenceCode: SystemPreferenceCode): Package[] {
    return packs.map((pack) => {
      const weight = +pack.weight.replace(',', '.');
      const weightInG = MeasureHelper.convertWeight(weight, pack.measurementUnitCode, MeasurementCodeType.Gram).measure;
      const convertedWeightInVetPreference = MeasureHelper.convertWeightWithCustomMapping(
        +weight,
        pack.measurementUnitCode,
        null,
        currentSystemPreferenceCode
      );
      const convertedWeightTxt = MeasureHelper.weightToString(convertedWeightInVetPreference);

      return {
        sCode: pack.scode,
        ean: pack.ean,
        convertedWeight: convertedWeightInVetPreference,
        measurementUnitCode: pack.measurementUnitCode,
        weight: weight,
        weightInG: weightInG,
        type: this.mapPackFormats(pack.format),
        text: convertedWeightTxt,
        productPrice: pack.productPrice,
      };
    });
  }

  private mapPackFormats(format: PackFormatV2): string {
    switch (format) {
      case PackFormatV2.POUCH:
        return 'pouch';
      case PackFormatV2.CAN:
        return 'can';
      default:
        return format;
    }
  }

  private mapBenefits(benefits: LocalBenefit[]): Benefit[] {
    return benefits.map((benefit) => ({
      title: benefit.label,
      description: benefit.benefit_description,
      image: benefit.pictogram && `${benefit.pictogram}?w=30&auto=compress`,
    }));
  }

  private mapCompositions(composition: { [key: string]: string }[]): string[] {
    return composition.map((c) => Object.values(c).at(0));
  }

  private filterPacksForWetProducts(packs: Pack[], isWet: boolean): Pack[] {
    return packs
      .filter((pack) => {
        return !isWet || (isWet && pack.format === PackFormatV2.POUCH) || pack.format === PackFormatV2.CAN;
      })
      .reduce((acc, pack) => {
        const sameWeightIndex: number = acc.map((pack) => pack.weight).findIndex((weight) => weight === pack.weight);
        if (sameWeightIndex === -1 || pack.format == 'starter_kit') {
          acc.push(pack);
        } else if (acc[sameWeightIndex].number_of_unit < pack.number_of_unit) {
          acc[sameWeightIndex] = pack;
        }
        return acc;
      }, [] as Pack[]);
  }
}
