import { TransportItemType } from '../../transports';
import { deepEqual, defaultLogLevel, getCurrentTimestamp, isNull } from '../../utils';
export function initializeLogsAPI(_unpatchedConsole, internalLogger, config, metas, transports, tracesApi) {
  internalLogger.debug('Initializing logs API');
  let lastPayload = null;
  const pushLog = (args, {
    context,
    level,
    skipDedupe,
    spanContext
  } = {}) => {
    try {
      const item = {
        type: TransportItemType.LOG,
        payload: {
          message: args.map(arg => {
            try {
              return String(arg);
            } catch (err) {
              return '';
            }
          }).join(' '),
          level: level !== null && level !== void 0 ? level : defaultLogLevel,
          context: context !== null && context !== void 0 ? context : {},
          timestamp: getCurrentTimestamp(),
          trace: spanContext ? {
            trace_id: spanContext.traceId,
            span_id: spanContext.spanId
          } : tracesApi.getTraceContext()
        },
        meta: metas.value
      };
      const testingPayload = {
        message: item.payload.message,
        level: item.payload.level,
        context: item.payload.context
      };
      if (!skipDedupe && config.dedupe && !isNull(lastPayload) && deepEqual(testingPayload, lastPayload)) {
        internalLogger.debug('Skipping log push because it is the same as the last one\n', item.payload);
        return;
      }
      lastPayload = testingPayload;
      internalLogger.debug('Pushing log\n', item);
      transports.execute(item);
    } catch (err) {
      internalLogger.error('Error pushing log\n', err);
    }
  };
  return {
    pushLog
  };
}
