import { PetProfileFormValues, GrowthChartStandardWeightsBodyApi } from '@app/core/models';
import { WeightFormValue } from '@app/shared/components/weight-form/weight-form';
import { LifestageType, MeasureHelper, MeasurementCodeType, SpeciesCode } from '@app/shared/utils';
import { SystemPreferenceCode } from '../enums/system-preference-code';

export function formatDataToGrowthChartStandardWeightsBodyApi(
  profile: Pick<PetProfileFormValues, 'speciesCode' | 'birthdate' | 'gender'>,
  weight: Pick<WeightFormValue, 'targetWeight'>,
  currentBigMeasurementUnit: MeasurementCodeType
): GrowthChartStandardWeightsBodyApi {
  return {
    speciesCode: profile.speciesCode,
    genderCode: profile.gender,
    birthDate: profile.birthdate,
    targetWeight: {
      measure: weight.targetWeight,
      measureUnit: currentBigMeasurementUnit,
    },
    outputMeasureUnit: currentBigMeasurementUnit,
    withReco: true,
    withPetWeights: true,
  };
}

export function getMinMaxBetweenTwoDate(date1: Date, date2: Date): { min: Date; max: Date } {
  return date1 > date2 ? { min: date2, max: date1 } : { min: date1, max: date2 };
}

export function differenceInWeeks(date1: Date, date2: Date): number {
  return Math.abs(Math.round((date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24 * 7)));
}

export function petHasGrowthChart(
  lifestage: LifestageType,
  speciesCode: SpeciesCode,
  adultTargetWeight: number | null,
  currentSystemPreferenceCode: SystemPreferenceCode
): boolean {
  if (adultTargetWeight === null || lifestage === null || speciesCode === null) {
    return false;
  }
  const isImperialSystem = currentSystemPreferenceCode === SystemPreferenceCode.imperialSystemCode;
  const adultTargetWeightInKg = isImperialSystem
    ? MeasureHelper.convertWeight(adultTargetWeight, MeasurementCodeType.Pound, MeasurementCodeType.Kilogram).measure
    : adultTargetWeight;
  const isPuppyOrKitten = lifestage === LifestageType.Puppy || lifestage === LifestageType.Kitten;
  const isWeightOffChart =
    (speciesCode === SpeciesCode.Dog && adultTargetWeightInKg > 40) ||
    (speciesCode === SpeciesCode.Cat && (adultTargetWeightInKg < 2 || adultTargetWeightInKg > 7));
  return isPuppyOrKitten && !isWeightOffChart;
}
