import { UAParser } from 'ua-parser-js';
export const browserMeta = () => {
  const parser = new UAParser();
  const {
    name,
    version
  } = parser.getBrowser();
  const {
    name: osName,
    version: osVersion
  } = parser.getOS();
  const userAgent = parser.getUA();
  const language = navigator.language;
  const mobile = navigator.userAgent.includes('Mobi');
  const brands = getBrands();
  const unknown = 'unknown';
  return {
    browser: {
      name: name !== null && name !== void 0 ? name : unknown,
      version: version !== null && version !== void 0 ? version : unknown,
      os: `${osName !== null && osName !== void 0 ? osName : unknown} ${osVersion !== null && osVersion !== void 0 ? osVersion : unknown}`,
      userAgent: userAgent !== null && userAgent !== void 0 ? userAgent : unknown,
      language: language !== null && language !== void 0 ? language : unknown,
      mobile,
      brands: brands !== null && brands !== void 0 ? brands : unknown
    }
  };
  function getBrands() {
    if (!name || !version) {
      return undefined;
    }
    if ('userAgentData' in navigator && navigator.userAgentData) {
      // userAgentData in experimental (only Chrome supports it) thus TS does not ship the respective type declarations
      return navigator.userAgentData.brands;
    }
    return undefined;
  }
};
