import { CreatePatientPopinFormValues } from './../../components/create-patient-popin/create-patient-popin';
import { Patient, PatientSearchInterfaces, Pet, PetOwner, PetProfileFormValues } from '@app/core/models';
import { PetOwnerFormValues } from '@app/shared/components/pet-owner-form/pet-owner-form';
import { Helper, LifestageType, SpeciesCode } from '@app/shared/utils';
import { ReproductionStatusCode } from '@app/shared/utils/enums/reproduction-status-code.enum';
import { translateKey } from '@app/shared/utils/static-helpers/translate';

export function getWLPAvailabilityError(lifeStage?: LifestageType): string | null {
  if (Helper.YOUNG_LIFESTAGES.includes(lifeStage)) {
    return translateKey('identification_error_wlp');
  }
  return null;
}

export function getWLPAvailabilityWarning(reproductionStatusCode?: ReproductionStatusCode): string | null {
  if ([ReproductionStatusCode.Gestation, ReproductionStatusCode.Lactation].includes(reproductionStatusCode)) {
    return translateKey('alert_female-gestation-lactation');
  }
  return null;
}

export function getRDAvailabilityError(speciesCode?: SpeciesCode, date?: Date): string | null {
  if (speciesCode !== SpeciesCode.Cat) {
    return translateKey('identification_error_rd');
  } else if (Helper.getPetAgeObject(date)?.years < 7) {
    return translateKey('rd_warning_pet-too-young');
  }
  return null;
}

export function getMUFAvailabilityError(lifeStage?: LifestageType): string | null {
  if (Helper.YOUNG_LIFESTAGES.includes(lifeStage)) {
    return translateKey('personalized-bag_junior-error');
  }
  return null;
}

export function formatPatientSearch(patient: any): PatientSearchInterfaces {
  return {
    owner: {
      addresses: patient.contact.addresses || [],
      email: patient.contact.email || '',
      familyName: patient.contact.familyName || '',
      givenName: patient.contact.givenName || '',
      _id: patient.contact._id || '',
    },
    pet: patient.pet || {},
    id: patient.id || '',
    petId: patient.petId || '',
    lastConsultation: patient.lastConsultation
      ? {
          date: patient.lastConsultation.date,
          id: patient.lastConsultation.id,
          patientId: patient.lastConsultation.patientId,
        }
      : undefined,
  };
}

export function formatProfileFormValuesToPet(profile: PetProfileFormValues): Pet {
  return {
    name: profile.name,
    genderCode: profile.gender,
    speciesCode: profile.speciesCode,
    breedCode: profile.breed,
    birth: {
      date: profile.birthdate,
    },
    neutered: profile.neutered,
    reproductionStatusCode: profile.reproductionStatus,
    lifeStage: profile.lifestage,
  };
}

export function formatOwnerFormValuesToOwner(owner: PetOwnerFormValues | CreatePatientPopinFormValues): PetOwner {
  return (
    owner.existingOwner || {
      familyName: owner.familyName,
      givenName: owner.givenName,
      email: owner.email,
      telephone: 'telephone' in owner ? owner.telephone : '',
    }
  );
}

export function formatOwnerToOwnerFormValues(owner: PetOwner, isExistingPetOwner = true): PetOwnerFormValues {
  return {
    familyName: owner?.familyName,
    givenName: owner?.givenName,
    telephone: owner?.telephone,
    email: owner?.email,
    existingOwner: isExistingPetOwner ? owner : null,
    consent: false,
    noEmail: false,
  };
}

export function isPetOwnerComplete(petOwner: PetOwner): boolean {
  return !!petOwner && !!petOwner.familyName && !!petOwner.givenName && !!petOwner.email;
}

export const buildPatientApiBody = (pet: Pet, petOwner?: PetOwner, clinicId?: string, vetId?: string) => {
  const newPet = {
    breedCode: pet?.breedCode,
    genderCode: pet?.genderCode,
    neutered: pet?.neutered,
    petActivityCode: pet?.petActivityCode,
    reproductionStatusCode: pet?.reproductionStatusCode,
    weight: pet?.weight,
    idealBodyWeight: pet?.idealBodyWeight,
    vetId: vetId,
    birth: pet?.birth,
    name: pet?.name,
    photo: pet?.photo,
    speciesCode: pet?.speciesCode,
  };
  return {
    vetId: vetId,
    clinicId,
    contactId: petOwner?.id,
    ...(pet?.id ? { petId: pet?.id } : { pet: newPet }),
  } as Patient;
};
