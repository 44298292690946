import { LocaleContent } from '@app/shared/data/locale-content';
import { BreedSize, MeasurementCodeType, MeasurementSubstanceTypeCodeEnum, SpeciesCode, SubstanceTypeEnum } from '@app/shared/utils/enums';

export const speciesContent: LocaleContent[] = [
  {
    translationCode: 'specie-dog',
    code: SpeciesCode.Dog,
    text: 'Dog',
    localizedText: $localize`:@@specie-dog:`,
  },
  {
    translationCode: 'specie-cat',
    code: SpeciesCode.Cat,
    text: 'Cat',
    localizedText: $localize`:@@specie-cat:`,
  },
];
export const substanceContent: LocaleContent[] = [
  {
    code: SubstanceTypeEnum.BloodUrea,
    translationCode: 'rd-select-blood-urea',
    text: 'Blood Urea',
    localizedText: $localize`:@@rd-select-blood-urea:`,
  },
  {
    code: SubstanceTypeEnum.BloodUreaNitro,
    translationCode: 'rd-select-blood-urea-nitro',
    text: 'Blood Urea Nitrogen',
    localizedText: $localize`:@@rd-select-blood-urea-nitro:`,
  },
];
export const lifestageTypeTranslationCodes = [
  { code: 'baby', value: 'lifestage-baby' },
  { code: 'kitten', value: 'lifestage-kitten' },
  { code: 'puppy', value: 'lifestage-puppy' },
  { code: 'adult', value: 'lifestage-adult' },
  { code: 'mature', value: 'lifestage-mature' },
  { code: 'ageing', value: 'lifestage-ageing' },
  { code: 'senior', value: 'lifestage-ageing' },
  { code: 'junior', value: 'lifestage-junior' },
  { code: 'All', value: 'lifestage-all' },
];

export const sizesTranslationCodes = [
  { code: BreedSize.XSmall, value: 'size-x-small' },
  { code: BreedSize.Small, value: 'size-mini' },
  { code: BreedSize.Medium, value: 'size-medium' },
  { code: BreedSize.Large, value: 'size-maxi' },
  { code: BreedSize.XLarge, value: 'size-giant' },
];

export const measurementUnitsSuffixesTranslationCodes = [
  { code: MeasurementCodeType.Gram, value: 'measurement_gram-suffix' },
  { code: MeasurementCodeType.Kilogram, value: 'measurement_kilogram-suffix' },
  { code: MeasurementCodeType.Ounce, value: 'measurement_ounce-suffix' },
  { code: MeasurementCodeType.Pound, value: 'measurement_pound-suffix' },
  { code: MeasurementCodeType.GramPerMegacalory, value: 'measurement_g-mcal' },
  { code: MeasurementCodeType.MilligramPerMegacalory, value: 'measurement_mg-mcal' },
  { code: MeasurementCodeType.KilocaloryPerKilogram, value: 'measurement_kcal-kg' },
];

export const measurementCreatinineContent: LocaleContent[] = [
  {
    code: MeasurementSubstanceTypeCodeEnum.mgPerL,
    text: 'mg/L',
    translationCode: 'measurement_mg-l',
    localizedText: $localize`:@@measurement_mg-l:`,
  },
  {
    code: MeasurementSubstanceTypeCodeEnum.mgPerDl,
    text: 'mg/dl',
    translationCode: 'measurement_mg-dl',
    localizedText: $localize`:@@measurement_mg-dl:`,
  },
  {
    code: MeasurementSubstanceTypeCodeEnum.microMolPerL,
    text: 'µmol/L',
    translationCode: 'measurement_micro-mol-l',
    localizedText: $localize`:@@measurement_micro-mol-l:`,
  },
];
export const measurementUreaContent: LocaleContent[] = [
  {
    code: MeasurementSubstanceTypeCodeEnum.gPerL,
    text: 'g/L',
    translationCode: 'measurement_g-l',
    localizedText: $localize`:@@measurement_g-l:`,
  },
  {
    code: MeasurementSubstanceTypeCodeEnum.mgPerDl,
    text: 'mg/dl',
    translationCode: 'measurement_mg-dl',
    localizedText: $localize`:@@measurement_mg-dl:`,
  },
  {
    code: MeasurementSubstanceTypeCodeEnum.mmolPerL,
    text: 'mmol/L',
    translationCode: 'measurement_milli-mol-l',
    localizedText: $localize`:@@measurement_milli-mol-l:`,
  },
];
