import { MeasurementCodeType } from '@app/shared/utils';

export interface ChartConsultation {
  weight: number;
  weightMax?: number;
  weightMin?: number;
  targetWeight?: number;
  date: ChartDate;
}

interface ChartDate {
  graph: string;
  list: string;
}

export interface WeightChart {
  measure: number;
  measureUnit: MeasurementCodeType | string;
  weightDate: Date | string;
  svgSize: { [key in SvgSize]?: boolean };
}

export enum SvgSize {
  L = 'L',
  M = 'M',
  S = 'S',
  EQUAL = 'EQUAL',
  MINI = 'MINI',
}
