import { Action } from '@ngrx/store';
import { Vet, VetUser } from '@app/core/models';
/**
 * Ngrx old syntax, do not add actions here
 */
export enum VetActionTypes {
  /**
   * Set vet actioon,
   * Should only be used in RegisterConnectComponent
   * Deprecated, will be removed
   */
  SetVetActionType = '[Vet] Set Vet',

  // Update vet
  UpdateVetActionType = '[Vet] Update Vet',
  UpdateVetSuccessActionType = '[Vet] Update Vet Success',
  UpdateVetFailedActionType = '[Vet] Update Vet Failed',

  // Update vet user
  UpdateVetUserActionType = '[Vet] Update VetUser',
  UpdateVetUserSuccessActionType = '[Vet] Update VetUser Success',
  UpdateVetUserFailedActionType = '[Vet] Update VetUser Failed',

  SetCurrentClinicIdActionType = '[Vet] Set Current Clinic Id',
}

/*** SET VET ***/

export class SetVet implements Action {
  public readonly type = VetActionTypes.SetVetActionType;
  constructor(public vet: Vet) {}
}

/*** UPDATE VET ***/

export class UpdateVet implements Action {
  public readonly type = VetActionTypes.UpdateVetActionType;
  constructor(public vet: Vet, public reload?: boolean) {}
}

export class UpdateVetSuccess implements Action {
  public readonly type = VetActionTypes.UpdateVetSuccessActionType;
  constructor(public vet: Vet) {}
}

export class UpdateVetFailed implements Action {
  readonly type = VetActionTypes.UpdateVetFailedActionType;
  constructor(public errorMessage: string) {}
}

/*** UPDATE VET USER ***/

export class UpdateVetUser implements Action {
  public readonly type = VetActionTypes.UpdateVetUserActionType;
  constructor(public oktaId: string, public vetUser: VetUser) {}
}

export class UpdateVetUserSuccess implements Action {
  public readonly type = VetActionTypes.UpdateVetUserSuccessActionType;
  constructor(public vetUser: VetUser) {}
}

export class UpdateVetUserFailed implements Action {
  readonly type = VetActionTypes.UpdateVetUserFailedActionType;
  constructor(public errorMessage: string) {}
}
/**
 * Set current clinic id
 */
export class SetCurrentClinicId implements Action {
  readonly type = VetActionTypes.SetCurrentClinicIdActionType;
  constructor(public clinicId: string) {}
}

export type VetActions =
  | SetVet
  | UpdateVet
  | UpdateVetSuccess
  | UpdateVetFailed
  | UpdateVetUser
  | UpdateVetUserSuccess
  | UpdateVetUserFailed
  | SetCurrentClinicId;
