export enum EventEnum {
  CONTACT_US = 'contactUs',
  CONTINUE_TO_PRODUCTS = 'continueToProducts',
  CONTINUE_TO_RATION = 'continueToRation',
  HOME_SEARCH_CLICK = 'homeSearchClick',
  LAUNCH_SMART_RECO = 'launchSmartReco',
  MODULE_CLICK = 'moduleClick',
  QUICK_LINK_CLICK = 'quickLinkClick',
  MOSAIC_CLICK = 'mosaicClick',
  NUTRITIONAL_RECOMMENDATION = 'nutritionalRecommendation',
  PRINT = 'print',
  PRODUCT_EDIT_PRICES = 'productEditPrices',
  PRODUCT_SAVE_PRICES = 'productSavePrices',
  PRODUCT_DETAILS_DISPLAYED = 'productDetailsDisplayed',
  SCREEN_DISPLAYED = 'screenDisplayed',
  MODAL_DISPLAYED = 'modalDisplayed',
  SEND_BY_EMAIL = 'sendByEmail',
  START_DETECTION = 'startDetection',
  PRODUCT_ADD_CLICK = 'productAddClick',
  CRITERIA_MODAL_DISPLAYED = 'criteriaModalDisplayed',
  PET_RECORD_SEARCH_CLICK = 'petRecordSearchClick',
  PRODUCT_SEARCH_CLICK = 'productSearchClick',
  WEIGHT_MANAGEMENT_FROM_TOOL = 'weightLossPlanRedirectionClick',
  TERRITORY_NAVIGATION_CLICK = 'territoryNavigationClick',
  RETRY_CLICK = 'retryClick',
  SEND_FEEDBACK_CLICK = 'sendFeedbackClick',
  PET_PANEL_CLICK = 'petPanelClick',
  WARNING_AUTOMATIC_RATIONING = 'warningAutomaticRationing',
  AUTOMATIC_RATIONING_CALCULATION = 'automaticRationingCalculation',
  RESET_DAILY_RATION = 'resetDailyRation',
  PATIENT_BLOCK_DISPLAY = 'patientBlockDisplay',
  WET_ALLOWANCE_SELECTION = 'RCGlobalVS1wetAllowanceSelection',
  DAILYALLOCATIONBUTTON = 'dailyAllocationButton',
  TOP_NAV_CLICK = 'topNavClick',
  PRODUCT_BOOK_BUTTON = 'ProductBookButton',
  SHARE_RECOMMENDATION_BUTTON = 'shareRecommendationButton',
  CONSULTATION_STARTED = 'consultationStarted',
  PET_IDENTIFIED = 'petIdentified',
  WEIGHT_LOSS_PLAN_BUTTON = 'weightLossPlanButton',
  PLAN_NEXT_VISIT_BUTTON = 'planNextVisitButton',
  CONSULTATION_NOT_SAVED_BUTTON = 'consultationNotSavedButton',
  ORDER_CONFIRMATION_BUTTON = 'orderConfirmationButton',
  CONTINUE_TO_INDIVIDUALIS_RECO = 'continueToIndividualisReco',
  INDIVIDUALIS_PRODUCT_SELECTION = 'individualisProductSelection',
  GROW_CHART_MODAL_DISPLAYED = 'growthChartModalDisplayed',
  GROW_CHART_MODAL_CLICK = 'growthChartModalClick',
  PRODUCT_IMPRESSION = 'productImpression',
  INDIVIDUALIS_ELIGIBILITY_DATA = 'individualisEligibilityData',
}

export enum LabelEnum {
  B2B_WEBSHOP = 'B2B Webshop',
  DAILY_ALLOWANCE = 'Daily Allowance',
  MULTIFUNCTION = 'Multifunction',
  PRODUCT_FINDER = 'Product Finder',
  SMART_RECOMMENDATION = 'Smart Recommendation',
  RENAL_DETECT = 'Renal detect',
  VET_FOCUS = 'Vet Focus',
  VET_PORTAL = 'Vet Portal',
  WEIGHT_MANAGEMENT = 'Weight Management',
  INDIVIDUALIS = 'Individualis',
  EDIT_BODY_WEIGHT = 'Edit body weight',
  EDIT_DIAGNOSIS = 'Edit diagnosis',
  EDIT_PET_DETAILS = 'Edit pet details',
  EDIT_GROWTH_CHART = 'Edit growth chart',
  GRID_VIEW = 'Grid view',
  LIST_VIEW = 'List view',
}

export enum ModuleNameEnum {
  DAILY_ALLOWANCE = 'Daily Allowance',
  MULTIFUNCTION = 'Multifunction',
  RENAL_DETECT = 'Renal Detect',
  SMART_RECOMMENDATION = 'Smart Recommendation',
  UNDEFINED = 'undefined',
  WEIGHT_MANAGEMENT = 'Weight Management',
}

export enum ModuleTypeEnum {
  CONSULTATION = 'Consultation',
  ORDER = 'Order',
}

export enum PageBlockEnum {
  ORDER_SUMMARY = 'Order Summary',
  NUTRITIONAL_ASSESSMENT = 'Nutritional assessment',
  GROWTH_CHART = 'Growth Chart',
  PATHOLOGIES = 'Disorders & Predispositions',
  IDENTIFICATION = 'Identification',
  PATIENT_PROFILE = 'Patient profile',
  WEIGHT_AND_ACTIVITY_LEVEL = 'Weight and activity level',
}

export enum PageTypeEnum {
  NUTRITIONAL_RECOMMENDATION = 'Nutritional Recommendation',
  ORDER_CONFIRMATION = ' Order Confirmation',
  PET_DETAILS = 'Pet Details',
  PET_HEALTH_TRACKING = 'Pet Health Tracking',
  PET_WEIGHT_MANAGEMENT = 'Pet Weight Management',
  PRODUCT_SELECTION = 'Product Selection',
  WELCOME_TOUR = 'Welcome Tour',
  CHECKOUT = 'Checkout',
  SHARE_RECOMMENDATION = 'Share recommendation',
  PLAN_NEXT_VISIT_PAGE = 'Plan next visit',
}
export enum StatusEnum {
  ERROR = 'Error',
  SUCCESS = 'Success',
}

export enum DailyAllocationButtonsActionGtm {
  EDIT_DAILY_ALLOCATION = 'Edit daily allocation',
  SHARE_RECOMMENDATION = 'Share recommendation',
  SAVE = 'Save',
  PURCHASE_PRODUCT = 'Purchase product',
  CONTINUE_TO_PLAN_NEXT_VISIT = 'Continue',
}

export enum TopNavLinkGtmEnum {
  PRICES = 'Prices',
}

export enum ProductBookButtonActionGtm {
  PURCHASE_PRODUCT = 'Purchase product',
}

export enum ShareRecommendationButtonActionGtm {
  PRINT = 'Print',
  SAVE_CONSULTATION = 'Save consultation',
  QRCODE = 'QR code',
  SEND = 'Send',
  SEE_PREVIEW = 'See preview',
  SEND_ORDER = 'Send order',
  VET_SOURCE = 'Vet Source',
}

export enum WeightLossPlanButtonActionGtm {
  CONTINUE = 'Continue',
  PLAN_NEXT_VISIT = 'Plan next visit',
  SHARE_WITHOUT_WLP = 'Share without weight loss plan',
}

export enum ConsultationNotSavedButtonActionGtm {
  SAVE_CONSULTATION = 'Save consultation',
  EXIT_WITHOUT_SAVING = 'Exit without saving',
  CLOSE = 'Close',
}

export enum OrderConfirmationButtonActionGtm {
  FOLLOW_ORDER = 'Follow order',
}
