import { ConsoleInstrumentation, ErrorsInstrumentation, PerformanceInstrumentation, SessionInstrumentation, ViewInstrumentation, WebVitalsInstrumentation } from '../instrumentations';
export function getWebInstrumentations(options = {}) {
  const instrumentations = [new ErrorsInstrumentation(), new WebVitalsInstrumentation(), new SessionInstrumentation(), new ViewInstrumentation()];
  if (options.enablePerformanceInstrumentation !== false) {
    // unshift to ensure that initialization starts before the other instrumentations
    instrumentations.unshift(new PerformanceInstrumentation());
  }
  if (options.captureConsole !== false) {
    instrumentations.push(new ConsoleInstrumentation({
      disabledLevels: options.captureConsoleDisabledLevels
    }));
  }
  return instrumentations;
}
